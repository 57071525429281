import React from 'react'
import styled from 'styled-components'
import MaterialBreadcrumbs from '@material-ui/core/Breadcrumbs'
import { Link } from 'react-router-dom'
import { BREADCRUMB_TEXT, BREADCRUMB_HOVER_TEXT } from '../../constants/colors'

const StyledBreadcrumbWrapper = styled(MaterialBreadcrumbs)({
  position: 'absolute',
  top: '7.6rem'
})

export const BreadcrumbWrapper = ({ children }) => {
  return <StyledBreadcrumbWrapper className='breadcrumbWrapper'>{children}</StyledBreadcrumbWrapper>
}

export const BreadcrumbLink = styled(Link)({
  fontFamily: 'Inter',
  fontWeight: 500,
  fontSize: '0.875rem',
  color: BREADCRUMB_TEXT,
  textDecoration: 'none',
  '&:hover': {
    color: BREADCRUMB_HOVER_TEXT,
    textDecoration: 'underline'
  }
})

export const BreadcrumbText = styled.div({
  fontFamily: 'Inter',
  fontWeight: 700,
  fontSize: '0.875rem',
  color: BREADCRUMB_TEXT
})
