import * as GRADE from 'constants/grades'
import { READING_READINESS_INFO, SINGLE_COL_PWR_INFO } from 'copy/info'
import { RISK_FLAG_INFO } from 'copy/riskFlagInfo'
import { PWR_INFO } from 'copy/pwrInfo'
import { PWR, DYSLEXIA, KREADY, SINGLE_COL_PWR } from 'constants/assessments'

export const getGradeKey = (classroomGrades) => {
  const hasKinder = classroomGrades.includes(GRADE.KINDERGARTEN)
  const hasFirst = classroomGrades.includes(GRADE.FIRST)
  const hasSecond = classroomGrades.includes(GRADE.SECOND)

  const targetGrades = [GRADE.KINDERGARTEN, GRADE.FIRST, GRADE.SECOND]
  const uniqueGrades = [...new Set(classroomGrades)]
  const filteredGrades = uniqueGrades.filter((grade) => targetGrades.includes(grade))

  if (filteredGrades.length > 1) {
    return GRADE.MULTI
  } else if (hasKinder) {
    return GRADE.KINDERGARTEN
  } else if (hasFirst) {
    return GRADE.FIRST
  } else if (hasSecond) {
    return GRADE.SECOND
  }

  return null
}

const getInfo = (infoObj, timePeriod, gradeKey) => {
  if (infoObj && infoObj[timePeriod] && infoObj[timePeriod][gradeKey]) {
    return infoObj[timePeriod][gradeKey]
  }

  // accounts for case that there is no info text for specified time period and grade
  return ''
}

export const getPredictiveProfileInfo = (predictiveProfilekey, classroomGrades, timePeriod) => {
  const gradeKey = getGradeKey(classroomGrades)
  switch (predictiveProfilekey) {
    case DYSLEXIA:
      return getInfo(RISK_FLAG_INFO, timePeriod, gradeKey)
    case PWR:
      return getInfo(PWR_INFO, timePeriod, gradeKey)
    case SINGLE_COL_PWR:
      return SINGLE_COL_PWR_INFO
    case KREADY:
      // reading readiness only relevant to PreK
      return getInfo(READING_READINESS_INFO, timePeriod, GRADE.PK)
  }
}
