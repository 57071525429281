import * as ROLE from 'constants/roles'
import * as COPY from 'constants/copy'
import { PK } from 'constants/grades'
import { DYSLEXIA, PWR, KREADY } from 'constants/assessments'
import { isAtRisk } from 'constants/thresholds'
import { RISK_CATEGORIES } from 'constants/singleColPwr'
import { logError } from 'sentry'

export const hasManagePermission = (role) => {
  return [ROLE.SUPER_ADMIN, ROLE.DISTRICT_ADMIN, ROLE.SCHOOL_ADMIN, ROLE.TEACHER].includes(role)
}

export const hasDeletePermission = (role) => {
  return [ROLE.SUPER_ADMIN, ROLE.DISTRICT_ADMIN, ROLE.SCHOOL_ADMIN].includes(role)
}

export const getStudentTeacher = (student) => {
  const hasClassroom = student.ParticipantGroup.length
  const teacherNames =
    hasClassroom &&
    // remove classrooms with an unassigned teacher
    student.ParticipantGroup.filter((classroom) => classroom.User[0])
      // iterate through filtered classrooms and then through User array for each classroom (a student can have multiple classrooms, and a classroom can have multiple teachers)
      .map((classroom) => classroom.User.map((teacher) => `${teacher?.firstName} ${teacher?.lastName}`))
      .flat()
      .join(', ')

  return teacherNames || COPY.NO_TEACHER_ASSIGNED
}

export const isStudentAtRisk = (score, grade, timePeriod) => {
  return score && isAtRisk(grade, DYSLEXIA, timePeriod, score)
}

export const isStudentReadingReady = (score, grade, timePeriod) => {
  return score && !isAtRisk(grade, PWR, timePeriod, score)
}

export const isPreKStudentReadingReady = (score, timePeriod) => {
  return score && !isAtRisk(PK, KREADY, timePeriod, score)
}

export const determineStudentSingleColPwrRiskCategory = (
  studentGrade,
  studentId,
  timePeriod,
  pwrScore,
  dyslexiaRiskScore
) => {
  if (pwrScore === null || dyslexiaRiskScore === null) {
    return null
  }

  const isReadingReady = isStudentReadingReady(pwrScore, studentGrade, timePeriod)
  const isAtDyslexiaRisk = isStudentAtRisk(dyslexiaRiskScore, studentGrade, timePeriod)

  if (!isReadingReady && isAtDyslexiaRisk) {
    return RISK_CATEGORIES.HIGH
  } else if (!isReadingReady && !isAtDyslexiaRisk) {
    return RISK_CATEGORIES.MODERATE
  } else if (isReadingReady && !isAtDyslexiaRisk) {
    return RISK_CATEGORIES.LOW
  } else if (isReadingReady && isAtDyslexiaRisk) {
    // this case should never happen - alert through Sentry if it does
    logError({
      error: `Invalid single column pwr state for student ${studentId}.`,
      breadcrumb: { data: { timePeriod: timePeriod, pwrScore: pwrScore, dyslexiaRiskScore: dyslexiaRiskScore } }
    })
    return RISK_CATEGORIES.MODERATE
  }
}
