import React from 'react'
import { BreadcrumbWrapper, BreadcrumbLink, BreadcrumbText } from '../../../../ui-components/breadcrumbs'
import * as ROLE from '../../../../constants/roles'

const Breadcrumbs = ({ classroom, role, userSchools }) => {
  const districtId = classroom.OrganizationUnit.Organization.organizationId
  const districtName = classroom.OrganizationUnit.Organization.name
  const schoolId = classroom.OrganizationUnit.organizationUnitId
  const schoolName = classroom.OrganizationUnit.name
  const isMultiSchoolUser = userSchools.length > 1
  if (role === ROLE.SUPER_ADMIN) {
    return (
      <BreadcrumbWrapper>
        <BreadcrumbLink to='/super-admin'>Districts</BreadcrumbLink>
        <BreadcrumbLink to={`/district/${districtId}`}>{districtName}</BreadcrumbLink>
        <BreadcrumbLink to={`/school/${schoolId}`}>{schoolName}</BreadcrumbLink>
        <BreadcrumbText>{classroom.name}</BreadcrumbText>
      </BreadcrumbWrapper>
    )
  } else if ([ROLE.DISTRICT_ADMIN, ROLE.SCHOOL_ADMIN] || isMultiSchoolUser) {
    return (
      <BreadcrumbWrapper>
        <BreadcrumbLink to={`/district/${districtId}`}>Schools</BreadcrumbLink>
        <BreadcrumbLink to={`/school/${schoolId}`}>{schoolName}</BreadcrumbLink>
        <BreadcrumbText>{classroom.name}</BreadcrumbText>
      </BreadcrumbWrapper>
    )
  } else {
    return (
      <BreadcrumbWrapper>
        <BreadcrumbLink to={`/school/${schoolId}`}>Classrooms</BreadcrumbLink>
        <BreadcrumbText>{classroom.name}</BreadcrumbText>
      </BreadcrumbWrapper>
    )
  }
}

export default Breadcrumbs
