import styled from 'styled-components'
import { AUTH_LINK_TEXT, AUTH_FOOTER_TEXT } from '../../constants/colors'

export const Footer = styled.div({
  fontFamily: 'Inter',
  fontSize: '1rem',
  fontWeight: 500,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  padding: 0,
  position: 'absolute',
  top: '52rem',
  color: AUTH_FOOTER_TEXT
})

export const PrivacyPolicy = styled.div({
  whiteSpace: 'pre-line',
  margin: '1rem'
})

export const PrivacyPolicyLink = styled.div({
  color: AUTH_LINK_TEXT,
  marginBottom: '0.5rem',
  textDecoration: 'underline',
  '&:hover': {
    cursor: 'pointer'
  }
})
