export const correctMarkSyles = { padding: '4px' }
export const incorrectMarkStyles = { backgroundColor: '#fdf4e0', border: 'solid 1px #f5b800', padding: '4px' }
export const nameHeaderStyle = { fontFamily: 'Quicksand', color: '#4e5d78', marginBottom: '5px' }
export const headerStyle = { fontFamily: 'Quicksand', color: '#4e5d78' }
export const headerStyle2 = { fontFamily: 'Quicksand', color: '#c1c7d0' }
export const headerStyle3 = { fontFamily: 'Quicksand', color: '#8a94a6' }

export const legendStyles = {
  width: '80px',
  height: '50px',
  display: 'flex',
  justifyContent: 'flex-start',
  gap: '10px',
  flexGrow: '0',
  margin: '16px 0 0 500px',
  padding: '22px 80px 17px 17px',
  borderRadius: '5px',
  border: 'solid 1px #c1c7d0',
  backgroundColor: '#ffffff'
}
export const legendMarkContainerStyles = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: '8px'
}
export const legendTextContainerStyles = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: '10px'
}
export const legendTextStyle = {
  fontFamily: 'Inter',
  fontSize: '12px',
  fontWeight: '500',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  textAlign: 'left',
  color: '#4e5d78'
}
export const ratioStyles = {
  display: 'flex',
  border: 'solid 1px #c1c7d0',
  borderRadius: '5px',
  fontFamily: 'Inter',
  color: '#8A94A6',
  padding: '10px 18px'
}

export const ratioTestNameStyles = {
  fontSize: '15px',
  fontWeight: 600,
  fontFamily: 'Quicksand',
  padding: '5px 0',
  color: '#4e5d78'
}

export const ratioContainerStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100px',
  padding: '0 0 0 10px'
}

export const ratioScoresContainerStyles = {
  display: 'flex',
  justifyContent: 'flex-end',
  marginBottom: '10px'
}

export const headerContentStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  padding: '10px'
}
