import React from 'react'
import { TableContainer, Table, TableRow, TableCell, TextField } from 'components/ItemInventoryV2/ui-components/tables'
import { CorrectIcon, IncorrectIcon } from 'components/ItemInventoryV2/ui-components/icons'

const tableCellContent = (item) => {
  if (item === 1) {
    return <CorrectIcon />
  }
  if (item === 0) {
    return <IncorrectIcon />
  }
  return null
}
const ReadOnlyInventoryTable = ({ data }) => {
  return (
    <TableContainer>
      <Table>
        <TableRow>
          <TableCell className='titleHeader'>
            <TextField className='titleText'>{data.headers[0]}</TextField>
          </TableCell>
          {data.headers.slice(1).map((item, colIdx) => {
            return (
              <TableCell key={colIdx} className='letterHeader'>
                <TextField className='letterText'>{item}</TextField>
              </TableCell>
            )
          })}
        </TableRow>
        {data.scores.map((row, rowIdx) => (
          <TableRow key={rowIdx}>
            <TableCell className='titleBody'>
              <TextField className='titleText'>{row[0]}</TextField>
            </TableCell>
            {row.slice(1).map((item, colIdx) => (
              <TableCell
                key={colIdx}
                className={`body ${item === 1 ? 'correct' : item === 0 ? 'incorrect' : 'disabled'}`}
              >
                {tableCellContent(item)}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </Table>
    </TableContainer>
  )
}

export default ReadOnlyInventoryTable
