import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import { Wrapper, Circle } from './styles'
import AddIcon from '@material-ui/icons/Add'

const ScoreEntryIcon = () => {
  return (
    <Wrapper>
      <Tooltip title={'Score Entry coming soon'}>
        <Circle>
          <AddIcon />
        </Circle>
      </Tooltip>
    </Wrapper>
  )
}

export default ScoreEntryIcon
