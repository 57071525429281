import * as GRADE from 'constants/grades'
import { BOY, MOY, EOY } from 'constants/timePeriods'

export const FIRST_GRADE_BOY = 'The predictive profiles will populate at MOY.'

const READINES_READINESS_SUMMARY =
  'Reading Readiness indicates the child demonstrates phonological awareness and oral language skills that are foundational in learning to read.'
const READING_READINESS_EXCLAMATION_POINT =
  'Exclamation point indicates the child has emerging readiness and is still developing early skills of phonological awareness and oral language.'

export const READING_READINESS_INFO = {
  [BOY]: {
    [GRADE.PK]: ''
  },
  [MOY]: {
    [GRADE.PK]: ''
  },
  [EOY]: {
    [GRADE.PK]:
      READINES_READINESS_SUMMARY +
      ' ' +
      'This flag has been driven by an algorithm reflective of the student scores in Vocabulary and First Sound Matching.' +
      '\n\n' +
      READING_READINESS_EXCLAMATION_POINT
  }
}

export const SINGLE_COL_PWR_INFO =
  'This percentage indicates the student’s likelihood of reaching grade-level expectations for reading words in isolation by the end of the year (EOY) without remediation, based on the student\'s likely performance on the KTEA-3 Letter & Word Recognition test at EOY. This percentage is determined by a predictive algorithm based on the student\'s score in​ Word Reading.\n\nA "low performance" prediction indicates potential for severe word reading difficulties at EOY, including risk for dyslexia. A "moderate performance" prediction indicates potential for some word reading difficulties at EOY, with likely below average word reading abilities.'
