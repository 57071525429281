import React, { useEffect, useState } from 'react'
import AssessmentGroupTable from './components/AssessmentGroupTable'
import { AssessmentGroupWrapper, TableWrapper, TableInnerWrapper } from './styles'
import { filterAssessmentGroups } from 'utils/assessments'

const StudentResultsTableSlim = ({
  student,
  studentResults,
  mostRecentRANScore,
  assmntConfig,
  timePeriodAssmntLists,
  currTimePeriod
}) => {
  const [assessmentGroups, setAssessmentGroups] = useState([])

  useEffect(() => {
    if (timePeriodAssmntLists) {
      // filters report to only display assessments the student has taken in the specified time period
      const assessments = timePeriodAssmntLists[currTimePeriod]
      const assessmentGroups = filterAssessmentGroups(assessments)
      setAssessmentGroups(assessmentGroups)
    }
  }, [timePeriodAssmntLists, currTimePeriod])

  return (
    <TableWrapper>
      <TableInnerWrapper>
        {assessmentGroups.map((assessmentGroup, index) => {
          // loop through the assessment groups, create a table for each group
          return (
            <AssessmentGroupWrapper key={assessmentGroup.title}>
              <AssessmentGroupTable
                student={student}
                studentResults={studentResults}
                mostRecentRANScore={mostRecentRANScore}
                assessmentGroup={assessmentGroup}
                currTimePeriod={currTimePeriod}
                timePeriodAssmntLists={timePeriodAssmntLists}
                assmntConfig={assmntConfig}
                showHeaderScale={index === 0}
              />
            </AssessmentGroupWrapper>
          )
        })}
      </TableInnerWrapper>
    </TableWrapper>
  )
}

export default StudentResultsTableSlim
