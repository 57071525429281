import styled from 'styled-components'
import MaterialTable from '@material-ui/core/Table'
import MaterialTableRow from '@material-ui/core/TableRow'
import MaterialTableCell from '@material-ui/core/TableCell'
import MaterialTableContainer from '@material-ui/core/TableContainer'
import {
  TABLE_BACKGROUND,
  TABLE_HEADER_TEXT,
  TABLE_BORDER,
  TABLE_ROW_HOVER,
  TABLE_TEXT_DEFAULT,
  TEST_RESULTS_TEXT
} from '../../constants/colors'

export const TableContainer = styled(MaterialTableContainer)({
  '&.MuiTableContainer-root': {
    borderRadius: '1rem'
  }
})

export const TableWrapper = styled(MaterialTable)({
  border: `1px solid ${TABLE_BORDER}`
})

export const TableRowHead = styled(MaterialTableRow)({
  '& .MuiTableCell-head': {
    fontSize: '0.75rem',
    fontFamily: 'Inter',
    fontWeight: 500,
    height: '3rem',
    paddingTop: 0,
    paddingBottom: 0,
    textTransform: 'uppercase',
    backgroundColor: TABLE_BACKGROUND,
    color: TABLE_HEADER_TEXT
  }
})

export const TableRowBody = styled(MaterialTableRow)({
  '&.MuiTableRow-root': {
    height: '4.25rem',
    backgroundColor: TABLE_BACKGROUND
  },
  '&:hover': {
    backgroundColor: TABLE_ROW_HOVER
  }
})

export const TableCell = styled(MaterialTableCell)`
  &.MuiTableCell-body {
    font-size: 0.875rem;
    font-family: Inter;
    font-weight: 600;
    color: ${(props) => (props.test === 'true' ? TEST_RESULTS_TEXT : TABLE_TEXT_DEFAULT)};
    padding-top: 0;
    padding-bottom: 0;
    max-width: 10rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`
