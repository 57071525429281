import styled from 'styled-components'
import React from 'react'
import { Link } from 'react-router-dom'
import { TABLE_TEXT_PRIORITY, LINK_HOVER } from '../constants/colors'

// ROUTER LINK
const InternalLink = styled(Link)({
  textDecoration: 'none',
  color: TABLE_TEXT_PRIORITY,
  '&:visited': {
    color: TABLE_TEXT_PRIORITY
  },
  '&:hover': {
    color: LINK_HOVER
  }
})

export const RouterLink = ({ cellData }) => {
  const { name, id, key } = cellData
  return <InternalLink to={`/${key}/${id}`}>{name}</InternalLink>
}

// WEB LINK
const ExternalLink = styled.a({
  textDecoration: 'none',
  color: 'inherit',
  '&:visited': {
    color: 'inherit'
  },
  '&:hover': {
    color: LINK_HOVER
  }
})

export const WebLink = ({ cellData }) => {
  if (cellData.website) {
    const website = cellData.website
    // should the text inside the a tag be the website address? name of district? "Link"?
    return <ExternalLink href={website}>{website}</ExternalLink>
  } else {
    return 'N/A'
  }
}

// PRIORITY TEXT
const Text = styled.div({
  color: TABLE_TEXT_PRIORITY
})

export const PriorityText = ({ cellData }) => {
  return <Text>{cellData}</Text>
}
