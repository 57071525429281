import styled from 'styled-components'
import MaterialDeleteIcon from '@material-ui/icons/Delete'
import { ICON_DEFAULT, ICON_HOVER, WARNING_ICON } from '../../constants/colors'
import MaterialWarningIcon from '@material-ui/icons/Warning'

export const Icon = styled(MaterialDeleteIcon)({
  color: ICON_DEFAULT,
  '&:hover': {
    color: ICON_HOVER
  }
})

export const WarningIcon = styled(MaterialWarningIcon)({
  color: WARNING_ICON,
  width: '2rem',
  height: '2rem',
  paddingRight: '.5rem'
})

export const WarningMessage = styled.p`
  display: flex;
  align-items: center;
`
