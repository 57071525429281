import React from 'react'
import { RiskFlagIcon, PWRIcon, ReadingReadinessIcon } from 'ui-components/icons'
import { IconWrapper, Icon } from './styles'
import { PWR, DYSLEXIA, KREADY, SINGLE_COL_PWR } from 'constants/assessments'

const PredictiveProfileToken = ({ tokenName }) => {
  const getToken = () => {
    switch (tokenName) {
      case DYSLEXIA:
        return <RiskFlagIcon />
      case PWR:
        return <PWRIcon />
      case SINGLE_COL_PWR:
        return <PWRIcon />
      case KREADY:
        return <ReadingReadinessIcon />
      default:
        return null
    }
  }

  return (
    <IconWrapper>
      <Icon>{getToken()}</Icon>
    </IconWrapper>
  )
}

export default PredictiveProfileToken
