import React, { useState } from 'react'
import StudentDialog from '../../../../components/StudentDialog'
import { EditIcon } from '../../../../ui-components/icons'
import { getFormattedDate } from '../../../../utils/dates'
import {
  StudentInfoWrapper,
  Header,
  EditWrapper,
  Row,
  Column,
  ParticipantCodeWrapper,
  ParticipantCode,
  ParticipantCodeTag
} from './styles'
import * as COPY from '../../copy'
import { getSelectedTags } from '../../../../utils/tags'

const StudentInfo = ({ student, role, onUpdate, onAlert }) => {
  const [studentDialog, setStudentDialog] = useState({ isOpen: false })

  const { firstName, lastName, dateOfBirth, grade, tags } = student
  const birthday = dateOfBirth ? getFormattedDate(dateOfBirth) : 'Not Provided'

  const hasClassroom = student.ParticipantGroup.length
  const teacherNames =
    hasClassroom &&
    student.ParticipantGroup.filter((classroom) => classroom.User[0]) // filter out classrooms with unassigned teacher
      .map((classroom) => classroom.User.map((teacher) => `${teacher?.firstName} ${teacher?.lastName}`))
      .flat()
      .join(', ')

  const teacher = teacherNames || COPY.NO_TEACHER_ASSIGNED
  let lastTest = null
  if (student.ParticipantTests.length !== 0) {
    lastTest = student.ParticipantTests.sort((b, a) => new Date(a.endDtTm) - new Date(b.endDtTm))[0].endDtTm
  }

  const dateOfLastTest = lastTest ? getFormattedDate(lastTest) : COPY.NO_INFO_TO_DISPLAY

  const getTagLabels = (tags) => {
    const tagsObj = getSelectedTags(tags)
    return tagsObj
      .map((tag) => {
        return tag.label
      })
      .join(', ')
  }

  const studentTags = tags ? getTagLabels(tags) : COPY.NO_INFO_TO_DISPLAY

  const onStudentUpdate = async () => {
    setStudentDialog({ isOpen: false })
    await onUpdate()
  }

  return (
    <StudentInfoWrapper>
      <Header>
        <h2>{`${firstName} ${lastName}`}</h2>
        <EditWrapper>
          <EditIcon onEdit={() => setStudentDialog({ isOpen: true })} />
        </EditWrapper>
      </Header>

      <Row>
        <Column className='first'>
          <p>
            Teacher: <b>{teacher}</b>
          </p>
          <p>
            Grade: <b>{grade || COPY.NO_INFO_TO_DISPLAY}</b>
          </p>
          <p>
            Birthday: <b>{birthday}</b>
          </p>
        </Column>
        <Column>
          <p>
            Last Test: <b>{dateOfLastTest}</b>
          </p>
          <p>
            Tags: <b>{studentTags}</b>
          </p>
          <p>
            Student ID: <b>{student.externalId ? student.externalId : 'Not Provided'}</b>
          </p>
        </Column>
      </Row>
      <ParticipantCodeWrapper>
        <ParticipantCodeTag>
          <p>Participant Code:</p>

          <p>
            <ParticipantCode>{student.participantCode}</ParticipantCode>
          </p>
        </ParticipantCodeTag>
      </ParticipantCodeWrapper>

      {studentDialog.isOpen && (
        <StudentDialog
          student={student}
          districtId={student.OrganizationUnit.Organization.organizationId}
          schoolId={student.OrganizationUnit.organizationUnitId}
          role={role}
          onAction={onStudentUpdate}
          onClose={() => setStudentDialog({ isOpen: false })}
          onAlert={onAlert}
        />
      )}
    </StudentInfoWrapper>
  )
}

export default StudentInfo
