import styled from 'styled-components'
import { LIGHT_BACKGROUND } from '../../../../constants/colors'

export const LogoContainer = styled.div`
  background-color: ${LIGHT_BACKGROUND};
  padding-left: 2rem;
`

export const LogoImg = styled.img`
  width: 10.5rem;
  height: 2.93rem;
`
