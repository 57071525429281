import React, { useState, useEffect, useCallback } from 'react'
import { getUsersByDistrict } from '../../../../lib/api'
import * as COPY from '../../copy'
import Table from '../../../../components/Table'
import { createUserRowData, getUserColumns, hasManagePermission } from './helper'
import DistrictUserDialog from '../../../../components/DistrictUserDialog'
import { TableWrapper, CreateButton } from '../../../../ui-components/tabs'
import { EmptyStateWrapper } from '../../../../ui-components/viewStyles'
import { alphanumericSort } from '../../../../utils/sorts'

const UserTab = ({ district, onAlert, role }) => {
  const [users, setUsers] = useState([])
  const [userDialog, setUserDialog] = useState({ isOpen: false, user: null })
  const [loading, setLoading] = useState({ isComplete: false, hasError: false })

  const loadUsers = useCallback(async () => {
    try {
      const districtUsers = await getUsersByDistrict(district.organizationId)
      setUsers(districtUsers)
      setLoading({ isComplete: true, hasError: false })
    } catch (err) {
      setLoading({ isComplete: true, hasError: true })
      onAlert('error', COPY.DISTRICT_ADMIN_LOAD_ERROR)
    }
  }, [onAlert, district.organizationId])

  useEffect(() => {
    if (loading.hasError) return

    loadUsers()
  }, [loadUsers, loading.hasError])

  const onEdit = (user) => {
    setUserDialog({ isOpen: true, user: user })
  }

  let userRows = users.map((user) => createUserRowData(user, role, onEdit, onAlert))

  userRows = alphanumericSort(userRows, 'user')

  const userColumns = getUserColumns(role)

  const onUserCreateOrUpdate = async () => {
    setUserDialog({ isOpen: false, user: null })
    await loadUsers()
  }

  return (
    <div>
      {userDialog.isOpen && (
        <DistrictUserDialog
          user={userDialog.user}
          districtId={district.organizationId}
          onAction={onUserCreateOrUpdate}
          onClose={() => setUserDialog({ isOpen: false, user: null })}
          onAlert={onAlert}
        />
      )}
      <TableWrapper>
        {hasManagePermission(role) && (
          <CreateButton
            id='createUserButton'
            onClick={() => setUserDialog({ isOpen: true, user: null })}
            text='Create User'
          />
        )}
        {loading.isComplete && (
          <div>
            {users.length ? (
              <Table rows={userRows} columns={userColumns} />
            ) : (
              <EmptyStateWrapper>{COPY.EMPTY_STATE_DISTRICT_USERS}</EmptyStateWrapper>
            )}
          </div>
        )}
      </TableWrapper>
    </div>
  )
}

export default UserTab
