import styled from 'styled-components'

export const Wrapper = styled.div`
  gap: 10px;
  display: flex;
  flex-direction: column;
`
export const Percentage = styled.div`
  font-size: 1rem;
`

export const LabelPercentage = styled.div`
  font-size: 0.875rem;
  line-height: 10px;
`

export const Label = styled.div`
  font-size: 0.75rem;
  line-height: 10px;
`
