import {
  NotApplicableIcon,
  AtRiskIcon,
  HighRiskIcon,
  NoRiskIcon,
  PWRPercentageIcon,
  NotAvailableIcon,
  SingleColPWRLowRiskIcon,
  SingleColPWRModRiskIcon,
  SingleColPWRHighRiskIcon,
  DyslexiaMessageIcon
} from './styles'
import * as COPY from './copy'
import {
  IncompleteIcon,
  NeedsScoringIcon,
  RiskFlagIcon,
  PWRIcon,
  ReadingReadinessIcon,
  CheckMarkIcon,
  OnTrackIcon,
  NotYetIcon
} from 'ui-components/icons'
import * as RULES from 'constants/displayRules'
import { DYSLEXIA, PWR, KREADY, SINGLE_COL_PWR } from 'constants/assessments'
import { getThreshold } from 'constants/thresholds'
import { GRADE_ORDER, DISPLAY_GRADES, FIRST, SECOND, PK, KINDERGARTEN } from 'constants/grades'
import { timePeriodsToDate } from 'constants/timePeriods'
import { SINGLE_COL_PWR_CUTOFF_TEXT, pwrTextByRisk } from 'constants/singleColPwr'
import { probabilisticPercentage } from 'utils/formatting'

const addPWRPercentage = (pwrKey, grade, isMultiGrade, timePeriod) => {
  // if no threshold set, display empty string
  const validThreshold = getThreshold(grade, PWR, timePeriod)?.value
  pwrKey.push({
    title: isMultiGrade ? DISPLAY_GRADES[grade] : null,
    IconComponent: PWRPercentageIcon,
    text: validThreshold ? `Below ${probabilisticPercentage(validThreshold)}` : ''
  })
}

const addPWRValue = (rule, riskPredictionKey, pwrKey, grade, isMultiGrade, timePeriod) => {
  if (rule === RULES.ALG_ICON) {
    riskPredictionKey.push({ IconComponent: PWRIcon, text: 'Below expectations' })
  }
  if (rule === RULES.ALG_PERCENTAGE) {
    addPWRPercentage(pwrKey, grade, isMultiGrade, timePeriod)
  }
}

export const setPWRKey = (riskPredictionKey, pwrKey, grades, timePeriod, isClassroomView) => {
  if (isClassroomView) {
    const isMultiGrade = grades.length > 1
    if (isMultiGrade) {
      // for multigrade classroom, get display rules for every grade in the classroom
      GRADE_ORDER.forEach((grade) => {
        if (grades.includes(grade)) {
          const displayRule = RULES.DISPLAY_RULES[grade][PWR][timePeriod]
          addPWRValue(displayRule, riskPredictionKey, pwrKey, grade, isMultiGrade, timePeriod)
        }
      })
    } else {
      // only one grade in classroom
      const grade = grades[0]
      const displayRule = RULES.DISPLAY_RULES[grade][PWR][timePeriod]
      addPWRValue(displayRule, riskPredictionKey, pwrKey, grade, false, timePeriod)
    }
  } else {
    // Student View
    // student can only have 1 grade
    const studentGrade = grades[0]
    // need to take into account all time periods to date
    // reversing array ensures that the key uses data from the most recent time period if multiple time periods are the same display type
    // ex. if multiple time periods are algorithm_percentage, the threshold associated with the most recent time period will be displayed
    const rules = []
    timePeriodsToDate[timePeriod].forEach((period) => {
      const displayRule = RULES.DISPLAY_RULES[studentGrade][PWR][period]
      if (displayRule !== null && !rules.includes(displayRule)) {
        rules.push(displayRule)
        addPWRValue(displayRule, riskPredictionKey, pwrKey, studentGrade, false, period)
      }
    })

    addPWRValue(riskPredictionKey, pwrKey, rules, studentGrade, false, timePeriod)
  }
}

const addSingleColPWRValue = (singleColPwrKey, grades, timePeriod, isMultiGrade) => {
  grades.forEach((grade) => {
    const subColumn = [
      {
        title: isMultiGrade ? DISPLAY_GRADES[grade] : null,
        IconComponent: SingleColPWRHighRiskIcon,
        categoryText: pwrTextByRisk.HIGH,
        text: SINGLE_COL_PWR_CUTOFF_TEXT[grade][timePeriod].HIGH
      },
      {
        IconComponent: SingleColPWRModRiskIcon,
        categoryText: pwrTextByRisk.MODERATE,
        text: SINGLE_COL_PWR_CUTOFF_TEXT[grade][timePeriod].MODERATE
      },
      {
        IconComponent: SingleColPWRLowRiskIcon,
        categoryText: pwrTextByRisk.LOW,
        text: SINGLE_COL_PWR_CUTOFF_TEXT[grade][timePeriod].LOW
      }
    ]

    if (grades.includes(SECOND) && grades.includes(FIRST)) {
      singleColPwrKey.push(subColumn)
    } else {
      singleColPwrKey.push(...subColumn)
    }
  })
}

export const setSingleColPWRKey = (singleColPwrKey, grades, timePeriod, isClassroomView) => {
  const isMultiGrade = grades.length > 1
  if (isClassroomView && isMultiGrade) {
    const applicableGrades = []
    GRADE_ORDER.forEach((grade) => {
      if (grades.includes(grade)) {
        const displayRule = RULES.DISPLAY_RULES[grade][SINGLE_COL_PWR][timePeriod]
        if (displayRule === RULES.ALG_PERCENTAGE) {
          applicableGrades.push(grade)
        }
      }
    })
    addSingleColPWRValue(singleColPwrKey, applicableGrades, timePeriod, isMultiGrade)
  } else {
    // only one grade in classroom or student view
    addSingleColPWRValue(singleColPwrKey, grades, timePeriod, false)
  }
}

export const getPredictiveProfileKey = (predictiveProfile, grades, timePeriod, isClassroomView) => {
  // the predictive profile key won't be displayed if there are no risk scores calculated for the specified grades and time period
  if (!predictiveProfile.length) return null

  const riskPredictionKey = []
  const pwrKey = []
  const readingReadinessKey = []
  const singleColPwrKey = []
  const hasGrade1andGrade2 = grades.includes(FIRST) && grades.includes(SECOND)

  predictiveProfile.forEach((key) => {
    switch (key) {
      case DYSLEXIA:
        riskPredictionKey.push({
          IconComponent: RiskFlagIcon,
          text: 'At risk for dyslexia'
        })
        break
      case PWR: {
        setPWRKey(riskPredictionKey, pwrKey, grades, timePeriod, isClassroomView)
        break
      }
      case KREADY:
        readingReadinessKey.push({ IconComponent: ReadingReadinessIcon, text: 'Emerging' })
        break
      case SINGLE_COL_PWR: {
        setSingleColPWRKey(singleColPwrKey, grades, timePeriod, isClassroomView)
        break
      }
    }
  })

  const hasSingleColPwrOnly = Boolean(
    singleColPwrKey.length && !riskPredictionKey.length && !readingReadinessKey.length && !pwrKey.length
  )

  const columns = []
  if (riskPredictionKey.length) {
    riskPredictionKey.push({ IconComponent: CheckMarkIcon, text: 'No risk detected' })
    columns.push({ title: 'Risk Prediction', rows: riskPredictionKey })
  }
  if (readingReadinessKey.length) {
    readingReadinessKey.push({ IconComponent: CheckMarkIcon, text: 'Ready' })
    columns.push({ title: 'Reading Readiness', rows: readingReadinessKey })
  }
  if (pwrKey.length) {
    columns.push({ title: 'Potential for Word Reading', subColumns: pwrKey })
  }
  if (singleColPwrKey.length) {
    if (isClassroomView) {
      singleColPwrKey.push({
        IconComponent: DyslexiaMessageIcon,
        iconText: {
          triggerText: 'What happened to the dyslexia flag?',
          info: COPY.DYSLEXIA_REMOVAL_TEXT
        }
      })
    }
    // if single col pwr is multi grade, then the icons will be rendered in subcolumns that
    // contain rows (multiGradePWRSubCols). Otherwise the icons will be rendered as rows
    columns.push({
      title: hasSingleColPwrOnly ? '' : 'Potential for Word Reading',
      multiGradePWRSubCols: hasGrade1andGrade2 ? singleColPwrKey : null,
      rows: hasGrade1andGrade2 ? null : singleColPwrKey
    })
  }

  return {
    title: hasSingleColPwrOnly ? 'Potential for Word Reading' : 'Predictive Profile Key',
    columns
  }
}

const shouldDisplayColumnSubtitle = (grades, isMultiGrade) => {
  if (isMultiGrade) {
    const hasPKorK = grades.some((grade) => grade === PK || grade === KINDERGARTEN)
    const has1or2 = grades.some((grade) => grade === FIRST || grade === SECOND)
    return hasPKorK || !has1or2
  } else {
    return ![FIRST, SECOND].includes(grades[0])
  }
}

export const getStatusKey = (grades, isClassroomView) => {
  const rows = [
    { IconComponent: IncompleteIcon, text: 'Test is incomplete' },
    { IconComponent: NeedsScoringIcon, text: 'Test needs scoring' }
  ]

  const isMultiGrade = grades.length > 1
  const isStudentView = !isClassroomView
  if (isMultiGrade || isStudentView) {
    rows.push({ IconComponent: NotApplicableIcon, text: 'Test is not applicable at this time' })
  }

  rows.push({
    IconComponent: NotAvailableIcon,
    text: 'Test score not available as student did not pass the practice',
    iconText: 'N/A'
  })

  const displayColSubTitle = shouldDisplayColumnSubtitle(grades, isMultiGrade)

  if (!displayColSubTitle) {
    return { title: 'Status', columns: [{ rows }] }
  }
  return { title: 'Status Key', columns: [{ title: 'Completion', rows }] }
}

export const getScoringKey = (scoringUnits, grades) => {
  // ensures that key elements are rendered in expected order
  const scoringKeyOrder = [RULES.PERCENTILE, RULES.PROFICIENCY]
  const isMultiGrade = grades.length > 1
  const displayColSubTitle = shouldDisplayColumnSubtitle(grades, isMultiGrade)
  const columns = []
  scoringKeyOrder.forEach((unit) => {
    if (scoringUnits.includes(unit)) {
      switch (unit) {
        case RULES.PROFICIENCY:
          columns.push({
            title: 'Proficiency',
            info: COPY.PROFICIENCY_SUMMARY,
            rows: [
              { IconComponent: OnTrackIcon, text: 'On Track' },
              { IconComponent: NotYetIcon, text: 'Not Yet' }
            ]
          })
          break
        case RULES.PERCENTILE:
          columns.push({
            title: displayColSubTitle ? 'Nationally Normed Percentiles' : null,
            info: displayColSubTitle ? COPY.PERCENTILE_SUMMARY : null,
            rows: [
              { IconComponent: HighRiskIcon, categoryText: 'Well Below Benchmark', text: 'At/Below 20th %ile' },
              { IconComponent: AtRiskIcon, categoryText: 'Below Benchmark', text: 'At/Below 40th %ile' },
              { IconComponent: NoRiskIcon, categoryText: 'At/Above Benchmark', text: 'At/Above 40th %ile' }
            ]
          })
      }
    }
  })

  if (columns.length) {
    if (!displayColSubTitle) {
      return { title: 'Nationally Normed Percentiles', info: COPY.PERCENTILE_SUMMARY, columns }
    } else {
      return { title: 'Scoring Key', columns }
    }
  }
  return null
}
