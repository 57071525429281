import React from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import {
  AUTH_BACKGROUND,
  AUTH_MODAL_BACKGROUND,
  AUTH_LINK_TEXT,
  AUTH_HEADER,
  AUTH_SUBHEADER,
  ERROR_BACKGROUND,
  ERROR_TEXT,
  AUTH_TEXT
} from '../constants/colors'
import { EB_LOGO } from '../constants/assets'
import { Link } from 'react-router-dom'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'

export const AuthBackground = createGlobalStyle({
  body: {
    backgroundColor: AUTH_BACKGROUND
  }
})

export const BackArrow = styled(ArrowBackIosIcon)`
  color: ${AUTH_TEXT};

  &.MuiSvgIcon-root {
    height: 0.8rem;
    width: 0.8rem;
  }
`

export const ContentWrapper = styled.div({
  display: 'flex',
  height: '100%',
  width: '100%',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: AUTH_BACKGROUND
})

export const FormWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '21.125rem',
  paddingTop: '3.4rem',
  paddingBottom: '3.4rem',
  paddingRight: '4rem',
  paddingLeft: '4rem',
  backgroundColor: AUTH_MODAL_BACKGROUND,
  borderRadius: '1rem'
})

export const InputWrapper = styled.div({
  marginBottom: '1rem',
  width: '100%'
})

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 30%;
`
export const SSOButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 0.75rem;
  column-gap: 0rem;
  justify-items: center;
  align-items: center;
  width: 24.125rem;
  margin-top: 1.5rem;
`

export const PageLink = styled(Link)({
  fontFamily: 'Inter',
  fontWeight: 500,
  fontSize: '1rem',
  color: AUTH_LINK_TEXT,
  textDecoration: 'underline'
})

export const SmallError = styled.small`
  padding-bottom: 3px;
  margin-bottom: 5px;
  padding-top: 3px;
  border-radius: 5px;
  text-align: center;
  color: ${ERROR_TEXT};
  background-color: ${ERROR_BACKGROUND};
`

export const AuthHeader = styled.div({
  fontFamily: 'Quicksand',
  fontWeight: 600,
  fontSize: '2rem',
  color: AUTH_HEADER,

  '&.reset': {
    marginBottom: '2.5rem'
  }
})

export const AuthSubHeader = styled.div({
  fontFamily: 'Inter',
  fontWeight: 500,
  fontSize: '1rem',
  color: AUTH_SUBHEADER,
  textAlign: 'center'
})

export const StyledLogo = styled.img({
  width: '14rem',
  height: '3.9rem',
  marginBottom: '2rem'
})

export const AuthLogo = () => {
  return <StyledLogo src={EB_LOGO} id='earlybirdLogo' />
}
