import styled from 'styled-components'
import MaterialDialog from '@material-ui/core/Dialog'
import MaterialCloseIcon from '@material-ui/icons/Close'
import { RAN_HEADER_TEXT, DIALOG_WRAPPER_BG, RAN_DIALOG_BG } from '../../constants/colors'

export const Image = styled.img`
  height: auto;
  width: 100%;
`
export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem;
`
export const SubHeader = styled.h2`
  font-family: Quicksand;
  font-size: 1rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.74;
  letter-spacing: normal;
  text-align: left;
  color: ${RAN_HEADER_TEXT};
`
export const Header = styled.h1`
  font-family: Quicksand;
  font-size: 1.5rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: ${RAN_HEADER_TEXT};
`
export const DialogWrapper = styled.div`
  flex-grow: 0;
  padding: 1.125rem 1.188rem 2.188rem 1.063rem;
  border-radius: 0.938rem;
  box-shadow: 0 1.438rem 2.75rem 0 rgba(176, 183, 195, 0.14);
  background-color: ${DIALOG_WRAPPER_BG};
`

export const ContentWrapper = styled.div`
  margin: 5px 0 0;
  padding: 2.056rem 1.625rem 1.5rem 1.375rem;
  border-radius: 0.938rem;
  box-shadow: 0 1.438rem 2.75rem 0 rgba(176, 183, 195, 0.14);
  background-color: ${RAN_DIALOG_BG};
`
export const Dialog = styled(MaterialDialog)`
  & .MuiDialog-paper {
    border-radius: 0.938rem;
  }

  & .MuiDialog-paperWidthSm {
    max-width: 500px;
  }
`
export const CloseWrapper = styled.div({
  color: RAN_HEADER_TEXT,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-end',
  marginBottom: '0.5rem',
  textDecoration: 'none',
  '&:hover': {
    cursor: 'pointer'
  }
})

export const CloseIcon = styled(MaterialCloseIcon)({
  marginLeft: '.2rem',
  width: '1.4rem',
  height: '1.4rem'
})
