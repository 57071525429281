import React from 'react'
import { TableCell } from '../../styles'
import { InternalLink, LinkWrapper } from './styles'

const StudentNameCell = ({ cellData, cellClasses }) => {
  const { lastName, firstName, middleName, participantId, highlightedStudentId } = cellData

  return (
    <TableCell className={`${cellClasses} studentNameCell ${highlightedStudentId === participantId && 'highlight'}`}>
      <LinkWrapper>
        <InternalLink to={`/student/${participantId}`}>{`${lastName}, ${
          middleName ? `${firstName} ${middleName}` : firstName
        }`}</InternalLink>
      </LinkWrapper>
    </TableCell>
  )
}

export default StudentNameCell
