import styled from 'styled-components'
import * as COLOR from '../../../../constants/colors'

export const StudentInfoWrapper = styled.div`
  p {
    font-family: Inter;
    color: ${COLOR.STUDENT_VIEW_LIGHT_TEXT};
    font-size: 0.8rem;
    b {
      color: ${COLOR.STUDENT_VIEW_DARK_TEXT};
    }
  }
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
