import React from 'react'
import { BreadcrumbWrapper, BreadcrumbLink, BreadcrumbText } from '../../../../ui-components/breadcrumbs'
import * as ROLE from '../../../../constants/roles'

const Breadcrumbs = ({ school, role, userSchools }) => {
  const districtId = school.Organization.organizationId
  const districtName = school.Organization.name
  const isMultiSchoolUser = userSchools.length > 1
  if (role === ROLE.SUPER_ADMIN) {
    return (
      <BreadcrumbWrapper>
        <BreadcrumbLink to='/super-admin'>Districts</BreadcrumbLink>
        <BreadcrumbLink to={`/district/${districtId}`}>{districtName}</BreadcrumbLink>
        <BreadcrumbText>{school.name}</BreadcrumbText>
      </BreadcrumbWrapper>
    )
  } else if ([ROLE.DISTRICT_ADMIN, ROLE.SCHOOL_ADMIN].includes(role) || isMultiSchoolUser) {
    return (
      <BreadcrumbWrapper>
        <BreadcrumbLink to={`/district/${districtId}`}>Schools</BreadcrumbLink>
        <BreadcrumbText>{school.name}</BreadcrumbText>
      </BreadcrumbWrapper>
    )
  } else {
    return null
  }
}

export default Breadcrumbs
