import React from 'react'
import { BreadcrumbWrapper, BreadcrumbLink, BreadcrumbText } from '../../../../ui-components/breadcrumbs'
import * as ROLE from '../../../../constants/roles'

const Breadcrumbs = ({ student, role, userSchools }) => {
  const districtId = student.OrganizationUnit.Organization.organizationId
  const districtName = student.OrganizationUnit.Organization.name
  const schoolId = student.OrganizationUnit.organizationUnitId
  const schoolName = student.OrganizationUnit.name
  const classroomId = student.ParticipantGroup[0] ? student.ParticipantGroup[0].participantGroupId : null
  const classroomName = student.ParticipantGroup[0] ? student.ParticipantGroup[0].name : null
  const isMultiClassroomParticipant = student.ParticipantGroup.length > 1
  const isMultiSchoolUser = userSchools.length > 1
  if (role === ROLE.SUPER_ADMIN) {
    return (
      <BreadcrumbWrapper>
        <BreadcrumbLink to='/super-admin'>Districts</BreadcrumbLink>
        <BreadcrumbLink to={`/district/${districtId}`}>{districtName}</BreadcrumbLink>
        <BreadcrumbLink to={`/school/${schoolId}`}>{schoolName}</BreadcrumbLink>
        {classroomId && !isMultiClassroomParticipant && (
          <BreadcrumbLink to={`/classroom/${classroomId}`}>{classroomName}</BreadcrumbLink>
        )}
        <BreadcrumbText>{`${student.firstName} ${student.lastName}`}</BreadcrumbText>
      </BreadcrumbWrapper>
    )
  } else if ([ROLE.DISTRICT_ADMIN, ROLE.SCHOOL_ADMIN].includes(role) || isMultiSchoolUser) {
    return (
      <BreadcrumbWrapper>
        <BreadcrumbLink to={`/district/${districtId}`}>Schools</BreadcrumbLink>
        <BreadcrumbLink to={`/school/${schoolId}`}>{schoolName}</BreadcrumbLink>
        {classroomId && !isMultiClassroomParticipant && (
          <BreadcrumbLink to={`/classroom/${classroomId}`}>{classroomName}</BreadcrumbLink>
        )}
        <BreadcrumbText>{`${student.firstName} ${student.lastName}`}</BreadcrumbText>
      </BreadcrumbWrapper>
    )
  } else {
    return (
      <BreadcrumbWrapper>
        <BreadcrumbLink to={`/school/${schoolId}`}>Classrooms</BreadcrumbLink>
        {classroomId && !isMultiClassroomParticipant && (
          <BreadcrumbLink to={`/classroom/${classroomId}`}>{classroomName}</BreadcrumbLink>
        )}
        <BreadcrumbText>{`${student.firstName} ${student.lastName}`}</BreadcrumbText>
      </BreadcrumbWrapper>
    )
  }
}
export default Breadcrumbs
