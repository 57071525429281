// @ts-check
import * as React from 'react'
import { CoverLetterContainer, LogoContainer, LogoImg } from './styles'
import { EB_LOGO } from 'constants/assets'
import KindergartenCopy from './components/KindergartenCopy'
import PreKCopy from './components/PreKCopy'
import FirstGradeCopy from './components/FirstGradeCopy'
import SecondGradeCopy from './components/SecondGradeCopy'
import KindergartenCopyV2 from './components/KindergartenCopyV2'
import PreKCopyV2 from './components/PreKCopyV2'
import FirstGradeCopyV2 from './components/FirstGradeCopyV2'
import SecondGradeCopyV2 from './components/SecondGradeCopyV2'
import { useFeatureFlagEnabled } from 'posthog-js/react'

const MainCopy = ({ variant, timePeriod }) => {
  const rerGrade1Enabled = useFeatureFlagEnabled('rer-grade-1')
  switch (variant) {
    case 'K':
      return rerGrade1Enabled ? (
        <KindergartenCopyV2 timePeriod={timePeriod} />
      ) : (
        <KindergartenCopy timePeriod={timePeriod} />
      )
    case 'PK':
      return rerGrade1Enabled ? <PreKCopyV2 timePeriod={timePeriod} /> : <PreKCopy timePeriod={timePeriod} />
    case '1':
      return rerGrade1Enabled ? (
        <FirstGradeCopyV2 timePeriod={timePeriod} />
      ) : (
        <FirstGradeCopy timePeriod={timePeriod} />
      )
    case '2':
      return rerGrade1Enabled ? (
        <SecondGradeCopyV2 timePeriod={timePeriod} />
      ) : (
        <SecondGradeCopy timePeriod={timePeriod} />
      )
    default:
      return null
  }
}

/**
 * @param {object} props
 * @param {'K'|'PK'|'1'|'2'} props.variant - The variant of the coverletter copy to use
 * @param {'BOY'|'MOY'|'EOY'} props.timePeriod - the time period code for the requested coverletter
 * @returns coverletter markup for current family report, with copy based on supplied variant and timePeriod
 */
const CoverLetter = ({ variant, timePeriod }) => (
  <CoverLetterContainer>
    <div>
      <LogoContainer>
        <LogoImg src={EB_LOGO} />
      </LogoContainer>
      <h1>EarlyBird Education</h1>
      <h2>Early Literacy Assessment</h2>
    </div>
    <p>Date: {new Date().toLocaleDateString()}</p>
    <MainCopy variant={variant} timePeriod={timePeriod} />
  </CoverLetterContainer>
)

export default CoverLetter
