import React, { useState } from 'react'
import { TableCell, Popover, PopoverWrapper, PopoverHeader, PopoverTestName, PopoverItem, PopoverBody } from './styles'
import { CorrectIcon, IncorrectIcon } from 'components/ItemInventoryV2/ui-components/icons'
import { LETTER_SOUNDS_ID, LETTER_NAMES_ID } from 'constants/assessments'

const EditableScoreCell = ({ testId, item, currentScore, originalScore, onClick }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const isScoreEdited = currentScore !== originalScore
  const isScoreCorrect = currentScore === 1

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      {currentScore === undefined ? (
        <TableCell className='disabled'>
          <IncorrectIcon />
        </TableCell>
      ) : (
        <>
          <TableCell
            className={`body ${isScoreEdited && 'edited'} ${isScoreCorrect ? 'correct' : 'incorrect'}`}
            onClick={() => onClick(item)}
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            data-testid={`${isScoreCorrect ? 'correct' : 'incorrect'}${isScoreEdited ? '-edited' : ''}-cell`}
          >
            {isScoreCorrect ? <CorrectIcon /> : <IncorrectIcon />}
          </TableCell>
          <Popover
            sx={{
              pointerEvents: 'none',
              transform: 'translate(0px, 10px)'
            }}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
            onClose={handlePopoverClose}
          >
            <PopoverWrapper>
              <PopoverHeader>
                <PopoverTestName>{testId === LETTER_SOUNDS_ID && 'Letter Sound:'}</PopoverTestName>
                <PopoverTestName>{testId === LETTER_NAMES_ID && 'Letter Name:'}</PopoverTestName>
                <PopoverItem>{item}</PopoverItem>
              </PopoverHeader>
              <PopoverBody>Click on the score to change it to {isScoreCorrect ? 'incorrect' : 'correct'}</PopoverBody>
            </PopoverWrapper>
          </Popover>
        </>
      )}
    </>
  )
}

export default EditableScoreCell
