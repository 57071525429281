import styled from 'styled-components'
import { HAMBURGER_ICON, MENU_TEXT, APPBAR_COLOR } from '../../constants/colors'

export const Navigation = styled.nav`
  display: flex;
  flex-flow: row nonwrap;
  justify-content: flex-start;
  align-items: center;
  width: 91%;
  background: ${APPBAR_COLOR};
  color: ${HAMBURGER_ICON};
  z-index: 2;
  position: absolute;
  font-family: quicksand;
  font-weight: 600;
  height: 6.25rem;

  .avatar {
    margin-left: auto;
  }
`

export const HamburgerIcon = styled.button`
  top: 5%;
  left: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  color: ${MENU_TEXT};
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;

  &:focus {
    outline: none;
  }
`
