export const getFormattedDate = (dateString) => {
  const date = new Date(dateString)

  const utcMonth = date.getUTCMonth() + 1
  const month = utcMonth < 10 ? `0${utcMonth}` : utcMonth

  const utcDay = date.getUTCDate()
  const day = utcDay < 10 ? `0${utcDay}` : utcDay

  const year = date.getUTCFullYear()
  return month + '/' + day + '/' + year
}
