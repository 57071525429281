import styled from 'styled-components'

export const StyledLink = styled.a`
  padding: 0;
  border: 0;
  display: flex;
  justify-content: center;
  width: 11.4rem;
  cursor: pointer;
  background: none;

  img {
    width: 100%;
  }
`
