import React from 'react'
import EditableScoreCell from '../EditableScoreCell'
import Legend from '../Legend'
import { ITEM_INVENTORY_ALPHABETICAL_ORDER } from 'components/ItemInventoryV2/constants'
import { TableContainer, Table, TableRow, TableCell, TextField } from 'components/ItemInventoryV2/ui-components/tables'
import { LETTER_SOUNDS_ID } from 'constants/assessments'
import { getQuestionCode } from 'components/ItemInventoryV2/utils'

const EditableInventoryTable = ({ testId, editedScores, originalScores, onScoreClick }) => {
  return (
    <>
      <TableContainer>
        <Table>
          <TableRow>
            <TableCell className='titleHeader'>
              <TextField className='titleText'>Letter</TextField>
            </TableCell>
            {ITEM_INVENTORY_ALPHABETICAL_ORDER.map((item, colIdx) => {
              return (
                <TableCell key={colIdx} className='letterHeader'>
                  <TextField className='letterText'>{item}</TextField>
                </TableCell>
              )
            })}
          </TableRow>
          <TableRow>
            <TableCell className='titleBody'>
              <TextField className='titleText'>{testId === LETTER_SOUNDS_ID ? 'Sound' : 'Name'}</TextField>
            </TableCell>
            {ITEM_INVENTORY_ALPHABETICAL_ORDER.map((item, colIdx) => {
              const itemCode = getQuestionCode(testId, item)
              return (
                <EditableScoreCell
                  key={colIdx}
                  item={item}
                  testId={testId}
                  currentScore={editedScores[itemCode]}
                  originalScore={originalScores[itemCode]}
                  onClick={onScoreClick}
                />
              )
            })}
          </TableRow>
        </Table>
      </TableContainer>
      <Legend />
    </>
  )
}

export default EditableInventoryTable
