import styled from 'styled-components'
import MaterialTable from '@material-ui/core/Table'
import MaterialTableContainer from '@material-ui/core/TableContainer'
import MaterialTableCell from '@material-ui/core/TableCell'
import MaterialTableBody from '@material-ui/core/TableBody'
import MaterialTableHead from '@material-ui/core/TableHead'
import * as COLOR from '../../constants/colors'

// set border bottom and right to be the same as background color to offset borderCollapse: 'unset'
const setCellBackgroundColor = (color) => {
  return {
    backgroundColor: color,
    borderBottom: `1px solid ${color}`,
    borderRight: `1px solid ${color}`
  }
}

export const TableContainer = styled(MaterialTableContainer)({
  '&.MuiTableContainer-root': {
    borderTopLeftRadius: '1rem',
    borderTopRightRadius: '1rem',
    backgroundColor: COLOR.TABLE_BACKGROUND_COLOR
  }
})

// borderCollapse: 'unset' needed so that table content is hidden behind stickly table headers/columns on scroll
export const TableWrapper = styled(MaterialTable)({
  '&.MuiTable-root': {
    borderCollapse: 'unset'
  }
})

export const TableBody = styled(MaterialTableBody)({})

export const TableHead = styled(MaterialTableHead)({
  position: 'sticky',
  top: 0,
  zIndex: 99 // must be less than 100 to not overlap the hamburger menu
})

export const TableCell = styled(MaterialTableCell)({
  '&.MuiTableCell-root': {
    textAlign: 'center',
    lineHeight: '1.1rem',
    border: `1px solid ${COLOR.STUDENT_DETAILS_CELL_BORDER}`,
    height: '45px',
    padding: '2px',
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: '0.875rem',
    ...setCellBackgroundColor(COLOR.STUDENT_DETAILS_CELL_BACKGROUND)
  },

  '&.header': {
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: '0.75rem',
    height: '4rem',
    letterSpacing: '1px',
    backgroundColor: COLOR.STUDENT_DETAILS_HEADER_BACKGROUND,
    borderRight: `1px solid ${COLOR.STUDENT_DETAILS_HEADER_BACKGROUND}`,
    color: COLOR.STUDENT_DETAILS_HEADER_TEXT,
    textTransform: 'uppercase'
  },

  '&.unitHeader': {
    fontFamily: 'Inter',
    fontWeight: 300,
    fontSize: '0.75rem',
    height: '1.9rem',
    backgroundColor: COLOR.UNIT_HEADER_BACKGROUND,
    color: COLOR.UNIT_HEADER_TEXT,
    padding: 0
  },

  '&.subHeader': {
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: '0.75rem',
    letterSpacing: '0.5px',
    color: COLOR.STUDENT_DETAILS_SUBHEADER_TEXT,
    backgroundColor: COLOR.STUDENT_DETAILS_CELL_BACKGROUND,
    borderBottom: `1px solid ${COLOR.STUDENT_DETAILS_DIVIDER}`
  },

  '&.stickyHeader': {
    position: 'sticky',
    left: 0
  },

  '&.tenColWidth': {
    '&.MuiTableCell-root': {
      minWidth: '3.625rem'
    }
  },

  '&.colWidth': {
    '&.MuiTableCell-root': {
      width: '3.625rem',
      paddingLeft: '1rem',
      paddingRight: '1rem'
    }
  },

  '&.divider': {
    borderRight: `1px solid ${COLOR.STUDENT_DETAILS_DIVIDER} !important`
  },

  '&.gradeDivider': {
    borderBottom: `1px solid ${COLOR.STUDENT_DETAILS_DIVIDER} !important`
  },

  '&.pwrRiskPercentage': {
    fontWeight: 600,
    '&.MuiTableCell-root': {
      color: COLOR.PWR_RISK_TEXT,
      backgroundColor: COLOR.PWR_RISK_BACKGROUND
    }
  },

  '&.singleColPwrHighRisk': {
    '&.MuiTableCell-root': {
      color: COLOR.SINGLE_COL_PWR_HIGH_RISK_TEXT,
      backgroundColor: COLOR.SINGLE_COL_PWR_HIGH_RISK_BG
    }
  },

  '&.singleColPwrModerateRisk': {
    '&.MuiTableCell-root': {
      color: COLOR.SINGLE_COL_PWR_MODERATE_RISK_TEXT,
      backgroundColor: COLOR.SINGLE_COL_PWR_MODERATE_RISK_BG
    }
  },

  '&.singleColPwrLowRisk': {
    '&.MuiTableCell-root': {
      color: COLOR.SINGLE_COL_PWR_LOW_RISK_TEXT
    }
  },

  '&.defaultCell': {
    fontWeight: 600,
    color: COLOR.ASSMNT_PERCENTILE_TEXT,
    position: 'relative',
    zIndex: 0
  },

  '&.highRiskAssmnt': {
    ...setCellBackgroundColor(COLOR.RISK_PERCENTILE_BACKGROUND),
    '&.MuiTableCell-root': {
      color: COLOR.ASSMNT_RISK_TEXT
    }
  },

  '&.atRiskAssmnt': {
    ...setCellBackgroundColor(COLOR.LOWER_RISK_PERCENTILE_BACKGROUND),
    '&.MuiTableCell-root': {
      color: COLOR.RISK_PERCENTILE_TEXT
    }
  },

  '&.notApplicable': {
    ...setCellBackgroundColor(COLOR.NOT_APPLICABLE_ASSMNT)
  },

  '&.studentNameStickyHeader': {
    position: 'sticky',
    left: 0,
    textAlign: 'left',
    '&.MuiTableCell-root': {
      paddingLeft: '1.5rem'
    }
  },

  '&.studentNameCell': {
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: '0.875rem',
    textAlign: 'left',
    minWidth: '10rem',
    position: 'sticky',
    left: 0,
    zIndex: 50
  },

  '&.gradeStickyHeader': {
    position: 'sticky',
    left: '10.36rem'
  },

  '&.gradeCell': {
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: '0.875rem',
    color: COLOR.ASSMNT_PERCENTILE_TEXT,
    position: 'sticky',
    left: '10.36rem',
    zIndex: 50
  },

  '&.highlight': {
    backgroundColor: COLOR.CELL_HIGHLIGHT,
    borderRight: `1px solid ${COLOR.CELL_HIGHLIGHT}`
  },

  '&.hoverCell': {
    border: `3px solid ${COLOR.CELL_HOVER} !important`,
    '&.MuiTableCell-root': {
      // remove padding to accommodate thicker border and prevent cell from changing size on hover
      padding: 0
    }
  }
})

export const TableBarWrapper = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '0.5rem'
})

export const SelectWrapper = styled.div({
  width: '14.438rem'
})
