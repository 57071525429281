import React, { useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import InfoPageBreadcrumbs from '../../components/InfoPageBreadcrumbs'
import { ContentView } from '../../ui-components/backgrounds'
import { useSessionStore, actionTypes } from '../../stores/SessionStore'
import HubspotForm from 'react-hubspot-form'
import LoadingIndicator from '../../components/LoadingIndicator'
import { HOLIDAY_HOURS_INFO } from './copy'
import { AlertWrapper, Alert } from '../LoginView/styles'

const title = 'Customer Support'

const CustomerSupport = () => {
  const { state, dispatch } = useSessionStore()
  const { viewHeader } = state
  const displayBanner = HOLIDAY_HOURS_INFO

  useEffect(() => {
    if (viewHeader !== '') {
      dispatch({ type: actionTypes.UPDATE_FIELD, field: 'viewHeader', data: '' })
    }
  }, [viewHeader, dispatch])

  const isViewLoaded = viewHeader === ''

  return (
    <div>
      {isViewLoaded && (
        <Grid container justify='center' rowSpacing={4}>
          <Grid item xs={displayBanner ? 2 : 11}>
            <InfoPageBreadcrumbs title={title} />
          </Grid>
          {displayBanner && (
            <Grid item xs={9} style={{ marginTop: '5rem', paddingLeft: '2rem' }}>
              <AlertWrapper>
                <Alert severity='info'>{HOLIDAY_HOURS_INFO}</Alert>
              </AlertWrapper>
            </Grid>
          )}
          <Grid item xs={11}>
            <ContentView style={displayBanner ? { marginTop: '35px' } : {}}>
              <HubspotForm
                portalId='8847320'
                formId='fe9ce7d2-8b4d-49b3-8289-e4ef213c0562'
                loading={<LoadingIndicator />}
                onReady={() => {
                  const script = document.createElement('script')
                  script.src = 'https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js'
                  const jqueryScript = document.getElementsByTagName('script')
                  const src = Array.from(jqueryScript).filter((item) => item.src === script.src)
                  if (src.length === 0) {
                    document.body.appendChild(script)
                  }
                }}
                onSubmit={() => {}}
              />
            </ContentView>
          </Grid>
        </Grid>
      )}
    </div>
  )
}

export default CustomerSupport
