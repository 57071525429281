import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { useSessionStore, actionTypes } from '../../stores/SessionStore'
import { useAuth } from 'auth'
import { ViewWrapper } from '../../ui-components/backgrounds'
import {
  AuthBackground,
  ContentWrapper,
  FormWrapper,
  InputWrapper,
  AuthLogo,
  SSOButtonWrapper
} from '../../ui-components/authStyles'
import AuthFooter from '../../components/AuthFooter'
import { TextField } from '../../ui-components/inputs/inputs'
import { LargeButton } from '../../ui-components/buttons/buttons'
import ToastNotification from '../../components/ToastNotification'
import * as COPY from '../../constants/copy'
import { LOGIN_ERROR, GENERIC_LOGIN_ERROR, OFFICE_HOURS_LINK } from './copy'
import { LoginWrapper, LoginHeader, LoginSubHeader, ForgotPasswordLink, AlertWrapper, Alert } from './styles'
import { useFeatureFlagPayload, useFeatureFlagEnabled } from 'posthog-js/react'
import { GoogleButton, CleverButton, ClassLinkButton, MicrosoftButton } from 'ui-components/buttons/sso/loginButtons'

const NewUserLink = () => {
  const enabled = useFeatureFlagEnabled('new-user-password-registration')
  return enabled ? (
    <ForgotPasswordLink to='/register'>New Users click here to register a password</ForgotPasswordLink>
  ) : null
}

const LoginView = () => {
  const { signIn } = useAuth()
  const { dispatch, state } = useSessionStore()
  const { didLogOut } = state
  const location = useLocation()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [emailError, setEmailError] = useState(null)
  const [passwordError, setPasswordError] = useState(null)
  const [alert, setAlert] = useState({ isOpen: false, type: '', message: '' })
  const [isDisabled, setIsDisabled] = useState(false)
  const loginViewPayload = useFeatureFlagPayload('login-banner')
  const officeHoursLinkEnabled = useFeatureFlagEnabled('office-hours-link')
  const googleSSOEnabled = useFeatureFlagEnabled('google-sso')
  const cleverSSOEnabled = useFeatureFlagEnabled('clever-sso')
  const classlinkSSOEnabled = useFeatureFlagEnabled('classlink-sso')
  const microsoftSSOEnabled = useFeatureFlagEnabled('microsoft-sso')

  const initiateSignIn = async () => {
    if (email === '' && password === '') {
      setIsDisabled(false)
      setEmailError(true)
      setPasswordError(true)
    } else if (email === '') {
      setIsDisabled(false)
      setEmailError(true)
    } else if (password === '') {
      setIsDisabled(false)
      setPasswordError(true)
    } else {
      try {
        const cognitoUser = await signIn(email, password)
        if (cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
          dispatch({
            type: actionTypes.ADD_NEW_USER,
            userEmail: email,
            tempPassword: password
          })
        } else {
          dispatch({
            type: actionTypes.LOGIN,
            userEmail: email,
            isLoggedIn: true,
            loginRedirectPathName: location.state && didLogOut === null ? location.state.from.pathname : null
          })
        }
      } catch (error) {
        setIsDisabled(false)
        setPassword('')
        setAlert({ isOpen: true, type: 'error', message: LOGIN_ERROR })
      }
    }
  }

  useEffect(() => {
    const loginError = sessionStorage.getItem('loginError')
    if (loginError) {
      setAlert({ isOpen: true, type: 'error', message: GENERIC_LOGIN_ERROR })
      sessionStorage.removeItem('loginError')
    }
  }, [])

  return (
    <ViewWrapper>
      <AuthBackground />
      {loginViewPayload && (
        <AlertWrapper style={{ textAlign: 'center' }}>
          <Alert severity={loginViewPayload.severity}>
            {loginViewPayload.message}
            {officeHoursLinkEnabled && (
              <>
                {' Click '}
                <a href={OFFICE_HOURS_LINK} target='_blank' rel='noreferrer noopener'>
                  here
                </a>
                {' to join.'}
              </>
            )}
          </Alert>
        </AlertWrapper>
      )}
      <ContentWrapper>
        <LoginWrapper bannerCount={1}>
          <AuthLogo />
          <FormWrapper>
            <LoginHeader>Sign In</LoginHeader>
            <LoginSubHeader>Welcome back to EarlyBird!</LoginSubHeader>
            <form onSubmit={(e) => e.preventDefault()}>
              <InputWrapper>
                <TextField
                  type='email'
                  name='email'
                  placeholder='Your email'
                  value={email}
                  label='Email'
                  onChange={(event) => {
                    if (emailError) {
                      setEmailError(false)
                    }
                    setEmail(event.target.value)
                  }}
                  error={emailError}
                  helperText={emailError ? COPY.EMPTY_EMAIL_ERROR : ''}
                  severity={emailError ? 'error' : null}
                  data-testid='emailInput'
                />
              </InputWrapper>
              <InputWrapper>
                <TextField
                  type='password'
                  placeholder='Password'
                  value={password}
                  label='Password'
                  onChange={(event) => {
                    if (passwordError) {
                      setPasswordError(false)
                    }
                    setPassword(event.target.value)
                  }}
                  error={passwordError}
                  helperText={passwordError ? COPY.EMPTY_PASSWORD_ERROR : ''}
                  severity={passwordError ? 'error' : null}
                  data-testid='passwordInput'
                />
              </InputWrapper>

              <LargeButton
                type='submit'
                alt='submit'
                data-testid='submit'
                disabled={isDisabled}
                onClick={async () => {
                  setIsDisabled(true)
                  await initiateSignIn()
                }}
              >
                Log in
              </LargeButton>
            </form>
            <ForgotPasswordLink to='/forgot-password'>Forgot Password?</ForgotPasswordLink>
            <NewUserLink />
            <SSOButtonWrapper>
              {googleSSOEnabled && <GoogleButton />}
              {cleverSSOEnabled && <CleverButton />}
              {classlinkSSOEnabled && <ClassLinkButton />}
              {microsoftSSOEnabled && <MicrosoftButton />}
            </SSOButtonWrapper>
          </FormWrapper>
        </LoginWrapper>
        <AuthFooter />
      </ContentWrapper>

      {alert.isOpen && (
        <ToastNotification
          alert={alert}
          onClose={() => {
            setAlert({ isOpen: false, type: '', message: '' })
          }}
        />
      )}
    </ViewWrapper>
  )
}

export default LoginView
