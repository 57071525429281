import * as RULES from 'constants/displayRules'
import * as A from 'constants/assessments'
import { PERCENTILE_AT_RISK_THRESHOLD, THETA_CUTOFFS, RATIO_THRESHOLD } from 'constants/thresholds'

export const isAssessmentComplete = (assessment) => {
  if (assessment) {
    const isComplete = assessment.completedFlag
    if (A.RAN_ASSESSMENT_IDS.includes(assessment.key)) {
      // RAN only considered complete if scored
      const isScored = assessment.abilityPercentile !== null
      return isComplete && isScored
    }
    return isComplete
  }
  return false
}

export const getAssessmentScoreAndThreshold = (result, assessmentId, currTimePeriod) => {
  let score = null
  let threshold = null

  const displayRule = RULES.DISPLAY_RULES[result.grade][assessmentId][currTimePeriod]
  switch (displayRule) {
    case RULES.RATIO: {
      score = result.correctItems
      threshold = RATIO_THRESHOLD
      break
    }
    case RULES.PROFICIENCY: {
      score = parseFloat(result.finalTheta)
      threshold = THETA_CUTOFFS[result.grade][assessmentId][currTimePeriod]
      break
    }
    case RULES.PERCENTILE: {
      score = Math.round(result.abilityPercentile)
      threshold = PERCENTILE_AT_RISK_THRESHOLD
      break
    }
  }

  return { score, threshold }
}
