import React from 'react'

const AudioPlayerCell = ({ cellData }) => {
  const audioPath = cellData
  return (
    <audio src={audioPath} controls>
      <source type='audio/mpeg' />
      Your browser does not support the audio element.
    </audio>
  )
}

export default AudioPlayerCell
