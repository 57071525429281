import React from 'react'
import styled from 'styled-components'
import MaterialChip from '@mui/material/Chip'
import MaterialTooltip, { tooltipClasses } from '@mui/material/Tooltip'
import * as COLOR from 'constants/colors'

export const Row = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center'
})

export const Chip = styled(MaterialChip)({
  marginLeft: '0.5rem',
  '&.MuiChip-root': {
    backgroundColor: COLOR.SCHOOL_CHIP_BG,
    border: `1px solid ${COLOR.SCHOOL_CHIP_BORDER}`,
    color: COLOR.SCHOOL_CHIP_TEXT,
    fontWeight: 500
  }
})

export const Tooltip = styled(({ className, ...props }) => (
  <MaterialTooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: COLOR.SCHOOL_TOOLTIP_BG,
    color: COLOR.SCHOOL_TOOLTIP_TEXT,
    border: `1px solid ${COLOR.SCHOOL_TOOLTIP_BORDER}`,
    fontSize: '0.75rem',
    lineHeight: '1.75rem',
    padding: '0.75rem 1.25rem 0.75rem 1.25rem'
  }
}))
