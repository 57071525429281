import styled from 'styled-components'
import {
  STUDENT_VIEW_DARK_TEXT,
  STUDENT_VIEW_MEDIUM_TEXT,
  NO_ACCORDION_BG,
  NEXT_STEPS_LINK_HOVER
} from '../../constants/colors'

export const StudentResourcesWrapper = styled.div`
  & .explanation {
    font-family: Inter;
    font-size: 0.875rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.74;
    letter-spacing: normal;
    text-align: left;
    color: ${STUDENT_VIEW_MEDIUM_TEXT};
  }
  h2 {
    margin: 0 1.938rem 0.75rem 0;
    font-family: Quicksand;
    font-size: 1.5rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
    color: ${STUDENT_VIEW_DARK_TEXT};
  }
  & .noAccordion {
    border-radius: 0.938rem;
    background-color: ${NO_ACCORDION_BG};
    font-family: Inter;
    font-size: 0.875rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: ${STUDENT_VIEW_DARK_TEXT};
    padding: 0.75rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
`

export const ExternalLink = styled.a`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  color: inherit;
  text-decoration: none;

  p {
    padding-right: 0.5rem;
  }

  &:hover {
    color: ${NEXT_STEPS_LINK_HOVER};
  }
`
