import React, { useEffect } from 'react'
import AssessmentGroupTable from './components/AssessmentGroupTable'
import PredictiveProfileTable from './components/PredictiveProfileTable'
import { AssessmentGroupWrapper, TableWrapper, TableInnerWrapper } from './styles'
import TableLegend from 'components/TableLegend'
import { getAssessmentGroups, getPredictiveProfileRows, getScoringUnits } from './helper'
import { BOY, MOY, EOY, timePeriodsToDate } from 'constants/timePeriods'

const StudentResultsTable = ({
  student,
  studentResults,
  mostRecentRANScore,
  assmntConfig,
  timePeriodAssmntLists,
  onUpdate,
  currTimePeriod,
  selectedTimePeriod,
  setSelectedTimePeriod,
  isAdminView
}) => {
  useEffect(() => {
    setSelectedTimePeriod(currTimePeriod)
  }, [currTimePeriod, setSelectedTimePeriod])

  const assessmentGroups = getAssessmentGroups(timePeriodAssmntLists)
  const scoringUnits = getScoringUnits(assessmentGroups, student.grade, currTimePeriod)

  // predictive profile is displayed for each time period
  const predictiveProfileTimePeriods = [BOY, MOY, EOY]

  return (
    <TableWrapper>
      <TableInnerWrapper>
        <PredictiveProfileTable
          student={student}
          predictiveProfileRows={getPredictiveProfileRows(student.grade, predictiveProfileTimePeriods)}
          selectedTimePeriod={selectedTimePeriod}
          setSelectedTimePeriod={setSelectedTimePeriod}
        />

        {assessmentGroups.map((assessmentGroup) => {
          // loop through the assessment groups, create a table for each group
          return (
            <AssessmentGroupWrapper key={assessmentGroup.title}>
              <AssessmentGroupTable
                isAdminView={isAdminView}
                student={student}
                studentResults={studentResults}
                mostRecentRANScore={mostRecentRANScore}
                assessmentGroup={assessmentGroup}
                selectedTimePeriod={selectedTimePeriod}
                assmntConfig={assmntConfig}
                timePeriodAssmntLists={timePeriodAssmntLists}
                onUpdate={onUpdate}
              />
            </AssessmentGroupWrapper>
          )
        })}
        <TableLegend
          grades={[student.grade]}
          // the table legend should only include predictive profile values that are relevant to time periods previous to/including the current time period
          predictiveProfile={getPredictiveProfileRows(student.grade, timePeriodsToDate[currTimePeriod])}
          scoringUnits={scoringUnits}
          timePeriod={currTimePeriod}
          isClassroomView={false}
        />
      </TableInnerWrapper>
    </TableWrapper>
  )
}

export default StudentResultsTable
