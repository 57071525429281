import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { ContentWrapper } from './styles'

const LoadingIndicator = () => {
  return (
    <ContentWrapper>
      <CircularProgress id='loading' />
    </ContentWrapper>
  )
}

export default LoadingIndicator
