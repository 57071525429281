import { isStudentAtRisk, isStudentReadingReady, isPreKStudentReadingReady } from 'utils/students'
import { probabilisticPercentage } from 'utils/formatting'
import {
  DYSLEXIA,
  PWR,
  KREADY,
  RAN_ASSESSMENT_IDS,
  PREDICTIVE_PROFILE_DISPLAY_NAMES,
  SINGLE_COL_PWR
} from 'constants/assessments'
import * as RULES from 'constants/displayRules'
import * as T from 'constants/thresholds'
import * as GRADE from 'constants/grades'

export const formatCSVHeaders = (columns) => {
  const headers = []
  columns.forEach((column) => {
    column.subColumns.forEach((subColumn) => {
      const label = subColumn.key === 'studentName' ? 'Student Name' : subColumn.title
      if (subColumn.key === 'studentName') {
        const studentHeaders = [
          { label: 'Last Name', key: 'lastName' },
          { label: 'First Name', key: 'firstName' },
          { label: 'Participant Code', key: 'participantCode' },
          { label: 'Tags', key: 'tags' }
        ]
        headers.push(...studentHeaders)
      } else if (subColumn.key === PWR) {
        const header = { label: PREDICTIVE_PROFILE_DISPLAY_NAMES[PWR], key: subColumn.key }
        headers.push(header)
      } else if (subColumn.key === DYSLEXIA) {
        const header = { label: PREDICTIVE_PROFILE_DISPLAY_NAMES[DYSLEXIA], key: subColumn.key }
        headers.push(header)
      } else if (subColumn.key === SINGLE_COL_PWR) {
        const header = { label: PREDICTIVE_PROFILE_DISPLAY_NAMES[SINGLE_COL_PWR], key: subColumn.key }
        headers.push(header)
      } else {
        headers.push({ label: label, key: subColumn.key })
      }
    })
  })
  return headers
}

const assmntNeedsScoring = (cellData) => {
  const { assessmentId, isComplete, abilityPercentile, isInvalid } = cellData
  const isRAN = RAN_ASSESSMENT_IDS.includes(assessmentId)
  const needsScoring = isComplete && !abilityPercentile && isInvalid === null
  return isRAN && needsScoring
}

export const getFormattedData = (key, cellData, student, selectedTimePeriod) => {
  const getDisplayRule = (key) => {
    return RULES.DISPLAY_RULES[student.grade][key][selectedTimePeriod]
  }

  switch (key) {
    case 'firstName':
      return cellData.firstName
    case 'lastName':
      return cellData.lastName
    case 'participantCode':
      return cellData.participantCode
    case 'tags':
      return cellData.tags
    case 'grade':
      return cellData
    case PWR: {
      const displayRule = getDisplayRule(PWR)
      switch (displayRule) {
        case RULES.GRAYED_OUT:
        case null:
          return 'N/A'
        case RULES.ALG_ICON: {
          const { score } = cellData
          if (score) {
            return isStudentReadingReady(score, student.grade, selectedTimePeriod)
              ? 'No risk detected'
              : 'Below expectations'
          } else return ''
        }
        case RULES.ALG_PERCENTAGE: {
          const { score } = cellData
          return score ? probabilisticPercentage(score) : ''
        }
      }
      break
    }
    case SINGLE_COL_PWR: {
      const displayRule = getDisplayRule(SINGLE_COL_PWR)
      switch (displayRule) {
        case RULES.GRAYED_OUT:
        case null:
          return 'N/A'
        case RULES.ALG_PERCENTAGE: {
          const { score } = cellData
          return score ? probabilisticPercentage(score) : ''
        }
      }
      break
    }
    case DYSLEXIA: {
      const displayRule = getDisplayRule(DYSLEXIA)
      switch (displayRule) {
        case RULES.GRAYED_OUT:
        case null:
          return 'N/A'
        case RULES.ALG_ICON: {
          const { score } = cellData
          if (score) {
            return isStudentAtRisk(score, student.grade, selectedTimePeriod) ? 'At risk' : 'No risk detected'
          } else return ''
        }
      }
      break
    }
    case KREADY: {
      const displayRule = getDisplayRule(KREADY)
      switch (displayRule) {
        case RULES.TBD:
          return 'TBD'
        case RULES.GRAYED_OUT:
        case null:
          return 'N/A'
        case RULES.ALG_ICON: {
          const { score } = cellData
          if (score) {
            return isPreKStudentReadingReady(score, selectedTimePeriod) ? 'Ready' : 'Emerging'
          } else return ''
        }
      }
      break
    }
    default: {
      // assessment results
      const { isInvalid, isComplete, abilityPercentile, correctItems, totalItems, finalTheta } = cellData
      const displayRule = getDisplayRule(key)
      if (displayRule === null) return ''
      if (isInvalid) return 'N/A'
      else if (assmntNeedsScoring(cellData)) return 'Needs Scoring'
      else if (!isComplete) return 'Incomplete'
      else {
        switch (displayRule) {
          case RULES.TBD:
            return 'TBD'
          case RULES.RATIO:
            return `${correctItems} of ${totalItems}`
          case RULES.PROFICIENCY: {
            return T.isProficient(student.grade, key, selectedTimePeriod, finalTheta) ? 'On Track' : 'Not Yet'
          }
          case RULES.PERCENTILE: {
            return Math.round(Math.min(99, Math.max(1, abilityPercentile)))
          }
        }
      }
    }
  }
}

export const formatCSVData = (classroomResults, classroomGrades, headers, selectedTimePeriod, ranScores) => {
  const formattedData = []
  // format students in specified grade order
  GRADE.GRADE_ORDER.map((grade) => {
    // skip grade if no students in classroom belong to that grade
    const hasGrade = classroomGrades.includes(grade)
    if (!hasGrade) return null

    // format students in grade in sorted order
    const sortedStudentIds = classroomResults.sortedStudentIds[grade]
    const sortedGradeData = sortedStudentIds.map((studentId) => {
      const student = classroomResults.studentResults[studentId]
      return headers.reduce((obj, curr) => {
        let cellData = student[curr.key]
        if (['firstName', 'lastName', 'participantCode', 'tags'].includes(curr.key)) {
          cellData = student.studentName
        }
        if ([DYSLEXIA, PWR, KREADY, SINGLE_COL_PWR].includes(curr.key)) {
          const profileForPeriod = student.predictiveProfile[selectedTimePeriod]
          if (curr.key === SINGLE_COL_PWR) {
            cellData = {
              score: profileForPeriod ? profileForPeriod[PWR] : null
            }
          } else {
            cellData = {
              score: profileForPeriod ? profileForPeriod[curr.key] : null
            }
          }
        }
        if (RAN_ASSESSMENT_IDS.includes(curr.key) && !cellData) {
          // if current time period doesn't have RAN data, use the student's most recent RAN score
          const mostRecentRan = ranScores[student.studentName.participantId]
          if (mostRecentRan) {
            cellData = {
              assessmentId: mostRecentRan.testCode,
              isComplete: mostRecentRan.completedFlag,
              abilityPercentile: mostRecentRan.abilityPercentile,
              isInvalid: mostRecentRan.invalid
            }
          }
        }
        const formattedData = cellData ? getFormattedData(curr.key, cellData, student, selectedTimePeriod) : ''

        return {
          ...obj,
          [curr.key]: formattedData
        }
      }, [])
    })

    formattedData.push(...sortedGradeData)
  })
  return formattedData
}

export const createStudentsCSV = (classroomResults, classroomGrades, columns, selectedTimePeriod, ranScores) => {
  const headers = formatCSVHeaders(columns)
  const data = formatCSVData(classroomResults, classroomGrades, headers, selectedTimePeriod, ranScores)

  const separator = ','

  const headerRow = headers
    .map((header) => {
      return header.label
    })
    .join(separator)

  const dataRows = data
    .map((row) => {
      return headers
        .map((header) => {
          return row[header.key]
        })
        .join(separator)
    })
    .join('\n')

  return headerRow + '\n' + dataRows
}

export const buildDataURL = (classroomResults, classroomGrades, columns, selectedTimePeriod, ranScores) => {
  const csv = createStudentsCSV(classroomResults, classroomGrades, columns, selectedTimePeriod, ranScores)
  const type = 'text/csv'
  const blob = new Blob([csv], { type })
  const dataURL = `data:${type};charset=utf-8'}${csv}`

  const URL = window.URL || window.webkitURL

  return typeof URL.createObjectURL === 'undefined' ? dataURL : URL.createObjectURL(blob)
}
