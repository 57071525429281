import { request } from '../helper'

export const getStudent = async (studentId, startDate = null, endDate = null) => {
  const dateParams = startDate && endDate ? `&startDate=${startDate}&endDate=${endDate}` : ''
  const url = `/participants/${studentId}?v=1${dateParams}`
  return await request('GET', url)
}

export const createStudent = async (payload) => {
  const url = '/participants'
  return await request('POST', url, payload)
}

export const updateStudent = async (payload) => {
  const url = '/participants'
  return await request('PUT', url, payload)
}

export const getStudentsBySchool = async (schoolId) => {
  const url = `/participants/organizationUnit/${schoolId}`
  return await request('GET', url)
}

export const getStudentsByClassroom = async (classroomId, startDate = null, endDate = null) => {
  const dateParams = startDate && endDate ? `&startDate=${startDate}&endDate=${endDate}` : ''
  const url = `/participants/participantGroup/${classroomId}?v=1${dateParams}`
  return await request('GET', url)
}

export const deleteStudent = async (studentId) => {
  const url = `/participants/delete/${studentId}`
  return await request('DELETE', url)
}

export const getStudentRiskScores = async (studentId) => {
  const url = `/participants/riskScores/${studentId}`
  return await request('GET', url)
}

export const getStudentMostRecentRAN = async (studentId) => {
  const url = `/participantTest/RAN/participant/${studentId}`
  const ranScores = await request('GET', url)
  /* eslint indent:off */
  const mostRecentRanScore = ranScores.length
    ? ranScores.reduce((a, b) => {
        return new Date(a.createdAt) > new Date(b.createdAt) ? a : b
      })
    : null

  return mostRecentRanScore
}
