export const TEST_DETAILS_LOAD_ERROR = 'Unable to load test details.'
export const NO_TEST_RESULTS = 'No results!'
export const VIEW_HEADER = 'RAN Test Details'

export const RAN_SCORING_INSTRUCTIONS = {
  paragraphs: [
    'The RAN score is the amount of time it takes the student to name all fifty objects. EarlyBird displays the time from which the audio began recording, until the time in which the student pressed the green arrow upon completion. The teacher confirms that time by listening to the recording, making sure there are no gaps in time or excessive errors. Errors can be calculated by following the graphic available below or the RAN score sheet in the Teacher Quick Guide.',
    'The teacher confirms the number of seconds it took to complete the RAN and enters that time in the space provided. If applicable, the teacher also enters the number of errors. Please note the number of errors is not counted against the total score. The number of errors is a factor in determining if a student’s score is valid. Ten or more errors would indicate the student skipped or repeated a row, or was not fully focused on naming each word correctly. At that point, the teacher would enter a ‘0’ for the response time, indicating the test was invalid and needs re-administration. The RAN may be readministered separately in unlocked mode.',
    'Things to keep in mind when scoring:'
  ],
  listItems: [
    'Student says the wrong object name counts as one error',
    'Student says the wrong object name and then corrects with another wrong object name, counts as two errors',
    'Student skips an object counts as one error',
    'Tester has to prompt student to continue counts as one error',
    'Student skips a row and is redirected counts as one error',
    'Student self-corrects is NOT an error',
    'Student repeats a word is NOT an error',
    'Similar content words (home/house or cat/cats) are NOT errors'
  ]
}

export const RAN_SCORING_INSTRUCTIONS_HEADER = 'RAN Scoring Instructions'
