export const PK = 'PK'
export const KINDERGARTEN = 'K'
export const FIRST = '1'
export const SECOND = '2'

// multi grade
export const MULTI = 'MULTI'

export const GRADE_ORDER = [PK, KINDERGARTEN, FIRST, SECOND]

export const DISPLAY_GRADES = {
  [PK]: 'Pre-K',
  [KINDERGARTEN]: 'Kindergarten',
  [FIRST]: '1st Grade',
  [SECOND]: '2nd Grade'
}
