import React from 'react'
import { ASSETS_PATH } from '../../../../constants/assets'
import { CharacterImg, ImageWrapper } from './styles'

const CharacterToken = ({ assessmentId }) => {
  const imagePath = `${ASSETS_PATH}/${assessmentId}/token-enabled.png`
  const altText = assessmentId
  return (
    <ImageWrapper>
      <CharacterImg alt={altText} src={imagePath} />
    </ImageWrapper>
  )
}

export default CharacterToken
