import styled from 'styled-components'
import { TableRow as MaterialTableRow, TableCell as MaterialTableCell } from '@mui/material'
// import MaterialTableContainer from '@material-ui/core/TableContainer'

export const TableContainer = styled.div`
  // border-radius: 15px;
  // border: solid 1px red;
  flex-grow: 0;
  overflow-x: auto;
`
export const Table = styled.div`
  display: inline-table;
  // border-spacing: 0;
  // border-collapse: separate;
  border: solid 1px #c1c7d0;
  border-radius: 15px;
  width: 100%;
`

export const TableCell = styled(MaterialTableCell)`
  background-color: #f8f8f8;

  // styling for letter header and correct/incorrect body cells
  &.body,
  &.letterHeader {
    height: 22px;
  }

  // styling for correct/incorrect body  only
  &.body {
    flex-grow: 0;
    background-color: #ffffff;
  }

  // styling for all title cells
  &.title {
    flex-grow: 0;
  }

  // internal border
  &:not(:last-child) {
    border-right: solid 1px #c1c7d0;
  }

  //styling for creating a thicker bottom border for the first row of cells
  &.titleHeader,
  &.letterHeader {
    border-bottom: solid 2px #c1c7d0;
  }

  //styling for incorrect cells only
  &.body.incorrect {
    background-color: #fdf4e0;
    // border-right: solid 1px #f5b800;
    // border-right: solid 1px #c1c7d0;
    box-shadow: 0 0 0 1px #f5b800;
  }

  //styling for null cells only
  &.body.null {
    background-color: #ebebeb;
  }
`

export const TableRow = styled(MaterialTableRow)`
  //styling border radius for all corners
  &:first-child {
    ${TableCell}:first-child {
      /* Curved border radius for the first cell in each row */
      border-top-left-radius: 15px;
    }

    ${TableCell}:last-child {
      /* Curved border radius for the last cell in each row */
      border-top-right-radius: 15px;
    }
  }

  &:last-child {
    ${TableCell}:first-child {
      /* Curved border radius for the first cell in each row */
      border-bottom-left-radius: 15px;
    }

    ${TableCell}:last-child {
      /* Curved border radius for the last cell in each row */
      border-bottom-right-radius: 15px;
    }
  }

  //styling bottom borders to be darker than default MUI table cell border
  &:not(:last-of-type) {
    ${TableCell} {
      border-bottom: solid 1px #c1c7d0;
    }
  }

  &:first-of-type {
    ${TableCell} {
      border-bottom: solid 2.5px #c1c7d0;
    }
  }
`

export const TextField = styled.span`
  color: #4e5d78;
  &.letterText {
    display: flex;
    justify-content: center;
    flex-grow: 0;
    font-family: Lexend;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }

  &.titleText {
    flex-grow: 0;
    margin: 0 5px 0 0;
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
  }
`
