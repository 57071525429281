import styled from 'styled-components'
import MaterialPopover from '@material-ui/core/Popover'
import * as COLOR from '../../constants/colors'

export const PopoverWrapper = styled(MaterialPopover)({
  '& .MuiPaper-root': {
    fontFamily: 'Inter',
    fontSize: '0.75rem',
    color: COLOR.POPOVER_TEXT,
    maxWidth: '12rem',
    padding: '0.5rem'
  }
})
