import React from 'react'
import { getFormattedDate } from '../../../../utils/dates'
import { StudentInfoWrapper, Header } from './styles'
import * as COPY from '../../copy'

const StudentInfo = ({ student }) => {
  const { firstName, lastName, grade } = student

  const hasClassroom = student.ParticipantGroup[0]
  const hasTeacher = hasClassroom && student.ParticipantGroup[0].User[0]
  const teacherName =
    hasTeacher && `${student.ParticipantGroup[0].User[0].firstName} ${student.ParticipantGroup[0].User[0].lastName}`

  const teacher = teacherName || COPY.NO_TEACHER_ASSIGNED
  const lastTest = student.ParticipantTests && student.ParticipantTests[0] && student.ParticipantTests[0].endDtTm

  const dateOfLastTest = lastTest ? getFormattedDate(lastTest) : COPY.NO_INFO_TO_DISPLAY

  return (
    <StudentInfoWrapper>
      <Header>
        <h2>{`${firstName} ${lastName}`}</h2>
      </Header>
      <p>
        Teacher: <b>{teacher}</b>
      </p>
      <p>
        Grade: <b>{grade || COPY.NO_INFO_TO_DISPLAY}</b>
      </p>
      <p>
        Last Test: <b>{dateOfLastTest}</b>
      </p>
    </StudentInfoWrapper>
  )
}

export default StudentInfo
