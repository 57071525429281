import styled from 'styled-components'

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 10px;
`
export const RatioContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
`
