import { configureScope, addBreadcrumb, captureException } from '@sentry/react'

/**
 * Logs error to Sentry and console
 * @param {Error} error
 * @param {Object} breadcrumb
 * @param {string} breadcrumb.category - Indicates what area the event took place.
 * @param {string} breadcrumb.message - Description of event.
 * @param {Object} breadcrumb.data - Key-value mapping of event metadata.
 */
export const logError = ({ error, breadcrumb }) => {
  // if breadcrumb is provided, create Sentry breadcrumb
  if (breadcrumb) {
    addBreadcrumb({
      type: 'info',
      level: 'error',
      ...breadcrumb
    })
  }

  // record error in Sentry
  captureException(error)

  // log error to console
  console.error(`Sentry error: ${error}`)
  if (breadcrumb) {
    console.error(breadcrumb)
  }
}

export const addUserIdentifier = (userId) => {
  configureScope(function (scope) {
    scope.setUser({
      id: userId
    })
  })
}

export const addDistrictIdentifier = (districtId) => {
  configureScope(function (scope) {
    scope.setTag('organizationId', districtId)
  })
}
