import React, { useState } from 'react'
import ReadOnlyItemInventory from './components/ReadOnlyItemInventory'
import EditModeItemInventory from './components/EditModeItemInventory'

const ItemInventoryV2 = ({ studentResults, studentName, onClose }) => {
  const [editMode, setEditMode] = useState({ isActive: false, testId: null })

  return (
    <>
      {editMode.isActive ? (
        <EditModeItemInventory
          testId={editMode.testId}
          testData={studentResults[editMode.testId]}
          studentName={studentName}
          onClose={onClose}
        />
      ) : (
        <ReadOnlyItemInventory
          studentResults={studentResults}
          studentName={studentName}
          setEditMode={(testId) => setEditMode({ isActive: true, testId: testId })}
          onClose={onClose}
        />
      )}
    </>
  )
}

export default ItemInventoryV2
