import styled from 'styled-components'
import * as COLOR from '../../constants/colors'

export const Image = styled.img`
  height: auto;
  width: 80%;
`
export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem;
`
export const Header = styled.h2`
  font-family: Quicksand;
  font-size: 1.5rem;
  font-weight: 600;
  color: ${COLOR.TEST_DETAILS_HEADER};
`
export const Subheader = styled.h3`
  font-family: Quicksand;
  font-size: 1.25rem;
  color: ${COLOR.TEST_DETAILS_HEADER};
  font-weight: 600;
`
