import React from 'react'
import { RatioScoreContainer } from './styles'

const RatioScore = ({ correctItems, totalItems }) => {
  return (
    <RatioScoreContainer className='ratioScore'>
      <span>
        <span style={{ fontSize: '1.025rem' }}>{correctItems}</span>
        <span style={{ fontSize: '0.775rem' }}> / {totalItems}</span>
      </span>
    </RatioScoreContainer>
  )
}

export default RatioScore
