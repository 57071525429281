import { TEACHER_RESOURCES_LINK } from '../../constants/links'

export const OBJ_RAN_HEADER = 'What does the Object RAN score indicate?'
export const OBJ_RAN_BODY = {
  paragraphs: [
    'In the Object RAN subtest, the student demonstrates his or her ability to recall the names of familiar objects in a quick and automatic manner.  Since this is a timed assessment, this can reveal a student’s natural ability to retrieve information efficiently from long-term memory.  The ability for a student to retrieve information in a quick and automatic manner has been linked to fluency.',
    'Students may have difficulty with Object RAN for many reasons such as attention deficit, executive functioning, or oral language issues.  A low RAN score does not necessarily imply a struggling reader, however, combined with other subtests of early literacy skills it may uncover a risk and the need for early reading intervention.'
  ],
  link: `${TEACHER_RESOURCES_LINK}/rapid-automatized-naming`
}
export const LETTER_RAN_HEADER = 'What does the Letter RAN score indicate?'
export const LETTER_RAN_BODY = {
  paragraphs: [
    'In the Letter RAN subtest, the student demonstrates his or her ability to recall the names of familiar letters in a quick and automatic manner.  Since this is a timed assessment, this can reveal a student’s natural ability to retrieve information efficiently from long-term memory.  The ability for a student to retrieve information in a quick and automatic manner has been linked to fluency.',
    'Students may have difficulty with Letter RAN for many reasons such as attention deficit, executive functioning, or oral language issues.  A low Letter RAN score does not necessarily imply a struggling reader, however, combined with other subtests of early literacy skills it may uncover a risk and the need for early reading intervention.'
  ],
  link: `${TEACHER_RESOURCES_LINK}/rapid-automatized-naming`
}
export const RER_LETTER_RAN_HEADER = 'What does the Letter RAN score indicate?'
export const RER_LETTER_RAN_BODY = {
  paragraphs: [
    'In the Letter RAN subtest, the student demonstrates his or her ability to recall the names of familiar letters in a quick and automatic manner.  Since this is a timed assessment, this can reveal a student’s natural ability to retrieve information efficiently from long-term memory.  The ability for a student to retrieve information in a quick and automatic manner has been linked to fluency.',
    'Students may have difficulty with Letter RAN for many reasons such as attention deficit, executive functioning, or oral language issues.  A low Letter RAN score does not necessarily imply a struggling reader, however, combined with other subtests of early literacy skills it may uncover a risk and the need for early reading intervention.'
  ],
  link: `${TEACHER_RESOURCES_LINK}/rapid-automatized-naming`
}
export const LETTER_NAMES_HEADER = 'What does the Letter Name score indicate?'
export const LETTER_NAMES_BODY = {
  paragraphs: [
    'Assessing for letter names tells us a bit about a student’s learning profile.  When students score well, they reveal their ability to commit information to long term memory, as well as their ability to retrieve information in an automatic manner.  Letter knowledge has been thought to be a predictor of later reading success.  However, there are many reasons for a student to not score well in this subtest.  Students may have had a lack of instruction, limited exposure to print, English language learning, attention deficit, or slow processing speed.',
    'A low score in Letter Name means a student was not able to retrieve the expected names of the letters presented in the subtest.  Any cause for low scoring in this subtest would indicate the need for instruction that was multisensory with repeated practice.'
  ],
  link: `${TEACHER_RESOURCES_LINK}/letter-name`
}
export const LETTER_SOUNDS_HEADER = 'What does the Letter Sound score indicate?'
export const LETTER_SOUNDS_BODY = {
  paragraphs: [
    'Assessing letter sounds can tell us about a student’s learning profile.  When students score well, they reveal their ability to commit information to long term memory, as well as their ability to retrieve information in an automatic manner.  There is a strong correlation to letter-sound knowledge as a predictor of later reading success, however, there are many reasons for a student to not score well in this subtest.  Students may have had a lack of instruction, limited exposure to print, English language learning, attention deficit, or slow processing speed.',
    'A low score in Letter Sound means a student was not able to match the expected sounds with the letters presented in the subtest.  Any cause for low scoring in this subtest would indicate the need for instruction that was multisensory with repeated practice.'
  ],
  link: `${TEACHER_RESOURCES_LINK}/letter-sound`
}
export const RHYMING_HEADER = 'What does the Rhyming score indicate?'
export const RHYMING_BODY = {
  paragraphs: [
    'There are factors that should be considered when interpreting a student’s Rhyming subtest score.  First, determine if a student has developed the foundational skill of rhyme identification.  In other words, when presented with two words orally, can the student identify if the two words rhyme, yes or no?  Once a student can consistently determine if two words rhyme, then add the component of a third word.',
    'A low score in the Rhyming subtest would indicate that a student was not able to discriminate which two words rhyme when presented with three choices.  This skill has to do with not only being able to identify words that rhyme, but also being able to hold onto the endings of three separate words in working memory while making a judgment.  This requires a capacity in phonological memory and working memory for discrimination of sounds.'
  ],
  link: `${TEACHER_RESOURCES_LINK}/rhyming`
}
export const FIRST_SOUND_HEADER = 'What does the First Sound Matching score indicate?'
export const FIRST_SOUND_BODY = {
  paragraphs: [
    'First Sound Matching requires that a student is able to isolate and identify the first sound of a word.  Isolating the first sound of a word is the beginning step of phoneme segmentation.  Phoneme segmentation if the ability to break apart a word into individual phonemes or sounds.  The next skill in this subtest is the ability to hold onto three separate sounds in working memory and compare them to the original isolated sound.  Determining which word of three words has the same first sound requires a student to retain phonemes in working memory and use discrimination skills to make a selection.',
    'A low score in First Sound Matching would indicate either the student was not able to isolate the first sound of a given word, or the student was not able to discriminate which word of three had the same first sound.   This could also indicate a student’s limitations in phonological and working memory.'
  ],
  link: `${TEACHER_RESOURCES_LINK}/first-sound-matching`
}
export const BLENDING_HEADER = 'What does the Blending score indicate?'
export const BLENDING_BODY = {
  paragraphs: [
    'Blending requires a student to retain phonemes in sequential order and the ability to join those phonemes in a fluent and continuous manner.  This skill relies on a capacity in phonological memory along with phonological processing.  When the individual phonemes are combined fluently, they produce a spoken word.  Having background knowledge of the English Language can encourage the blending since known words can be retrieved from long-term memory.',
    'There are some factors that should be considered when interpreting a student’s low score.  A student needs to have foundational skills in phonemic awareness, which is the ability to hear and recognize an individual unit of sound or sounds.  A low score may indicate a student’s inability to hold onto information in a sequenced manner or the inability to combine individual speech sounds together.  Since the Blending subtest uses real words, a student with limited English proficiency may need special consideration when interpreting his or her score.'
  ],
  link: `${TEACHER_RESOURCES_LINK}/blending`
}
export const NONWORD_HEADER = 'What does the Nonword Repetition score indicate?'
export const NONWORD_BODY = {
  paragraphs: [
    'There are multiple factors that should be considered when interpreting a student’s Nonword Repetition score.  A student needs to have foundational skills in phonemic awareness, which is the ability to hear, recognize, and produce individual units of sound in the English language.  A student also needs to have the capacity in phonological short-term memory to retain sequenced phonemes for correct nonword repetition.',
    'A low score in Nonword Repetition could indicate a student’s limitations in short-term and phonological memory, resulting in the inability to retain the phonemes and repeat them sequentially.  Since there is no meaning associated with these non-words, students are not able to rely on retrieval skills of words they have previously heard.   A student with limited English proficiency, not being familiar with the sounds of the English language may need special consideration when interpreting his or her score.'
  ],
  link: `${TEACHER_RESOURCES_LINK}/non-word-repetition`
}
export const VOCAB_HEADER = 'What does the Vocabulary score indicate?'
export const VOCAB_BODY = {
  paragraphs: [
    'The Vocabulary subtest is a measure of a student’s receptive vocabulary.  This means a student is able to retrieve the meaning of words from long-term memory and make an appropriate identification from a sample of four choices.  This subtest is a reflection of a student’s environmental exposure to language and his or her background knowledge of words, specifically in English.',
    'A low score in this subtest could indicate poor receptive language abilities, or the student’s inability to recall word meaning.  A low score could also result from a student’s inadequate exposure to an environment rich in language.  Given this test is based on background knowledge of English language words, a student that is an English Language Learner may need special consideration when interpreting his or her score.'
  ],
  link: `${TEACHER_RESOURCES_LINK}/vocabulary`
}
export const WORD_MATCHING_HEADER = 'What does the Word Matching score indicate?'
export const WORD_MATCHING_BODY = {
  paragraphs: [
    'The Word Matching subtest is a measure of a student’s receptive language and the ability to discriminate between words with common attributes.  The subtest uses a variety of words including nouns, verbs, and adjectives.  A student must be able to classify word meanings on his or her own, deciding which attributes would be most appropriate.  This requires a capacity in working memory, as well as the cognitive ability to categorize and discriminate. ',
    'A low score in this subtest could indicate the words presented were not in the student’s active vocabulary.  A low score could also indicate that the student was not able to categorize words or make a connection between words with common attributes.  This skill has to do with not only being able to identify the attributes of word meanings but also being able to hold onto the meanings of multiple words in working memory while making a decision on similarities. This test is based on background knowledge of English language words and meanings.  An English Language Learner may need special consideration when interpreting his or her score.'
  ],
  link: `${TEACHER_RESOURCES_LINK}/word-matching`
}
export const ORAL_SENTENCE_HEADER = 'What does the Oral Sentence Comprehension score indicate?'
export const ORAL_SENTENCE_BODY = {
  paragraphs: [
    'The sentences used in this subtest vary in complexity.  They can range from very literal, requiring no interpretation of meaning, to very complex, relying on ability to inference.  The student must use his or her receptive language and background knowledge before making a visual discrimination between the images.  Since there are four images presented, the student must use working memory and discrimination skills in order to select which picture best fits the meaning of the sentence.',
    'A low score in this subtest could indicate the words used in the sentences were not in the student’s active vocabulary or the student does not have adequate background knowledge of the images presented to make the correct selection.   A student also needs to discriminate between the images presented, which requires working memory and the ability to make a selection based on the criteria of the sentence.'
  ],
  link: `${TEACHER_RESOURCES_LINK}/oral-sentence-comprehension`
}
export const FOLLOW_DIR_HEADER = 'What does the Follow Directions score indicate?'
export const FOLLOW_DIR_BODY = {
  paragraphs: [
    'There are several factors that should be considered when interpreting a student’s score in Follow Directions.  This subtest is a measure of a student’s receptive language as well as the ability to hold onto multistep directions in sequential order.  Words used in the directions cover concepts such as conditional, directional, and ordinal positioning.  Examples of these types of words are before and except, small and smallest, and first, second, and last.  Keep in mind there is a significant level of working memory involved when executing these directions.',
    'A low score in the Follow Directions subtest could indicate the words presented in the subtest were not part of the student’s receptive language.   This skill has to do not only with being able to understand the meanings of the words but also be able to discriminate between the objects presented.  Low scores could be an indication of a deficit in vocabulary, working memory, oral language comprehension, or the ability to hold onto items in a sequential order.'
  ],
  link: `${TEACHER_RESOURCES_LINK}/follow-directions`
}
export const DELETION_HEADER = 'What does the Deletion score indicate?'
export const DELETION_BODY = {
  paragraphs: [
    'Deletion requires a student to be able to segment and retain the sounds of a word in sequential order.  This skill requires a capacity in phonological and working memory.  Once the student can isolate and delete the phoneme(s), he or she should be able to verbalize the remaining part of the word.  Having background knowledge of the English language can encourage the student to produce the remaining word.',
    'There are some factors that should be considered when interpreting a student’s low score.  A student needs to have foundational skills in phonemic awareness, which is the ability to segment and recognize an individual unit of sound in the English language.  A student will also need the ability to hang onto phonemes in a sequenced manner in order to manipulate them properly.  This will require a capacity in phonological and working memory.  Since the Deletion subtest uses real words, a student with limited English proficiency may needing special consideration when interpreting his or her score.'
  ],
  link: `${TEACHER_RESOURCES_LINK}/deletion`
}
export const NW_READING_HEADER = 'What does the Nonword Reading score indicate?'
export const NW_READING_BODY = {
  paragraphs: [
    'Nonword Reading can help identify students with poor sound/symbol correspondence and/or weak decoding skills.  Students must rely on their knowledge of grapheme-phoneme mapping along with the coarticulation of sounds to produce the blended nonword.   This process requires working phonological memory and the ability to keep those sounds in sequential order.',
    'Since there is no way for a student to retrieve the word from long-term memory based on meaning, the student must strongly rely on the mechanics of decoding to read the nonword.  A student must also have established sound/symbol correspondence and some basic phonics rules in order to apply the correct sounds.  A low score in Nonword Reading could indicate a student’s limitations in working phonological memory, correct phoneme sequencing, or the need to strengthen sound/symbol correspondence and the basic principles of phonics.'
  ],
  link: `${TEACHER_RESOURCES_LINK}/nonword-reading`
}

export const WORD_READING_HEADER = 'What does the Word Reading score indicate?'
export const WORD_READING_BODY = {
  paragraphs: [
    'Word Reading can help identify students with poor sight word recognition and/or weak decoding skills.  Words are presented in isolation so students can use decoding or orthographic mapping to retrieve words, rather than applying context and syntax clues for word retrieval from connected text.',
    'Orthographic mapping results from phoneme-grapheme mapping.   This is the process used to strengthen sight word reading.  It uses the letter sounds that correspond to spelling patterns, pronunciation, and meaning of specific words.  Students scoring low will need to revisit their knowledge of sound/symbol correspondence, blending, and decoding skills to strengthen orthographic mapping and store words for future retrieval.',
    'A low score in Word Reading may identify the need to strengthen sight word vocabulary and decoding strategies.  Low sight word vocabulary can cause reading to be slow and laborious.  A student with low sight word vocabulary will need to decode each word individually which will impact overall fluency and comprehension.'
  ],
  link: `${TEACHER_RESOURCES_LINK}/word-reading`
}

export const RER_EVO_HEADER = 'What does the Expressive Vocabulary score indicate?'
export const RER_EVO_BODY = {
  paragraphs: [
    'Vocabulary refers to the words we use in communication. Active vocabulary refers to the words we understand and use in daily conversation. Research shows a student’s active vocabulary strongly relates to successful outcomes in later reading comprehension.',
    'Within active vocabulary, we refer to words we hear and understand as our receptive vocabulary, and words we use in conversation, as our expressive vocabulary. In EarlyBird, we measure a student’s receptive vocabulary in PreK – Grade 1, and a student’s expressive vocabulary in Grade 2.',
    'In Grade 2, the student is shown a picture of an object and asked to identify it by saying the name of the object. For example, the student is presented with a picture of a dog and then asked to name the object. In this example, the student would correctly respond by saying the word dog. Other answers may also be scored as correct, like puppy.'
  ],
  link: `${TEACHER_RESOURCES_LINK}/vocabulary`
}

export const RER_NW_SPELLING_HEADER = 'What does the Nonword Spelling score indicate?'
export const RER_NW_SPELLING_BODY = {
  paragraphs: [
    'Nonword Spelling can help identify students with poor sound/symbol correspondence, along with weak phoneme segmentation skills.  Students must rely on isolating all the sounds in the word while using their knowledge of phoneme-grapheme mapping in order to spell a word.  This process requires working phonological memory and the ability to keep those sounds in sequential order.',
    'Since there is no way for a student to retrieve the word from long-term memory based on meaning, the student must strongly rely on the mechanics of encoding to spell the nonword.  A student must have good phoneme segmentation along with established sound/symbol correspondence and basic phonics rules in order to apply the correct graphemes.  A low score in Nonword Spelling could indicate a student’s limitations in working phonological memory, phoneme segmentation, phoneme sequencing, or the need to strengthen sound/symbol correspondence and the basic principles of phonics.'
  ],
  link: `${TEACHER_RESOURCES_LINK}/nonword-spelling`
}

export const NEXT_STEPS_EXPLANATION =
  'These resources are here to help you take the “next steps” in providing instruction for students. The evidence-based resources listed were compiled with assistance from leading national experts in literacy education and vetted by teachers. EarlyBird points you to specific resources based on results from the screener. EarlyBird has no affiliation with these resources and the information contained on this website does not constitute an endorsement of specific providers or resources. Recommendations displayed below align with subtests in which a student scored below expectations on any individual assessment.'

export const NEXT_STEPS_HEADER = 'Next Steps'

export const MET_EXPECTATIONS_TEXT =
  'This student met expectations for currently completed subtests. You can still view the next steps resources below:'

export const INCOMPLETE_ASSESSMENTS_TEXT = 'View the Next Steps Resources with the link below.'

export const NEXT_STEPS_LINK = TEACHER_RESOURCES_LINK

export const NEXT_STEPS_LINK_TEXT = 'Next Steps'
