// @ts-check

import React, { useState, useEffect, useCallback } from 'react'
/* eslint indent: off */
import { getSchoolsByDistrict } from '../../../../lib/api'
import * as COPY from '../../copy'
import Table from '../../../../components/Table'
import { createSchoolRowData, createSchoolColumns, hasManagePermission } from './helper'
import SchoolDialog from '../../../../components/SchoolDialog'
import TabActionButtons from 'components/TabActionButtons'
import { TableWrapper } from '../../../../ui-components/tabs'
import { EmptyStateWrapper } from '../../../../ui-components/viewStyles'
import { alphanumericSort } from '../../../../utils/sorts'
import { downloadFile } from 'utils/reports'

const SchoolTab = ({ district, onAlert, role }) => {
  const [schools, setSchools] = useState([])
  const [schoolDialog, setSchoolDialog] = useState({ isOpen: false, school: null })
  const [loading, setLoading] = useState({ isComplete: false, hasError: false })

  const loadSchools = useCallback(async () => {
    try {
      if (district.organizationId) {
        return await getSchoolsByDistrict(district.organizationId)
      }
    } catch (err) {
      setLoading({ isComplete: true, hasError: true })
      onAlert('error', COPY.DISTRICT_LOAD_ERROR)
      return null
    }
  }, [onAlert, district])

  useEffect(() => {
    if (loading.hasError) return

    let isMounted = true
    ;(async () => {
      const schoolList = await loadSchools()
      if (isMounted && schoolList) {
        setSchools(schoolList.OrganizationUnits)
        setLoading({ isComplete: true, hasError: false })
      }
    })()
    return () => {
      isMounted = false
    }
  }, [loadSchools, loading.hasError])

  const onEdit = (school) => {
    setSchoolDialog({ isOpen: true, school: school })
  }

  let schoolRows = schools.map((school) => createSchoolRowData(school, role, onEdit, onAlert))

  schoolRows = alphanumericSort(schoolRows, 'school', 'name')

  const schoolColumns = createSchoolColumns(role)

  const onSchoolCreateOrUpdate = async () => {
    setSchoolDialog({ isOpen: false, school: null })
    // reload schools to get updated list
    await loadSchools()
  }

  return (
    <div>
      {schoolDialog.isOpen && (
        <SchoolDialog
          school={schoolDialog.school}
          districtId={district.organizationId}
          onAction={onSchoolCreateOrUpdate}
          onClose={() => setSchoolDialog({ isOpen: false, school: null })}
          onAlert={onAlert}
          role={role}
        />
      )}

      <TableWrapper>
        {hasManagePermission(role) && (
          <TabActionButtons
            downloadButtonProps={{
              id: 'downloadDistrictCSVButton',
              text: 'Download District CSV',
              onClick: () =>
                downloadFile({
                  link: `${process.env.REACT_APP_API_BASE_URL}/report/organization/${district.organizationId}`,
                  onAlert: onAlert
                })
            }}
            createButtonProps={{
              id: 'createSchoolButton',
              text: 'Create School',
              onClick: () => setSchoolDialog({ isOpen: true, school: null })
            }}
          />
        )}
        {loading.isComplete && (
          <div>
            {schools.length ? (
              <Table rows={schoolRows} columns={schoolColumns} />
            ) : (
              <EmptyStateWrapper>{COPY.EMPTY_STATE_SCHOOL}</EmptyStateWrapper>
            )}
          </div>
        )}
      </TableWrapper>
    </div>
  )
}

export default SchoolTab
