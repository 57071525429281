import { request } from '../helper'

export const getClassroom = async (classroomId) => {
  const url = `/participantGroups/${classroomId}`
  return await request('GET', url)
}

export const createClassroom = async (payload) => {
  const url = '/participantGroups'
  return await request('POST', url, payload)
}

export const updateClassroom = async (payload) => {
  const url = '/participantGroups'
  return await request('PUT', url, payload)
}

export const getClassroomsBySchool = async (schoolId) => {
  const url = `/participantGroups/organizationUnit/${schoolId}`
  return await request('GET', url)
}

export const getClassroomsByDistrict = async (districtId) => {
  const url = `/participantGroups/organization/${districtId}`
  return await request('GET', url)
}

export const deleteClassroom = async (classroomId) => {
  const url = `/participantGroups/${classroomId}`
  return await request('DELETE', url)
}

export const getClassroomRiskScores = async (classroomId) => {
  const url = `/participants/participantGroup/riskScores/${classroomId}`
  return await request('GET', url)
}
