import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getClassroom } from '../../lib/api'
import Grid from '@material-ui/core/Grid'
import TabContext from '@material-ui/lab/TabContext'
import { Tab, TabList, TabPanel, TabWrapper } from '../../ui-components/tabs'
import Breadcrumbs from './components/Breadcrumbs'
import ResultsTab from './components/ResultsTab'
import StudentTab from './components/StudentTab'
import ToastNotification from '../../components/ToastNotification'
import * as COPY from './copy'
import { useSessionStore, actionTypes } from '../../stores/SessionStore'
import { useAuth } from 'auth'
import Banner from 'components/Banner'
import { useFeatureFlagPayload } from 'posthog-js/react'

const RESULTS_TAB = 'resultsTab'
const STUDENT_TAB = 'studentTab'

const ClassroomView = () => {
  const { signOut } = useAuth()
  const params = useParams()
  const { classroomId } = params
  const { state, dispatch } = useSessionStore()
  const { role, viewHeader, schoolIds } = state
  const [alert, setAlert] = useState({ isOpen: false, type: '', message: '' })
  const [classroom, setClassroom] = useState({})
  const [currTab, setCurrTab] = useState(RESULTS_TAB)
  const [loading, setLoading] = useState({ isComplete: false, hasError: false })
  const bannerPayload = useFeatureFlagPayload('classroom-banner')

  useEffect(() => {
    let isMounted = true
    ;(async () => {
      try {
        const classroomInfo = await getClassroom(classroomId)
        if (isMounted) {
          setClassroom(classroomInfo)
          setLoading({ isComplete: true, hasError: false })
        }
      } catch (err) {
        if (isMounted) {
          if (err.code === 401) {
            // timeout error
            await signOut()
            dispatch({ type: actionTypes.LOGOUT })
          }
          setLoading({ isComplete: true, hasError: true })
          setAlert({ isOpen: true, type: 'error', message: COPY.CLASSROOM_LOAD_ERROR })
        }
      }
    })()
    return () => {
      isMounted = false
    }
  }, [classroomId, dispatch, signOut])

  useEffect(() => {
    if (loading.isComplete && !loading.hasError) {
      if (viewHeader !== classroom.name) {
        dispatch({ type: actionTypes.UPDATE_FIELD, field: 'viewHeader', data: classroom.name })
      }
    }
  }, [loading, classroom.name, viewHeader, dispatch])

  const classroomHasBannerTargetGrade = (targetGrades) => {
    if (!targetGrades || !Array.isArray(targetGrades)) {
      return false
    }
    return classroom?.Participant.some((student) => targetGrades.includes(student.grade))
  }

  const handleTabChange = (_, newValue) => {
    setCurrTab(newValue)
  }

  const onAlert = (type, message) => {
    setAlert({ isOpen: true, type: type, message: message })
  }

  const isViewLoaded = loading.isComplete && !loading.hasError && viewHeader === classroom.name

  return (
    <div>
      {isViewLoaded && (
        <>
          <Grid container justify='center' spacing={2}>
            <Grid item xs={11}>
              <Breadcrumbs classroom={classroom} role={role} userSchools={schoolIds} />
            </Grid>
          </Grid>
          <TabWrapper>
            <Grid container justify='center' spacing={2}>
              {bannerPayload && classroomHasBannerTargetGrade(bannerPayload.grades) && (
                <Banner payload={bannerPayload} />
              )}
              <Grid item xs={11}>
                <TabContext value={currTab}>
                  <TabList onChange={handleTabChange}>
                    <Tab label='Results' value={RESULTS_TAB} />
                    <Tab label='Students' value={STUDENT_TAB} />
                  </TabList>

                  <TabPanel value={RESULTS_TAB}>
                    <ResultsTab classroom={classroom} onAlert={onAlert} />
                  </TabPanel>
                  <TabPanel value={STUDENT_TAB}>
                    <StudentTab classroom={classroom} role={role} onAlert={onAlert} />
                  </TabPanel>
                </TabContext>
              </Grid>
            </Grid>
          </TabWrapper>
        </>
      )}
      {alert.isOpen && (
        <ToastNotification
          alert={alert}
          onClose={() => {
            setAlert({ isOpen: false, type: '', message: '' })
          }}
        />
      )}
    </div>
  )
}

export default ClassroomView
