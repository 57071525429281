import styled from 'styled-components'
import { MENU_TEXT, DIVIDER, MENU_BACKGROUND, MENU_SHADOW } from '../../constants/colors'

export const HamburgerNav = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: ${MENU_BACKGROUND};
  height: min-content;
  width: 15rem;
  z-index: 100;
  text-align: left;
  padding: 2rem;
  position: absolute;
  top: 0;
  margin-top: 4.5rem;
  border: 1px solid ${MENU_SHADOW};
  border-top-width: 0;
  transition: transform 0.3s ease-in-out;
  transform: ${({ open }) => (open ? 'translateY(0)' : 'translateY(-120%)')};
  color: ${MENU_TEXT};

  a {
    font-size: 1rem;
    display: flex;
    flex-direction: row;
    font-weight: normal;
    color: ${MENU_TEXT};
    text-decoration: none;
    transition: color 0.3s linear;

    div {
      padding-right: 1rem;
    }

    &:hover {
      font-weight: bold;
      background: ${MENU_BACKGROUND};
    }
  }

  .welcome {
    font-size: 1.3rem;
    font-weight: bold;
    padding-left: 0px;
    display: flex;
    flex-direction: column;
  }

  .welcome-spacer {
    height: 0.75rem;
  }

  .email-cont {
    width: 100%;
  }

  #user-email {
    font-size: 1rem;
    font-weight: 100;
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    margin: 0;
    text-align: center;
  }

  .dividerAbove {
    border-top: 1px solid ${DIVIDER};
  }

  .logout {
    margin-top: 2.5rem;
    font-weight: bold;
    border-bottom: none;

    &:hover {
      text-decoration: underline;
      background: ${MENU_BACKGROUND};
    }
  }
`
