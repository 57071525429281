import React from 'react'
import InfoPopover from 'components/InfoPopover'
import { StandardAddButton, SecondaryButton } from 'ui-components/buttons/buttons'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import { CSV_REPORT_INFO_LABEL, CSV_REPORT_INFO_TEXT } from './copy'
import { ActionRow, ButtonWrapper, InfoWrapper } from './styles'

const ReportDownloadButton = ({ id, onClick, text }) => {
  return (
    <div>
      <SecondaryButton id={id} onClick={onClick}>
        <FileDownloadOutlinedIcon fontSize='small' style={{ paddingRight: 5 }} />
        {text}
      </SecondaryButton>
      <InfoWrapper>
        <InfoPopover info={CSV_REPORT_INFO_TEXT} triggerText={CSV_REPORT_INFO_LABEL} textPosition='right' />
      </InfoWrapper>
    </div>
  )
}

const TabActionButtons = ({ downloadButtonProps, createButtonProps }) => {
  return (
    <ActionRow>
      <ButtonWrapper>
        <ReportDownloadButton {...downloadButtonProps} />
      </ButtonWrapper>
      <ButtonWrapper>
        <StandardAddButton {...createButtonProps} />
      </ButtonWrapper>
    </ActionRow>
  )
}

export default TabActionButtons
