import React from 'react'
import LinkIcon from '@material-ui/icons/Link'
import { ExternalLink } from '../../styles'

const AccordionBody = ({ body }) => {
  const { paragraphs, link } = body
  return (
    <div>
      {paragraphs.map((paragraph) => {
        const index = paragraphs.indexOf(paragraph)
        return <p key={index}>{paragraph}</p>
      })}
      {link ? (
        <ExternalLink href={link} rel='noreferrer' target='_blank'>
          <p>More Info</p>
          <LinkIcon />
        </ExternalLink>
      ) : (
        <p>More info coming soon!</p>
      )}
    </div>
  )
}

export default AccordionBody
