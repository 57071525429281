import React, { useState, useEffect } from 'react'
import Dialog from '../Dialog'
import FormSelect from './components/FormSelect'
import FormMultiSelect from './components/FormMultiSelect'
import FormAutocompleteSelect from './components/FormAutocompleteSelect'
import FormTextField from './components/FormTextField'
import ToastNotification from '../ToastNotification'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFNSUtils from '@date-io/date-fns'
import { Column, MultiColRow, DatePicker, StyledField } from './styles'
import * as COPY from './copy'

/*
Field object:
type - text, select, autocompleteSelect, multiSelect, datepicker
key - string
label - string
isRequired - boolean
isDisabled - boolean
defaultValue
options - {key: string, value: string, label: string} (types: select, autocompleteSelect, multiSelect)
tooltipText - string (select only)
parentKey - key of parent field, which determines the options displayed (select/multiselect only)
childKey - key of child field (select/multiselect only)
*/
const FormDialog = ({
  fields,
  multiCol = false,
  header,
  actionTitle,
  onAction,
  onClose,
  centerDialogActions = false
}) => {
  const [fieldValues, setFieldValues] = useState({})
  const [invalidFields, setInvalidFields] = useState([])
  const [alert, setAlert] = useState({ isOpen: false, type: '', message: '' })

  // set default values
  useEffect(() => {
    let defaultValues = {}

    if (multiCol) {
      // get default values for fields in each col
      const rowDefaultValues = fields.map((col) => {
        return col.reduce((obj, curr) => {
          return {
            ...obj,
            [curr.key]: curr.defaultValue
          }
        }, {})
      })
      // merge row default values
      rowDefaultValues.forEach((row) => {
        defaultValues = { ...defaultValues, ...row }
      })
    } else {
      defaultValues = fields.reduce((obj, curr) => {
        return {
          ...obj,
          [curr.key]: curr.defaultValue
        }
      }, {})
    }
    setFieldValues(defaultValues)
  }, [multiCol, fields])

  const updateField = (fieldKey, value, childKey = null) => {
    let updateValues = { ...fieldValues, [fieldKey]: value }
    // resets child field if its parent field is updated
    if (childKey) {
      updateValues = { ...updateValues, [childKey]: '' }
    }

    setFieldValues(updateValues)
  }

  const validateRequiredField = (field) => {
    if (field.isRequired) {
      if (field.type === 'datepicker') {
        if (!fieldValues[field.key]) {
          return false
        } else if (fieldValues[field.key].toString() === 'Invalid Date') {
          setAlert({ isOpen: true, type: 'error', message: COPY.INVALID_DATE_ERROR })
          return false
        }
      } else if (!fieldValues[field.key] || !fieldValues[field.key].length) {
        // required field is invalid if null, undefined, or empty string
        return false
      }
    }
    return true
  }

  const validateForm = () => {
    const invalidRequiredFields = []
    if (multiCol) {
      fields.forEach((row) => {
        row.forEach((field) => {
          // only validate field if it's currently being displayed
          const isFieldDisplayed = field.conditionalRenderCheck ? field.conditionalRenderCheck(fieldValues) : true
          const isValid = isFieldDisplayed ? validateRequiredField(field) : true
          if (!isValid) invalidRequiredFields.push(field.key)
        })
      })
    } else {
      fields.forEach((field) => {
        const isValid = validateRequiredField(field)
        if (!isValid) invalidRequiredFields.push(field.key)
      })
    }

    return invalidRequiredFields
  }

  const renderFieldType = (field) => {
    switch (field.type) {
      case 'text':
        return (
          <FormTextField
            key={field.key}
            field={field}
            fieldValues={fieldValues}
            updateField={updateField}
            hasError={invalidFields.includes(field.key)}
          />
        )
      case 'select':
        return (
          <FormSelect
            key={field.key}
            field={field}
            fieldValues={fieldValues}
            updateField={updateField}
            hasError={invalidFields.includes(field.key)}
          />
        )
      case 'autocompleteSelect':
        return (
          <FormAutocompleteSelect
            key={field.key}
            field={field}
            fieldValues={fieldValues}
            updateField={updateField}
            hasError={invalidFields.includes(field.key)}
          />
        )
      case 'multiSelect':
        return (
          <FormMultiSelect
            key={field.key}
            field={field}
            fieldValues={fieldValues}
            updateField={updateField}
            hasError={invalidFields.includes(field.key)}
          />
        )
      case 'datepicker':
        return (
          <MuiPickersUtilsProvider utils={DateFNSUtils} key={field.key}>
            <DatePicker
              InputLabelProps={{ shrink: true }}
              id={`${field.key}DatePicker`}
              required={field.isRequired}
              disabled={field.isDisabled || false}
              label={field.label}
              value={fieldValues[field.key]}
              format='MM/dd/yyyy'
              variant='inline'
              onChange={(date) => updateField(field.key, date)}
              error={invalidFields.includes(field.key)}
              severity={invalidFields.includes(field.key) ? 'error' : ''}
              helperText={invalidFields.includes(field.key) ? `${field.label} is a required field.` : ''}
            />
          </MuiPickersUtilsProvider>
        )
    }
  }

  const formBody = multiCol ? (
    <Column>
      {fields.map((row, rowIdx) => {
        return (
          <MultiColRow key={rowIdx} className='multiCol'>
            {row.map((field, fieldIdx) => {
              // conditional rendering of field
              const isFieldDisplayed = field.conditionalRenderCheck ? field.conditionalRenderCheck(fieldValues) : true
              return isFieldDisplayed ? (
                <StyledField key={fieldIdx} multicol={row.length > 1}>
                  {renderFieldType(field)}
                </StyledField>
              ) : null
            })}
          </MultiColRow>
        )
      })}
    </Column>
  ) : (
    <Column>
      {fields.map((field, fieldIdx) => {
        return (
          <StyledField key={fieldIdx} multicol={false}>
            {renderFieldType(field)}
          </StyledField>
        )
      })}
    </Column>
  )

  return (
    <>
      <Dialog
        centerDialogActions={centerDialogActions}
        header={header}
        body={formBody}
        actionTitle={actionTitle}
        onAction={async () => {
          // reset invalid fields
          setInvalidFields([])
          const invalidRequiredFields = validateForm()
          const isValidForm = !invalidRequiredFields.length
          // if all required fields completed, call action
          if (isValidForm) {
            try {
              await onAction(fieldValues)
            } catch (err) {
              const errMessage = err.message.length ? err.message : COPY.SUBMIT_ERROR_MESSAGE
              setAlert({ isOpen: true, type: 'error', message: errMessage })
            }
          } else {
            setInvalidFields(invalidRequiredFields)
          }
        }}
        onClose={onClose}
        fullWidth={true}
      />
      {alert.isOpen && (
        <ToastNotification
          alert={alert}
          onClose={() => {
            setAlert({ isOpen: false, type: '', message: '' })
          }}
        />
      )}
    </>
  )
}

export default FormDialog
