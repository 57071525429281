import { getFormattedDate } from '../../utils/dates'

export const organizeClassroomsBySchool = (classrooms) => {
  return classrooms.reduce((obj, curr) => {
    const schoolId = curr.organizationUnitId
    return {
      ...obj,
      [schoolId]: obj[schoolId] ? [curr, ...obj[schoolId]] : [curr]
    }
  }, {})
}

export const getSchoolOptions = (schools) => {
  const schoolOptions = schools.map((school) => {
    return {
      key: school.organizationUnitId,
      value: school.organizationUnitId,
      label: school.name
    }
  })

  return schoolOptions
}

export const formatClassrooms = (classrooms) => {
  return classrooms
    .filter((classroom) => classroom?.enabledInEBPlatform === true)
    .map((classroom) => {
      return {
        key: classroom.participantGroupId,
        value: classroom.participantGroupId,
        label: classroom.name
      }
    })
}

export const getClassroomOptions = (classroomsBySchool, schools) => {
  return schools.reduce((obj, school) => {
    const classrooms = classroomsBySchool[school.organizationUnitId]
    return {
      ...obj,
      [school.organizationUnitId]: classrooms ? formatClassrooms(classrooms) : []
    }
  }, {})
}

export const getDefaultClassrooms = (student, defaultValues) => {
  if (student && student.ParticipantGroup) {
    return formatClassrooms(student.ParticipantGroup)
  } else if (defaultValues && defaultValues.classrooms) {
    return formatClassrooms(defaultValues.classrooms)
  }
  return []
}

export const getDefaultSchool = (student, schoolId) => {
  if (student && student.ParticipantGroup && student.ParticipantGroup[0]) {
    return student.ParticipantGroup[0].organizationUnitId
  } else {
    return schoolId
  }
}

export const getDefaultGrade = (student) => {
  if (student) {
    return student.grade ? student.grade : ''
  }
  // defaults new student to blank grade so student isn't accidentally assigned to incorrect default grade
  return ''
}

export const buildStudentPayload = (student, fieldValues) => {
  return {
    participantId: student ? student.participantId : null,
    organizationUnitId: fieldValues.schoolId,
    participantGroupIds:
      fieldValues.classrooms && fieldValues.classrooms.length
        ? fieldValues.classrooms.map((classroom) => classroom.value)
        : null,
    firstName: fieldValues.firstName,
    middleName: fieldValues.middleName,
    lastName: fieldValues.lastName,
    dateOfBirth: getFormattedDate(fieldValues.dateOfBirth),
    grade: fieldValues.grade,
    tags: fieldValues.tags ? getSelectedTagValues(fieldValues.tags) : null,
    externalId: fieldValues.externalId
  }
}

const getSelectedTagValues = (selectedTags) => {
  return selectedTags.map((tag) => tag.value).join(',')
}
