export const downloadFile = async ({ link, onAlert }) => {
  try {
    const response = await fetch(link, {
      method: 'GET',
      credentials: 'include'
    })
    if (!response.ok) {
      throw new Error(`${response.status}: An error occurred while fetching the file`)
    }

    const filename = response.headers.get('Filename')
    const blob = await response.blob()
    const file = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = file
    a.download = filename
    a.click()
  } catch (err) {
    onAlert('error', 'Report not found')
  }
}
