// COLORS
const lightGray = '#f4f4f4'
const darkGray = '#474747'
const gray = '#e9ebee'
const secondaryGray = '#dfe4ea'
const secondaryDarkGray = '#ced5df'
const blueGray = '#E6EBEF'
const darkerBlueGray = 'rgba(193, 199, 208, 0.40)'
// const black = '#222222'
// const mediumGray = '#c3c7c4'

// pinks + reds
const pink = '#ffc0cb'
const maroon = '#800000'

// greens
const darkerGreen = '#388c76'
const forestGreen = '#254022'
const alligatorGreen = '#3b9880'

// ---------NEW COLORS (commented out until use, otherwise it breaks)
// PRIMARY/SECONDARY/TRANSPARENT COLORS
// // blue
const primaryBlue = '#475eb6'
const secondaryBlue = '#ebf2ff'
const darkerSecondaryBlue = '#70A2FF'
const transparentBlue = '#F4F8FF'
const lightBlue = '#c9dbfd'
const brightBlue = '#375dc5'
const veryDarkBlue = '#2c3a53'
// // green
const primaryGreen = '#3b9880'
// const secondaryGreen = '#E5F6EF'
// const transparentGreen = '#F5FCF9'
// // yellow
const primaryYellow = '#F5B800'
const secondaryYellow = '#FDF4E0'
const lightYellow = '#fff2c2'
const darkYellow = '#8f6900'
const mutedYellow = '#FFF5CE'

// const transparentYellow = '#FFFBF1'

// // red
const primaryRed = '#FF5630'
const softRed = '#f46f6f'

const mutedRed = '#FFE8E8'
const darkRed = '#ae3535'
const darkRed2 = '#F68C8C'
// const transparentRed = '#FFF6F4'
const primaryPurple = '#936efc'

// // TYPOGRAPHY COLORS
// // navy/royal blue
const navyBlue = '#113384'
const darkNavyBlue = '#132c8d'
const darkerNavyBlue = '#0e2a6c'
// // varying grays that get lighter
const textDarkGray = '#4E5D78'
const textMediumGray = '#8A94A6'
const textLightGray = '#B0B7C3'
const lightGrey = '#e9ebee'
const lightestGray = 'rgba(78, 93, 120, 0.07)'

// // BACKGROUND COLORS
// // shades of gray that get lighter
const bgDarkGray = '#C1C7D0'
const bgDarkerGray = '#8a94a6'
const bgMediumGray = '#f3f3f3'
const bgLightGray = '#f4f6f8'
const bgLighterGray = '#fcfcfc'
const white = '#FFFFFF'

export const BUTTON_BACKGROUND = primaryBlue
export const BUTTON_TEXT_WHITE = white
export const BUTTON_TEXT_HOVER = white
export const BUTTON_BACKGROUND_HOVER = navyBlue
export const BUTTON_SECONDARY_BACKGROUND = secondaryGray
export const BUTTON_SECONDARY_BORDER = primaryBlue
export const BUTTON_SECONDARY_TEXT = navyBlue
export const BUTTON_SECONDARY_TEXT_HOVER = darkerNavyBlue
export const BUTTON_SECONDARY_BACKGROUND_HOVER = secondaryDarkGray

export const CANCEL_BUTTON_BACKGROUND = bgDarkerGray
export const CANCEL_BUTTON_TEXT = white
export const CANCEL_BUTTON_HOVER = bgDarkGray

// district users table
export const RESEND_BUTTON_COLOR = white
export const RESEND_BUTTON_TEXT = primaryBlue
export const RESEND_BUTTON_HOVER_TEXT = navyBlue
export const RESEND_BUTTON_BORDER = textLightGray
export const SCHOOL_CHIP_BG = secondaryBlue
export const SCHOOL_CHIP_BORDER = textDarkGray
export const SCHOOL_CHIP_TEXT = textDarkGray
export const SCHOOL_TOOLTIP_BG = white
export const SCHOOL_TOOLTIP_BORDER = textDarkGray
export const SCHOOL_TOOLTIP_TEXT = textDarkGray

// RAN scoring dialog
export const AUDIO_WRAPPER_BG = white
export const RAN_HEADER_TEXT = textDarkGray
export const DIALOG_WRAPPER_BG = bgMediumGray
export const RAN_DIALOG_BG = white

// auth views (login, forgot password, reset password)
export const AUTH_LINK_TEXT = navyBlue
export const AUTH_BACKGROUND = bgLightGray
export const AUTH_MODAL_BACKGROUND = white
export const AUTH_HEADER = navyBlue
export const AUTH_SUBHEADER = textLightGray
export const AUTH_TEXT = textDarkGray
export const AUTH_FOOTER_TEXT = textDarkGray
export const PIP_BACKGROUND = white
export const ERROR_BACKGROUND = pink
export const ERROR_TEXT = maroon
export const BUTTON_COLOR = alligatorGreen
export const BUTTON_TEXT = white
export const BUTTON_HOVER = darkerGreen
export const RESET_CHECK_FULFILLED = primaryBlue
export const RESET_CHECK_UNFULFILLED = textLightGray
export const PWD_REQ_TEXT = white
export const FORGOT_PWD_TEXT = textDarkGray

// hamburger menu
export const APPBAR_COLOR = white
export const HAMBURGER_ICON = primaryBlue
export const MENU_TEXT = navyBlue
export const DIVIDER = navyBlue
export const MENU_BACKGROUND = white
export const MENU_SHADOW = textLightGray
export const VIEW_HEADER_TEXT = textDarkGray

// avatar
export const AVATAR_BACKGROUND = primaryBlue
export const AVATAR_TEXT = white

// toast notification
export const SUCCESS_MSG_ACTION = forestGreen

// tab underline
export const TAB_UNDERLINE = navyBlue
export const TAB_BACKGROUND = bgLightGray
export const TAB_DIVIDER = bgDarkGray
export const TAB_SELECTED_TEXT = textDarkGray
export const TAB_UNSELECTED_TEXT = textLightGray

// table
export const TABLE_BACKGROUND = white
export const TABLE_HEADER_TEXT = textDarkGray
export const TABLE_BORDER = bgLightGray
export const TABLE_WRAPPER = bgLightGray
export const TABLE_ROW_HOVER = transparentBlue
export const TABLE_TEXT_DEFAULT = textLightGray
export const TABLE_TEXT_PRIORITY = textDarkGray
export const ICON_DEFAULT = textLightGray
export const ICON_HOVER = primaryBlue
export const LINK_HOVER = primaryBlue
export const WARNING_CHIP = primaryYellow

// super admin view table buttons
export const TABLE_BUTTON_BORDER = navyBlue
export const TABLE_BUTTON_HOVER = alligatorGreen

// student details table
export const TABLE_BACKGROUND_COLOR = white
export const LOWER_RISK_PERCENTILE_BACKGROUND = lightBlue
export const RISK_PERCENTILE_BACKGROUND = primaryBlue
export const RISK_PERCENTILE_TEXT = primaryBlue
export const ASSMNT_RISK_TEXT = white
export const ASSMNT_PERCENTILE_TEXT = textMediumGray
export const STUDENT_DETAILS_CELL_BORDER = bgMediumGray
export const STUDENT_DETAILS_DIVIDER = bgDarkerGray
export const STUDENT_DETAILS_BACKGROUND = lightGray
export const PWR_RISK_TEXT = darkYellow
export const PWR_RISK_BACKGROUND = lightYellow
export const STUDENT_DETAILS_HEADER_BACKGROUND = textDarkGray
export const STUDENT_DETAILS_HEADER_TEXT = white
export const STUDENT_DETAILS_SUBHEADER_TEXT = darkGray
export const STUDENT_DETAILS_CELL_BACKGROUND = white
export const STUDENT_NAME_LINK = textDarkGray
export const STUDENT_NAME_LINK_HOVER = textMediumGray
export const STUDENT_DETAILS_LEGEND_TEXT = textDarkGray
export const POPOVER_TEXT = textDarkGray
export const COMPLETE_CHECK = textLightGray
export const NOT_APPLICABLE_ASSMNT = lightGrey
export const LEGEND_BACKGROUND = white
export const LEGEND_HEADER_UNDERLINE = bgDarkGray
export const CELL_HOVER = darkerSecondaryBlue
export const CELL_HIGHLIGHT = secondaryBlue
export const UNIT_HEADER_BACKGROUND = bgLighterGray
export const UNIT_HEADER_TEXT = textDarkGray
export const RATIO_SCORE_TEXT = textDarkGray
export const RATIO_SCORE_TEXT_HOVER = textMediumGray
export const RATIO_CONTAINER_BORDER = bgDarkGray
export const SINGLE_COL_PWR_HIGH_RISK_TEXT = darkRed
export const SINGLE_COL_PWR_HIGH_RISK_BG = mutedRed
export const SINGLE_COL_PWR_MODERATE_RISK_TEXT = darkYellow
export const SINGLE_COL_PWR_MODERATE_RISK_BG = mutedYellow
export const SINGLE_COL_PWR_LOW_RISK_TEXT = textDarkGray

// table icons
export const NEEDS_SCORING_ICON = primaryBlue
export const INCOMPLETE_ICON = primaryPurple
export const RISK_FLAG_ICON = softRed
export const EXCLAMATION_ICON = primaryYellow
export const INFO_ICON_DEFAULT = textDarkGray
export const INFO_ICON_ACTIVE = textMediumGray
export const NOT_YET_ICON = primaryYellow
export const ON_TRACK_ICON = textMediumGray
export const NO_RISK_ICON_BACKGROUND = white
export const NO_RISK_ICON_BORDER = textMediumGray
export const NOT_APPLICABLE_ICON_BACKGROUND = lightGrey
export const NOT_APPLICABLE_ICON_BORDER = textMediumGray
export const PWR_PERCENTAGE_ICON = lightYellow
export const PWR_PERCENTAGE_ICON_BORDER = primaryYellow
export const TOGGLE_BACKGROUND = blueGray
export const TOGGLE_BORDER = darkerBlueGray
export const TOGGLE_THUMB = white
export const TOGGLE_THUMB_BORDER = bgMediumGray
export const TOGGLE_THUMB_SHADOW = lightestGray
export const TOGGLE_TEXT = textMediumGray
export const TOGGLE_TEXT_BOLD = textDarkGray
export const NOT_AVAILABLE_ICON = textMediumGray
export const SINGLE_COL_PWR_HIGH_RISK_BORDER = darkRed2
export const SINGLE_COL_PWR_MODERATE_RISK_BORDER = primaryYellow
export const SINGLE_COL_PWR_LOW_RISK_BG = white
export const SINGLE_COL_PWR_LOW_RISK_BORDER = textMediumGray
export const DYSLEXIA_MESSAGE_BACKGROUND = bgLightGray
export const SCORE_ENTRY_ICON_BG = blueGray

// loading indicator
export const LIGHT_BACKGROUND = white

// student view
export const STUDENT_VIEW_SHADOW = bgLightGray
export const STUDENT_VIEW_BACKGROUND = white
export const STUDENT_RESULTS_BORDER = bgDarkGray
export const STUDENT_VIEW_DARK_TEXT = textDarkGray
export const STUDENT_VIEW_MEDIUM_TEXT = textMediumGray
export const STUDENT_VIEW_LIGHT_TEXT = textLightGray
export const NO_ACCORDION_BG = bgLightGray
export const NEXT_STEPS_LINK_HOVER = primaryBlue
export const SLIDER_ON_TRACK_BACKGROUND = bgMediumGray
export const SLIDER_ON_TRACK_BORDER = textLightGray
export const SLIDER_AT_RISK_BACKGROUND = lightBlue
export const SLIDER_AT_RISK_BORDER = primaryBlue
export const SLIDER_AT_HIGH_RISK = primaryBlue
export const STUDENT_VIEW_LOWER_RISK_TEXT = navyBlue
export const SELECTED_TIME_PERIOD_BORDER = primaryYellow
export const SELECTED_TIME_PERIOD_BACKGROUND = secondaryYellow
export const SLIDER_DARK = darkNavyBlue
export const SLIDER_MEDIUM = primaryBlue
export const SLIDER_LIGHT = lightBlue
export const SLIDER_WHITE = white
export const SLIDER_MARKER = primaryYellow
export const SLIDER_BORDER = textLightGray
export const PREDICTIVE_PROFILE_TOKEN_BORDER = textDarkGray
export const NOT_APPLICABLE = gray
export const PARTICIPANT_CODE_BACKGROUND = secondaryBlue
export const PARTICIPANT_CODE_TEXT = primaryBlue
export const PLACEHOLDER_BACKGROUND = textDarkGray

// accordion
export const ACCORDION_BG = bgLightGray
export const ACCORDION_TEXT = textDarkGray

// inputs
export const INPUT_PLACEHOLDER_TEXT = textLightGray
export const DEFAULT_INPUT_BORDER = textLightGray
export const SUCCESS_INPUT_BORDER = primaryGreen
export const ACTIVE_INPUT_BORDER = primaryBlue
export const ERROR_INPUT_BORDER = primaryRed
export const INPUT_BACKGROUND = white
export const DISABLED_INPUT = lightGray

// breadcrumbs
export const BREADCRUMB_TEXT = textDarkGray
export const BREADCRUMB_HOVER_TEXT = primaryBlue

// dialogs
export const DIALOG_HEADER_TEXT = navyBlue
export const DIALOG_LABEL_TEXT = textLightGray
export const DIALOG_INPUT_TEXT = textDarkGray
export const WARNING_ICON = primaryYellow
export const ITEM_INVENTORY_SELECTED_BG = bgLightGray
export const ITEM_INVENTORY_TEXT = textDarkGray
export const ITEM_INVENTORY_TABLE_BORDER = bgDarkGray
export const ITEM_INVENTORY_EDITED_BORDER = primaryBlue
export const ITEM_INVENTORY_INCORRECT_BG = secondaryYellow
export const ITEM_INVENTORY_INCORRECT_BORDER = primaryYellow
export const ITEM_INVENTORY_DISABLED_BG = gray
export const ITEM_INVENTORY_POPOVER_HEADER = secondaryBlue
export const ITEM_INVENTORY_HOVER_BORDER = darkerSecondaryBlue

// test details view
export const TEST_RESULTS_TEXT = textDarkGray
export const TEST_DETAILS_HEADER = textDarkGray

// unauthorized/empty states
export const UNAUTHORIZED_TEXT = textDarkGray
export const EMPTY_STATES_TEXT = textDarkGray

// about eb
export const ABOUT_EB_TEXT = textDarkGray
export const ABOUT_EB_HEADER = navyBlue
export const EB_LINK_HOVER = primaryBlue
export const EB_LINK = navyBlue

// reports
export const REPORT_BUTTON_INFO_TEXT = textDarkGray

// banner
export const BANNER_LINK = brightBlue
export const BANNER_TEXT = veryDarkBlue
