import { RouterLink } from '../../../../ui-components/cells'
import * as ROLE from '../../../../constants/roles'
import EditCell from '../../../../components/EditCell'
import { deleteSchool } from '../../../../lib/api'

export const hasManagePermission = (role) => {
  return [ROLE.SUPER_ADMIN, ROLE.DISTRICT_ADMIN].includes(role)
}

export const createSchoolColumns = (role) => {
  const schoolColumns = [{ title: 'Name', key: 'school', customCell: RouterLink }]

  if (hasManagePermission(role)) {
    schoolColumns.push({ title: 'Edit', key: 'edit', customCell: EditCell })
  }

  return schoolColumns
}

export const createSchoolRowData = (school, role, onEdit, onAlert) => {
  const schoolRows = {
    school: { name: school.name, id: school.organizationUnitId, key: 'school' }
  }

  if (hasManagePermission(role)) {
    schoolRows.edit = {
      onEdit: () => onEdit(school),
      deleteData: {
        itemId: school.organizationUnitId,
        itemName: school.name,
        onDelete: deleteSchool,
        actionTitle: 'Delete',
        onAlert: onAlert,
        type: 'school'
      }
    }
  }

  return schoolRows
}
