import { Auth } from 'aws-amplify'
import { useFeatureFlagEnabled } from 'posthog-js/react'
import { useCallback } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'

import { requestPasswordReset, confirmPasswordReset } from 'lib/api/login'

const API_BASE = process.env.REACT_APP_API_BASE_URL

export const useAuth = () => {
  const sessionAuthEnabled = useFeatureFlagEnabled('session-auth')
  const queryClient = useQueryClient()

  const getUserInfo = useCallback(async () => {
    if (sessionAuthEnabled) {
      return await getSessionUserInfo()
    }
    return await Auth.currentUserInfo()
  }, [sessionAuthEnabled])

  const signIn = useCallback(
    async (email, password) => {
      if (sessionAuthEnabled) {
        return await sessionSignIn(email, password)
      }
      return await Auth.signIn(email, password)
    },
    [sessionAuthEnabled]
  )

  const signOut = useCallback(async () => {
    if (sessionAuthEnabled) {
      await sessionSignOut()
      queryClient.invalidateQueries({ queryKey: ['current-user'] })
    } else {
      await Auth.signOut()
      queryClient.invalidateQueries({ queryKey: ['current-user'] })
    }
  }, [sessionAuthEnabled, queryClient])

  const resetPassword = async (email) => {
    if (sessionAuthEnabled) {
      await requestPasswordReset(email)
    } else {
      Auth.forgotPassword(email)
    }
  }

  const confirmNewPassword = async (email, code, password) => {
    if (sessionAuthEnabled) {
      await confirmPasswordReset(email, code, password)
    } else {
      Auth.forgotPasswordSubmit(email, code, password)
    }
  }
  const completePasswordSignup = async (user, newPassword, tempPassword) => {
    if (sessionAuthEnabled) {
      return await completeNewPasswordRequest(user.email, newPassword, tempPassword)
    }
    return await Auth.completeNewPassword(user, newPassword)
  }

  return {
    getUserInfo,
    signIn,
    signOut,
    resetPassword,
    confirmNewPassword,
    completePasswordSignup
  }
}

export const useCurrentUserQuery = () => {
  const sessionAuthEnabled = useFeatureFlagEnabled('session-auth')
  const query = useQuery({
    queryKey: ['current-user', sessionAuthEnabled],
    queryFn: getUserInfo,
    enabled: sessionAuthEnabled !== undefined
  })
  return query
}

const getUserInfo = async ({ queryKey }) => {
  const [, sessionAuthEnabled] = queryKey
  if (sessionAuthEnabled) return await getSessionUserInfo()
  const amplifyData = await Auth.currentUserInfo()
  if (!amplifyData) return null
  return { email: amplifyData.attributes.email }
}

const getSessionUserInfo = async () => {
  const resp = await fetch(`${API_BASE}/dashboard/current-user`, {
    credentials: 'include'
  })
  if (resp.status === 401) {
    return null
  }
  const data = await resp.json()
  return data
}

const sessionSignIn = async (email, password) => {
  const resp = await fetch(`${API_BASE}/dashboard/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({ username: email, password })
  })

  if (resp.status === 412) {
    return { email, challengeName: 'NEW_PASSWORD_REQUIRED' }
  }

  if (!resp.ok) {
    throw new Error(`Login failed: ${resp.status} ${resp.statusText}`)
  }

  return await resp.json()
}

const sessionSignOut = async () => {
  const resp = await fetch(`${API_BASE}/dashboard/logout`, {
    method: 'POST',
    credentials: 'include'
  })
  if (!resp.ok) {
    console.error(`Signout failed: ${resp.status} ${resp.statusText}`)
  }
}

const completeNewPasswordRequest = async (email, newPassword, tempPassword) => {
  const resp = await fetch(`${API_BASE}/dashboard/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({ username: email, password: tempPassword, newpassword: newPassword })
  })

  if (!resp.ok) {
    throw new Error(`Login failed: ${resp.status} ${resp.statusText}`)
  }

  return await resp.json()
}
