import React, { useState } from 'react'
import Dialog from '../Dialog'
import { IconButton } from '../../ui-components/icons'
import { Icon, WarningIcon, WarningMessage } from './styles'
import { WARNING } from './copy'
import posthog from 'posthog-js'

const DeleteIcon = ({ itemId, itemName, onDelete, actionTitle, onAlert, userWarning = false, type }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const deleteItem = async () => {
    try {
      await onDelete(itemId)
      const successMsg = `${itemName} was successfully deleted.`
      onAlert('success', successMsg)
      posthog.capture('Deleted entity', { type: type })
    } catch (err) {
      onAlert('error', err.message)
    }
  }

  return (
    <div>
      <IconButton onClick={() => setIsDialogOpen(true)} className='deleteButton'>
        <Icon />
      </IconButton>
      {isDialogOpen && (
        <Dialog
          body={
            <div>
              <p>{`Are you sure you want to delete ${itemName} and its associations?`}</p>
              {userWarning && (
                <WarningMessage>
                  <WarningIcon />
                  {WARNING}
                </WarningMessage>
              )}
            </div>
          }
          header={`Delete ${itemName}`}
          actionTitle={actionTitle}
          onAction={() => {
            deleteItem()
            setIsDialogOpen(false)
          }}
          onClose={() => setIsDialogOpen(false)}
        />
      )}
    </div>
  )
}

export default DeleteIcon
