import React from 'react'
import { TableCell } from '../../styles'
import CharacterToken from '../CharacterToken'

const CharacterCell = ({ assessmentKey }) => {
  return (
    <TableCell className='rowToken'>
      <CharacterToken assessmentId={assessmentKey} />
    </TableCell>
  )
}

export default CharacterCell
