import addDays from 'date-fns/addDays'

export const getCurrentTimePeriod = (assmntConfig) => {
  const today = new Date()
  const period = assmntConfig.find((config) => {
    const startDate = new Date(config.startDate)
    const endDate = addDays(new Date(config.endDate), 1)
    return today >= startDate && today < endDate
  })
  return period.periodName
}
