import React from 'react'
import * as S from './styles'
import { ReactComponent as CorrectMark } from 'ui-components/tables/Correct.svg'
import { ReactComponent as IncorrectMark } from 'ui-components/tables/Incorrect.svg'

const Legend = () => {
  return (
    <div style={S.legendContainerStyles}>
      <div style={S.legendStyles}>
        <div style={S.legendMarkContainerStyles}>
          <CorrectMark style={S.correctMarkSyles} width={13} height={9} />
          <IncorrectMark style={S.incorrectMarkStyles} width={11} height={10} />
        </div>
        <div style={S.legendTextContainerStyles}>
          <span style={S.legendTextStyle}> Correct</span>
          <span style={S.legendTextStyle}> Incorrect</span>
        </div>
      </div>
    </div>
  )
}

export default Legend
