import styled from 'styled-components'
import * as COLOR from '../../../../constants/colors'

export const ButtonLink = styled.a({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textDecoration: 'none',
  color: COLOR.BUTTON_TEXT_WHITE,
  fontFamily: 'Quicksand',
  fontWeight: 600,
  fontSize: '0.875rem',
  border: 0,
  borderRadius: '0.9rem',
  height: '2.6rem',
  width: '11.25rem',
  backgroundColor: `${COLOR.BUTTON_BACKGROUND}`,
  marginBottom: '2.25rem',
  '&:hover': {
    backgroundColor: `${COLOR.BUTTON_BACKGROUND_HOVER}`,
    color: `${COLOR.BUTTON_TEXT_HOVER}`
  },
  '&:visited': {
    color: COLOR.BUTTON_TEXT_WHITE
  }
})
