import styled from 'styled-components'
import { StandardButton } from '../../ui-components/buttons/buttons'
import * as COLOR from 'constants/colors'

export const ResendButton = styled(StandardButton)({
  '&.MuiButton-root': {
    width: '9.3rem',
    backgroundColor: COLOR.RESEND_BUTTON_COLOR,
    color: COLOR.RESEND_BUTTON_TEXT,
    border: `solid 1px ${COLOR.RESEND_BUTTON_BORDER}`,
    '&:hover': {
      backgroundColor: COLOR.RESEND_BUTTON_COLOR,
      color: COLOR.RESEND_BUTTON_HOVER_TEXT,
      cursor: 'pointer'
    }
  }
})

export const InviteButton = styled(StandardButton)({
  '&.MuiButton-root': {
    width: '9.3rem',
    backgroundColor: COLOR.RESEND_BUTTON_TEXT,
    color: COLOR.RESEND_BUTTON_COLOR,
    border: `solid 1px ${COLOR.RESEND_BUTTON_BORDER}`,
    '&:hover': {
      backgroundColor: COLOR.RESEND_BUTTON_COLOR,
      color: COLOR.RESEND_BUTTON_HOVER_TEXT,
      cursor: 'pointer'
    }
  }
})
