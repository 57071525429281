// roles

export const SUPER_ADMIN = 'SUPER_ADMIN'
export const DISTRICT_ADMIN = 'ORG_ADMIN'
export const SCHOOL_ADMIN = 'ORG_UNIT_ADMIN'
export const SPECIALIST = 'SPECIALIST'
export const TEACHER = 'TEACHER'

export const SUPER_ADMIN_ROLE_ID = '565b1f1b-c7a6-47e0-a1cc-29f849b0ff05'
export const DISTRICT_ADMIN_ROLE_ID = '565b1f1b-c7a6-47e0-a1cc-29f849b0ff01'
export const SCHOOL_ADMIN_ROLE_ID = '565b1f1b-c7a6-47e0-a1cc-29f849b0ff02'
export const TEACHER_ROLE_ID = '565b1f1b-c7a6-47e0-a1cc-29f849b0ff04'

export const DISPLAY_SUPER_ADMIN = 'Super Admin'
export const DISPLAY_DISTRICT_ADMIN = 'District Admin'
export const DISPLAY_SCHOOL_ADMIN = 'School Admin'
export const DISPLAY_TEACHER = 'Teacher'

export const ROLE_BY_ID = {
  [SUPER_ADMIN_ROLE_ID]: DISPLAY_SUPER_ADMIN,
  [DISTRICT_ADMIN_ROLE_ID]: DISPLAY_DISTRICT_ADMIN,
  [SCHOOL_ADMIN_ROLE_ID]: DISPLAY_SCHOOL_ADMIN,
  [TEACHER_ROLE_ID]: DISPLAY_TEACHER
}
