import React, { useState } from 'react'
import Popover from 'components/Popover'
import { Wrapper, InfoIcon } from './styles'

const InfoPopover = ({ id, info, triggerText = null, textPosition = 'left' }) => {
  const [isOpen, setOpen] = useState(false)

  return (
    <Popover
      id={id}
      trigger={
        <Wrapper>
          {triggerText && textPosition === 'left' && triggerText}
          <InfoIcon className={`${isOpen && 'infoOpen'} ${triggerText && textPosition}`} />
          {triggerText && textPosition === 'right' && triggerText}
        </Wrapper>
      }
      body={<span style={{ whiteSpace: 'pre-line' }}>{info}</span>}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
    />
  )
}

export default InfoPopover
