import * as GRADE from './grades'
import { BOY, MOY, EOY } from './timePeriods'

export const RISK_CATEGORIES = {
  LOW: 'LOW',
  MODERATE: 'MODERATE',
  HIGH: 'HIGH'
}

// pwr is potential for word reading, so high risk equates to low potential and vice versa
export const pwrTextByRisk = {
  [RISK_CATEGORIES.HIGH]: 'Low',
  [RISK_CATEGORIES.MODERATE]: 'Moderate',
  [RISK_CATEGORIES.LOW]: 'High'
}

export const SINGLE_COL_PWR_CUTOFF_TEXT = {
  [GRADE.FIRST]: {
    [BOY]: {
      [RISK_CATEGORIES.HIGH]: '0-34%',
      [RISK_CATEGORIES.MODERATE]: '34-65%',
      [RISK_CATEGORIES.LOW]: '65-100%'
    },
    [MOY]: {
      [RISK_CATEGORIES.HIGH]: '0-34%',
      [RISK_CATEGORIES.MODERATE]: '34-62%',
      [RISK_CATEGORIES.LOW]: '62-100%'
    },
    [EOY]: {
      [RISK_CATEGORIES.HIGH]: '0-20%',
      [RISK_CATEGORIES.MODERATE]: '20-62%',
      [RISK_CATEGORIES.LOW]: '62-100%'
    }
  },
  [GRADE.SECOND]: {
    [BOY]: {
      [RISK_CATEGORIES.HIGH]: '0-43%',
      [RISK_CATEGORIES.MODERATE]: '43-61%',
      [RISK_CATEGORIES.LOW]: '61-100%'
    },
    [MOY]: {
      [RISK_CATEGORIES.HIGH]: '0-39%',
      [RISK_CATEGORIES.MODERATE]: '39-56%',
      [RISK_CATEGORIES.LOW]: '56-100%'
    },
    [EOY]: {
      [RISK_CATEGORIES.HIGH]: '0-28%',
      [RISK_CATEGORIES.MODERATE]: '28-53%',
      [RISK_CATEGORIES.LOW]: '53-100%'
    }
  }
}
