// @ts-check

import { request } from '../helper'

export const getUserByEmail = async (email) => {
  const url = `/user/userByEmail/${email}`
  return await request('GET', url)
}

export const createUser = async (payload) => {
  const url = '/user'
  return await request('POST', url, payload)
}

export const updateUser = async (payload) => {
  const url = '/user'
  return await request('PUT', url, payload)
}

export const getUsersByDistrict = async (districtId) => {
  const url = `/user/organization/${districtId}`
  return await request('GET', url)
}

export const getUsersBySchool = async (schoolId) => {
  const url = `/user/organizationUnit/${schoolId}`
  return await request('GET', url)
}

export const getSuperAdmins = async () => {
  const url = '/user/superAdmins'
  return await request('GET', url)
}

export const deleteUser = async (userId) => {
  const url = `/user/${userId}`
  return await request('DELETE', url)
}

export const resendInvite = async (userEmail) => {
  const url = `/user/resendInvite/${userEmail}`
  return await request('POST', url)
}

export const inviteUser = async (userEmail) => {
  const url = `/user/inviteUser/${userEmail}`
  return await request('POST', url)
}

export const updateUserAuthStatus = async (payload) => {
  const url = '/user/updateAuthStatus'
  return await request('PUT', url, payload)
}

export const registerUser = async (userEmail) => {
  const url = '/registerUser'
  return await request('POST', url, { email: userEmail }, {}, false)
}
