import React, { useState, useEffect } from 'react'
import { PasswordReq, CheckIcon, Requirements, Row, Col, StyledText } from './styles'

const PasswordValidator = ({ password }) => {
  const [containsUppercase, setUppercase] = useState(false)
  const [containsLowercase, setLowercase] = useState(false)
  const [containsNumber, setNumber] = useState(false)
  const [containsSymbol, setSymbol] = useState(false)
  const [containsSixChars, setSixChars] = useState(false)

  useEffect(() => {
    const uppercase = /[A-Z]/
    const lowercase = /[a-z]/
    const number = /[0-9]/
    const symbol = /[^\w\s]|_/

    password.match(uppercase) ? setUppercase(true) : setUppercase(false)
    password.match(lowercase) ? setLowercase(true) : setLowercase(false)
    password.match(number) ? setNumber(true) : setNumber(false)
    password.match(symbol) ? setSymbol(true) : setSymbol(false)
    password.length >= 6 ? setSixChars(true) : setSixChars(false)
  }, [password])

  return (
    <div>
      <StyledText>New password must include:</StyledText>
      <Requirements>
        <Row>
          <Col>
            <PasswordReq id={containsUppercase ? 'uppercaseEnabled' : 'uppercaseDisabled'}>
              <CheckIcon isDisabled={containsUppercase} /> Uppercase
            </PasswordReq>
            <PasswordReq id='sixChars'>
              <CheckIcon isDisabled={containsSixChars} /> 6 Characters
            </PasswordReq>
          </Col>
          <Col>
            <PasswordReq id={containsLowercase ? 'lowercaseEnabled' : 'lowercaseDisabled'}>
              <CheckIcon isDisabled={containsLowercase} /> Lowercase
            </PasswordReq>
            <PasswordReq id={containsNumber ? 'numberEnabled' : 'numberDisabled'}>
              <CheckIcon isDisabled={containsNumber} /> Number
            </PasswordReq>
          </Col>
          <Col>
            <PasswordReq id={containsSymbol ? 'symbolEnabled' : 'symbolDisabled'}>
              <CheckIcon isDisabled={containsSymbol} /> Symbol
            </PasswordReq>
          </Col>
        </Row>
      </Requirements>
    </div>
  )
}

export default PasswordValidator
