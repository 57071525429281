import React from 'react'
import { AccordionSummary, ResourcesAccordion } from './styles'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const Accordion = ({ header, body, defaultExpanded = false }) => {
  return (
    <ResourcesAccordion defaultExpanded={defaultExpanded}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>{header}</AccordionSummary>
      <AccordionDetails>{body}</AccordionDetails>
    </ResourcesAccordion>
  )
}

export default Accordion
