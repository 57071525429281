import React, { useState, useEffect, useCallback } from 'react'
import Table from '../../../../components/Table'
import ClassroomDialog from '../../../../components/ClassroomDialog'
import TabActionButtons from 'components/TabActionButtons'
import { TableWrapper } from '../../../../ui-components/tabs'
import { getClassroomsBySchool } from '../../../../lib/api'
import { hasManagePermissions, getClassroomColumns, createClassroomData } from './helper'
import * as COPY from '../../copy'
import * as ROLE from '../../../../constants/roles'
import { EmptyStateWrapper } from '../../../../ui-components/viewStyles'
import { alphanumericSort } from '../../../../utils/sorts'
import { downloadFile } from 'utils/reports'

export const ClassroomTab = ({ school, role, onAlert }) => {
  const [classrooms, setClassrooms] = useState([])
  const [classroomDialog, setClassroomDialog] = useState({ isOpen: false, classroom: null })
  const [loading, setLoading] = useState({ isComplete: false, hasError: false })

  const loadClassrooms = useCallback(async () => {
    try {
      return await getClassroomsBySchool(school.organizationUnitId)
    } catch (err) {
      setLoading({ isComplete: true, hasError: true })
      onAlert('error', COPY.CLASSROOM_LOAD_ERROR)
      return null
    }
  }, [school, onAlert])

  useEffect(() => {
    if (loading.hasError) return

    let isMounted = true
    ;(async () => {
      const schoolClassrooms = await loadClassrooms()
      if (isMounted && schoolClassrooms) {
        setClassrooms(schoolClassrooms)
        setLoading({ isComplete: true, hasError: false })
      }
    })()
    return () => {
      isMounted = false
    }
  }, [loadClassrooms, loading.hasError])

  const onEdit = (classroom) => {
    setClassroomDialog({ isOpen: true, classroom: classroom })
  }

  const classroomColumns = getClassroomColumns(role)

  let classroomRows = classrooms.map((classroom) => createClassroomData(classroom, role, onEdit, onAlert))

  classroomRows = alphanumericSort(classroomRows, 'classroom', 'name')

  const onClassroomCreateOrUpdate = async () => {
    setClassroomDialog({ isOpen: false, classroom: null })
    // reload classrooms to get updated list
    await loadClassrooms()
  }

  return (
    <div>
      {classroomDialog.isOpen && (
        <ClassroomDialog
          classroom={classroomDialog.classroom}
          schoolId={school.organizationUnitId}
          onAction={onClassroomCreateOrUpdate}
          onClose={() => setClassroomDialog({ isOpen: false, classroom: false })}
          onAlert={onAlert}
          role={role}
        />
      )}
      <TableWrapper>
        {hasManagePermissions(role) && (
          <TabActionButtons
            downloadButtonProps={{
              id: 'downloadSchoolCSVButton',
              text: 'Download School CSV',
              onClick: () =>
                downloadFile({
                  link: `${process.env.REACT_APP_API_BASE_URL}/report/organizationUnit/${school.organizationUnitId}`,
                  onAlert: onAlert
                })
            }}
            createButtonProps={{
              id: 'createClassroomButton',
              text: 'Create Classroom',
              onClick: () => setClassroomDialog({ isOpen: true, classroom: null })
            }}
          />
        )}
        {loading.isComplete && (
          <div>
            {classrooms.length ? (
              <Table rows={classroomRows} columns={classroomColumns} />
            ) : (
              <EmptyStateWrapper>
                {role === ROLE.TEACHER ? COPY.EMPTY_STATE_CLASSROOM_TEACHER : COPY.EMPTY_STATE_CLASSROOM}
              </EmptyStateWrapper>
            )}
          </div>
        )}
      </TableWrapper>
    </div>
  )
}

export default ClassroomTab
