import * as React from 'react'
import { logError as logSentryError } from '../../sentry'

/* eslint-disable */
// getting contradictory eslint feedback here so disabling temporarily

const Fallback = (error) => (
  <div>
    Something went wrong: <span>{error.message}</span>
  </div>
)

const logError = (error, errorInfo) => {
  logSentryError({
    error: error,
    breadcrumb: {
      category: 'ErrorBoundary',
      data: {
        errorInfo: errorInfo
      }
    }
  })
}

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      error: null
    }
  }

  static getDerivedStateFromError(error) {
    return { error }
  }

  componentDidCatch(error, errorInfo) {
    this.props.handleError(error, errorInfo)
  }

  render() {
    if (this.state.error) {
      return this.props.fallback(this.state.error)
    }

    return this.props.children
  }
}

ErrorBoundary.defaultProps = {
  fallback: Fallback,
  handleError: logError
}

export default ErrorBoundary
