import styled from 'styled-components'
import * as COLOR from '../../../../constants/colors'

export const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  border: solid 2px ${COLOR.PREDICTIVE_PROFILE_TOKEN_BORDER};
`

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 3.5rem;
`
