import { RouterLink } from '../../../../ui-components/cells'
import { getFormattedDate } from '../../../../utils/dates'
import { hasDeletePermission, hasManagePermission, getStudentTeacher } from '../../../../utils/students'
import EditCell from '../../../../components/EditCell'
import { deleteStudent } from '../../../../lib/api'
import * as COPY from '../../../../constants/copy'

// table data

export const getStudentColumns = (role) => {
  const studentColumns = [
    { key: 'student', title: 'Name', customCell: RouterLink },
    { key: 'participantCode', title: 'Participant Code' },
    { key: 'dob', title: 'Date of Birth' },
    { key: 'teacher', title: 'Teacher' }
  ]
  if (hasManagePermission(role)) {
    studentColumns.push({ title: 'Edit', key: 'edit', customCell: EditCell })
  }

  return studentColumns
}

export const getClassroomTeachers = (classroom) => {
  const hasTeacher = classroom.User.length
  return hasTeacher
    ? classroom.User.map((teacher) => `${teacher.firstName} ${teacher.lastName}`).join(', ')
    : COPY.NO_TEACHER_ASSIGNED
}

export const createStudentData = (student, role, onEdit, onAlert) => {
  const studentData = {
    student: { name: `${student.lastName}, ${student.firstName}`, id: student.participantId, key: 'student' },
    participantCode: student.participantCode,
    dob: getFormattedDate(student.dateOfBirth),
    teacher: getStudentTeacher(student)
  }

  if (hasManagePermission(role)) {
    studentData.edit = {
      onEdit: () => onEdit(student)
    }
  }
  if (hasDeletePermission(role)) {
    studentData.edit.deleteData = {
      itemId: student.participantId,
      itemName: `${student.firstName} ${student.lastName}`,
      onDelete: deleteStudent,
      actionTitle: 'Delete',
      onAlert: onAlert,
      userWarning: true,
      type: 'student'
    }
  }

  return studentData
}
