import styled from 'styled-components'
import * as COLOR from '../../../../constants/colors'

export const CharacterImg = styled.img`
  width: 4em;
  height: 4em;
`
export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 3.5rem;
`

export const PlaceHolderImg = styled.div`
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  background-color: ${COLOR.PLACEHOLDER_BACKGROUND};
  border: solid 2px ${COLOR.PLACEHOLDER_BACKGROUND};
  color: ${COLOR.AVATAR_TEXT};
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Quicksand;
  font-size: 1rem;
  font-weight: 600;
`
