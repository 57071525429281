import * as ROLE from 'constants/roles'

export const buildUserPayload = (user, districtId, fieldValues) => {
  const roleId = fieldValues.role

  const payload = {
    userId: user ? user.userId : null,
    organizationId: districtId,
    firstName: fieldValues.firstName,
    lastName: fieldValues.lastName,
    email: user ? user.email : fieldValues.email,
    roles: [fieldValues.role]
  }

  if ([ROLE.SCHOOL_ADMIN_ROLE_ID, ROLE.TEACHER_ROLE_ID].includes(roleId)) {
    payload.organizationUnitIds = fieldValues.schools.map((school) => school.value)
  }

  return payload
}
