import 'tracing'
import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { SessionProvider } from './stores/SessionStore'
import * as Analytics from './analytics'
import { PostHogProvider } from 'posthog-js/react'
import { QueryClientProvider, QueryClient } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

const postHogOptions = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_API_HOST,
  ui_host: process.env.REACT_APP_PUBLIC_POSTHOG_UI_HOST
}

Sentry.init({
  dsn: 'https://9cc5ebaa15ab43b68d48f5ea97d6a20e@o4504486750060544.ingest.sentry.io/4504616444821504',
  environment: process.env.REACT_APP_SENTRY_ENV || process.env.NODE_ENV
})

Analytics.initialize()

const queryClient = new QueryClient()

ReactDOM.render(
  <PostHogProvider apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY} options={postHogOptions}>
    <SessionProvider>
      <QueryClientProvider client={queryClient}>
        <App />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </SessionProvider>
  </PostHogProvider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
