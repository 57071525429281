import React from 'react'
import googleLogin from './SignInWithGoogle.png'
import cleverLogin from './LogInWithClever.png'
import classlinkLogin from './SignInWithClassLink.png'
import microsoftLogin from './SignInWithMicrosoft.svg'
import { StyledLink } from './loginStyles'

const constructUrl = (ssoProvider) => {
  const ssoUrl = `${process.env.REACT_APP_ROSTERSTREAM_SSO_HOST}/login/${ssoProvider}/${process.env.REACT_APP_ROSTERSTREAM_SSO_APP_ID}`
  const encodedCallbackURI = encodeURIComponent(`${window.location.origin}/login-callback`)

  return `${ssoUrl}?callback=${encodedCallbackURI}`
}

export const GoogleButton = () => {
  const loginUrl = constructUrl('google')
  return (
    <StyledLink href={loginUrl}>
      <img src={googleLogin} alt='google-login' />
    </StyledLink>
  )
}

export const CleverButton = () => {
  const loginUrl = constructUrl('clever')
  return (
    <StyledLink href={loginUrl}>
      <img src={cleverLogin} alt='clever-login' />
    </StyledLink>
  )
}

export const ClassLinkButton = () => {
  const loginUrl = constructUrl('classlink')
  return (
    <StyledLink href={loginUrl}>
      <img src={classlinkLogin} alt='classlink-login' />
    </StyledLink>
  )
}

export const MicrosoftButton = () => {
  const loginUrl = constructUrl('microsoft')
  return (
    <StyledLink href={loginUrl}>
      <img src={microsoftLogin} alt='microsoft-login' />
    </StyledLink>
  )
}
