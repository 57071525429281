/* eslint indent: off */
import React, { useEffect, useState } from 'react'
import FormDialog from '../FormDialog'
import { getUsersBySchool, createClassroom, updateClassroom } from '../../lib/api'
import * as COPY from './copy'
import * as ROLE from '../../constants/roles'
import { getTeacherOptions, buildClassroomPayload } from './helper'
import posthog from 'posthog-js'

export const ClassroomDialog = ({ classroom, schoolId, role, onAction, onClose, onAlert }) => {
  const [schoolTeachers, setSchoolTeachers] = useState([])
  const [isLoaded, setIsLoaded] = useState(false)

  // get teacher options by fetching teachers in the school
  useEffect(() => {
    ;(async () => {
      try {
        const schoolUsers = await getUsersBySchool(schoolId)
        const teachers = getTeacherOptions(schoolUsers)
        setSchoolTeachers(teachers)
        setIsLoaded(true)
      } catch (err) {
        onAlert('error', COPY.TEACHER_LOAD_ERROR)
      }
    })()
  }, [schoolId, onAlert])

  const fields = isLoaded
    ? [
        {
          key: 'name',
          label: 'Name',
          isRequired: true,
          type: 'text',
          defaultValue: classroom ? classroom.name : ''
        },
        {
          key: 'teachers',
          label: 'Teachers',
          isRequired: false,
          isDisabled: role === ROLE.TEACHER,
          type: 'multiSelect',
          options: schoolTeachers,
          defaultValue: classroom ? getTeacherOptions(classroom.User) : ''
        }
      ]
    : []

  const updateOrCreateClassroom = async (fieldValues) => {
    const payload = buildClassroomPayload(classroom, schoolId, fieldValues)
    if (classroom) {
      await updateClassroom(payload)
    } else {
      await createClassroom(payload)
    }
    await onAction()
    const successMsg = classroom ? COPY.CLASSROOM_UPDATE_SUCCESS : COPY.CLASSROOM_CREATE_SUCCESS
    onAlert('success', successMsg)
    posthog.capture(classroom ? 'Edited classroom' : 'Created classroom', { userRole: role })
  }

  return (
    <div>
      {isLoaded && (
        <FormDialog
          fields={fields}
          header={`${classroom ? 'Update' : 'Create'} Classroom`}
          actionTitle={classroom ? 'Update' : 'Create'}
          onAction={updateOrCreateClassroom}
          onClose={onClose}
        />
      )}
    </div>
  )
}

export default ClassroomDialog
