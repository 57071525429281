import React from 'react'
import styled from 'styled-components'
import MaterialTab from '@material-ui/core/Tab'
import MaterialTabList from '@material-ui/lab/TabList'
import MaterialTabPanel from '@material-ui/lab/TabPanel'
import { StandardAddButton } from '../buttons/buttons'
import {
  TAB_BACKGROUND,
  TAB_DIVIDER,
  TAB_SELECTED_TEXT,
  TAB_UNSELECTED_TEXT,
  TABLE_WRAPPER,
  LINK_HOVER
} from '../../constants/colors'

// TAB COMPONENTS

export const TabList = styled(MaterialTabList)({
  '& .MuiTabs-indicator': {
    display: 'none'
  }
})

export const Tab = styled(MaterialTab)({
  '&.MuiButtonBase-root': {
    backgroundColor: TAB_BACKGROUND
  },
  '&.MuiTab-textColorInherit.Mui-selected': {
    color: TAB_SELECTED_TEXT
  },
  '&.MuiTab-root': {
    fontSize: '0.875rem',
    fontFamily: 'Inter',
    fontWeight: 600,
    height: '2.7rem',
    textTransform: 'capitalize',
    borderRight: `1px solid ${TAB_DIVIDER}`,
    color: TAB_UNSELECTED_TEXT,
    '&:hover': {
      color: LINK_HOVER
    },
    '&:first-child': {
      borderTopLeftRadius: '1rem',
      borderBottomLeftRadius: '1rem'
    },
    '&:last-child': {
      border: 'none',
      borderTopRightRadius: '1rem',
      borderBottomRightRadius: '1rem'
    }
  }
})

export const TabPanel = styled(MaterialTabPanel)({
  '&.MuiTabPanel-root': {
    padding: 0
  }
})

// TAB CONTENT

export const TabWrapper = styled.div({
  position: 'absolute',
  top: '11.25rem',
  width: '100%'
})

export const TableWrapper = styled.div({
  backgroundColor: TABLE_WRAPPER,
  padding: '2.25rem',
  borderRadius: '1rem',
  marginTop: '2rem',
  marginBottom: '2rem'
})

export const CreateButtonRow = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  marginBottom: '2.25rem',
  width: '100%'
})

export const CreateButtonWrapper = styled.div({
  width: '13.875rem'
})

export const CreateButton = (props) => {
  return (
    <CreateButtonRow>
      <CreateButtonWrapper>
        <StandardAddButton {...props} />
      </CreateButtonWrapper>
    </CreateButtonRow>
  )
}
