import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getSchool } from '../../lib/api'
import ToastNotification from '../../components/ToastNotification'
import * as COPY from './copy'
import Grid from '@material-ui/core/Grid'
import TabContext from '@material-ui/lab/TabContext'
import { Tab, TabList, TabPanel, TabWrapper } from '../../ui-components/tabs'
import { useSessionStore, actionTypes } from '../../stores/SessionStore'
import Breadcrumbs from './components/Breadcrumbs'
import StudentTab from './components/StudentTab'
import ClassroomTab from './components/ClassroomTab'
import Unauthorized from '../../components/Unauthorized'
import { useAuth } from 'auth'

const CLASSROOM_TAB = 'classroom'
const STUDENT_TAB = 'student'

const SchoolView = () => {
  const { signOut } = useAuth()
  const params = useParams()
  const { schoolId } = params
  const { state, dispatch } = useSessionStore()
  const { role, viewHeader, schoolIds } = state
  const [alert, setAlert] = useState({ isOpen: false, type: '', message: '' })
  const [currTab, setCurrTab] = useState(CLASSROOM_TAB)
  const [school, setSchool] = useState(null)
  const [isUnauthorized, setIsUnauthorized] = useState(false)
  const [loading, setLoading] = useState({ isComplete: false, hasError: false })

  useEffect(() => {
    if (loading.hasError) return

    let isMounted = true
    ;(async () => {
      try {
        const schoolInfo = await getSchool(schoolId)
        if (isMounted) {
          setSchool(schoolInfo)
          setLoading({ isComplete: true, hasError: false })
        }
      } catch (err) {
        if (err.code === 403) {
          setIsUnauthorized(true)
        } else if (err.code === 401) {
          // timeout error
          await signOut()
          dispatch({ type: actionTypes.LOGOUT })
        } else {
          setLoading({ isComplete: true, hasError: true })
          setAlert({ isOpen: true, type: 'error', message: COPY.SCHOOL_LOAD_ERROR })
        }
      }
    })()
    return () => {
      isMounted = false
    }
  }, [schoolId, loading.hasError, dispatch, signOut])

  useEffect(() => {
    if (loading.isComplete && !loading.hasError) {
      if (viewHeader !== school.name) {
        dispatch({ type: actionTypes.UPDATE_FIELD, field: 'viewHeader', data: school.name })
      }
    }
  }, [loading, school, viewHeader, dispatch])

  // tabs
  const handleTabChange = (event, newValue) => {
    setCurrTab(newValue)
  }

  const onAlert = (type, message) => {
    setAlert({ isOpen: true, type: type, message: message })
  }

  if (isUnauthorized) {
    return <Unauthorized />
  }

  const isViewLoaded = loading.isComplete && !loading.hasError && viewHeader === school.name

  return (
    <div>
      {isViewLoaded && (
        <>
          <Grid container justify='center' spacing={2}>
            <Grid item xs={11}>
              <Breadcrumbs school={school} role={role} userSchools={schoolIds} />
            </Grid>
          </Grid>

          <TabWrapper>
            <Grid container justify='center' spacing={2}>
              <Grid item xs={11}>
                <TabContext value={currTab}>
                  <TabList onChange={handleTabChange}>
                    <Tab label='Classrooms' value={CLASSROOM_TAB} />
                    <Tab label='Students' value={STUDENT_TAB} />
                  </TabList>

                  <TabPanel value={CLASSROOM_TAB}>
                    <ClassroomTab school={school} role={role} onAlert={onAlert} />
                  </TabPanel>

                  <TabPanel value={STUDENT_TAB}>
                    <StudentTab school={school} role={role} onAlert={onAlert} />
                  </TabPanel>
                </TabContext>
              </Grid>
            </Grid>
          </TabWrapper>
        </>
      )}

      {alert.isOpen && (
        <ToastNotification
          alert={alert}
          onClose={() => {
            setAlert({ isOpen: false, type: '', message: '' })
          }}
        />
      )}
    </div>
  )
}

export default SchoolView
