import React from 'react'
import { Row, Chip, Tooltip } from './styles'
import * as ROLE from 'constants/roles'

const SchoolCell = ({ cellData }) => {
  const { schoolNames, roleId } = cellData

  const schoolCount = schoolNames.length
  const isMultiSchool = schoolCount > 1
  const displaySchool = schoolNames[0]
  const additionalSchools = isMultiSchool ? schoolNames.slice(1) : []

  const isDistrictAdmin = roleId === ROLE.DISTRICT_ADMIN_ROLE_ID

  return (
    <Row>
      <div>{isDistrictAdmin ? 'All' : displaySchool}</div>
      {isMultiSchool ? (
        <Tooltip
          title={
            <div>
              {additionalSchools.map((school, idx) => (
                <div key={idx}>{school}</div>
              ))}
            </div>
          }
          placement='right'
        >
          <Chip label={`+${schoolCount - 1} more`} size='small' />
        </Tooltip>
      ) : null}
    </Row>
  )
}

export default SchoolCell
