import React from 'react'
import { EditIcon } from 'ui-components/icons'
import { Container, TestName, RatioContainer, Ratio, Numerator, Denominator, EditContainer } from './styles'

const RatioScore = ({ testName, correctItems, totalItems, isEdited, onEdit = null }) => {
  return (
    <Container>
      <div>
        <TestName>{testName}</TestName>
        <RatioContainer edited={isEdited} data-testid={`overall-score${isEdited ? '-edited' : ''}`}>
          <Ratio>
            <Numerator data-testid='num'>{correctItems}</Numerator>
            <Denominator data-testid='den'>/{totalItems}</Denominator>
          </Ratio>
        </RatioContainer>
      </div>
      <EditContainer>
        <EditIcon onEdit={onEdit} />
      </EditContainer>
    </Container>
  )
}

export default RatioScore
