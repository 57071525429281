export const TAGS = [
  { label: 'ELL', value: 'ELL', key: 'ELL' },
  { label: 'IEP', value: 'IEP', key: 'IEP' },
  { label: 'Family History', value: 'familyhistory', key: 'familyhistory' },
  { label: 'Exempt from Assessment', value: 'exempt', key: 'exempt' }
]

export const getSelectedTags = (tags) => {
  // split tags into array, find the matching tag object from tags const
  tags = tags ? tags.split(',').map((tag) => tag.trim()) : []
  // get valid tag key options
  const tagOptions = TAGS.map((obj) => obj.key)

  tags = tags.map((tag) => {
    // if the current tag is one of the valid tag key options
    if (tagOptions.includes(tag)) {
      return TAGS.find((item) => {
        return item.value === tag
      })
    } else {
      // if the current tag is somehow not a valid tag key option, return null
      return null
    }
  })

  // return tags array without null items
  return tags.filter((tag) => tag !== null)
}
