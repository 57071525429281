import * as ASSESSMENT from './assessments'
import * as GRADES from 'constants/grades'
import { BOY, MOY, EOY } from 'constants/timePeriods'

export const PERCENTILE_AT_RISK_THRESHOLD = 40.0
export const PERCENTILE_HIGH_RISK_THRESHOLD = 20.0
export const RATIO_THRESHOLD = 18

/**
 * @constant TYPES enumerates a list of kinds of risk scores.
 * @type {object}
 */
export const TYPES = {
  pwr: ASSESSMENT.PWR,
  dyslexia: ASSESSMENT.DYSLEXIA,
  kready: ASSESSMENT.KREADY
}

/**
 * @constant OP defines a list of comparator functions to use with scores and thresholds. Used by `isAtRisk`.
 * @type {object}
 */
export const OP = {
  lt: (score, threshold) => score < threshold,
  gt: (score, threshold) => score > threshold
}

/**
 * @constant THRESHOLDS defines a number of values and operators for comparing scores to our thresholds or "cutoffs". The following structure is used THRESHOLDS[grade][type][value|op]
 * @type {object}
 */
export const THRESHOLDS = {
  [GRADES.KINDERGARTEN]: {
    [TYPES.pwr]: {
      [BOY]: {
        value: 0.6429,
        op: OP.lt
      },
      [MOY]: {
        value: 0.6429,
        op: OP.lt
      },
      [EOY]: {
        value: 0.5128,
        op: OP.lt
      }
    },
    [TYPES.dyslexia]: {
      [BOY]: {
        value: 0.222,
        op: OP.gt
      },
      [MOY]: {
        value: 0.222,
        op: OP.gt
      },
      [EOY]: {
        value: 0.222,
        op: OP.gt
      }
    }
  },
  [GRADES.FIRST]: {
    [TYPES.pwr]: {
      [BOY]: {
        value: 0.6520432265,
        op: OP.lt
      },
      [MOY]: {
        value: 0.6260837485,
        op: OP.lt
      },
      [EOY]: {
        value: 0.6214175602,
        op: OP.lt
      }
    },
    [TYPES.dyslexia]: {
      [BOY]: {
        value: 0.1714194754,
        op: OP.gt
      },
      [MOY]: {
        value: 0.1229738369,
        op: OP.gt
      },
      [EOY]: {
        value: 0.1268153892,
        op: OP.gt
      }
    }
  },
  [GRADES.PK]: {
    [TYPES.kready]: {
      [EOY]: {
        value: 0.565,
        op: OP.lt
      }
    }
  },
  [GRADES.SECOND]: {
    [TYPES.pwr]: {
      [BOY]: {
        value: 0.6055758093,
        op: OP.lt
      },
      [MOY]: {
        value: 0.5608109739,
        op: OP.lt
      },
      [EOY]: {
        value: 0.5278596149,
        op: OP.lt
      }
    },
    [TYPES.dyslexia]: {
      [BOY]: {
        value: 0.1435153128,
        op: OP.gt
      },
      [MOY]: {
        value: 0.1343224282,
        op: OP.gt
      },
      [EOY]: {
        value: 0.1663420189,
        op: OP.gt
      }
    }
  }
}

/**
 * @function isAtRisk takes a grade, type, timePeriod, and score, then outputs a boolean true if the score indicates a risk based on the values in the THRESHOLDS constant.
 * @arg {string} grade as defined by the constants.
 * @arg {string} type either 'pwr' or 'dyslexia'.
 * @arg {string} timePeriod as defined by the constants.
 * @see THRESHOLDS for values and operators.
 * @see GRADES for constant values.
 */
export const isAtRisk = (grade, type, timePeriod, score) => {
  if (score === null) {
    return false
  }
  if (!THRESHOLDS[grade] || !THRESHOLDS[grade][type] || !THRESHOLDS[grade][type][timePeriod]) {
    console.error(`No thresholds set for grade: ${grade}, type: ${type}, and timePeriod: ${timePeriod}`)
    return false
  }
  const th = THRESHOLDS[grade][type][timePeriod]
  return th.op(score, th.value)
}

export const THETA_CUTOFFS = {
  [GRADES.PK]: {
    [ASSESSMENT.RHYMING_ID]: {
      [BOY]: -0.52,
      [MOY]: -0.52
    },
    [ASSESSMENT.VOCAB_MEASURE_ID]: {
      [BOY]: -0.33,
      [MOY]: -0.33
    },
    [ASSESSMENT.ORAL_SENTENCE_MEASURE_ID]: {
      [BOY]: -1,
      [MOY]: -1.5
    },
    [ASSESSMENT.FIRST_SOUNDS_ID]: {
      [MOY]: -0.585
    },
    [ASSESSMENT.LETTER_NAMES_ID]: {
      [MOY]: 0,
      [EOY]: 0
    },
    [ASSESSMENT.LETTER_SOUNDS_ID]: {
      [MOY]: -0.95,
      [EOY]: -0.95
    }
  }
}

/**
 * @function isProficient takes a grade, assessmentId, timePeriod, and theta, then outputs a boolean true if the theta indicates proficiency based on the values in the THETA_CUTOFFS constant.
 * @arg {string} grade as defined by the constants.
 * @arg {string} assessmentId as defined by the constants.
 * @arg {string} timePeriod as defined by the constants.
 * @see THETA_CUTOFFS for values.
 * @see GRADES for grade constant values.
 * @see ASSESSMENT for assessment constant values.
 */
export const isProficient = (grade, assessmentId, timePeriod, theta) => {
  if (theta === null) {
    return false
  }
  if (
    !THETA_CUTOFFS[grade] ||
    !THETA_CUTOFFS[grade][assessmentId] ||
    THETA_CUTOFFS[grade][assessmentId][timePeriod] === undefined
  ) {
    throw new Error(
      `No theta cutoffs set for grade: ${grade}, assessment: ${assessmentId}, and time period: ${timePeriod}`
    )
  }

  const thetaCutoff = THETA_CUTOFFS[grade][assessmentId][timePeriod]
  const parsedTheta = parseFloat(theta)

  return parsedTheta >= thetaCutoff
}

/**
 * @function getThreshold takes a grade, risk type, and timePeriod, then returns the THRESHOLDS object if it is defined or null if undefined
 * @arg {string} grade as defined by the constants.
 * @arg {string} type as defined by the constants.
 * @arg {string} timePeriod as defined by the constants.
 * @see THRESHOLDS for values.
 * @see GRADES for grade constant values.
 * @see ASSESSMENT for assessment constant values.
 */
export const getThreshold = (grade, type, timePeriod) => {
  const validThreshold = THRESHOLDS?.[grade]?.[type]?.[timePeriod]

  return validThreshold || null
}
