// school alert messages
export const SCHOOL_LOAD_ERROR = 'Unable to load school data.'
export const STUDENT_LOAD_ERROR = 'Unable to load students in school.'
export const USER_LOAD_ERROR = 'Unable to load users in school.'
export const CLASSROOM_LOAD_ERROR = 'Unable to load classrooms in school.'

// table messages
export const NO_NAME_PROVIDED = 'No name provided'

// empty state messages
export const EMPTY_STATE_CLASSROOM =
  'No classrooms belong to this school yet. Click the button to create the first one!'
export const EMPTY_STATE_CLASSROOM_TEACHER =
  'No classrooms belong to this school yet. Contact your school administrator if you think this may be a mistake.'
export const EMPTY_STATE_STUDENT = 'No students belong to this school yet. Click the button to create the first one!'
export const EMPTY_STATE_STUDENT_NO_CLASSROOMS =
  'Oops! You need a classroom before you can create any students. Contact your school administrator to set up a classroom.'
export const EMPTY_STATE_SCHOOL_USER =
  'No school users belong to this school yet. Click the button to create the first one!'
