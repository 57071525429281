/* eslint curly: off */
import React, { useState, useEffect } from 'react'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import StudentNameCell from './components/StudentNameCell'
import GradeCell from './components/GradeCell'
import PredictiveProfileCell from './components/PredictiveProfileCell'
import CSVButton from './components/CSVButton'
import TableLegend from 'components/TableLegend'
import InfoPopover from 'components/InfoPopover'
import SoundSymbolCorrToggle from 'components/SoundSymbolCorrToggle'
import { TableContainer, TableWrapper, TableCell, SelectWrapper, TableBarWrapper, TableHead } from './styles'
import FormControl from '@mui/material/FormControl'
import { SmallerSelect, MenuItem } from 'ui-components/inputs/inputs'
import { BOY, MOY, EOY, BOYLabel, MOYLabel, EOYLabel } from 'constants/timePeriods'
import { DYSLEXIA, PWR, SINGLE_COL_PWR, KREADY, PREDICTIVE_PROFILE_DISPLAY_NAMES } from 'constants/assessments'
import * as GRADE from 'constants/grades'
import * as ASSESSMENT from 'constants/assessments'
import * as RULES from 'constants/displayRules'
import {
  getColUnits,
  getFormattedColUnits,
  hasCellDivider,
  getStickyCols,
  getColWidth,
  getHighestGrade,
  getGradeDivider,
  getAssessmentCols,
  getPredictiveProfileKeys,
  getAllUnits,
  classroomHasScoredLNLS
} from './helper'
import { getPredictiveProfileInfo } from 'utils/info'
import Dialog from 'components/Dialog'
import ItemInventory from './components/ItemInventory'
import ItemInventoryV2 from 'components/ItemInventoryV2'
import { hasSoundSymbolCorrNorms } from 'utils/configuration'
import { useFeatureFlagEnabled } from 'posthog-js/react'

const getColumns = (assmntList, timePeriod, classroomGrades) => {
  // define all columns in the table
  const studentDetailColumns = [
    {
      key: 'studentInfo',
      title: 'Student Info',
      studentDetail: true,
      subColumns: [
        { key: 'studentName', title: 'Name', customCell: StudentNameCell },
        { key: 'grade', title: 'Grade', customCell: GradeCell }
      ]
    }
  ]

  // determine which columns in the predictive profile should be displayed
  const predictiveProfileSubCols = []
  const subColKeys = [DYSLEXIA, PWR, KREADY, SINGLE_COL_PWR]

  subColKeys.forEach((key) => {
    // display column if at least one grade in the classroom needs that value displayed
    const shouldDisplay =
      classroomGrades.filter((grade) => RULES.DISPLAY_RULES[grade][key][timePeriod] !== null).length > 0
    if (shouldDisplay) {
      predictiveProfileSubCols.push({
        key: key,
        title: PREDICTIVE_PROFILE_DISPLAY_NAMES[key],
        customCell: PredictiveProfileCell,
        info: getPredictiveProfileInfo(key, classroomGrades, timePeriod)
      })
    }
  })

  // only display predictive profile if at least one of the subcolumns needs to be displayed
  if (predictiveProfileSubCols.length > 0) {
    const predictiveProfile = {
      key: 'predictiveProfile',
      title: 'Predictive Profile',
      studentDetail: true,
      subColumns: predictiveProfileSubCols
    }

    studentDetailColumns.push(predictiveProfile)
  }

  const assessmentCols = getAssessmentCols(assmntList, classroomGrades, timePeriod)
  studentDetailColumns.push(...assessmentCols)

  return studentDetailColumns
}

const StudentDetailsTable = ({
  classroomName,
  classroomGrades,
  classroomResults,
  currTimePeriod,
  assmntConfig,
  ranScores
}) => {
  const [timePeriod, setTimePeriod] = useState('')
  const [studentDetailColumns, setStudentDetailColumns] = useState([])
  const [combinedAssmntLists, setCombinedAssmntLists] = useState(null)
  const [hoverCell, setHoverCell] = useState(null)
  const [isLoaded, setIsLoaded] = useState(false)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [modalStudentId, setModalStudentId] = useState(null)
  // sound symbol correspondance toggle will always default to percentile
  const [soundSymbolCorrDisplayRule, setSoundSymbolCorrDisplayRule] = useState(RULES.PERCENTILE)

  const lnlsOverwritesEnabled = useFeatureFlagEnabled('lnls-score-overwrite')
  const rerGr1Enabled = useFeatureFlagEnabled('rer-grade-1')
  const toggleKey = rerGr1Enabled ? 'phonics' : 'soundSymbolCorr'

  useEffect(() => {
    // set the time period to be the current time period from the parent component
    setTimePeriod(currTimePeriod)
  }, [currTimePeriod])

  useEffect(() => {
    // get the combined assessment lists for boy/moy/eoy for all grades in the classroom so that all relevant assessments are displayed
    const combinedAssmnts = {}

    for (const timePeriod in classroomResults) {
      const assmntListByGrade = classroomResults[timePeriod].assmntList
      const allGradeAssmnts = []
      for (const grade in assmntListByGrade) {
        const gradeList = assmntListByGrade[grade].split(',')
        allGradeAssmnts.push(...gradeList)
      }
      combinedAssmnts[timePeriod] = Array.from(new Set(allGradeAssmnts)).join(',')
    }
    setCombinedAssmntLists(combinedAssmnts)
  }, [classroomResults])

  useEffect(() => {
    // change the columns based on the time period toggle
    if (combinedAssmntLists && classroomGrades.length) {
      const columns = getColumns(combinedAssmntLists[timePeriod], timePeriod, classroomGrades)
      setStudentDetailColumns(columns)
      setIsLoaded(true)
    }
  }, [combinedAssmntLists, timePeriod, classroomGrades])

  const shouldDisplaySoundSymbolCorrToggle = () => {
    const relevantAssessments = [ASSESSMENT.LETTER_NAMES_ID, ASSESSMENT.LETTER_SOUNDS_ID]
    return relevantAssessments.some((assessment) => combinedAssmntLists[timePeriod].includes(assessment))
  }

  const createCSVFileName = () => {
    return `${classroomName.toLowerCase().split(' ').join('_')}_student_details_${timePeriod}.csv`
  }

  const onTimePeriodChange = (event) => {
    setTimePeriod(event.target.value)
  }

  const getCellData = (student, colKey, subColKey) => {
    const studentId = student.studentName.participantId
    const baseCellData = {
      key: subColKey,
      timePeriod: timePeriod,
      studentId: studentId,
      studentGrade: student.grade,
      setHoverCell: setHoverCell
    }

    switch (subColKey) {
      case PWR:
      case DYSLEXIA:
      case KREADY: {
        const riskScore = student[colKey][timePeriod][subColKey] || null
        return {
          ...baseCellData,
          riskScore: riskScore
        }
      }
      case SINGLE_COL_PWR: {
        const riskScore = {
          pwr: student[colKey][timePeriod][PWR] || null,
          dyslexia: student[colKey][timePeriod][DYSLEXIA] || null
        }
        return {
          ...baseCellData,
          riskScore: riskScore
        }
      }
      case ASSESSMENT.OBJ_RAN_ID:
      case ASSESSMENT.RER_LETTER_RAN_ID:
      case ASSESSMENT.LETTER_RAN_ID: {
        const currPeriodConfig = assmntConfig.find((config) => config.periodName === timePeriod)
        // only use most recent RAN score if it corresponds to the current cell's RAN type and if it was completed before the current time period
        const studentRanScore =
          ranScores[studentId] && ranScores[studentId].testCode === subColKey ? ranScores[studentId] : null
        const mostRecentRanScore =
          studentRanScore && studentRanScore.startDtTm < currPeriodConfig.startDate ? studentRanScore : null

        return {
          ...baseCellData,
          classroomResults: classroomResults,
          mostRecentRanScore: mostRecentRanScore,
          assmntListByGrade: classroomResults[timePeriod].assmntList
        }
      }
      case ASSESSMENT.LETTER_NAMES_ID:
      case ASSESSMENT.LETTER_SOUNDS_ID:
        return {
          ...baseCellData,
          assmntListByGrade: classroomResults[timePeriod].assmntList,
          results: student[subColKey],
          displayRuleOverwrite: hasSoundSymbolCorrNorms(timePeriod) ? soundSymbolCorrDisplayRule : null,
          onClickCell: () => {
            const hasCompleteAssessment =
              student?.TEST_LETTER_NAMES?.isComplete || student?.TEST_LETTER_SOUNDS?.isComplete
            // the cell is only clickable once an LN or LS assessment has been completed
            if (hasCompleteAssessment) {
              if (
                (hasSoundSymbolCorrNorms(timePeriod) && soundSymbolCorrDisplayRule === RULES.RATIO) || // if norms are available, the cell is only clickable if the toggle is set to display the scores as ratios
                !hasSoundSymbolCorrNorms(timePeriod) // if norms are not available, then the cell is clickable because scores will always be displayed as ratios
              ) {
                setIsDialogOpen(true)
                setModalStudentId(studentId)
              }
            }
          }
        }
      case 'studentName':
        // student name cell
        return {
          ...student[subColKey],
          highlightedStudentId: hoverCell ? hoverCell.studentId : null
        }
      case 'grade':
        // student grade cell
        return {
          studentGrade: student[subColKey],
          studentId: studentId,
          highlightedStudentId: hoverCell ? hoverCell.studentId : null
        }
      default:
        // assessment cell
        return {
          ...baseCellData,
          assmntListByGrade: classroomResults[timePeriod].assmntList,
          results: student[subColKey]
        }
    }
  }

  if (!isLoaded) return null

  const highestClassroomGrade = getHighestGrade(classroomGrades)
  const columnUnits = getColUnits(studentDetailColumns, classroomGrades, timePeriod)

  const getSelectedStudentResults = () => {
    return classroomResults[timePeriod].studentResults[modalStudentId]
  }

  const getStudentName = () => {
    return (
      classroomResults[timePeriod].studentResults[modalStudentId].studentName.firstName +
      ' ' +
      classroomResults[timePeriod].studentResults[modalStudentId].studentName.lastName
    )
  }

  return (
    <>
      <TableBarWrapper>
        <SelectWrapper>
          <FormControl fullWidth>
            <SmallerSelect
              id='timePeriodSelect'
              value={timePeriod}
              label='timePeriodSelect'
              onChange={onTimePeriodChange}
            >
              <MenuItem value={BOY}>{BOYLabel}</MenuItem>
              <MenuItem value={MOY}>{MOYLabel}</MenuItem>
              <MenuItem value={EOY}>{EOYLabel}</MenuItem>
            </SmallerSelect>
          </FormControl>
        </SelectWrapper>

        <CSVButton
          columns={studentDetailColumns}
          classroomResults={timePeriod ? classroomResults[timePeriod] : []}
          classroomGrades={classroomGrades}
          ranScores={ranScores}
          fileName={createCSVFileName()}
          selectedTimePeriod={timePeriod}
        />
      </TableBarWrapper>
      <TableContainer id={`resultsFor${currTimePeriod}`}>
        <TableWrapper>
          <TableHead>
            <TableRow>
              {studentDetailColumns.map((column) => {
                return (
                  <TableCell
                    key={column.key}
                    className={`header ${column.key === 'studentInfo' && 'stickyHeader'}`}
                    colSpan={column.subColumns.length}
                  >
                    {column.title}
                  </TableCell>
                )
              })}
            </TableRow>
            <TableRow>
              {studentDetailColumns.map((column, colIdx) => {
                const hasDivider = colIdx !== studentDetailColumns.length - 1
                const formattedColUnits = getFormattedColUnits(columnUnits[column.key])
                const displayToggle = shouldDisplaySoundSymbolCorrToggle()
                return (
                  <TableCell
                    key={column.key}
                    className={`unitHeader ${hasDivider && 'divider'} ${
                      column.key === 'studentInfo' && 'stickyHeader'
                    }`}
                    colSpan={column.subColumns.length}
                  >
                    {displayToggle && column.key === toggleKey && hasSoundSymbolCorrNorms(timePeriod) ? (
                      <SoundSymbolCorrToggle
                        currDisplayRule={soundSymbolCorrDisplayRule}
                        setDisplayRule={setSoundSymbolCorrDisplayRule}
                        isDisabled={!classroomHasScoredLNLS(classroomResults[timePeriod]?.studentResults)}
                      />
                    ) : (
                      <div>{formattedColUnits}</div>
                    )}
                  </TableCell>
                )
              })}
            </TableRow>
            <TableRow>
              {studentDetailColumns.map((column, colIdx) => {
                return column.subColumns.map((subColumn, subColIdx) => {
                  const hasDivider = hasCellDivider(studentDetailColumns, column.subColumns, colIdx, subColIdx)
                  return (
                    <TableCell
                      key={subColumn.key}
                      className={`subHeader ${hasDivider && 'divider'} ${getStickyCols(subColumn.key)} ${getColWidth(
                        combinedAssmntLists[timePeriod]
                      )} ${hoverCell && subColumn.key === hoverCell.subColKey && 'highlight'}`}
                    >
                      {subColumn.title}
                      {subColumn.info && <InfoPopover id={`${subColumn.key}Popover`} info={subColumn.info} />}
                    </TableCell>
                  )
                })
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {timePeriod // ensures that multi grade classrooms display students from lowest to highest grade
              ? GRADE.GRADE_ORDER.map((grade) => {
                  const hasGrade = classroomGrades.includes(grade)
                  if (!hasGrade) return null

                  const isHighestGradeInClassroom = highestClassroomGrade === grade
                  const results = classroomResults[timePeriod]
                  // get all students in specified grade
                  const sortedStudentIds = results.sortedStudentIds[grade]
                  return sortedStudentIds.map((studentId, studentIdx) => {
                    const student = results.studentResults[studentId]

                    /* eslint indent: off */
                    const rowId = student.studentName.participantId
                    return (
                      // render student's risk score and assessment results in table row
                      <TableRow id={rowId} key={rowId}>
                        {studentDetailColumns.map((column, colIdx) => {
                          // column represents a "group" (ex. predictive profile, phonemic awareness, oral lang comprehension)
                          // subColumns represent the data that falls within that group (ex. pwr and dyslexia for predictive profile)
                          return column.subColumns.map((subColumn, subColIdx) => {
                            const hasDivider = hasCellDivider(
                              studentDetailColumns,
                              column.subColumns,
                              colIdx,
                              subColIdx
                            )
                            return (
                              <subColumn.customCell
                                key={subColumn.key}
                                cellData={getCellData(student, column.key, subColumn.key)}
                                cellClasses={`${hasDivider && 'divider'} ${getGradeDivider(
                                  studentIdx,
                                  sortedStudentIds,
                                  isHighestGradeInClassroom
                                )}`}
                              />
                            )
                          })
                        })}
                      </TableRow>
                    )
                  })
                })
              : null}
          </TableBody>
        </TableWrapper>
      </TableContainer>
      <TableLegend
        grades={classroomGrades}
        predictiveProfile={getPredictiveProfileKeys(studentDetailColumns)}
        scoringUnits={getAllUnits(columnUnits)}
        timePeriod={timePeriod}
        isClassroomView={true}
      />
      {isDialogOpen && !lnlsOverwritesEnabled && (
        <Dialog
          style={{ backgroundColor: '#f4f6f8' }}
          body={<ItemInventory studentResults={getSelectedStudentResults()} studentName={getStudentName()} />}
          actionTitle={'Close'}
          cancelTitle={null}
          onAction={() => {
            setIsDialogOpen(false)
          }}
          onClose={() => setIsDialogOpen(false)}
        />
      )}
      {isDialogOpen && lnlsOverwritesEnabled && (
        <ItemInventoryV2
          studentResults={getSelectedStudentResults()}
          studentName={getStudentName()}
          onClose={() => setIsDialogOpen(false)}
        />
      )}
    </>
  )
}

export default StudentDetailsTable
