import React from 'react'
import * as COPY from '../../copy'

const RANScoringInstructions = () => {
  const { paragraphs, listItems } = COPY.RAN_SCORING_INSTRUCTIONS
  return (
    <div>
      {paragraphs.map((paragraph) => {
        const index = paragraphs.indexOf(paragraph)
        return <p key={index}>{paragraph}</p>
      })}
      <ul>
        {listItems.map((item) => {
          const index = listItems.indexOf(item)
          return <li key={index}>{item}</li>
        })}
      </ul>
    </div>
  )
}

export default RANScoringInstructions
