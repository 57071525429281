import styled from 'styled-components'
import MaterialSnackbar from '@material-ui/core/Snackbar'
import { SUCCESS_MSG_ACTION } from '../../constants/colors'

export const Snackbar = styled(MaterialSnackbar)`
  .action {
    color: ${SUCCESS_MSG_ACTION};
    cursor: pointer;
    margin-bottom: 0.2rem;
    margin-left: 4rem;
    font-weight: bold;

    &:hover {
      text-decoration: underline;
    }
  }

  .MuiAlert-action {
    display: flex;
    opacity: 0.9;
    padding: 7px 0;
    font-size: 22px;
    align-items: start;
  }
`
