import styled from 'styled-components'
import { AUDIO_WRAPPER_BG } from '../../../../constants/colors'

export const AudioWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 0.938rem;
  background-color: ${AUDIO_WRAPPER_BG};
  padding: 1rem;

  p {
    text-align: right;
    font-weight: 400;
  }
`
