import React from 'react'
import { TableCell } from '../../styles'

const GradeCell = ({ cellData, cellClasses }) => {
  const { studentGrade, highlightedStudentId, studentId } = cellData
  return (
    <TableCell className={`${cellClasses} gradeCell ${highlightedStudentId === studentId && 'highlight'}`}>
      {studentGrade}
    </TableCell>
  )
}

export default GradeCell
