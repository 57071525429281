import styled from 'styled-components'
import MaterialTableRow from '@material-ui/core/TableRow'
import MaterialTableCell from '@material-ui/core/TableCell'
import MaterialTableContainer from '@material-ui/core/TableContainer'
import MaterialTable from '@material-ui/core/Table'
import MaterialTableBody from '@material-ui/core/TableBody'
import * as COLOR from '../../../../constants/colors'

export const TableRow = styled(MaterialTableRow)`
  &.MuiTableRow-root {
    height: 0rem;
    width: 100%;
    border-collapse: collapse;
  }
`

const selectedTimePeriodBorder = `solid 2px ${COLOR.SELECTED_TIME_PERIOD_BORDER}`

export const TableCell = styled(MaterialTableCell)`
  &.MuiTableCell-root {
    padding: 0px;
    height: 3rem;
    border: solid 1px ${COLOR.STUDENT_RESULTS_BORDER};
  }

  &.rowToken {
    width: 5rem;
    border-right: none;
    padding-left: 0.5rem;
  }

  &.rowTitle {
    padding: 0.625rem 0.5rem 0.635rem 0rem;
    width: 10.125rem;
    min-width: 10.125rem;
    font-family: Quicksand;
    font-size: 1rem;
    font-weight: 600;
    color: ${COLOR.STUDENT_VIEW_DARK_TEXT};
    border-left: none;
  }

  &.scoreCell {
    text-align: center;
    width: 4rem;
    min-width: 3rem;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
  }

  &.pwrRiskPercentage {
    font-family: 'Quicksand';
    font-weight: 600;
    font-size: 1rem;
    color: ${COLOR.PWR_RISK_TEXT};
    background-color: ${COLOR.PWR_RISK_BACKGROUND}
  }

  &.defaultCell {
    font-family: 'Quicksand';
    font-weight: 600;
    font-size: 1rem;
    color: ${COLOR.STUDENT_VIEW_DARK_TEXT};
  }

  &.highRiskAssmnt {
    background-color: ${COLOR.RISK_PERCENTILE_BACKGROUND};
    color: ${COLOR.ASSMNT_RISK_TEXT};
  }

  &.atRiskAssmnt {
    background-color: ${COLOR.LOWER_RISK_PERCENTILE_BACKGROUND};
    color: ${COLOR.STUDENT_VIEW_LOWER_RISK_TEXT};
  }

  &.sliderCell {
    width: 20rem;
    max-width: 20rem;
  }

  &.selectedTimePeriod {
    border-right: ${selectedTimePeriodBorder};
    border-left: ${selectedTimePeriodBorder};
  }

  &.firstInSelectedGroup {
    border-top: ${selectedTimePeriodBorder};
  }

  &.lastInSelectedGroup {
    border-bottom: ${selectedTimePeriodBorder};
  }

  &.notApplicable {
    background-color: ${COLOR.NOT_APPLICABLE};
  }
}
`

export const TableContainer = styled(MaterialTableContainer)`
  &.MuiTableContainer-root {
    overflow-x: hidden;
  }
`
export const Table = styled(MaterialTable)`
  &.MuiTable-root {
    border: none;
    border-bottom: none;
  }
  @media print {
    page-break-inside: auto;
  }
`
export const TableBody = styled(MaterialTableBody)`
  &.MuiTableBody-root {
    border: none;
    border-color: none;
  }
`

export const AssessmentGroupWrapper = styled.div`
  @media print {
    page-break-inside: avoid;
    page-break-after: auto;
  }
`
export const TableWrapper = styled.div`
  flex-grow: 0;
  background-color: ${COLOR.STUDENT_VIEW_BACKGROUND};
  display: flex;
  justify-content: center;
`

export const TableInnerWrapper = styled.div`
  max-width: 50rem;
  min-width: 20rem;
`

export const StudentTableHeader = styled(TableCell)({
  '&.MuiTableCell-root': {
    height: '2rem',
    border: 'none',
    paddingLeft: '1.5rem'
  },
  fontSize: '0.75rem',
  lineHeight: '1rem',
  textTransform: 'uppercase',
  fontFamily: 'Inter',
  fontWeight: 500,
  letterSpacing: '1px',
  color: COLOR.STUDENT_VIEW_DARK_TEXT
})

export const TimePeriodHeader = styled(TableCell)({
  '&.MuiTableCell-root': {
    height: '2rem',
    border: (props) => {
      return props.selected ? selectedTimePeriodBorder : 'none'
    },
    backgroundColor: (props) => {
      return props.selected ? COLOR.SELECTED_TIME_PERIOD_BACKGROUND : 'none'
    },
    color: COLOR.STUDENT_VIEW_DARK_TEXT,
    textAlign: 'center',
    fontFamily: 'Inter',
    fontWeight: 700,
    fontSize: '1rem',
    userSelect: 'none',
    '&:hover': {
      cursor: 'pointer'
    }
  }
})

export const SliderScaleWrapper = styled.div({
  display: 'flex',
  width: '100%',
  justifyContent: 'center'
})

export const SliderScale = styled.div({
  display: 'flex',
  flexDirection: 'row',
  width: '17rem'
})

export const SliderScaleNumber = styled.div({
  width: '20%',
  textAlign: 'right'
})

export const EmptySliderCell = styled.div({
  minWidth: '19rem'
})
