import React from 'react'
import Paper from '@material-ui/core/Paper'
import { TextField, MultiSelectAutocomplete, selectOptionStyles } from '../../../../ui-components/inputs/inputs'

const FormMultiSelect = ({ field, fieldValues, updateField, hasError }) => {
  const getOptions = () => {
    if (field.parentKey) {
      const currParentValue = fieldValues[field.parentKey]
      return field.parentKey ? field.options[currParentValue] : field.options
    } else {
      return field.options
    }
  }

  return (
    <MultiSelectAutocomplete
      multiple
      id={`${field.key}MultiSelect`}
      key={field.key}
      disabled={field.isDisabled || false}
      options={getOptions()}
      value={fieldValues[field.key] || []}
      getOptionLabel={(option) => option.label}
      getOptionSelected={(option, value) => {
        return option.key === value.key
      }}
      // selected value is stored with format {key: string, value: string, label: string}
      onChange={(_, value) => updateField(field.key, value)}
      PaperComponent={({ children }) => <Paper style={selectOptionStyles}>{children}</Paper>}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            label={field.label}
            required={field.isRequired}
            error={hasError}
            severity={hasError ? 'error' : ''}
            helperText={hasError ? `${field.label} is a required field.` : ''}
          />
        )
      }}
    />
  )
}

export default FormMultiSelect
