import React from 'react'
import { resendInvite, inviteUser } from '../../lib/api'
import * as COPY from './copy'
import { ResendButton, InviteButton } from './styles'
import SendIcon from '@material-ui/icons/Send'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import posthog from 'posthog-js'

const ResendInviteCell = ({ cellData }) => {
  const { userEmail, onAlert, isPending, isNew } = cellData

  const onResendClick = async () => {
    try {
      await resendInvite(userEmail)
      onAlert('success', COPY.RESEND_INVITE_SUCCESS)
      posthog.capture('Resent user invite')
    } catch (err) {
      onAlert('error', err.message)
    }
  }

  const onInviteClick = async () => {
    try {
      await inviteUser(userEmail)
      onAlert('success', COPY.RESEND_INVITE_SUCCESS)
      posthog.capture('Invited user')
    } catch (err) {
      onAlert('error', err.message)
    }
  }

  return (
    <>
      {isPending ? (
        <ResendButton onClick={onResendClick}>
          <SendIcon fontSize='small' style={{ paddingRight: 5 }} />
          Resend Invite
        </ResendButton>
      ) : (
        <div id='activeUser' />
      )}
      {isNew ? (
        <InviteButton onClick={onInviteClick}>
          <AddCircleIcon fontSize='small' style={{ paddingRight: 5 }} />
          Invite User
        </InviteButton>
      ) : (
        <div id='activeUser' />
      )}
    </>
  )
}

export default ResendInviteCell
