import styled from 'styled-components'
import MaterialAccordionSummary from '@material-ui/core/AccordionSummary'
import MaterialAccordion from '@material-ui/core/Accordion'
import { ACCORDION_BG, ACCORDION_TEXT } from '../../constants/colors'

export const AccordionSummary = styled(MaterialAccordionSummary)({
  '& .MuiAccordionSummary-content': {
    fontWeight: 600,
    color: ACCORDION_TEXT,
    fontFamily: 'Quicksand',
    fontSize: '1rem'
  }
})

export const ResourcesAccordion = styled(MaterialAccordion)({
  '& .MuiButtonBase-root.MuiAccordionSummary-root': {
    backgroundColor: ACCORDION_BG,
    marginTop: '.5rem'
  },
  '& .MuiAccordionDetails-root': {
    backgroundColor: ACCORDION_BG,
    fontFamily: 'Inter',
    fontSize: '0.875rem',
    color: ACCORDION_TEXT
  },
  '&.MuiAccordion-rounded': {
    borderRadius: '0.938rem'
  },

  '&.MuiAccordion-root': {
    paddingLeft: '.75rem',
    paddingRight: '.75rem',
    backgroundColor: ACCORDION_BG,
    position: 'none'
  },

  '&.MuiAccordion-root:before': {
    backgroundColor: 'white'
  },

  '&.MuiPaper-elevation1': {
    boxShadow: 'none'
  }
})
