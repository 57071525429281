/* eslint indent: off */
import React, { useEffect, useState } from 'react'
import FormDialog from '../FormDialog'
import { getSchoolsByDistrict, createStudent, updateStudent, getClassroomsByDistrict } from '../../lib/api'
import {
  organizeClassroomsBySchool,
  getClassroomOptions,
  getDefaultClassrooms,
  getDefaultGrade,
  getSchoolOptions,
  buildStudentPayload,
  getDefaultSchool
} from './helper'
import { getFormattedDate } from '../../utils/dates'
import * as COPY from './copy'
import { TAGS, getSelectedTags } from '../../utils/tags'
import * as ROLES from '../../constants/roles'
import { useFeatureFlagEnabled } from 'posthog-js/react'
import posthog from 'posthog-js'

const PREK_OPTION = { label: 'PreK', value: 'PK', key: 'gradePK' }
const KIND_OPTION = { label: 'Kindergarten', value: 'K', key: 'gradeK' }
const FIRST_GRADE_OPTION = { label: '1', value: '1', key: 'grade1' }
const SECOND_GRADE_OPTION = { label: '2', value: '2', key: 'grade2' }

const StudentDialog = ({ student = null, schoolId, districtId, role, defaultValues, onAction, onClose, onAlert }) => {
  const [schools, setSchools] = useState([])
  const [classroomsBySchool, setClassroomsBySchool] = useState({})
  const [isLoaded, setIsLoaded] = useState(false)
  const grade2enabled = useFeatureFlagEnabled('grade-2-student-create')

  // fetch available school and classroom options
  useEffect(() => {
    ;(async () => {
      try {
        const schools = await getSchoolsByDistrict(districtId)
        setSchools(schools.OrganizationUnits)

        const classrooms = await getClassroomsByDistrict(districtId)
        const formattedClassroomsBySchool = organizeClassroomsBySchool(classrooms)
        setClassroomsBySchool(formattedClassroomsBySchool)

        setIsLoaded(true)
      } catch (err) {
        onAlert('error', COPY.OPTION_LOAD_ERROR)
      }
    })()
  }, [districtId, schoolId, onAlert])

  const gradeOptions = grade2enabled
    ? [PREK_OPTION, KIND_OPTION, FIRST_GRADE_OPTION, SECOND_GRADE_OPTION]
    : [PREK_OPTION, KIND_OPTION, FIRST_GRADE_OPTION]

  const fields = isLoaded
    ? [
        [
          {
            key: 'firstName',
            label: 'First Name',
            isRequired: true,
            type: 'text',
            defaultValue: student ? student.firstName : ''
          },
          {
            key: 'middleName',
            label: 'Middle Name',
            isRequired: false,
            type: 'text',
            defaultValue: student ? student.middleName : ''
          },
          {
            key: 'lastName',
            label: 'Last Name',
            isRequired: true,
            type: 'text',
            defaultValue: student ? student.lastName : ''
          }
        ],
        [
          {
            key: 'dateOfBirth',
            label: 'Date of Birth',
            isRequired: true,
            type: 'datepicker',
            defaultValue: student ? (student.dateOfBirth ? getFormattedDate(student.dateOfBirth) : null) : null
          },
          {
            key: 'grade',
            label: 'Grade',
            isRequired: true,
            type: 'select',
            options: gradeOptions,
            defaultValue: getDefaultGrade(student)
          }
        ],
        [
          {
            key: 'schoolId',
            label: 'School',
            isRequired: true,
            type: 'select',
            options: getSchoolOptions(schools),
            defaultValue: getDefaultSchool(student, schoolId),
            childKey: 'classrooms'
          },
          {
            key: 'classrooms',
            label: 'Classroom',
            isRequired: role === ROLES.TEACHER,
            type: 'multiSelect',
            options: getClassroomOptions(classroomsBySchool, schools),
            defaultValue: getDefaultClassrooms(student, defaultValues),
            parentKey: 'schoolId'
          }
        ],
        [
          {
            key: 'externalId',
            label: 'Student ID',
            isRequired: false,
            type: 'text',
            defaultValue: student ? student.externalId : ''
          },
          {
            key: 'tags',
            label: 'Tags',
            isRequired: false,
            type: 'multiSelect',
            options: TAGS,
            defaultValue: student && student.tags ? getSelectedTags(student.tags) : []
          }
        ]
      ]
    : []

  const updateOrCreateStudent = async (fieldValues) => {
    const payload = buildStudentPayload(student, fieldValues)
    if (student) {
      await updateStudent(payload)
    } else {
      await createStudent(payload)
    }
    await onAction()
    const successMsg = student ? COPY.STUDENT_UPDATE_SUCCESS : COPY.STUDENT_CREATE_SUCCESS
    onAlert('success', successMsg)
    posthog.capture(student ? 'Edited student' : 'Created student', { userRole: role })
  }

  return (
    <div>
      {isLoaded && (
        <FormDialog
          centerDialogActions={true}
          fields={fields}
          multiCol={true}
          header={`${student ? 'Update' : 'Create'} Student`}
          actionTitle={student ? 'Update' : 'Create'}
          onAction={updateOrCreateStudent}
          onClose={onClose}
        />
      )}
    </div>
  )
}

export default StudentDialog
