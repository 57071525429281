import styled from 'styled-components'
import MaterialDialogActions from '@material-ui/core/DialogActions'
import MaterialDialogTitle from '@material-ui/core/DialogTitle'
import MaterialDialog from '@material-ui/core/Dialog'
import MaterialDialogContent from '@material-ui/core/DialogContent'
import { DIALOG_HEADER_TEXT, DIALOG_LABEL_TEXT, DIALOG_INPUT_TEXT } from '../../constants/colors'
import { StandardButton } from '../../ui-components/buttons/buttons'
import * as COLOR from '../../constants/colors'

export const DialogTitle = styled(MaterialDialogTitle)`
  &.MuiDialogTitle-root {
    padding-top: 3.125rem;
    padding-left: 3.125rem;
    padding-right: 3.125rem;
    padding-bottom: 1.25rem;
  }

  h2 {
    color: ${DIALOG_HEADER_TEXT};
    font-family: Quicksand;
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 0.91;
  }
`

export const DialogContent = styled(MaterialDialogContent)`
  &.MuiDialogContent-root {
    padding-left: 3.125rem;
    padding-right: 3.125rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: visible;
  }

  & .MuiFormHelperText-root {
    text-align: right;
    font-family: Inter;
  }

  & .MuiFormLabel-root {
    font-family: Inter;
    font-size: 0.875rem;
    font-weight: 600;
    color: ${DIALOG_LABEL_TEXT};
  }

  & .MuiInputBase-root {
    color: ${DIALOG_INPUT_TEXT};
    font-family: Inter;
    font-size: 1rem;
    font-weight: 600;
    max-height: 5.5rem;
    overflow-y: auto;
    overflow-x: hidden;
  }
`
const baseDialogActions = `
&.MuiDialogActions-root {
  justify-content: space-between;
  padding-left: 3.125rem;
  padding-right: 3.125rem;
  padding-bottom: 3.125rem;
  padding-top: 1.25rem;
}
`
const centeredDialogActions = `
&.MuiDialogActions-root {
  justify-content: space-around;
  padding-left: 13.125rem;
  padding-right: 13.125rem;
  padding-bottom: 3.125rem;
  padding-top: 1.25rem;
}
`
export const DialogActions = styled(MaterialDialogActions)`
  ${({ centerDialogActions }) => (centerDialogActions ? centeredDialogActions : baseDialogActions)}
`

export const DialogBox = styled(MaterialDialog)`
  & .MuiDialog-paper {
    border-radius: 0.938rem;
  }
`

export const DialogButton = styled.div`
  width: 13rem;
`

export const CancelButton = styled(StandardButton)({
  '&.MuiButton-root': {
    backgroundColor: `${COLOR.CANCEL_BUTTON_BACKGROUND}`,
    color: `${COLOR.CANCEL_BUTTON_TEXT}`,
    '&:hover': {
      backgroundColor: `${COLOR.CANCEL_BUTTON_HOVER}`,
      color: `${COLOR.CANCEL_BUTTON_TEXT}`
    }
  }
})
