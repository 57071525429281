import { ASSETS_PATH } from 'constants/assets'
import * as ASSESSMENTS from 'constants/assessments.js'

export const getAssessment = async (assessmentId) => {
  const url = `${ASSETS_PATH}/${assessmentId}/assessment.json`
  const response = await fetch(url)
  if (response.status !== 200) {
    throw Error(response.statusText)
  }
  return await response.json()
}

// filter the assessment groups array to only include the assessments groups and assessments that match the assessments list passed in
export const filterAssessmentGroups = (assessments) => {
  return ASSESSMENTS.ASSESSMENT_GROUPS.reduce((acc, group) => {
    const groupAssessments = group.assessments.filter((assmnt) => assessments.includes(assmnt.key))
    if (groupAssessments.length) {
      const newGroup = { ...group }
      newGroup.assessments = groupAssessments
      acc.push(newGroup)
    }
    return acc
  }, [])
}
