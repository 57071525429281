import ResendInviteCell from '../../../../components/ResendInviteCell'
import EditCell from '../../../../components/EditCell'
import UserInfoCell from '../UserInfoCell'
import SchoolCell from '../SchoolCell'
import { deleteUser } from '../../../../lib/api'
import * as ROLE from '../../../../constants/roles'

export const hasManagePermission = (role) => {
  return [ROLE.SUPER_ADMIN, ROLE.DISTRICT_ADMIN, ROLE.SCHOOL_ADMIN].includes(role)
}

export const getUserColumns = (role) => {
  const userColumns = [
    { title: 'User', key: 'user', customCell: UserInfoCell },
    { title: 'School(s)', key: 'schools', customCell: SchoolCell },
    { title: 'Role', key: 'role' }
  ]

  if (hasManagePermission(role)) {
    userColumns.push({ title: '', key: 'resendInvite', customCell: ResendInviteCell })
    userColumns.push({ title: 'Action', key: 'edit', customCell: EditCell })
  }

  return userColumns
}

export const createUserRowData = (user, role, onEdit, onAlert) => {
  const isPending = user.authStatus === 'Pending'
  const isNew = user.authStatus === 'None'
  const hasUserRole = user.Role[0]
  const userRoleId = hasUserRole && user.Role[0].roleId
  const userRows = {
    id: user.userId,
    user: {
      name: `${user.lastName}, ${user.firstName}`,
      email: user.email,
      isPending: isPending,
      isNew: isNew
    },
    schools: {
      schoolNames: user.OrganizationUnits.map((school) => school.name),
      roleId: userRoleId || ''
    },
    role: ROLE.ROLE_BY_ID[userRoleId]
  }

  if (hasManagePermission(role)) {
    userRows.edit = {
      onEdit: () => onEdit(user),
      deleteData: {
        itemId: user.userId,
        itemName: `${user.firstName} ${user.lastName}`,
        onDelete: deleteUser,
        actionTitle: 'Delete',
        onAlert: onAlert,
        userWarning: true,
        type: 'user'
      }
    }
    userRows.resendInvite = {
      userEmail: user.email,
      onAlert: onAlert,
      isPending: isPending,
      isNew: isNew
    }
  }

  return userRows
}
