import styled from 'styled-components'
import { CheckboxChecked } from '@styled-icons/boxicons-solid/CheckboxChecked'
import { RESET_CHECK_UNFULFILLED, RESET_CHECK_FULFILLED, AUTH_TEXT } from '../../constants/colors'

export const PasswordReq = styled.li`
  font-family: Inter;
  font-size: 0.875rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.74;
  letter-spacing: normal;
  text-align: left;
  color: ${AUTH_TEXT};
`

export const CheckIcon = styled(CheckboxChecked)`
  color: ${(props) => (props.isDisabled ? RESET_CHECK_FULFILLED : RESET_CHECK_UNFULFILLED)};
  width: 1.5rem;
  height: 1.5rem;
`
export const Requirements = styled.ul`
  list-style: none;
  font-size: 0.9rem;
  margin-block-start: 0.5em;
  margin-block-end: 1em;
  padding-inline-start: 10px;
  margin-inline-end: 3em;
`

export const StyledText = styled.p`
  margin-block-start: 0em;
  margin-block-end: 0em;
  font-family: Inter;
  font-size: 1rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.74;
  letter-spacing: normal;
  text-align: left;
  color: ${AUTH_TEXT};
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 125%;
  margin-bottom: 1em;
`
export const Col = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
`
