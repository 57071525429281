import React, { useState } from 'react'
import { useAuth } from 'auth'
import { getUserByEmail, updateUserAuthStatus } from '../../lib/api'
import PasswordValidator from '../../components/PasswordValidator'
import ToastNotification from '../../components/ToastNotification'
import { useSessionStore, actionTypes } from '../../stores/SessionStore'
import { ViewWrapper } from '../../ui-components/backgrounds'
import { LargeButton } from '../../ui-components/buttons/buttons'
import { AuthBackground, FormWrapper, InputWrapper, AuthLogo, ContentWrapper } from '../../ui-components/authStyles'
import { TextField } from '../../ui-components/inputs/inputs'
import * as COPY from '../../constants/copy'
import { COMPLETE_NEW_PWD_ERROR } from './copy'
import { LoginHeader, LoginSubHeader } from './styles'

const CompleteNewPassword = () => {
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const [passwordError, setPasswordError] = useState(null)
  const [passwordConfirmError, setPasswordConfirmError] = useState(null)
  const [alert, setAlert] = useState({ isOpen: false, type: '', message: '' })
  const [isDisabled, setIsDisabled] = useState(false)

  const { signIn, completePasswordSignup } = useAuth()
  const { state, dispatch } = useSessionStore()
  const { userEmail, tempPassword } = state

  const onSubmit = async () => {
    if (password === '') {
      setIsDisabled(false)
      setPasswordError(true)
    } else if (password !== passwordConfirm) {
      setIsDisabled(false)
      setPasswordConfirmError(true)
    } else {
      try {
        setIsDisabled(true)

        const newCognitoUser = await signIn(userEmail, tempPassword)
        await completePasswordSignup(newCognitoUser, password, tempPassword)
        const user = await getUserByEmail(userEmail)
        await updateUserAuthStatus({ userId: user.userId, authStatus: 'Active' })
        setAlert({ isOpen: true, type: 'success', message: 'New password successfully updated.' })
        dispatch({
          type: actionTypes.UPDATE_FIELD,
          field: 'isLoggedIn',
          data: true
        })
      } catch (err) {
        setIsDisabled(false)
        setAlert({ isOpen: true, type: 'error', message: COMPLETE_NEW_PWD_ERROR })
      }
    }
  }

  return (
    <ViewWrapper>
      <AuthBackground />
      <ContentWrapper>
        <AuthLogo />
        <FormWrapper>
          <LoginHeader>Create New Password</LoginHeader>
          <LoginSubHeader>Create a permanent password for your EarlyBird account.</LoginSubHeader>
          <InputWrapper>
            <TextField
              type='password'
              placeholder='Password'
              value={password}
              label='Password'
              onChange={(event) => {
                if (passwordError) {
                  setPasswordError(false)
                }
                setPassword(event.target.value)
              }}
              data-testid='pword1Input'
              error={passwordError}
              helperText={passwordError ? COPY.EMPTY_PASSWORD_ERROR : ''}
              severity={passwordError ? 'error' : null}
            />
            <TextField
              type='password'
              placeholder='Confirm Password'
              value={passwordConfirm}
              label='Confirm Password'
              onChange={(event) => {
                if (setPasswordConfirmError) {
                  setPasswordConfirmError(false)
                }
                setPasswordConfirm(event.target.value)
              }}
              data-testid='pword2Input'
              error={passwordConfirmError}
              helperText={passwordConfirmError ? COPY.MATCHING_PASSWORDS_ERROR : ''}
              severity={passwordConfirmError ? 'error' : null}
            />
          </InputWrapper>
          <PasswordValidator password={password} />
          <LargeButton
            type='button'
            alt='submit'
            data-testid='submit'
            disabled={isDisabled}
            onClick={() => {
              onSubmit()
            }}
          >
            Continue
          </LargeButton>
        </FormWrapper>
      </ContentWrapper>

      {alert.isOpen && (
        <ToastNotification
          alert={alert}
          onClose={() => {
            setAlert({ isOpen: false, type: '', message: '' })
          }}
        />
      )}
    </ViewWrapper>
  )
}

export default CompleteNewPassword
