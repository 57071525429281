import styled from 'styled-components'
import {
  STUDENT_VIEW_SHADOW,
  STUDENT_VIEW_BACKGROUND,
  STUDENT_VIEW_DARK_TEXT,
  BUTTON_TEXT_WHITE,
  BUTTON_TEXT_HOVER,
  BUTTON_BACKGROUND,
  BUTTON_BACKGROUND_HOVER
} from '../../constants/colors'

export const HeaderBar = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '0 .875rem'
})

export const ButtonLink = styled.a({
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textDecoration: 'none',
  color: BUTTON_TEXT_WHITE,
  fontFamily: 'Quicksand',
  fontWeight: 600,
  fontSize: '0.675rem',
  border: 0,
  borderRadius: '0.9rem',
  height: '2.6rem',
  width: '7.5rem',
  backgroundColor: `${BUTTON_BACKGROUND}`,
  '&:hover': {
    backgroundColor: `${BUTTON_BACKGROUND_HOVER}`,
    color: `${BUTTON_TEXT_HOVER}`
  },
  '&:visited': {
    color: BUTTON_TEXT_WHITE
  }
})

export const ContentWrapper = styled.div`
  top: 11.25rem;
  position: absolute;
`

export const TableShadow = styled.div`
  background: ${STUDENT_VIEW_SHADOW};
  padding: 1.688rem 1.188rem 1.125rem 1.75rem;
  border-radius: 1rem;

  h2 {
    margin: 0.313rem 4.188rem 1.125rem 0;
    font-family: Quicksand;
    font-size: 1.5rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
    color: ${STUDENT_VIEW_DARK_TEXT};
  }
`

export const InfoShadow = styled.div`
  margin: 0 0 0.938rem 0;
  padding: 2.125rem 0.75rem 2.063rem 1.438rem;
  border-radius: 1.25rem;
  background-color: ${STUDENT_VIEW_SHADOW};
`

export const InfoWrapper = styled.div`
  margin: 0 0.625rem 0 0;
  padding: 1.938rem 1.625rem 2.063rem 1.625rem;
  border-radius: 0.938rem;
  box-shadow: 0 1.438rem 2.75rem 0 rgba(176, 183, 195, 0.14);
  background-color: ${STUDENT_VIEW_BACKGROUND};

  h2 {
    margin: 0 0 0px;
    font-family: Quicksand;
    font-size: 1.5rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
    color: ${STUDENT_VIEW_DARK_TEXT};
  }
`

export const ResourcesShadow = styled.div`
  margin: 15px 0 15px 0px;
  padding: 34px 12px 33px 23px;
  border-radius: 1.25rem;
  background-color: ${STUDENT_VIEW_SHADOW};
`
export const ResourcesWrapper = styled.div`
  padding: 1.438rem 1.625rem 2.875rem 1.625rem;
  margin: 0 0.625rem 0 0;
  border-radius: 0.938rem;
  box-shadow: 0 1.438rem 2.75rem 0 rgba(176, 183, 195, 0.14);
  background-color: ${STUDENT_VIEW_BACKGROUND};
`

export const Column = styled.div({
  display: 'flex',
  flexDirection: 'column'
})
