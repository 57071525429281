import * as GRADE from 'constants/grades'
import { BOY, MOY, EOY } from 'constants/timePeriods'

const PWR_ICON_EXPLANATION =
  'Exclamation point indicates the student will benefit from explicit classroom instruction before the next benchmark period. Continued weakness in these areas could indicate the likelihood of not meeting end-of-year grade-level expectations in word reading.'
const PWR_PERCENTAGE_EXPLANATION =
  'This percentage indicates the student’s likelihood of reaching grade-level expectations for reading words in isolation by the end of the year without remediation.'
const PWR_SINGLE_SUBTEST_INTRO = 'This percentage is determined by an algorithm that reflects the student score in'
export const PWR_MULTI_SUBTEST_INTRO =
  'This percentage is determined by an algorithm that reflects the student scores for these subtests in each grade:'
const PWR_EOY =
  'The Potential for Word Reading is not calculated at the end of year since the Word Reading subtest is offered in the gameboard with scores appearing here on the dashboard.'
const PWR_SUBTEST_STRINGS = {
  [BOY]: {
    [GRADE.FIRST]: 'Word Reading',
    [GRADE.SECOND]: 'Word Reading'
  },
  [MOY]: {
    [GRADE.FIRST]: 'Word Reading',
    [GRADE.SECOND]: 'Word Reading'
  },
  [EOY]: {
    [GRADE.FIRST]: 'Word Reading',
    [GRADE.SECOND]: 'Word Reading'
  }
}

export const PWR_INFO = {
  [BOY]: {
    [GRADE.KINDERGARTEN]: PWR_ICON_EXPLANATION,
    [GRADE.FIRST]:
      PWR_PERCENTAGE_EXPLANATION + '\n\n' + `${PWR_SINGLE_SUBTEST_INTRO} ${PWR_SUBTEST_STRINGS[BOY][GRADE.FIRST]}.`,
    [GRADE.SECOND]:
      PWR_PERCENTAGE_EXPLANATION + '\n\n' + `${PWR_SINGLE_SUBTEST_INTRO} ${PWR_SUBTEST_STRINGS[BOY][GRADE.SECOND]}.`,
    [GRADE.MULTI]: PWR_ICON_EXPLANATION
  },
  [MOY]: {
    [GRADE.KINDERGARTEN]: `This percentage indicates the student’s likelihood of reaching grade-level expectations for reading words in isolation by the end of the year without remediation.

    In Kindergarten, this percentage is determined by a predictive algorithm based on the student's scores in Letter Sound, Blending, and Word Matching.`,
    [GRADE.FIRST]:
      PWR_PERCENTAGE_EXPLANATION + '\n\n' + `${PWR_SINGLE_SUBTEST_INTRO} ${PWR_SUBTEST_STRINGS[MOY][GRADE.FIRST]}.`,
    [GRADE.SECOND]:
      PWR_PERCENTAGE_EXPLANATION + '\n\n' + `${PWR_SINGLE_SUBTEST_INTRO} ${PWR_SUBTEST_STRINGS[MOY][GRADE.SECOND]}.`,
    [GRADE.MULTI]: `This percentage indicates the student’s likelihood of reaching grade-level expectations for reading words in isolation by the end of the year without remediation.

    In Kindergarten, this percentage is determined by a predictive algorithm based on the student's scores in Letter Sound, Blending, and Word Matching.`
  },
  [EOY]: {
    [GRADE.KINDERGARTEN]: PWR_EOY,
    [GRADE.FIRST]:
      PWR_PERCENTAGE_EXPLANATION + '\n\n' + `${PWR_SINGLE_SUBTEST_INTRO} ${PWR_SUBTEST_STRINGS[EOY][GRADE.FIRST]}.`,
    [GRADE.SECOND]:
      PWR_PERCENTAGE_EXPLANATION + '\n\n' + `${PWR_SINGLE_SUBTEST_INTRO} ${PWR_SUBTEST_STRINGS[EOY][GRADE.SECOND]}.`,
    [GRADE.MULTI]: PWR_EOY
  }
}
