import React from 'react'
import RANScoringSection from '../RANScoringSection'
import { LETTER_RAN_SKIPPED_MESSAGE } from './copy'
import { AudioWrapper } from './styles'

const QuestionResults = ({ results, isScored = false }) => {
  if (!results) {
    return <div>{LETTER_RAN_SKIPPED_MESSAGE}</div>
  }

  return (
    <div>
      <AudioWrapper>
        <audio src={results.audio} controls>
          <source type='audio/mpeg' />
          Your browser does not support the audio element.
        </audio>
        <p>{`Response Time: ${results.responseTime} secs`} </p>
      </AudioWrapper>

      {isScored && <RANScoringSection scoringData={results.scoring || {}} />}
    </div>
  )
}

export default QuestionResults
