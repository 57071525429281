import React from 'react'
import Dialog from 'components/Dialog'
import InventoryHeader from '../InventoryHeader/InventoryHeader'
import RatioScore from '../RatioScore/RatioScore'
import ReadOnlyInventoryTable from '../ReadOnlyInventoryTable/ReadOnlyInventoryTable'
import Legend from '../Legend/Legend'
import { HeaderContainer, RatioContainer } from './styles'
import { ITEM_INVENTORY_ALPHABETICAL_ORDER } from '../../constants'
import { LETTER_NAMES_ID, LETTER_SOUNDS_ID } from 'constants/assessments'

const getMappedInventoryData = (studentResults) => {
  const letterNamesData = studentResults.TEST_LETTER_NAMES
  const letterSoundsData = studentResults.TEST_LETTER_SOUNDS
  if (!letterNamesData && !letterSoundsData) {
    return []
  }
  return [
    ['Name'].concat(mapItemLevelResultsToRow('TEST_LETTER_NAMES', letterNamesData?.correctByCode)),
    ['Sound'].concat(mapItemLevelResultsToRow('TEST_LETTER_SOUNDS', letterSoundsData?.correctByCode))
  ]
}

const mapItemLevelResultsToRow = (testCode, correctByCode) => {
  // if a test has been started but 0 questions were answered, return an array filled with null
  if (!correctByCode) return Array(ITEM_INVENTORY_ALPHABETICAL_ORDER.length).fill(null)
  const itemFormat = {
    TEST_LETTER_SOUNDS: (letter) => `LS_${letter}`,
    TEST_LETTER_NAMES: (letter) => `LN_${letter}`
  }

  return ITEM_INVENTORY_ALPHABETICAL_ORDER.map((letter) => {
    const score = correctByCode[itemFormat[testCode](letter)]
    return score
  })
}

const ReadOnlyContent = ({ studentResults, studentName, setEditMode }) => {
  return (
    <>
      <HeaderContainer>
        <InventoryHeader studentName={studentName} />
        <RatioContainer>
          <RatioScore
            testName={'Letter Name'}
            correctItems={studentResults.TEST_LETTER_NAMES?.correctItems}
            totalItems={studentResults.TEST_LETTER_NAMES?.totalItems}
            onEdit={() => setEditMode(LETTER_NAMES_ID)}
          />
          <RatioScore
            testName={'Letter Sound'}
            correctItems={studentResults.TEST_LETTER_SOUNDS?.correctItems}
            totalItems={studentResults.TEST_LETTER_SOUNDS?.totalItems}
            onEdit={() => setEditMode(LETTER_SOUNDS_ID)}
          />
        </RatioContainer>
      </HeaderContainer>
      <ReadOnlyInventoryTable
        data={{
          headers: ['Letter'].concat(ITEM_INVENTORY_ALPHABETICAL_ORDER),
          scores: getMappedInventoryData(studentResults)
        }}
      />
      <Legend />
    </>
  )
}

const ReadOnlyItemInventory = ({ studentResults, studentName, setEditMode, onClose }) => {
  return (
    <Dialog
      body={<ReadOnlyContent studentResults={studentResults} studentName={studentName} setEditMode={setEditMode} />}
      actionTitle={'Close'}
      cancelTitle={null}
      onAction={() => onClose()}
    />
  )
}

export default ReadOnlyItemInventory
