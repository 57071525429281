/* eslint indent: off */
import * as React from 'react'
import {
  TableRow,
  TableContainer,
  Table,
  TableBody,
  StudentTableHeader,
  SliderScaleWrapper,
  SliderScale,
  TimePeriodHeader,
  SliderScaleNumber
} from '../../styles'
import PercentileCellSlim from '../PercentileCellSlim'
import AssessmentTitleCell from 'components/StudentResultsTable/components/AssessmentTitleCell'
import CharacterCell from 'components/StudentResultsTable/components/CharacterCell'
import PercentileSlider from 'components/StudentResultsTable/components/PercentileSlider'
import TableHead from '@material-ui/core/TableHead'
import { getScoreCellClasses } from '../../helper'
import { RAN_ASSESSMENT_IDS } from 'constants/assessments'
import * as RULES from 'constants/displayRules'

const AssessmentGroupTable = ({
  student,
  assessmentGroup,
  studentResults,
  mostRecentRANScore,
  currTimePeriod,
  timePeriodAssmntLists,
  assmntConfig,
  showHeaderScale
}) => {
  const resultsByPeriod = studentResults.reduce((obj, curr) => {
    return {
      ...obj,
      [curr.period]: curr.results
    }
  }, {})

  // find the student scores that match the assessment
  const getStudentScores = (timePeriod, studentAssmnts, assessment) => {
    let score = studentAssmnts
      ? studentAssmnts.find((studentAssmnt) => {
          return assessment.key === studentAssmnt.testCode
        })
      : {}

    // if no RAN score in current time period, use the most recent RAN score
    if (score === undefined && RAN_ASSESSMENT_IDS.includes(assessment.key)) {
      // only use most recent score if the test was taken before the current time period
      const timePeriodConfig = assmntConfig.find((config) => config.periodName === timePeriod)
      if (mostRecentRANScore && mostRecentRANScore.startDtTm < timePeriodConfig.startDate) {
        score = mostRecentRANScore
      }
    }

    return score
  }

  // determines what the assessment group label should be based on the display rules of the assessments that belong to the group
  const getHeaderLabel = () => {
    let hasPercentage = false
    let hasProficiency = false
    assessmentGroup.assessments.forEach((a) => {
      const isAssessmentInSelectedPeriod = timePeriodAssmntLists[currTimePeriod].includes(a.key)
      if (isAssessmentInSelectedPeriod) {
        const displayRule = RULES.DISPLAY_RULES[student.grade][a.key][currTimePeriod]
        if (displayRule === RULES.PERCENTILE) {
          hasPercentage = true
        }
        if (displayRule === RULES.PROFICIENCY) {
          hasProficiency = true
        }
      }
    })

    if (hasPercentage) {
      return '(%ile)'
    } else if (hasProficiency) {
      return '(not yet / on track)'
    } else {
      return ''
    }
  }

  const assessmentGroupCount = assessmentGroup.assessments.length
  const requiredData = resultsByPeriod && currTimePeriod && timePeriodAssmntLists
  const headerLabel = getHeaderLabel()

  return (
    <TableContainer>
      <Table>
        {showHeaderScale
          ? headWithScale(assessmentGroup, headerLabel)
          : headWithAssessmentNameOnly(assessmentGroup, headerLabel)}
        {requiredData && (
          <TableBody>
            {assessmentGroup.assessments
              .filter((assessment) => {
                // check if assessment should only be displayed for specific grades
                if (assessment.grades) {
                  return assessment.grades.includes(student.grade)
                }
                return true
              })
              .map((assessment, idx) => {
                // loop through the assessments for each assessment group

                // check if current assessment is included in BOY/MOY/EOY lists
                const isAssessmentInSelectedPeriod = timePeriodAssmntLists[currTimePeriod].includes(assessment.key)
                const scores = getStudentScores(currTimePeriod, resultsByPeriod[currTimePeriod], assessment)

                // get assessment display rules for current time period
                const displayRule = RULES.DISPLAY_RULES[student.grade][assessment.key][currTimePeriod]

                return (
                  <TableRow key={assessment.key}>
                    <CharacterCell assessmentKey={assessment.key} />
                    <AssessmentTitleCell assessment={assessment} studentScores={scores} />
                    <PercentileSlider studentScores={scores} isHidden={displayRule !== RULES.PERCENTILE} />
                    <PercentileCellSlim
                      timePeriod={currTimePeriod}
                      testCode={assessment.key}
                      studentScores={scores}
                      studentGrade={student.grade}
                      isApplicableAssmnt={isAssessmentInSelectedPeriod}
                      cellClasses={getScoreCellClasses(currTimePeriod, currTimePeriod, idx, assessmentGroupCount)}
                    />
                  </TableRow>
                )
              })}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  )
}

const headWithAssessmentNameOnly = (assessmentGroup, headerLabel) => (
  <TableHead>
    <TableRow>
      <StudentTableHeader colSpan={4}>
        {assessmentGroup.title} <span style={{ textTransform: 'lowercase' }}>{headerLabel}</span>
      </StudentTableHeader>
    </TableRow>
  </TableHead>
)

const headWithScale = (assessmentGroup, headerLabel) => (
  <TableHead>
    <TableRow>
      <StudentTableHeader colSpan={2}>
        {assessmentGroup.title} <span style={{ textTransform: 'lowercase' }}>{headerLabel}</span>
      </StudentTableHeader>
      <StudentTableHeader colSpan={1}>
        <SliderScaleWrapper>
          <SliderScale>
            {[20, 40, 60, 80].map((num, idx) => {
              return <SliderScaleNumber key={idx}>{num}</SliderScaleNumber>
            })}
          </SliderScale>
        </SliderScaleWrapper>
      </StudentTableHeader>
      <TimePeriodHeader selected={false} onClick={() => {}}>
        Score
      </TimePeriodHeader>
    </TableRow>
  </TableHead>
)

export default AssessmentGroupTable
