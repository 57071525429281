import React from 'react'
import { DownloadCSVIcon } from '../../ui-components/icons'
import { DownloadWrapper } from './styles'

const download = async (link, onAlert) => {
  try {
    await fetch(link, {
      method: 'GET',
      credentials: 'include'
    })
      .then((res) => res.blob())
      .then((blob) => {
        const file = window.URL.createObjectURL(blob)
        window.location.assign(file)
      })
  } catch (err) {
    onAlert('error', err.message)
  }
}

const DownloadIcon = ({ link, onAlert }) => {
  return (
    <DownloadWrapper>
      <DownloadCSVIcon onClick={() => download(link, onAlert)} />
    </DownloadWrapper>
  )
}

export default DownloadIcon
