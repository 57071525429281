import React from 'react'
import DeleteIcon from '../DeleteIcon'
import { EditIcon } from '../../ui-components/icons'
import { IconWrapper, EditWrapper } from './styles'
import DownloadIcon from '../DownloadIcon'
import ResendInviteCell from 'components/ResendInviteCell'

const EditCell = ({ cellData }) => {
  const { onEdit, deleteData, downloadData, resendInvite } = cellData
  return (
    <IconWrapper>
      {resendInvite && resendInvite.isPending && <ResendInviteCell {...resendInvite} />}
      <EditWrapper>
        <EditIcon onEdit={onEdit} />
      </EditWrapper>
      {deleteData && <DeleteIcon {...deleteData} />}
      {downloadData && <DownloadIcon {...downloadData} />}
    </IconWrapper>
  )
}

export default EditCell
