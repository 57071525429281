import styled from 'styled-components'
import * as COLORS from 'constants/colors'

export const ContentWrapper = styled.div`
  padding: 2rem;
  @page {
    margin: 2cm;
  }
`

export const CoverLetterContainer = styled.div`
  @media print {
    page-break-after: always;
  }
  font-size: 11pt;
  h1,
  h2,
  h3,
  h4 {
    margin: 0;
    color: ${COLORS.ABOUT_EB_HEADER};
  }
`

export const LogoContainer = styled.div`
  background-color: ${COLORS.LIGHT_BACKGROUND};
  padding-left: 2rem;
  float: right;
`

export const LogoImg = styled.img`
  width: 10.5rem;
  height: 2.93rem;
`
