import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSessionStore, actionTypes } from '../../stores/SessionStore'
import { ViewWrapper } from '../../ui-components/backgrounds'
import {
  FormWrapper,
  AuthBackground,
  ContentWrapper,
  AuthLogo,
  AuthHeader,
  AuthSubHeader
} from '../../ui-components/authStyles'
import { LargeButton } from '../../ui-components/buttons/buttons'
import { TextWrapper } from './styles'
import { TIMEOUT_MESSAGE } from './copy'

const IdleTimeoutView = () => {
  const history = useHistory()
  const { state, dispatch } = useSessionStore()
  const { loginRedirectPathName } = state

  // reset isTimedOut state after user has been timed out
  useEffect(() => {
    dispatch({ type: actionTypes.UPDATE_FIELD, field: 'isTimedOut', data: false })
  }, [dispatch])

  return (
    <ViewWrapper>
      <AuthBackground />
      <ContentWrapper>
        <AuthLogo />
        <FormWrapper>
          <AuthHeader>Session Timeout</AuthHeader>
          <AuthSubHeader>
            <TextWrapper>{TIMEOUT_MESSAGE}</TextWrapper>
          </AuthSubHeader>
          <LargeButton onClick={() => history.push(loginRedirectPathName)}>Log In</LargeButton>
        </FormWrapper>
      </ContentWrapper>
    </ViewWrapper>
  )
}

export default IdleTimeoutView
