/* eslint indent: off */
import React, { useState } from 'react'
import { TableRow, TableContainer, Table, TableBody, StudentTableHeader } from '../../styles'
import PercentileCell from '../../components/PercentileCell'
import AssessmentTitleCell from '../../components/AssessmentTitleCell'
import CharacterCell from '../../components/CharacterCell'
import PercentileSlider from '../PercentileSlider'
import SoundSymbolCorrToggle from 'components/SoundSymbolCorrToggle'
import ItemInventory from 'components/StudentDetailsTable/components/ItemInventory'
import ItemInventoryV2 from 'components/ItemInventoryV2'
import Dialog from 'components/Dialog'
import TableHead from '@material-ui/core/TableHead'
import { getScoreCellClasses, studentHasScoredLNLS } from '../../helper'
import { BOY, MOY, EOY } from 'constants/timePeriods'
import * as A from 'constants/assessments'
import * as RULES from 'constants/displayRules'
import { hasSoundSymbolCorrNorms } from 'utils/configuration'
import { useFeatureFlagEnabled } from 'posthog-js/react'

const AssessmentGroupTable = ({
  isAdminView,
  student,
  assessmentGroup,
  studentResults,
  mostRecentRANScore,
  onUpdate,
  selectedTimePeriod,
  assmntConfig,
  timePeriodAssmntLists
}) => {
  // sound symbol correspondance toggle will always default to percentile
  const [soundSymbolCorrDisplayRule, setSoundSymbolCorrDisplayRule] = useState(RULES.PERCENTILE)
  const [letterInventoryData, setLetterInventoryData] = useState(null)

  const lnlsOverwritesEnabled = useFeatureFlagEnabled('lnls-score-overwrite')
  const rerGr1Enabled = useFeatureFlagEnabled('rer-grade-1')
  const toggleKey = rerGr1Enabled ? 'phonics' : 'soundSymbolCorr'

  // creates object using period as the key to make results easily accessible
  const resultsByPeriod = studentResults.reduce((obj, curr) => {
    return {
      ...obj,
      [curr.period]: curr.results
    }
  }, {})

  const assessments = assessmentGroup.assessments.filter((assessment) => {
    // check if assessment should only be displayed for specific grades
    if (assessment.grades) {
      return assessment.grades.includes(student.grade)
    }
    return true
  })

  // find the student scores that match the assessment
  const getStudentScores = (timePeriod, studentAssmnts, assessment) => {
    let score = studentAssmnts
      ? studentAssmnts.find((studentAssmnt) => {
          return assessment.key === studentAssmnt.testCode
        })
      : {}

    // if no RAN score in current time period, use the most recent RAN score
    if (score === undefined && A.RAN_ASSESSMENT_IDS.includes(assessment.key)) {
      // only use most recent score if the test was taken before the current time period
      const timePeriodConfig = assmntConfig.find((config) => config.periodName === timePeriod)
      if (mostRecentRANScore && mostRecentRANScore.startDtTm < timePeriodConfig.startDate) {
        score = mostRecentRANScore
      }
    }

    return score
  }

  // Implements ability to overwrite display rules object with the value set by the sound symbol corr toggle for LN + LS
  const getDisplayRuleOverwrite = (assessment, timePeriod) => {
    return [A.LETTER_NAMES_ID, A.LETTER_SOUNDS_ID].includes(assessment.key) && hasSoundSymbolCorrNorms(timePeriod)
      ? soundSymbolCorrDisplayRule
      : null
  }

  const onClickCell = (assessment, timePeriod) => {
    // If sound symbol corr cell is clicked, then set data to display the letter inventory modal
    if ([A.LETTER_NAMES_ID, A.LETTER_SOUNDS_ID].includes(assessment)) {
      // gets results for the specified time period keyed by assessment type
      const timePeriodResults = resultsByPeriod[timePeriod].reduce((aggregate, result) => {
        return {
          ...aggregate,
          [result.testCode]: result
        }
      }, {})

      const hasCompleteAssessment =
        timePeriodResults[A.LETTER_NAMES_ID]?.completedFlag || timePeriodResults[A.LETTER_SOUNDS_ID]?.completedFlag
      // the cell is only clickable once an LN or LS assessment has been completed
      if (hasCompleteAssessment) {
        if (
          (hasSoundSymbolCorrNorms(timePeriod) && soundSymbolCorrDisplayRule === RULES.RATIO) || // if norms are available, the cell is only clickable if the toggle is set to display the scores as ratios
          !hasSoundSymbolCorrNorms(timePeriod) // if norms are not available, then the cell is clickable because scores will always be displayed as ratios
        ) {
          setLetterInventoryData(timePeriodResults)
        }
      }
    }
  }

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <StudentTableHeader colSpan={4} style={assessmentGroup.key === toggleKey ? { paddingBottom: 4 } : {}}>
              {assessmentGroup.title}{' '}
              {assessmentGroup.key === toggleKey &&
              hasSoundSymbolCorrNorms(BOY) && ( // feature flag is only checking BOY because the toggle will be available for every time period once this feature is available for BOY
                  <SoundSymbolCorrToggle
                    currDisplayRule={soundSymbolCorrDisplayRule}
                    setDisplayRule={setSoundSymbolCorrDisplayRule}
                    isDisabled={!studentHasScoredLNLS(resultsByPeriod)}
                  />
                )}
            </StudentTableHeader>
          </TableRow>
        </TableHead>
        {resultsByPeriod && (
          <TableBody>
            {assessments.map((assessment, idx) => {
              // loop through the assessments for each assessment group

              const scoresByPeriod = {
                BOY: getStudentScores(BOY, resultsByPeriod.BOY, assessment),
                MOY: getStudentScores(MOY, resultsByPeriod.MOY, assessment),
                EOY: getStudentScores(EOY, resultsByPeriod.EOY, assessment)
              }

              // renders PercentileCell based on the time period associated with the cell
              const renderPercentileCell = (timePeriod) => {
                return (
                  <PercentileCell
                    timePeriod={timePeriod}
                    testCode={assessment.key}
                    studentScores={scoresByPeriod[timePeriod]}
                    studentGrade={student.grade}
                    isApplicableAssmnt={timePeriodAssmntLists[timePeriod].includes(assessment.key)}
                    cellClasses={getScoreCellClasses(timePeriod, selectedTimePeriod, idx, assessments.length)}
                    displayRuleOverwrite={getDisplayRuleOverwrite(assessment, timePeriod)}
                    onClickCell={() => onClickCell(assessment.key, timePeriod)}
                  />
                )
              }

              const selectedTimePeriodDisplayRule =
                getDisplayRuleOverwrite(assessment, selectedTimePeriod) ||
                RULES.DISPLAY_RULES[student.grade][assessment.key][selectedTimePeriod]
              return (
                <TableRow key={assessment.key}>
                  <CharacterCell assessmentKey={assessment.key} />
                  <AssessmentTitleCell
                    isAdminView={isAdminView}
                    student={student}
                    onUpdate={onUpdate}
                    assessment={assessment}
                    studentScores={scoresByPeriod[selectedTimePeriod]}
                  />
                  <PercentileSlider
                    studentScores={scoresByPeriod[selectedTimePeriod]}
                    isHidden={selectedTimePeriodDisplayRule !== RULES.PERCENTILE}
                  />
                  {renderPercentileCell(BOY)}
                  {renderPercentileCell(MOY)}
                  {renderPercentileCell(EOY)}
                  {letterInventoryData && !lnlsOverwritesEnabled && (
                    <Dialog
                      body={
                        <div>
                          <ItemInventory
                            studentResults={letterInventoryData}
                            studentName={`${student.firstName} ${student.lastName}`}
                          />
                        </div>
                      }
                      actionTitle={'Close'}
                      cancelTitle={null}
                      onAction={() => setLetterInventoryData(null)}
                      onClose={() => setLetterInventoryData(null)}
                    />
                  )}
                  {letterInventoryData && lnlsOverwritesEnabled && (
                    <ItemInventoryV2
                      studentResults={letterInventoryData}
                      studentName={`${student.firstName} ${student.lastName}`}
                      onClose={() => setLetterInventoryData(null)}
                    />
                  )}
                </TableRow>
              )
            })}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  )
}

export default AssessmentGroupTable
