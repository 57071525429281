import React, { useState, useEffect } from 'react'
import { useAuth } from 'auth'
import { ViewWrapper } from '../../ui-components/backgrounds'
import {
  AuthBackground,
  ContentWrapper,
  FormWrapper,
  PageLink,
  AuthHeader,
  AuthLogo,
  BackArrow
} from '../../ui-components/authStyles'
import { BackWrapper, InputWrapper, FormContent } from './styles'
import { TextField } from '../../ui-components/inputs/inputs'
import { LargeButton } from '../../ui-components/buttons/buttons'
import ToastNotification from '../../components/ToastNotification'
import PasswordValidator from '../../components/PasswordValidator'
import * as COPY from '../../constants/copy'
import { RESET_PASSWORD_ERROR, RESET_PASSWORD_SUCCESS } from './copy'

const ResetPassword = ({ location }) => {
  const [email, setEmail] = useState('')
  const [verificationCode, setVerificationCode] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const [emailError, setEmailError] = useState(null)
  const [passwordError, setPasswordError] = useState(null)
  const [codeError, setCodeError] = useState(null)
  const [passwordConfirmError, setPasswordConfirmError] = useState(null)
  const [alert, setAlert] = useState({ isOpen: false, type: '', message: '' })
  const [isDisabled, setIsDisabled] = useState(false)
  const { confirmNewPassword } = useAuth()

  // using query params on the initial render to auto fill email/code for user
  useEffect(() => {
    if (location) {
      const params = new URLSearchParams(location.search)
      if (params.get('email')) {
        const paramsEmail = params.get('email')
        setEmail(paramsEmail)
      }
      if (params.get('code')) {
        const paramsCode = params.get('code')
        setVerificationCode(paramsCode)
      }
    }
    return () => {}
  }, [location])

  const resetNewPassword = async () => {
    if (email === '') {
      setIsDisabled(false)
      setEmailError(true)
    } else if (verificationCode === '') {
      setIsDisabled(false)
      setCodeError(true)
    } else if (password === '') {
      setIsDisabled(false)
      setPasswordError(true)
    } else if (password !== passwordConfirm) {
      setIsDisabled(false)
      setPasswordConfirmError(true)
    } else {
      try {
        await confirmNewPassword(email, verificationCode, password)
        setAlert({ isOpen: true, type: 'success', message: RESET_PASSWORD_SUCCESS })
      } catch (err) {
        setIsDisabled(false)
        setPassword('')
        setPasswordConfirm('')
        setAlert({ isOpen: true, type: 'error', message: RESET_PASSWORD_ERROR })
      }
    }
  }

  return (
    <ViewWrapper>
      <AuthBackground />
      <ContentWrapper>
        <AuthLogo />
        <FormWrapper>
          <AuthHeader className='reset'>Reset Your Password</AuthHeader>
          <FormContent>
            <InputWrapper>
              <TextField
                type='text'
                placeholder='Your Email'
                value={email}
                label='Email'
                onChange={(event) => {
                  if (emailError) {
                    setEmailError(false)
                  }
                  setEmail(event.target.value)
                }}
                data-testid='emailInput'
                error={emailError}
                helperText={emailError ? COPY.EMPTY_EMAIL_ERROR : ''}
                severity={emailError ? 'error' : null}
              />
            </InputWrapper>
            <InputWrapper>
              <TextField
                type='text'
                placeholder='Verification Code'
                value={verificationCode}
                label='Verification Code'
                onChange={(event) => {
                  if (codeError) {
                    setCodeError(false)
                  }
                  setVerificationCode(event.target.value)
                }}
                data-testid='codeInput'
                error={codeError}
                helperText={codeError ? COPY.EMPTY_VERIFICATION_CODE_ERROR : ''}
                severity={codeError ? 'error' : null}
              />
            </InputWrapper>
            <InputWrapper>
              <TextField
                type='password'
                placeholder='New Password'
                value={password}
                label='New Password'
                onChange={(event) => {
                  if (passwordError) {
                    setPasswordError(false)
                  }
                  setPassword(event.target.value)
                }}
                data-testid='pword1Input'
                error={passwordError}
                helperText={passwordError ? COPY.EMPTY_PASSWORD_ERROR : ''}
                severity={passwordError ? 'error' : null}
              />
            </InputWrapper>
            <InputWrapper>
              <TextField
                type='password'
                placeholder='Confirm New Password'
                value={passwordConfirm}
                label='Confirm New Password'
                onChange={(event) => {
                  if (setPasswordConfirmError) {
                    setPasswordConfirmError(false)
                  }
                  setPasswordConfirm(event.target.value)
                }}
                data-testid='pword2Input'
                error={passwordConfirmError}
                helperText={passwordConfirmError ? COPY.MATCHING_PASSWORDS_ERROR : ''}
                severity={passwordConfirmError ? 'error' : null}
              />
            </InputWrapper>
            <PasswordValidator password={password} />
            <LargeButton
              type='button'
              alt='submit'
              data-testid='submit'
              disabled={isDisabled}
              onClick={() => {
                setIsDisabled(true)
                resetNewPassword()
              }}
            >
              Reset Password
            </LargeButton>
          </FormContent>

          <BackWrapper>
            <BackArrow />
            <PageLink to='/login'>Back to Login</PageLink>
          </BackWrapper>
        </FormWrapper>
      </ContentWrapper>

      {alert.isOpen && (
        <ToastNotification
          alert={alert}
          path={alert.type === 'success' && '/login'}
          action={alert.type === 'success' && 'GO TO LOGIN'}
          onClose={() => {
            setAlert({ isOpen: false, type: '', message: '' })
          }}
        />
      )}
    </ViewWrapper>
  )
}

export default ResetPassword
