import React from 'react'
import TBDScore from 'components/TBDScore'
import SingleColPwrScore from 'components/SingleColPwrScore'
import RatioScore from 'ui-components/tables/cells/RatioScore'
import {
  IncompleteIcon,
  NeedsScoringIcon,
  OnTrackIcon,
  NotYetIcon,
  PWRIcon,
  RiskFlagIcon,
  ReadingReadinessIcon,
  CheckMarkIcon
} from 'ui-components/icons'
import { getOrdinalNumber } from 'utils/ordinalNumbers'
import {
  isStudentAtRisk,
  isStudentReadingReady,
  isPreKStudentReadingReady,
  determineStudentSingleColPwrRiskCategory
} from 'utils/students'
import { probabilisticPercentage } from 'utils/formatting'
import * as A from 'constants/assessments'
import * as T from 'constants/thresholds'
import * as RULES from 'constants/displayRules'
import { PWR, SINGLE_COL_PWR, DYSLEXIA, KREADY } from 'constants/assessments'
import { RISK_CATEGORIES } from 'constants/singleColPwr'
import { PK } from 'constants/grades'

export const getAssessmentDisplay = ({
  studentGrade,
  assessmentId,
  timePeriod,
  isComplete,
  abilityPercentile,
  finalTheta,
  correctItems,
  totalItems,
  isInvalid,
  displayRuleOverwrite
}) => {
  let content = null
  let classes = ''

  // If the original display rule is not null and displayRuleOverwrite is provided, display the score using that rule instead of the rule defined in the display rules object. Otherwise, use the display rules object to determine the format to display the assessment score.
  // If the original display rule is null then keep it as null
  const displayRule = RULES.DISPLAY_RULES[studentGrade][assessmentId][timePeriod]
    ? displayRuleOverwrite || RULES.DISPLAY_RULES[studentGrade][assessmentId][timePeriod]
    : null

  // if the rule is null, the assessment should not be displayed and all other display logic can be skipped
  if (displayRule === null) {
    return { content, classes: 'notApplicable' }
  }

  const isRAN = A.RAN_ASSESSMENT_IDS.includes(assessmentId)
  const needsScoring = isRAN && isComplete && !abilityPercentile && isInvalid === null

  if (isInvalid) {
    content = 'N/A'
    classes = 'invalid defaultCell'
  } else if (needsScoring) {
    content = <NeedsScoringIcon />
    classes = 'needsScoringAssmnt'
  } else if (!isComplete) {
    content = <IncompleteIcon />
    classes = 'incompleteAssmnt'
  }

  if (!content) {
    switch (displayRule) {
      case RULES.TBD:
        content = <TBDScore timePeriod={timePeriod} />
        break
      case RULES.RATIO:
        content = <RatioScore correctItems={correctItems} totalItems={totalItems} />
        break
      case RULES.PROFICIENCY: {
        content = T.isProficient(studentGrade, assessmentId, timePeriod, finalTheta) ? <OnTrackIcon /> : <NotYetIcon />
        break
      }
      case RULES.PERCENTILE: {
        content = getOrdinalNumber(abilityPercentile)
        classes = 'percent defaultCell'
        if (abilityPercentile === null || abilityPercentile === undefined) {
          break
        }
        if (abilityPercentile <= T.PERCENTILE_HIGH_RISK_THRESHOLD) classes = `${classes} highRiskAssmnt`
        else if (abilityPercentile <= T.PERCENTILE_AT_RISK_THRESHOLD) classes = `${classes} atRiskAssmnt`
        break
      }
    }
  }

  return { content, classes, displayRule }
}

export const getPredictiveProfileDisplay = (key, studentGrade, studentId, timePeriod, riskScore, isClassroomView) => {
  switch (key) {
    case DYSLEXIA:
      return getDyslexiaRiskDisplay(studentGrade, timePeriod, riskScore)
    case PWR:
      return getPWRDisplay(studentGrade, timePeriod, riskScore)
    case SINGLE_COL_PWR:
      return getSingleColPWRDisplay(
        studentGrade,
        studentId,
        timePeriod,
        riskScore.pwr,
        riskScore.dyslexia,
        isClassroomView
      )
    case KREADY:
      return getPreKReadingReadinessDisplay(timePeriod, riskScore)
  }
}

export const getPWRDisplay = (studentGrade, timePeriod, gradeLevelProbability) => {
  let content = ''
  let classes = ''

  const isReadingReady = isStudentReadingReady(gradeLevelProbability, studentGrade, timePeriod)
  const formattedPwr = probabilisticPercentage(gradeLevelProbability)

  const displayRule = RULES.DISPLAY_RULES[studentGrade][A.PWR][timePeriod]
  switch (displayRule) {
    case RULES.GRAYED_OUT:
    case null:
      classes = 'notApplicable'
      break
    case RULES.ALG_ICON: {
      if (isReadingReady) {
        content = <CheckMarkIcon />
      } else if (gradeLevelProbability) {
        content = <PWRIcon />
      }
      break
    }
    case RULES.ALG_PERCENTAGE: {
      if (isReadingReady) {
        content = <div>{formattedPwr}</div>
        classes = 'defaultCell'
      } else if (gradeLevelProbability) {
        content = <div>{formattedPwr}</div>
        classes = 'pwrRiskPercentage'
      }
      break
    }
  }

  return { content, classes }
}

const getRiskCategoryClasses = (riskCategory) => {
  switch (riskCategory) {
    case RISK_CATEGORIES.HIGH:
      return 'singleColPwrHighRisk'
    case RISK_CATEGORIES.MODERATE:
      return 'singleColPwrModerateRisk'
    case RISK_CATEGORIES.LOW:
      return 'singleColPwrLowRisk'
    default:
      return ''
  }
}

export const getSingleColPWRDisplay = (
  studentGrade,
  studentId,
  timePeriod,
  pwrScore,
  dyslexiaRiskScore,
  isClassroomView = false
) => {
  let content = ''
  let classes = ''

  const studentRiskCategory = determineStudentSingleColPwrRiskCategory(
    studentGrade,
    studentId,
    timePeriod,
    pwrScore,
    dyslexiaRiskScore
  )

  const displayRule = RULES.DISPLAY_RULES[studentGrade][A.SINGLE_COL_PWR][timePeriod]
  switch (displayRule) {
    case RULES.GRAYED_OUT:
    case null:
      classes = 'notApplicable'
      break
    case RULES.ALG_PERCENTAGE: {
      if (!studentRiskCategory) {
        // if risk scores not yet calculated, then display nothing
        break
      } else {
        content = (
          <SingleColPwrScore pwrScore={pwrScore} riskCategory={studentRiskCategory} displayLabel={isClassroomView} />
        )
        classes = getRiskCategoryClasses(studentRiskCategory)
      }
      break
    }
  }

  return { content, classes }
}

export const getDyslexiaRiskDisplay = (studentGrade, timePeriod, riskScore) => {
  let content = ''
  let classes = ''

  const atRisk = isStudentAtRisk(riskScore, studentGrade, timePeriod)

  const displayRule = RULES.DISPLAY_RULES[studentGrade][A.DYSLEXIA][timePeriod]
  switch (displayRule) {
    case RULES.GRAYED_OUT:
    case null:
      classes = 'notApplicable'
      break
    case RULES.ALG_ICON: {
      if (atRisk) {
        content = <RiskFlagIcon />
      } else if (riskScore) {
        content = <CheckMarkIcon />
      }
      break
    }
  }

  return { content, classes }
}

export const getPreKReadingReadinessDisplay = (timePeriod, riskScore) => {
  let content = ''
  let classes = ''

  const displayRule = RULES.DISPLAY_RULES[PK][A.KREADY][timePeriod]
  switch (displayRule) {
    case RULES.TBD:
      if (riskScore) {
        content = 'TBD'
      }
      classes = 'defaultCell'
      break
    case RULES.GRAYED_OUT:
    case null:
      classes = 'notApplicable'
      break
    case RULES.ALG_ICON: {
      const readingReady = isPreKStudentReadingReady(riskScore, timePeriod)
      if (readingReady) {
        content = <CheckMarkIcon />
      } else if (riskScore) {
        content = <ReadingReadinessIcon />
      }
      break
    }
  }

  return { content, classes }
}
