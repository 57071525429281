import React from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Paper from '@material-ui/core/Paper'
import { TextField, selectOptionStyles } from '../../../../ui-components/inputs/inputs'

const FormAutocompleteSelect = ({ field, fieldValues, updateField, hasError }) => {
  return (
    <Autocomplete
      id={`${field.key}AutocompleteSelect`}
      key={field.key}
      disabled={field.isDisabled || false}
      options={field.options}
      value={fieldValues[field.key] || null}
      getOptionSelected={() => {
        return fieldValues[field.key]
      }}
      onChange={(_, value) => {
        updateField(field.key, value ? value.value : '')
      }}
      PaperComponent={({ children }) => <Paper style={selectOptionStyles}>{children}</Paper>}
      getOptionLabel={(option) => option.value || option}
      renderOption={(option) => option.value}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            label={field.label}
            required={field.isRequired}
            error={hasError}
            severity={hasError ? 'error' : ''}
            helperText={hasError ? `${field.label} is a required field.` : ''}
          />
        )
      }}
    />
  )
}

export default FormAutocompleteSelect
