import * as React from 'react'
import StudentInfoSlim from './components/StudentInfoSlim'
import StudentResultsTableSlim from './components/StudentResultsTableSlim'
import ErrorBoundary from 'components/ErrorBoundary'
import { ContentWrapper } from './styles'
import CoverLetter from './CoverLetter'
import { BOY, MOY, EOY } from 'constants/timePeriods'
import * as COPY from './copy'

// Note: react-to-print lib requires that we forward a
// a ref to the "root" component that we would like to
// print.

const FamilyReport = React.forwardRef((props, ref) => {
  const { student, studentResults, timePeriod, mostRecentRANScore, timePeriodAssmntLists, assmntConfig } = props

  const timePeriods = {
    [BOY]: 'Beginning of Year',
    [MOY]: 'Middle of Year',
    [EOY]: 'End of Year'
  }

  const getCoverLetterVariant = (student) => student.grade

  return (
    <ContentWrapper ref={ref}>
      <CoverLetter variant={getCoverLetterVariant(student)} timePeriod={timePeriod} />
      <StudentInfoSlim student={student} />
      <h2>Subtest Results: {timePeriods[timePeriod]}</h2>
      <ErrorBoundary fallback={() => <div>{COPY.STUDENT_RESULTS_ERROR}</div>}>
        <StudentResultsTableSlim
          student={student}
          studentResults={studentResults}
          mostRecentRANScore={mostRecentRANScore}
          assmntConfig={assmntConfig}
          timePeriodAssmntLists={timePeriodAssmntLists}
          currTimePeriod={timePeriod}
        />
      </ErrorBoundary>
    </ContentWrapper>
  )
})

FamilyReport.displayName = 'FamilyReport'

export default FamilyReport
