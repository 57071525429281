import styled from 'styled-components'
import MaterialTextField from '@material-ui/core/TextField'
import MaterialFormControl from '@material-ui/core/FormControl'
import { KeyboardDatePicker } from '@material-ui/pickers'
import * as COLORS from '../../constants/colors'

export const Column = styled.div({
  display: 'flex',
  flexDirection: 'column',
  '&:not(:last-child)': {
    marginRight: '1.5rem'
  }
})

export const MultiColRow = styled.div({
  display: 'flex',
  flexDirection: 'row',
  '& > div': {
    flex: 1
  }
})

export const TextField = styled(MaterialTextField)({
  '&.MuiFormControl-root': {
    margin: '0.5rem',
    width: '100%'
  }
})

export const FormControl = styled(MaterialFormControl)({
  '&.MuiFormControl-root': {
    width: '100%',
    borderRadius: '0.938rem',
    marginTop: '.5rem'
  }
})

export const AutocompleteFormControl = styled(MaterialFormControl)({
  '&.MuiFormControl-root': {
    width: '100%'
  }
})

export const DatePicker = styled(KeyboardDatePicker)`
  width: 100%;
  height: 3.2rem;
  background: transparent;

  &.MuiFormControl-root {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    height: 4.2rem;
  }

  & .MuiInputBase-root {
    border: solid 1px
      ${(props) => (props.severity === 'error' ? COLORS.ERROR_INPUT_BORDER : COLORS.DEFAULT_INPUT_BORDER)};
    border-radius: 0.938rem;
    padding: 0.625rem;
    overflow: hidden;
  }

  & .Mui-focused {
    border-color: ${COLORS.ACTIVE_INPUT_BORDER};
    text-decoration: none;
  }

  & .MuiInput-underline:after {
    border-bottom: none;
  }

  & .MuiInput-underline:before {
    border-bottom: none;
  }

  & .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: none;
  }
`
export const TooltipText = styled.div({
  fontFamily: 'Inter',
  fontSize: '0.875rem'
})

export const StyledField = styled.div({
  width: (props) => (props.multicol ? '16.5rem' : '34rem'),
  minHeight: '5rem',
  '&:not(:last-child)': {
    marginRight: '1rem'
  },
  maxHeight: '7rem'
})
