import React, { useState, useRef } from 'react'
import { useAuth } from 'auth'
import { useLocation } from 'react-router-dom'
import { useSessionStore, actionTypes } from '../../stores/SessionStore'
import IdleTimer from 'react-idle-timer'
import Dialog from '../Dialog'
import { LOG_OUT_TEXT, STAY_LOGGED_IN_TEXT } from './copy'

// 1 hour
const SESSION_TIMEOUT_MS = 1000 * 60 * 60

// 30 seconds
const IDLE_TIMEOUT_MS = 1000 * 30

const IdleTimerContainer = () => {
  const location = useLocation()
  const [isIdlePromptOpen, setIsIdlePromptOpen] = useState(false)

  const { state, dispatch } = useSessionStore()
  // idle state is only checked when user is logged in
  const { isLoggedIn } = state

  const idleTimerRef = useRef(null)
  const sessionTimeoutRef = useRef(null)
  const { signOut } = useAuth()

  const logout = async (isIdle = false) => {
    try {
      await signOut()
      if (isIdle) {
        dispatch({ type: actionTypes.IDLE_LOGOUT, loginRedirectPathName: location.pathname })
      } else {
        dispatch({ type: actionTypes.LOGOUT })
      }
    } catch (err) {
      alert(`Error: ${err.message}`)
    }
  }

  const handleOnIdle = () => {
    setIsIdlePromptOpen(true)
    // will log out if the user doesn't respond to the idle prompt within IDLE_TIMEOUT_MS timeframe
    sessionTimeoutRef.current = setTimeout(async () => {
      const isIdle = true
      await logout(isIdle)
      setIsIdlePromptOpen(false)
    }, IDLE_TIMEOUT_MS)
  }

  return (
    <div>
      {isIdlePromptOpen && (
        <Dialog
          header='Are you still there?'
          actionTitle={STAY_LOGGED_IN_TEXT}
          onAction={() => {
            clearTimeout(sessionTimeoutRef.current)
            setIsIdlePromptOpen(false)
          }}
          cancelTitle={LOG_OUT_TEXT}
          onClose={() => {
            clearTimeout(sessionTimeoutRef.current)
            setIsIdlePromptOpen(false)
            logout()
          }}
        />
      )}
      {isLoggedIn && <IdleTimer ref={idleTimerRef} timeout={SESSION_TIMEOUT_MS} onIdle={handleOnIdle} />}
    </div>
  )
}

export default IdleTimerContainer
