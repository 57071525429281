import React, { useState } from 'react'
import { PopoverWrapper } from './styles'

const Popover = ({
  id,
  trigger,
  body,
  onOpen = null,
  onClose = null,
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'center'
  },
  transformOrigin = { vertical: 'top', horizontal: 'center' }
}) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const isOpen = Boolean(anchorEl)
  const openPopup = (event) => {
    setAnchorEl(event.currentTarget)
    if (onOpen) {
      onOpen()
    }
  }

  const closePopup = () => {
    setAnchorEl(null)
    if (onClose) {
      onClose()
    }
  }

  return (
    <div>
      <PopoverWrapper
        id={id}
        open={isOpen}
        anchorEl={anchorEl}
        onClose={closePopup}
        onClick={closePopup}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        {body}
      </PopoverWrapper>
      <trigger.type {...trigger.props} id={`${id}Trigger`} onClick={openPopup} />
    </div>
  )
}

export default Popover
