import posthog from 'posthog-js'
import * as GRADE from './grades'

export const LETTER_NAMES_ID = 'TEST_LETTER_NAMES'
export const LETTER_SOUNDS_ID = 'TEST_LETTER_SOUNDS'
export const VOCAB_MEASURE_ID = 'TEST_VOCAB_MEASURE'
export const ORAL_SENTENCE_MEASURE_ID = 'TEST_ORAL_SENTENCE_MEASURE'
export const BLENDING_ID = 'TEST_BLENDING'
export const OBJ_RAN_ID = 'TEST_RAN_WORDS'
export const LETTER_RAN_ID = 'TEST_LETTER_RAN'
export const RHYMING_ID = 'TEST_RHYMING_WORDS'
export const WORD_MATCHING_ID = 'TEST_WORD_MATCHING'
export const FIRST_SOUNDS_ID = 'TEST_FIRST_SOUNDS'
export const FOLLOW_DIRECTIONS_ID = 'TEST_FOLLOW_DIRECTIONS'
export const NON_WORD_REP_ID = 'TEST_NON_WORD_REPETITION_MEASURE'
export const DELETION_ID = 'TEST_DELETION'
export const WORD_READING_ID = 'TEST_WORD_READING'
export const NW_READING_ID = 'TEST_NW_READING'
export const RER_WR_ID = 'TEST_RER_WR'
export const RER_NW_READING_ID = 'TEST_RER_NW_READING'
export const RER_NW_REP_ID = 'TEST_RER_NW_REP'
export const RER_EVO_ID = 'TEST_RER_EXPRESSIVE_VOC'
export const RER_NW_SPELLING_ID = 'TEST_RER_NW_SPELLING'
export const RER_LETTER_RAN_ID = 'TEST_RER_LETTER_RAN'

export const RAN_ASSESSMENT_IDS = [OBJ_RAN_ID, LETTER_RAN_ID, RER_LETTER_RAN_ID]

export const EXPRESSIVE_ASSESSMENTS = [
  BLENDING_ID,
  DELETION_ID,
  LETTER_NAMES_ID,
  LETTER_RAN_ID,
  LETTER_SOUNDS_ID,
  NON_WORD_REP_ID,
  NW_READING_ID,
  OBJ_RAN_ID,
  RER_EVO_ID,
  RER_NW_READING_ID,
  RER_NW_REP_ID,
  RER_WR_ID,
  WORD_READING_ID,
  RER_LETTER_RAN_ID
]

// risk profile constants
export const DYSLEXIA = 'dyslexia'
export const SINGLE_COL_PWR = 'single_pwr'
export const PWR = 'pwr'
export const KREADY = 'kready'

export const PREDICTIVE_PROFILE_DISPLAY_NAMES = {
  [DYSLEXIA]: 'Risk Flag',
  [PWR]: 'Potential For Word Reading',
  [SINGLE_COL_PWR]: 'Potential for Word Reading',
  [KREADY]: 'Reading Readiness'
}

const ASSESSMENT_GROUPS_V1 = [
  {
    key: 'ran',
    title: 'Naming Speed',
    assessments: [
      { key: OBJ_RAN_ID, title: 'Object RAN', grades: [GRADE.PK, GRADE.KINDERGARTEN] },
      { key: LETTER_RAN_ID, title: 'Letter RAN', grades: [GRADE.FIRST] },
      { key: RER_LETTER_RAN_ID, title: 'Letter RAN', grades: [GRADE.SECOND] }
    ]
  },
  {
    key: 'soundSymbolCorr',
    title: 'Sound/Symbol Correspondence',
    assessments: [
      { key: LETTER_NAMES_ID, title: 'Letter Name' },
      { key: LETTER_SOUNDS_ID, title: 'Letter Sound' }
    ]
  },
  {
    key: 'phonAwareness',
    title: 'Phonemic/Phonological Awareness',
    assessments: [
      { key: RHYMING_ID, title: 'Rhyming' },
      {
        key: FIRST_SOUNDS_ID,
        title: 'First Sound Matching'
      },
      { key: BLENDING_ID, title: 'Blending' },
      { key: DELETION_ID, title: 'Deletion' },
      {
        key: NON_WORD_REP_ID,
        title: 'Nonword Repetition'
      },
      { key: RER_NW_REP_ID, title: 'Nonword Repetition' }
    ]
  },
  {
    key: 'phonics',
    title: 'Phonics',
    assessments: [
      { key: NW_READING_ID, title: 'Nonword Reading' },
      { key: RER_NW_READING_ID, title: 'Nonword Reading' },
      { key: WORD_READING_ID, title: 'Word Reading' },
      { key: RER_WR_ID, title: 'Word Reading' },
      { key: RER_NW_SPELLING_ID, title: 'Nonword Spelling' }
    ]
  },
  {
    key: 'oralLangComp',
    title: 'Oral Language Comprehension',
    assessments: [
      { key: VOCAB_MEASURE_ID, title: 'Vocabulary' },
      { key: WORD_MATCHING_ID, title: 'Word Matching' },
      {
        key: ORAL_SENTENCE_MEASURE_ID,
        title: 'Oral Sentence Comp.'
      },
      {
        key: FOLLOW_DIRECTIONS_ID,
        title: 'Follow Directions'
      },
      { key: RER_EVO_ID, title: 'Expressive Vocabulary' }
    ]
  }
]

const ASSESSMENT_GROUPS_V2 = [
  {
    key: 'phonAwareness',
    title: 'Phonemic Awareness',
    assessments: [
      { key: RHYMING_ID, title: 'Rhyming' },
      {
        key: FIRST_SOUNDS_ID,
        title: 'First Sound Matching'
      },
      { key: BLENDING_ID, title: 'Blending' },
      { key: DELETION_ID, title: 'Deletion' },
      {
        key: NON_WORD_REP_ID,
        title: 'Nonword Repetition'
      },
      { key: RER_NW_REP_ID, title: 'Nonword Repetition' }
    ]
  },
  {
    key: 'phonics',
    title: 'Phonics',
    assessments: [
      { key: LETTER_NAMES_ID, title: 'Letter Name' },
      { key: LETTER_SOUNDS_ID, title: 'Letter Sound' },
      { key: NW_READING_ID, title: 'Nonword Reading' },
      { key: RER_NW_READING_ID, title: 'Nonword Reading' },
      { key: RER_NW_SPELLING_ID, title: 'Nonword Spelling' }
    ]
  },
  {
    key: 'fluency',
    title: 'Fluency',
    assessments: [
      { key: WORD_READING_ID, title: 'Word Reading' },
      { key: RER_WR_ID, title: 'Word Reading' },
      { key: OBJ_RAN_ID, title: 'Object RAN', grades: [GRADE.PK, GRADE.KINDERGARTEN] },
      { key: LETTER_RAN_ID, title: 'Letter RAN', grades: [GRADE.FIRST] },
      { key: RER_LETTER_RAN_ID, title: 'Letter RAN', grades: [GRADE.SECOND] }
    ]
  },
  {
    key: 'vocabulary',
    title: 'Vocabulary',
    assessments: [
      { key: VOCAB_MEASURE_ID, title: 'Vocabulary' },
      { key: RER_EVO_ID, title: 'Expressive Vocabulary' },
      { key: WORD_MATCHING_ID, title: 'Word Matching' }
    ]
  },
  {
    key: 'langComp',
    title: 'Comprehension',
    assessments: [
      {
        key: ORAL_SENTENCE_MEASURE_ID,
        title: 'Oral Sentence Comp.'
      },
      {
        key: FOLLOW_DIRECTIONS_ID,
        title: 'Follow Directions'
      }
    ]
  }
]

// initialize array with v1
export const ASSESSMENT_GROUPS = [...ASSESSMENT_GROUPS_V1]

posthog.onFeatureFlags(() => {
  if (posthog.isFeatureEnabled('rer-grade-1')) {
    ASSESSMENT_GROUPS.length = 0 // clear the array to renmove v1
    ASSESSMENT_GROUPS.push(...ASSESSMENT_GROUPS_V2) // add v2
  }
})
