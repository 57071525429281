/* eslint indent: off */
import React from 'react'
import {
  Legend,
  KeyColumn,
  SubKeyColumn,
  Row,
  HeaderRow,
  LabelRow,
  RowHeader,
  Label,
  Header,
  SubHeader,
  SubColumn
} from './styles'
import InfoPopover from 'components/InfoPopover'

import { getPredictiveProfileKey, getStatusKey, getScoringKey } from './helper'

const getKeyContent = (grades, predictiveProfile, scoringUnits, timePeriod, isClassroomView) => {
  const keys = []

  const predictiveProfileKey = getPredictiveProfileKey(predictiveProfile, grades, timePeriod, isClassroomView)
  if (predictiveProfileKey) keys.push(predictiveProfileKey)

  const statusKey = getStatusKey(grades, isClassroomView)
  if (statusKey) keys.push(statusKey)

  const scoringKey = getScoringKey(scoringUnits, grades)
  if (scoringKey) keys.push(scoringKey)

  return keys
}

const TableLegend = ({ grades, predictiveProfile, scoringUnits, timePeriod, isClassroomView = false }) => {
  const keys = getKeyContent(grades, predictiveProfile, scoringUnits, timePeriod, isClassroomView)

  return (
    <Legend isClassroomView={isClassroomView}>
      {keys.map((key, keyIdx) => {
        return (
          <KeyColumn key={keyIdx} isCompressed={!isClassroomView}>
            <Header>{key.info ? <InfoPopover info={key.info} triggerText={key.title} /> : key.title}</Header>
            <Row>
              {key.columns.map((column, colIdx) => {
                return (
                  <SubKeyColumn key={colIdx} isCompressed={!isClassroomView}>
                    <HeaderRow>
                      <SubHeader>
                        {column.info ? <InfoPopover info={column.info} triggerText={column.title} /> : column.title}
                      </SubHeader>
                    </HeaderRow>
                    {column.rows
                      ? column.rows.map((row, rowIdx) => {
                          const { IconComponent, text, iconText, categoryText, title } = row
                          return (
                            <div key={`${colIdx}-${rowIdx}`}>
                              {title && <RowHeader>{title}</RowHeader>}
                              <LabelRow>
                                <IconComponent iconText={iconText} text={text} />
                                <div>
                                  {categoryText && <Label bold={true}>{categoryText}</Label>}
                                  {text && <Label>{text}</Label>}
                                </div>
                              </LabelRow>
                            </div>
                          )
                        })
                      : null}
                    {column.subColumns && (
                      <Row>
                        {column.subColumns.map((subCol, subColIdx) => {
                          const { title, IconComponent, text } = subCol
                          return (
                            <SubColumn key={`${colIdx}-${subColIdx}`}>
                              {title && <RowHeader>{title}</RowHeader>}
                              <LabelRow>
                                <IconComponent />
                                <Label>{text}</Label>
                              </LabelRow>
                            </SubColumn>
                          )
                        })}
                      </Row>
                    )}
                    {column.multiGradePWRSubCols && (
                      <>
                        <Row>
                          {column.multiGradePWRSubCols.slice(0, -1).map((subCol, subColIdx) => {
                            // This first row is for the single col pwr icons for both gr 1 and gr 2
                            return (
                              <SubColumn key={`${colIdx}-${subColIdx}`}>
                                {subCol.map((subRow, subRowIdx) => {
                                  const { title, IconComponent, categoryText, text } = subRow
                                  return (
                                    <div key={`${colIdx}-${subColIdx}-${subRowIdx}`}>
                                      {title && <RowHeader>{title}</RowHeader>}
                                      <LabelRow>
                                        <IconComponent />
                                        <div>
                                          {categoryText && <Label bold={true}>{categoryText}</Label>}
                                          {text && <Label>{text}</Label>}
                                        </div>
                                      </LabelRow>
                                    </div>
                                  )
                                })}
                              </SubColumn>
                            )
                          })}
                        </Row>
                        <Row style={{ paddingTop: '0.85rem', maxWidth: '12rem' }}>
                          {(() => {
                            // This second row is for the 'What happened to the dyslexia flag' icon,
                            // which is last in the multiGradePWRSubCols array
                            const subColIdx = column.multiGradePWRSubCols.length - 1
                            const lastSubCol = column.multiGradePWRSubCols[subColIdx]
                            const { IconComponent, iconText } = lastSubCol
                            return (
                              <SubColumn key={`${colIdx}-${subColIdx}`}>
                                <LabelRow>
                                  <IconComponent iconText={iconText} />
                                </LabelRow>
                              </SubColumn>
                            )
                          })()}
                        </Row>
                      </>
                    )}
                  </SubKeyColumn>
                )
              })}
            </Row>
          </KeyColumn>
        )
      })}
    </Legend>
  )
}

export default TableLegend
