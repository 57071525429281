import React from 'react'
import * as ROLE from '../../../../constants/roles'
import { BreadcrumbWrapper, BreadcrumbLink, BreadcrumbText } from '../../../../ui-components/breadcrumbs'

const Breadcrumbs = ({ district, role }) => {
  if (role === ROLE.SUPER_ADMIN) {
    return (
      <BreadcrumbWrapper>
        <BreadcrumbLink to='/super-admin'>Districts</BreadcrumbLink>
        <BreadcrumbText>{district.name}</BreadcrumbText>
      </BreadcrumbWrapper>
    )
  } else {
    return null
  }
}

export default Breadcrumbs
