import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { authenticateUser } from 'lib/api/login'
import { useSessionStore, actionTypes } from '../../stores/SessionStore'
import { useQueryClient } from '@tanstack/react-query'
import { logError } from 'sentry'
import LoadingIndicator from 'components/LoadingIndicator'

let didInit = false

const LoginCallback = () => {
  const [redirectTo, setRedirectTo] = useState(null)
  const { dispatch } = useSessionStore()
  const queryClient = useQueryClient()

  useEffect(() => {
    const processCallback = async () => {
      didInit = true
      const url = new URL(window.location.toString())
      const { searchParams } = url
      const code = searchParams.get('code')
      const callbackError = searchParams.get('error')

      if (callbackError) {
        console.error(`Callback Error code: ${callbackError}`)
        sessionStorage.setItem('loginError', callbackError.message)
        setRedirectTo('/login')
        return
      }

      try {
        if (code) {
          const responseBody = await authenticateUser({ code: code })
          const { error: verifyError, data } = responseBody

          if (verifyError) {
            throw new Error(`Verify Error code: ${verifyError}`)
          }
          if (!data) {
            throw new Error('No data received from server')
          }
          dispatch({
            type: actionTypes.UPDATE_FIELD,
            field: 'userEmail',
            data: data.email
          })
          dispatch({ type: actionTypes.UPDATE_FIELD, field: 'isLoggedIn', data: true })
        }
        queryClient.invalidateQueries({ queryKey: ['current-user'] })

        setRedirectTo('/')
      } catch (err) {
        console.error(
          `Authentication error: code: ${process.env.REACT_APP_ROSTERSTREAM_SSO_HOST} ${code} | message ${err.message}`
        )
        logError({ error: err })
        sessionStorage.setItem('loginError', err.message)
        setRedirectTo('/login')
      }
    }

    if (!didInit) processCallback()
  }, [dispatch, queryClient])

  if (redirectTo) {
    return <Redirect to={redirectTo} />
  }

  return <LoadingIndicator />
}

export default LoginCallback
