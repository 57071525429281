import React from 'react'
import { getAssessmentDisplay } from 'utils/display'
import { TableCell } from '../../styles'

const PercentileCellSlim = ({
  timePeriod,
  testCode,
  studentScores,
  studentGrade,
  isApplicableAssmnt,
  cellClasses,
  displayRuleOverwrite
}) => {
  let cellContent = null
  let cssClasses = ''

  if (!studentScores) {
    if (!isApplicableAssmnt) {
      // if assessment not applicable for time period display grayed out cell
      cssClasses = 'notApplicable'
    } else {
      // if assessment has not been started display empty cell
      cssClasses = 'emptyAssmnt'
    }

    return <TableCell className={`scoreCell ${cssClasses} ${cellClasses}`}>{cellContent}</TableCell>
  } else {
    // if assessment has been started, display based on display rules
    const { completedFlag, abilityPercentile, finalTheta, correctItems, totalItems, invalid } = studentScores
    const { content, classes } = getAssessmentDisplay({
      studentGrade: studentGrade,
      assessmentId: testCode,
      timePeriod: timePeriod,
      isComplete: completedFlag,
      abilityPercentile: abilityPercentile,
      finalTheta: finalTheta,
      correctItems: correctItems,
      totalItems: totalItems,
      isInvalid: invalid,
      displayRuleOverwrite
    })

    cellContent = content
    cssClasses = classes

    return <TableCell className={`scoreCell ${cssClasses} ${cellClasses}`}>{cellContent}</TableCell>
  }
}
export default PercentileCellSlim
