import React from 'react'
import styled from 'styled-components'
import { ReactComponent as CorrectMark } from 'ui-components/tables/Correct.svg'
import { ReactComponent as IncorrectMark } from 'ui-components/tables/Incorrect.svg'

export const Icon = styled.span`
  display: flex;
  justify-content: center;
`

export const CorrectIcon = () => {
  return (
    <Icon>
      <CorrectMark width={14} height={10} />
    </Icon>
  )
}

export const IncorrectIcon = () => {
  return (
    <Icon>
      <IncorrectMark width={12} height={12} />
    </Icon>
  )
}
