import styled from 'styled-components'
import * as COLOR from '../../../../constants/colors'

export const StudentInfoWrapper = styled.div`
  p {
    font-size: 0.9rem;
    font-family: Inter;
    font-size: 0.875rem;
    line-height: 1;
    color: ${COLOR.STUDENT_VIEW_LIGHT_TEXT};
    margin: 0;
    margin-bottom: 0.75rem;

    b {
      color: ${COLOR.STUDENT_VIEW_DARK_TEXT};
    }
  }
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.8rem;
`

export const EditWrapper = styled.div`
  margin-left: 0.5rem;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 0.5rem;
  border-bottom: solid 1px ${COLOR.STUDENT_VIEW_LIGHT_TEXT};
  margin-bottom: 1.2rem;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 11rem;
  overflow: hidden;
  text-overflow: ellipsis;

  &.first {
    margin-right: 3rem;
  }
`

export const ParticipantCodeWrapper = styled.div`
  display: flex;
`

export const ParticipantCodeTag = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${COLOR.PARTICIPANT_CODE_BACKGROUND};
  padding: 0.75rem;
  border-radius: 0.625rem;

  p {
    margin: 0;
    color: ${COLOR.STUDENT_VIEW_DARK_TEXT};
  }
`

export const ParticipantCode = styled.span`
  color: ${COLOR.PARTICIPANT_CODE_TEXT};
  font-weight: 700;
  margin-left: 0.25rem;
  padding: 0;
`
