import React from 'react'
import { TextField } from '../../../../ui-components/inputs/inputs'
import Tooltip from '@material-ui/core/Tooltip'
import { TooltipText } from '../../styles'

const FormTextField = ({ field, fieldValues, updateField, hasError }) => {
  const formBody = (
    <TextField
      key={field.key}
      id={`${field.key}TextField`}
      required={field.isRequired}
      // to disable a text field, add "isDisabled: true" to field obj
      disabled={Boolean(field.isDisabled)}
      label={field.label}
      value={fieldValues[field.key] || ''}
      onChange={(event) => updateField(field.key, event.target.value)}
      error={hasError}
      severity={hasError ? 'error' : ''}
      helperText={hasError ? `${field.label} is a required field.` : ''}
    />
  )

  return (
    <div>
      {field.toolTipText ? (
        <div id={`${field.key}Tooltip`}>
          <Tooltip title={<TooltipText>{field.toolTipText}</TooltipText>}>
            <span>{formBody}</span>
          </Tooltip>
        </div>
      ) : (
        formBody
      )}
    </div>
  )
}

export default FormTextField
