import styled from 'styled-components'
import { Link } from 'react-router-dom'
import * as COLOR from '../../../../constants/colors'

export const InternalLink = styled(Link)({
  textDecoration: 'none',
  color: COLOR.STUDENT_NAME_LINK,
  '&:visited': {
    color: COLOR.STUDENT_NAME_LINK
  },
  '&:hover': {
    color: COLOR.STUDENT_NAME_LINK_HOVER
  }
})

export const LinkWrapper = styled.div({
  margin: '0 0.5rem 0 1.5rem'
})
