const API_BASE = process.env.REACT_APP_API_BASE_URL

const buildAudioPath = (questionCode, participantId, participantTestId, authHeader) => {
  const getAudioEndpoint = `${API_BASE}/participantTest/getAudioFile`

  // formatting the questionId for the file path:
  // if questionCode has "TEST_" in the name, it is the practice test and the name needs to be formatted for the api.
  // otherwise, use a lowercase version of the code
  const questionId = questionCode.includes('TEST_')
    ? `test_ran_${questionCode.split('_').slice(2).join('_').toLowerCase()}`
    : questionCode.toLowerCase()

  return `${getAudioEndpoint}?fileName=${participantId}_${participantTestId}_${questionId}.wav&accessToken=${authHeader}`
}

export const getAssessmentDetails = async (testDetails) => {
  if (testDetails.participantId) {
    const { participantId, participantTestId, ParticipantTestQuestionResponses } = testDetails
    const tests = ParticipantTestQuestionResponses.map((test) => {
      // the response time is initially saved by the app as responseTimeInSecs, but the final response time set by teachers is saved as evalResponseTime
      const {
        questionCode,
        numOfErrors,
        responseTimeInSecs,
        evalResponseTime,
        participantTestQuestionResponseId
      } = test

      // returning an array of assessment detail objects
      return {
        questionId: participantTestQuestionResponseId,
        scoring: {
          questionId: participantTestQuestionResponseId,
          numOfErrors: numOfErrors,
          evalTime: evalResponseTime
        },
        responseTime: responseTimeInSecs,
        audio: buildAudioPath(questionCode, participantId, participantTestId)
      }
    })
    // splitting up the array into the practice audio and other audio recordings
    const practiceDetails = tests.length && [tests[0]]
    const questionDetails = tests.slice(1)

    return { practiceDetails, questionDetails }
  }
}
