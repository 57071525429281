import React from 'react'
import * as S from './styles'

const InventoryHeader = ({ studentName }) => {
  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <h1 style={S.nameHeaderStyle}>{studentName}</h1>
        <h2 style={{ marginTop: '5px' }}>
          <span style={S.headerStyle}>Sound/Symbol Correspondence </span>
          <span style={S.headerStyle2}>| </span> <span style={S.headerStyle3}>Full Inventory</span>
        </h2>
      </div>
    </>
  )
}

export default InventoryHeader
