import React from 'react'
import Tooltip from '@mui/material/Tooltip'
import { BOY, MOY, EOY } from 'constants/timePeriods'

const seasons = {
  [BOY]: 'Fall',
  [MOY]: 'Winter',
  [EOY]: 'Spring'
}

const TBDScore = ({ timePeriod }) => {
  return (
    <Tooltip title={`Score available later in ${seasons[timePeriod]}`}>
      <div>TBD</div>
    </Tooltip>
  )
}

export default TBDScore
