import React from 'react'
import { CustomSwitch } from './styles'
import { FormControlLabel } from '@material-ui/core'
import * as RULES from '../../constants/displayRules'

const SoundSymbolCorrToggle = ({ currDisplayRule, setDisplayRule, isDisabled = false }) => {
  const displayPercentile = currDisplayRule === RULES.PERCENTILE
  return (
    <FormControlLabel
      style={{ marginLeft: 0, marginRight: 0, padding: 6 }}
      control={
        <CustomSwitch
          checked={displayPercentile}
          onChange={() => {
            if (!isDisabled) {
              displayPercentile ? setDisplayRule(RULES.RATIO) : setDisplayRule(RULES.PERCENTILE)
            }
          }}
          content={{ before: 'Score', after: '%ile', displayPercentile, isDisabled }}
        />
      }
    />
  )
}

export default SoundSymbolCorrToggle
