import React from 'react'
import styled from 'styled-components'

import FlagIcon from '@material-ui/icons/Flag'
import MaterialIconButton from '@material-ui/core/IconButton'
import MaterialEditIcon from '@material-ui/icons/Edit'
import PriorityHighIcon from '@material-ui/icons/PriorityHigh'
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CheckIcon from '@material-ui/icons/Check'
import GetAppIcon from '@material-ui/icons/GetApp'
import { ReactComponent as IncompleteSVG } from './incomplete-icon.svg'
import { ReactComponent as CancelSVG } from './cancel-icon.svg'

import * as COLOR from '../constants/colors'

export const IncompleteIcon = styled(IncompleteSVG)({
  width: '1.125rem',
  height: '1.125rem',
  minWidth: '1.125rem',
  minHeight: '1.125rem',
  marginRight: '0.3rem'
})

export const NeedsScoringIcon = styled(CancelSVG)({
  width: '1.125rem',
  height: '1.125rem',
  minWidth: '1.125rem',
  minHeight: '1.125rem',
  marginRight: '0.3rem'
})

export const NotYetIcon = () => {
  const Icon = styled(DoNotDisturbOnIcon)({
    color: COLOR.NOT_YET_ICON
  })
  return <Icon fontSize='small' />
}

export const OnTrackIcon = () => {
  const Icon = styled(CheckCircleIcon)({
    color: COLOR.ON_TRACK_ICON
  })
  return <Icon fontSize='small' />
}

export const CheckMarkIcon = styled(CheckIcon)({
  color: COLOR.COMPLETE_CHECK
})

export const RiskFlagIcon = styled(FlagIcon)({
  color: COLOR.RISK_FLAG_ICON
})

export const PWRIcon = styled(PriorityHighIcon)({
  color: COLOR.EXCLAMATION_ICON
})

export const ReadingReadinessIcon = styled(PriorityHighIcon)({
  color: COLOR.EXCLAMATION_ICON
})

// icon button

const StyledIconButton = styled(MaterialIconButton)({
  '&.MuiIconButton-root': {
    padding: 0,
    '&:hover': {
      backgroundColor: COLOR.TABLE_ROW_HOVER
    }
  }
})

export const IconButton = ({ onClick, className, children }) => {
  return (
    <StyledIconButton onClick={onClick} className={className} disableFocusRipple={true} disableRipple={true}>
      {children}
    </StyledIconButton>
  )
}

// edit icon

const StyledEditIcon = styled(MaterialEditIcon)({
  color: COLOR.ICON_DEFAULT,
  '&:hover': {
    color: COLOR.ICON_HOVER
  }
})

export const EditIcon = ({ onEdit }) => {
  return (
    <IconButton onClick={onEdit} className='editButton'>
      <StyledEditIcon />
    </IconButton>
  )
}

// download icon
export const DownloadCSVIcon = styled(GetAppIcon)({
  color: COLOR.ICON_DEFAULT,
  '&:hover': {
    color: COLOR.ICON_HOVER
  }
})
