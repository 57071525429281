export const BOY = 'BOY'
export const MOY = 'MOY'
export const EOY = 'EOY'

export const BOYLabel = 'Beginning of Year (BOY)'
export const MOYLabel = 'Middle of Year (MOY)'
export const EOYLabel = 'End of Year (EOY)'

// for a given time period, the list of time periods that would have been completed/started to date in reverse order
export const timePeriodsToDate = {
  [BOY]: [BOY],
  [MOY]: [MOY, BOY],
  [EOY]: [EOY, MOY, BOY]
}
