import styled from 'styled-components'
import * as COLORS from '../constants/colors'

export const EmptyStateWrapper = styled.div`
  color: ${COLORS.EMPTY_STATES_TEXT};
  font-family: Inter;
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  justify-content: center;
  padding-top: 2rem;
  text-align: center;
`

export const Header = styled.h2`
  font-family: Quicksand;
  font-size: 2rem;
  font-weight: 600;
  font-stretch: normal;
  color: ${COLORS.ABOUT_EB_HEADER};
`

export const Body = styled.p`
  font-family: Inter;
  font-size: 1rem;
  font-weight: 500;
  color: ${COLORS.ABOUT_EB_TEXT};
`
export const ExternalLink = styled.a`
  color: ${COLORS.EB_LINK};
  p {
    padding-right: 0.5rem;
  }
  &:hover {
    color: ${COLORS.EB_LINK_HOVER};
  }
`
