import styled from 'styled-components'

export const FormContent = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  marginTop: '0rem'
})

export const ResetWrapper = styled.div({
  margin: '2rem auto 0 auto'
})

export const BackWrapper = styled.div({
  marginTop: '1.2rem'
})

export const InputWrapper = styled.div({
  margin: '.3rem',
  width: '100%'
})
