import React, { useState } from 'react'
import { PRIVACY_POLICY } from '../../constants/assets'
import { PrivacyPolicy, PrivacyPolicyLink, Footer } from './styles'
import Dialog from '@material-ui/core/Dialog'
import ToastNotification from '../ToastNotification'

const version = (process.env.REACT_APP_VERSION || 'local').replace('-prod', '')
const sha1 = process.env.REACT_APP_SHA1

const AuthFooter = () => {
  const [displayPrivacyPolicy, setDisplayPrivacyPolicy] = useState(false)
  const [privacyPolicy, setPrivacyPolicy] = useState('')
  const [isError, setIsError] = useState(false)

  const getPrivacyPolicy = async () => {
    try {
      const response = await fetch(PRIVACY_POLICY)
      if (response.status !== 200) {
        throw Error(response.statusText)
      }
      return await response.text()
    } catch (error) {
      setIsError(true)
      return null
    }
  }
  return (
    <>
      {isError && <ToastNotification type={'error'} message={'Error fetching Privacy Policy'} />}
      <Footer>
        <PrivacyPolicyLink
          id='privacyPolicyLink'
          onClick={async () => {
            const policy = await getPrivacyPolicy()
            setPrivacyPolicy(policy)
            setDisplayPrivacyPolicy(true)
          }}
        >
          Privacy Policy
        </PrivacyPolicyLink>
        <Dialog onClose={() => setDisplayPrivacyPolicy(false)} open={displayPrivacyPolicy}>
          <PrivacyPolicy id='privacyPolicy'>{privacyPolicy}</PrivacyPolicy>
        </Dialog>
        <div>
          Version {version} SHA1 {sha1}
        </div>
        <div>© EarlyBird Education, Inc. {new Date().getFullYear()}</div>
      </Footer>
    </>
  )
}

export default AuthFooter
