import styled from 'styled-components'
import { PageLink, AuthHeader, AuthSubHeader } from '../../ui-components/authStyles'

export const LoginHeader = styled(AuthHeader)({
  marginBottom: '1.25rem'
})

export const LoginSubHeader = styled(AuthSubHeader)({
  marginBottom: '2rem',
  textAlign: 'center'
})

export const LoginPageLink = styled(PageLink)({
  paddingTop: '2.75rem'
})
