import React, { useState, useEffect } from 'react'
import Accordion from '../Accordion'
import ScoringInstructions from './components/ScoringInstructions'
import QuestionResults from './components/QuestionResults'
import * as COPY from './copy'
import * as GRADE from '../../constants/grades'
import * as ASSET from '../../constants/assets'
import { getTestDetails } from '../../lib/api'
import { getRANAssessmentDetails } from '../../utils/assessmentAudio'
import ToastNotification from '../ToastNotification'
import {
  Image,
  ImageWrapper,
  SubHeader,
  Header,
  CloseWrapper,
  CloseIcon,
  DialogWrapper,
  ContentWrapper,
  Dialog
} from './styles'

const RANScoring = ({ student, onClose, testId, isInvalid, onUpdate }) => {
  const [testDetails, setTestDetails] = useState({})
  const [practiceResults, setPracticeResults] = useState([])
  const [questionResults, setQuestionResults] = useState([])
  const [loading, setLoading] = useState({ isComplete: false, hasError: false })
  const [alert, setAlert] = useState({ isOpen: false, type: '', message: '' })

  useEffect(() => {
    if (loading.hasError) return

    let isMounted = true
    ;(async () => {
      try {
        const testInfo = await getTestDetails(testId)
        if (isMounted) {
          setTestDetails(testInfo)
          setLoading({ isComplete: true, hasError: false })
        }
      } catch (err) {
        setLoading({ isComplete: true, hasError: true })
        setAlert({ isOpen: true, type: 'error', message: COPY.TEST_DETAILS_LOAD_ERROR })
      }
    })()
    return () => {
      isMounted = false
    }
  }, [testId, loading.hasError])

  useEffect(() => {
    ;(async () => {
      try {
        const details = await getRANAssessmentDetails(testDetails, isInvalid)
        if (details) {
          setPracticeResults(details.practiceDetails)
          setQuestionResults(details.questionDetails)
        }
      } catch (err) {
        setAlert({ isOpen: true, type: 'error', message: COPY.TEST_DETAILS_LOAD_ERROR })
      }
    })()
  }, [testDetails, isInvalid])

  const getReferenceImage = () => {
    switch (student.grade) {
      case GRADE.PK:
        return ASSET.OBJ_RAN_STIMULI_PK
      case GRADE.KINDERGARTEN:
        return ASSET.OBJ_RAN_STIMULI
      case GRADE.FIRST:
        return ASSET.LETTER_RAN_STIMULI
      case GRADE.SECOND:
        return ASSET.RER_LETTER_RAN_STIMULI
    }
  }

  const isContentLoaded = loading.isComplete && !loading.hasError
  return (
    <>
      {isContentLoaded && (
        <Dialog
          onClose={async () => {
            onClose()
            await onUpdate()
          }}
          open
        >
          <DialogWrapper>
            <CloseWrapper
              onClick={async () => {
                onClose()
                await onUpdate()
              }}
            >
              Close <CloseIcon />
            </CloseWrapper>
            <ContentWrapper>
              <Header>Test Details: RAN Scoring</Header>
              <Accordion
                header={COPY.RAN_SCORING_INSTRUCTIONS_HEADER}
                body={<ScoringInstructions grade={student.grade} />}
              />

              <Accordion header='Practice Question' body={<QuestionResults results={practiceResults} />} />
              <Accordion
                defaultExpanded
                header='Test Details/Scoring'
                body={<QuestionResults isScored results={questionResults} />}
              />
              <SubHeader>Reference Image:</SubHeader>
              <ImageWrapper>
                <Image src={getReferenceImage()} />
              </ImageWrapper>
            </ContentWrapper>
          </DialogWrapper>
        </Dialog>
      )}

      {alert.isOpen && (
        <ToastNotification
          alert={alert}
          onClose={() => {
            setAlert({ isOpen: false, type: '', message: '' })
          }}
        />
      )}
    </>
  )
}

export default RANScoring
