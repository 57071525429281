import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getDistrict } from '../../lib/api'
import ToastNotification from '../../components/ToastNotification'
import * as COPY from './copy'
import Grid from '@material-ui/core/Grid'
import TabContext from '@material-ui/lab/TabContext'
import { Tab, TabList, TabPanel, TabWrapper } from '../../ui-components/tabs'
import { useSessionStore, actionTypes } from '../../stores/SessionStore'
import Breadcrumbs from './components/Breadcrumbs'
import SchoolTab from './components/SchoolTab'
import UserTab from './components/UserTab'
import ReportsTab from './components/ReportsTab'
import Unauthorized from '../../components/Unauthorized'
import { useAuth } from 'auth'
import * as ROLE from 'constants/roles'

const SCHOOL_TAB = { value: 'school', label: 'Schools', element: SchoolTab }
const USER_TAB = { value: 'user', label: 'Users', element: UserTab }
const REPORTS_TAB = { value: 'reports', label: 'Reports', element: ReportsTab }

const DistrictView = () => {
  const { signOut } = useAuth()
  const params = useParams()
  const { state, dispatch } = useSessionStore()
  const { role, viewHeader } = state
  const { districtId } = params
  const [alert, setAlert] = useState({ isOpen: false, type: '', message: '' })
  const [currTab, setCurrTab] = useState(SCHOOL_TAB.value)
  const [district, setDistrict] = useState({})
  const [isUnauthorized, setIsUnauthorized] = useState(false)
  const [loading, setLoading] = useState({ isComplete: false, hasError: false })

  useEffect(() => {
    if (loading.hasError) return

    let isMounted = true
    ;(async () => {
      try {
        const districtInfo = await getDistrict(districtId)
        if (isMounted) {
          setDistrict(districtInfo)
          setLoading({ isComplete: true, hasError: false })
        }
      } catch (err) {
        if (err.code === 403) {
          setIsUnauthorized(true)
        } else if (err.code === 401) {
          // timeout error
          await signOut()
          dispatch({ type: actionTypes.LOGOUT })
        } else {
          setLoading({ isComplete: true, hasError: true })
          setAlert({ isOpen: true, type: 'error', message: COPY.DISTRICT_LOAD_ERROR })
        }
      }
    })()
    return () => {
      isMounted = false
    }
  }, [districtId, loading.hasError, dispatch, signOut])

  useEffect(() => {
    if (loading.isComplete && !loading.hasError) {
      if (viewHeader !== district.name) {
        dispatch({ type: actionTypes.UPDATE_FIELD, field: 'viewHeader', data: district.name })
      }
    }
  }, [loading, district.name, viewHeader, dispatch])

  const handleTabChange = (_, newValue) => {
    setCurrTab(newValue)
  }

  const onAlert = (type, message) => {
    setAlert({ isOpen: true, type: type, message: message })
  }

  if (isUnauthorized) {
    return <Unauthorized />
  }

  const isViewLoaded = loading.isComplete && !loading.hasError && viewHeader === district.name

  const tabList = {
    [ROLE.TEACHER]: [SCHOOL_TAB],
    [ROLE.SCHOOL_ADMIN]: [SCHOOL_TAB, USER_TAB],
    [ROLE.DISTRICT_ADMIN]: [SCHOOL_TAB, USER_TAB],
    [ROLE.SUPER_ADMIN]: [SCHOOL_TAB, USER_TAB, REPORTS_TAB]
  }[role]

  return (
    <div>
      {isViewLoaded && (
        <>
          <Grid container justify='center' spacing={2}>
            <Grid item xs={11}>
              <Breadcrumbs district={district} role={role} />
            </Grid>
          </Grid>

          <TabWrapper>
            <Grid container justify='center' spacing={2}>
              <Grid item xs={11}>
                <TabContext value={currTab}>
                  <TabList onChange={handleTabChange}>
                    {tabList.map((tab, idx) => (
                      <Tab label={tab.label} value={tab.value} key={idx} />
                    ))}
                  </TabList>

                  {tabList.map((tab, idx) => (
                    <TabPanel key={`panel-${idx}`} value={tab.value}>
                      <tab.element district={district} onAlert={onAlert} role={role} />
                    </TabPanel>
                  ))}
                </TabContext>
              </Grid>
            </Grid>
          </TabWrapper>
        </>
      )}

      {alert.isOpen && (
        <ToastNotification
          alert={alert}
          onClose={() => {
            setAlert({ isOpen: false, type: '', message: '' })
          }}
        />
      )}
    </div>
  )
}

export default DistrictView
