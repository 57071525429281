import React from 'react'
import styled from 'styled-components'
import * as COLOR from 'constants/colors'
import { RiskFlagIcon } from 'ui-components/icons'
import InfoPopover from 'components/InfoPopover'

export const Legend = styled.div({
  display: 'flex',
  flexDirection: 'row',
  backgroundColor: COLOR.LEGEND_BACKGROUND,
  color: COLOR.STUDENT_DETAILS_LEGEND_TEXT,
  padding: '1.5rem',
  fontFamily: 'Inter',
  overflowX: 'auto',
  borderBottomLeftRadius: (props) => (props.isClassroomView ? '1rem' : '0'),
  borderBottomRightRadius: (props) => (props.isClassroomView ? '1rem' : '0'),
  borderTop: (props) => (props.isClassroomView ? `1px solid ${COLOR.STUDENT_DETAILS_DIVIDER}` : 'none')
})

export const Column = styled.div({
  display: 'flex',
  flexDirection: 'column'
})

export const KeyColumn = styled(Column)({
  marginRight: (props) => (props.isCompressed ? '1rem' : '2.5rem')
})

export const SubKeyColumn = styled(Column)({
  paddingRight: (props) => (props.isCompressed ? '1rem' : '2rem')
})

export const Row = styled.div({
  display: 'flex',
  flexDirection: 'row'
})

export const HeaderRow = styled(Row)({
  alignItems: 'center'
})

export const LabelRow = styled(Row)({
  alignItems: 'center',
  marginBottom: '0.625rem'
})

export const Header = styled.div({
  fontSize: '0.75rem',
  fontWeight: 500,
  lineHeight: '0.9rem',
  textTransform: 'uppercase',
  paddingBottom: '0.25rem',
  borderBottom: `1px solid ${COLOR.LEGEND_HEADER_UNDERLINE}`,
  minHeight: '1.4rem',
  maxHeight: '3rem'
})

export const SubHeader = styled.div({
  fontSize: '0.875rem',
  fontWeight: 500,
  lineHeight: '1rem',
  paddingTop: '0.5rem',
  paddingBottom: '0.5rem'
})

export const SubColumn = styled.div({
  paddingRight: '3rem'
})

export const RowHeader = styled.div({
  fontSize: '0.75rem',
  fontWeight: 500,
  paddingBottom: '0.5rem'
})

export const Label = styled.div({
  fontSize: '0.75rem',
  fontWeight: (props) => (props.bold === true ? 600 : 400),
  lineHeight: '0.9rem',
  marginLeft: '0.25rem',
  maxWidth: '14rem'
})

export const PopoverWrapper = styled.div({
  marginLeft: '0.25rem'
})

const Square = styled.div({
  width: '0.9rem',
  height: '0.9rem',
  minWidth: '0.9rem',
  minHeight: '0.9rem',
  borderRadius: '2.5px'
})

export const PWRPercentageIcon = styled(Square)({
  backgroundColor: COLOR.PWR_PERCENTAGE_ICON,
  border: `1px solid ${COLOR.PWR_PERCENTAGE_ICON_BORDER}`,
  marginRight: '0.2rem'
})

export const SingleColPWRLowRiskIcon = styled(Square)({
  backgroundColor: COLOR.SINGLE_COL_PWR_LOW_RISK_BG,
  border: `1px solid ${COLOR.SINGLE_COL_PWR_LOW_RISK_BORDER}`,
  marginRight: '0.2rem'
})

export const SingleColPWRModRiskIcon = styled(Square)({
  backgroundColor: COLOR.SINGLE_COL_PWR_MODERATE_RISK_BG,
  border: `1px solid ${COLOR.SINGLE_COL_PWR_MODERATE_RISK_BORDER}`,
  marginRight: '0.2rem'
})

export const SingleColPWRHighRiskIcon = styled(Square)({
  backgroundColor: COLOR.SINGLE_COL_PWR_HIGH_RISK_BG,
  border: `1px solid ${COLOR.SINGLE_COL_PWR_HIGH_RISK_BORDER}`,
  marginRight: '0.2rem'
})

export const NotApplicableIcon = styled(Square)({
  backgroundColor: COLOR.NOT_APPLICABLE_ICON_BACKGROUND,
  border: `1px solid ${COLOR.NOT_APPLICABLE_ICON_BORDER}`,
  marginLeft: '0.1rem',
  marginRight: '0.3rem'
})

export const NoRiskIcon = styled(Square)({
  border: `1px solid ${COLOR.NO_RISK_ICON_BORDER}`,
  backgroundColor: COLOR.NO_RISK_ICON_BACKGROUND,
  marginRight: '0.2rem'
})

export const AtRiskIcon = styled(Square)({
  border: `1px solid ${COLOR.LOWER_RISK_PERCENTILE_BACKGROUND}`,
  backgroundColor: COLOR.LOWER_RISK_PERCENTILE_BACKGROUND,
  marginRight: '0.2rem'
})

export const HighRiskIcon = styled(Square)({
  border: `1px solid ${COLOR.RISK_PERCENTILE_BACKGROUND}`,
  backgroundColor: COLOR.RISK_PERCENTILE_BACKGROUND,
  marginRight: '0.2rem'
})

const Icon = styled.div({
  fontSize: '0.6rem',
  fontWeight: 600,
  lineHeight: '0.9rem',
  color: COLOR.NOT_AVAILABLE_ICON,
  marginRight: '0.3rem'
})

export const NotAvailableIcon = ({ iconText }) => {
  return <Icon>{iconText}</Icon>
}

const MessageContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  backgroundColor: COLOR.DYSLEXIA_MESSAGE_BACKGROUND,
  fontSize: '0.75rem',
  fontWeight: 400,
  lineHeight: '0.9rem',
  minWidth: '17rem',
  minHeight: '2rem',
  borderRadius: '0.3rem'
})

export const DyslexiaMessageIcon = ({ iconText }) => {
  return (
    <MessageContainer>
      <RiskFlagIcon />
      <InfoPopover info={iconText.info} triggerText={iconText.triggerText} />
    </MessageContainer>
  )
}
