import styled from 'styled-components'
import * as COLOR from '../../constants/colors'
import { Link } from 'react-router-dom'

export const Header = styled.h2`
  font-size: 1.25rem;
  color: ${COLOR.UNAUTHORIZED_TEXT};
`

export const UnauthorizedWrapper = styled.div`
  display: flex;

  align-items: center;
  flex-direction: column;
`

export const PageLink = styled(Link)({
  fontFamily: 'Inter',
  fontWeight: 500,
  fontSize: '1rem',
  color: COLOR.AUTH_LINK_TEXT,
  textDecoration: 'underline'
})
