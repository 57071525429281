import React, { useState, useEffect } from 'react'
import { getTestDetails } from 'lib/api'
import { buildAudioPath } from 'utils/assessmentAudio'
import { Header, CloseWrapper, CloseIcon, DialogWrapper, ContentWrapper, Dialog } from './styles'
import * as A from 'constants/assessments'

const TestDetails = ({ test, onClose }) => {
  const [isLoaded, setIsloaded] = useState(false)
  const [scores, setScores] = useState([])
  const { participantTestId, participantId, testCode } = test

  useEffect(() => {
    ;(async () => {
      const testId = test.participantTestId
      const details = await getTestDetails(testId)
      const scores = []
      details.ParticipantTestQuestionResponses.sort(
        (a, b) => Date.parse(a.createdAt) - Date.parse(b.createdAt)
      ).forEach((qr) => {
        const providerResp = JSON.parse(qr.providerResponse)
        if (providerResp.response) {
          providerResp.response = JSON.parse(providerResp.response)
        }
        const formattedResponse = JSON.stringify(providerResp, null, 2)
        const question = {
          questionCode: qr.questionCode,
          score: qr.score,
          providerResponse: formattedResponse
        }
        scores.push(question)
      })

      setScores(scores)
      setIsloaded(true)
    })()
  }, [test])

  return (
    <>
      {isLoaded && (
        <>
          <Dialog onClose={onClose} open>
            <DialogWrapper>
              <CloseWrapper onClick={onClose}>
                Close <CloseIcon />
              </CloseWrapper>
              <ContentWrapper>
                <Header>Test details</Header>
                <table border={1}>
                  <thead>
                    <tr>
                      <th>question ID</th>
                      <th>score</th>
                      <th>provider response</th>
                      <th>audio</th>
                    </tr>
                  </thead>
                  <tbody>
                    {scores.map((q, i) => {
                      let audioTag
                      if (testCode === A.DELETION_ID) {
                        const url1 = buildAudioPath(`${q.questionCode}_first`, participantId, participantTestId)
                        const url2 = buildAudioPath(`${q.questionCode}_second`, participantId, participantTestId)
                        audioTag = (
                          <div>
                            <audio controls src={url1}></audio>
                            <br />
                            <audio controls src={url2}></audio>
                          </div>
                        )
                      } else {
                        const url = buildAudioPath(q.questionCode, participantId, participantTestId)
                        audioTag = <audio controls src={url}></audio>
                      }
                      return (
                        <tr key={i} style={{ verticalAlign: 'top' }}>
                          <td>{q.questionCode}</td>
                          <td>{q.score}</td>
                          <td>
                            <textarea readOnly style={{ width: '30rem', height: '15rem' }}>
                              {q.providerResponse}
                            </textarea>
                          </td>
                          <td>{audioTag}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </ContentWrapper>
            </DialogWrapper>
          </Dialog>
        </>
      )}
    </>
  )
}

export default TestDetails
