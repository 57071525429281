/* eslint multiline-ternary: ["off", "always-multiline"] */
import React from 'react'
import CloseIcon from '@material-ui/icons/Close'
import { useHistory } from 'react-router-dom'
import IconButton from '@material-ui/core/IconButton'
import { Snackbar } from './styles'
import MuiAlert from '@material-ui/lab/Alert'

const Alert = (props) => {
  return <MuiAlert elevation={6} {...props} />
}

// type can be: 'error', 'warning', 'info', 'success'
const ToastNotification = ({ alert, action, path, onClose }) => {
  const history = useHistory()
  const { type, message } = alert

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    onClose()
    path && history.push(path)
  }

  return (
    <Snackbar
      id='openToastNoti'
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      open={true}
      action={action}
      autoHideDuration={type === 'error' ? null : 3000}
      onClose={onClose}
    >
      <Alert
        severity={type}
        action={
          action ? (
            <a onClick={handleClose} className='action'>
              {action}
            </a>
          ) : (
            <IconButton
              data-testid='toastCloseIcon'
              size='small'
              aria-label='close'
              color='inherit'
              onClick={handleClose}
            >
              <CloseIcon fontSize='small' />
            </IconButton>
          )
        }
      >
        {message}
      </Alert>
    </Snackbar>
  )
}

export default ToastNotification
