import { getSelectedTags } from '../../utils/tags'

const formatAssessments = (assessments) => {
  return assessments.reduce((obj, curr) => {
    return {
      ...obj,
      [curr.testCode]: {
        assessmentId: curr.testCode,
        isComplete: curr.completedFlag,
        isInvalid: curr.invalid,
        finalTheta: curr.finalTheta,
        abilityPercentile: curr.abilityPercentile,
        correctItems: curr.correctItems,
        totalItems: curr.totalItems,
        correctByCode: curr.correctByCode
      }
    }
  }, {})
}

export const formatStudent = (student, studentRiskScores) => {
  const assessments = student.ParticipantTests
  const formattedAssessments = assessments && assessments.length ? formatAssessments(assessments) : {}

  return {
    studentName: {
      participantId: student.participantId,
      name: `${student.lastName}, ${student.firstName}`,
      lastName: student.lastName,
      firstName: student.firstName,
      middleName: student.middleName,
      participantCode: student.participantCode,
      tags: getSelectedTags(student.tags)
        .map((tag) => tag.label)
        .join(' ')
    },
    grade: student.grade,
    predictiveProfile: studentRiskScores,
    ...formattedAssessments
  }
}

// sort students by last name and first name
export const sortStudents = (studentIds, students) => {
  const getStudentKey = (studentId, key) => {
    return students[studentId].studentName[key].toUpperCase()
  }

  return studentIds.sort((a, b) => {
    const lastNameA = getStudentKey(a, 'lastName')
    const lastNameB = getStudentKey(b, 'lastName')

    const firstNameA = getStudentKey(a, 'firstName')
    const firstNameB = getStudentKey(b, 'firstName')

    const result = lastNameA.localeCompare(lastNameB)
    return result !== 0 ? result : firstNameA.localeCompare(firstNameB)
  })
}

export const formatAndSortStudents = (students, riskScores) => {
  const formattedStudents = {}
  const sortedStudentIdsByGrade = students.reduce((obj, student) => {
    // formats student's info and assesment results based on classroom table columns
    const formattedStudent = formatStudent(student, riskScores[student.participantId])
    formattedStudents[student.participantId] = formattedStudent

    // separate students by grade
    if (obj[student.grade]) {
      obj[student.grade].push(student.participantId)
      return obj
    } else {
      return {
        ...obj,
        [student.grade]: [student.participantId]
      }
    }
  }, {})

  // for each grade in the classroom, sort students
  Object.keys(sortedStudentIdsByGrade).forEach((grade) => {
    sortedStudentIdsByGrade[grade] = sortStudents(sortedStudentIdsByGrade[grade], formattedStudents)
  })

  return { formattedStudents, sortedStudentIdsByGrade }
}
