import styled from 'styled-components'
import * as C from 'constants/colors'

export const Container = styled.div`
  display: flex;
  margin-left: 1.5rem;
  margin-bottom: 0.625rem;
`

export const TestName = styled.div`
  font-family: Quicksand;
  font-size: 15px;
  font-weight: 600;
  padding: 5px 0;
  color: ${C.RATIO_SCORE_TEXT};
`

export const RatioContainer = styled.div`
  font-family: Quicksand;
  border: solid
    ${(props) => (props.edited ? `2px ${C.ITEM_INVENTORY_EDITED_BORDER}` : `1px ${C.RATIO_CONTAINER_BORDER}`)};
  border-radius: 5px;
  color: ${C.RATIO_SCORE_TEXT};
  width: 5.5rem;
  height: 3.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Ratio = styled.div`
  display: flex;
  align-items: end;
  font-weight: 600;
`

export const Numerator = styled.span`
  font-size: 1.75rem;
`

export const Denominator = styled.span`
  font-size: 1rem;
  opacity: 90%;
  margin-bottom: 4px;
`

export const EditContainer = styled.div`
  margin-left: 5px;
`
