import React from 'react'
import FormDialog from '../FormDialog'
import { buildDistrictPayload } from './helper'
import { createDistrict, updateDistrict } from '../../lib/api'
import * as COPY from './copy'
import { STATES } from './constants/states'

const DistrictDialog = ({ district = null, onAction, onClose, onAlert }) => {
  const fields = [
    {
      key: 'name',
      label: 'Name',
      isRequired: true,
      type: 'text',
      defaultValue: district ? district.name : ''
    },
    {
      key: 'state',
      label: 'State',
      isRequired: true,
      type: 'autocompleteSelect',
      options: STATES,
      defaultValue: district ? district.state : ''
    },
    {
      key: 'website',
      label: 'Website',
      isRequired: false,
      type: 'text',
      defaultValue: district ? district.website : ''
    }
  ]

  const updateOrCreateDistrict = async (fieldValues) => {
    const payload = buildDistrictPayload(district, fieldValues)
    if (district) {
      await updateDistrict(payload)
    } else {
      await createDistrict(payload)
    }
    await onAction()
    const successMsg = district ? COPY.DISTRICT_UPDATE_SUCCESS : COPY.DISTRICT_CREATE_SUCCESS
    onAlert('success', successMsg)
  }

  return (
    <FormDialog
      fields={fields}
      header={`${district ? 'Update' : 'Create'} District`}
      actionTitle={district ? 'Update' : 'Create'}
      onAction={updateOrCreateDistrict}
      onClose={onClose}
    />
  )
}

export default DistrictDialog
