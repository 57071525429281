import { request } from '../helper'

export const authenticateUser = async (payload) => {
  const url = '/dashboard/login/sso'
  return await request('POST', url, payload, {}, false)
}

export const requestPasswordReset = async (email) => {
  const url = '/dashboard/forgot-password'
  const resp = await fetch(`${process.env.REACT_APP_API_BASE_URL}${url}`, {
    method: 'POST',
    body: JSON.stringify({ email }),
    headers: {
      'Content-Type': 'application/json'
    }
  })

  if (!resp.ok) {
    throw new Error('Network error')
  }

  return resp
}

export const confirmPasswordReset = async (email, code, password) => {
  const url = '/dashboard/forgot-password/new'
  const resp = await fetch(`${process.env.REACT_APP_API_BASE_URL}${url}`, {
    method: 'POST',
    body: JSON.stringify({
      email,
      code,
      password
    }),
    headers: {
      'Content-Type': 'application/json'
    }
  })

  if (!resp.ok) {
    throw new Error('Network error')
  }
  return resp
}
