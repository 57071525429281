import * as ROLE from '../../constants/roles'

export const PARTICIPANT_GROUP_TYPE = 'CLASSROOM'

export const getTeacherOptions = (users) => {
  return users
    .filter((user) => user.Role && user.Role.length && user.Role[0].roleName === ROLE.TEACHER)
    .map((teacher) => {
      const teacherName = `${teacher.firstName} ${teacher.lastName}`
      return { key: teacher.userId, value: teacher.userId, label: teacherName }
    })
}

export const formatSelectedTeachers = (teachers) => {
  return teachers && teachers.length ? teachers.map((teacher) => teacher.value).join(',') : ''
}

export const buildClassroomPayload = (classroom, schoolId, fieldValues) => {
  return {
    participantGroupId: classroom ? classroom.participantGroupId : null,
    name: fieldValues.name,
    organizationUnitId: schoolId,
    teacherId: formatSelectedTeachers(fieldValues.teachers),
    participantGroupType: PARTICIPANT_GROUP_TYPE
  }
}
