import React from 'react'
import { CellWrapper, TableCell, SliderWrapper, SliderGradient, Marker } from './styles'

const PercentileSlider = ({ studentScores, isHidden }) => {
  const assessmentComplete =
    studentScores && studentScores.abilityPercentile && studentScores.completedFlag && !studentScores.invalid
  const displayPercentileSlider = !isHidden && assessmentComplete

  return (
    <TableCell className='sliderCell'>
      <CellWrapper>
        {displayPercentileSlider ? (
          <SliderWrapper id='percentileSlider'>
            <Marker percentile={studentScores.abilityPercentile} />
            <SliderGradient />
          </SliderWrapper>
        ) : (
          <div id='emptySlider' />
        )}
      </CellWrapper>
    </TableCell>
  )
}

export default PercentileSlider
