import * as GRADE from 'constants/grades'

export const TEST_DETAILS_LOAD_ERROR = 'Unable to load test details.'
export const NO_TEST_RESULTS = 'No results!'
export const VIEW_HEADER = 'RAN Test Details'
export const RAN_SCORING_INSTRUCTIONS_HEADER = 'RAN Scoring Instructions'

const getItemCount = (grade) => {
  switch (grade) {
    case GRADE.PK:
      return 'forty objects'
    case GRADE.KINDERGARTEN:
      return 'fifty objects'
    case GRADE.FIRST:
      return 'fifty letters'
    case GRADE.SECOND:
      return 'fifty letters'
    default:
      return ''
  }
}

const getInstructionsPartOne = (grade) => {
  const itemCount = getItemCount(grade)
  return `The RAN score is the amount of time it takes the student to name all ${itemCount}. EarlyBird displays the total time it took for the student to name all ${itemCount}. To validate this time, the teacher listens to the student recording, making sure the student began speaking as soon as the audio started recording, and that the student stopped speaking and the audio promptly ended. If there is an unreasonable amount of time (more than a three seconds) where the student has blank audio at the start or end of the recording due to navigating the screen or pressing the green arrow, those seconds should be subtracted from the student’s total time.`
}

export const getRANScoringInstructions = (grade) => {
  const instructionsPartOne = getInstructionsPartOne(grade)
  const instructionsPartTwo = `The teacher needs to determine if this test is an accurate representation of a student${"'"}s ability. This test would be invalid if the student skipped or repeated a row or did not attend to the task and made seven or more errors. Please keep in mind that self-corrections are not counted as errors and are acceptable responses during this test. If the test is determined invalid, the teacher would check the Invalid checkbox, indicating the test needs to be readministered in unlocked mode. If the test is valid, the teacher confirms the number of seconds it took the student to complete the RAN and enters that time in the space provided.`
  return {
    paragraphs: [instructionsPartOne, instructionsPartTwo]
  }
}
