import React from 'react'
import FormDialog from '../FormDialog'
import * as COPY from './copy'
import { buildSchoolPayload } from './helper'
import { createSchool, updateSchool } from '../../lib/api'
import posthog from 'posthog-js'

const SchoolDialog = ({ school = null, districtId, onAction, onClose, onAlert, role }) => {
  const fields = [
    {
      key: 'name',
      label: 'Name',
      isRequired: true,
      type: 'text',
      defaultValue: school ? school.name : ''
    }
  ]

  const updateOrCreateSchool = async (fieldValues) => {
    const payload = buildSchoolPayload(school, districtId, fieldValues)
    if (school) {
      await updateSchool(payload)
    } else {
      await createSchool(payload)
    }
    await onAction()
    const successMsg = school ? COPY.SCHOOL_UPDATE_SUCCESS : COPY.SCHOOL_CREATE_SUCCESS
    onAlert('success', successMsg)
    posthog.capture(school ? 'Edited school' : 'Created school', { userRole: role })
  }

  return (
    <FormDialog
      fields={fields}
      header={`${school ? 'Update' : 'Create'} School`}
      actionTitle={school ? 'Update' : 'Create'}
      onAction={updateOrCreateSchool}
      onClose={onClose}
    />
  )
}

export default SchoolDialog
