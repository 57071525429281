import styled from 'styled-components'
import * as COLOR from '../../../../constants/colors'

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  button {
    margin: 0.3rem;
  }
`
export const ScoringSection = styled.div`
  margin: 0;
  padding-bottom: 0.5rem;
`

export const ScoredWrapper = styled.div`
  color: ${COLOR.TEST_RESULTS_TEXT};
`
