import React from 'react'
import { getAssessmentDisplay } from 'utils/display'
import { LETTER_NAMES_ID, LETTER_SOUNDS_ID } from 'constants/assessments'
import ScoreEntryIcon from 'components/ScoreEntryIcon'
import { TableCell } from '../../styles'
import { useFeatureFlagEnabled } from 'posthog-js/react'

const PercentileCell = ({
  timePeriod,
  testCode,
  studentScores,
  studentGrade,
  isApplicableAssmnt,
  cellClasses,
  displayRuleOverwrite,
  onClickCell
}) => {
  const lnlsScoreEntryEnabled = useFeatureFlagEnabled('lnls-score-entry')

  let cellContent = null
  let cssClasses = ''

  if (!studentScores) {
    if (!isApplicableAssmnt) {
      // if assessment not applicable for time period display grayed out cell
      cssClasses = 'notApplicable'
    } else if (lnlsScoreEntryEnabled && [LETTER_NAMES_ID, LETTER_SOUNDS_ID].includes(testCode)) {
      // display score entry icon if LN/LS scores have not been entered
      cellContent = <ScoreEntryIcon />
    } else {
      // if assessment has not been started display empty cell
      cssClasses = 'emptyAssmnt'
    }

    return <TableCell className={`scoreCell ${cssClasses} ${cellClasses}`}>{cellContent}</TableCell>
  } else {
    // if assessment has been started, display based on display rules
    const { completedFlag, abilityPercentile, finalTheta, correctItems, totalItems, invalid } = studentScores
    const { content, classes } = getAssessmentDisplay({
      studentGrade: studentGrade,
      assessmentId: testCode,
      timePeriod: timePeriod,
      isComplete: completedFlag,
      abilityPercentile: abilityPercentile,
      finalTheta: finalTheta,
      correctItems: correctItems,
      totalItems: totalItems,
      isInvalid: invalid,
      displayRuleOverwrite
    })

    cellContent = content
    cssClasses = classes

    return (
      <TableCell className={`scoreCell ${cssClasses} ${cellClasses}`} onClick={onClickCell}>
        {cellContent}
      </TableCell>
    )
  }
}
export default PercentileCell
