import styled from 'styled-components'

export const IconWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row'
})

export const EditWrapper = styled.div({
  marginRight: '3rem'
})
