import React from 'react'
import { getRANScoringInstructions } from '../../copy'

const ScoringInstructions = ({ grade }) => {
  const { paragraphs } = getRANScoringInstructions(grade)
  return (
    <div>
      {paragraphs.map((paragraph) => {
        const index = paragraphs.indexOf(paragraph)
        return <p key={index}>{paragraph}</p>
      })}
    </div>
  )
}

export default ScoringInstructions
