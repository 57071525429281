import React, { useState, useEffect, useCallback } from 'react'
import Table from '../../../../components/Table'
import StudentDialog from '../../../../components/StudentDialog'
import { getStudentsBySchool, getClassroomsBySchool } from '../../../../lib/api'
import * as COPY from '../../copy'
import * as ROLE from '../../../../constants/roles'
import { getStudentColumns, createStudentData, canCreateStudent } from './helper'
import { TableWrapper, CreateButton } from '../../../../ui-components/tabs'
import { EmptyStateWrapper } from '../../../../ui-components/viewStyles'
import { alphanumericSort } from '../../../../utils/sorts'
import posthog from 'posthog-js'

const StudentTab = ({ school, role, onAlert }) => {
  const [students, setStudents] = useState([])
  const [studentDialog, setStudentDialog] = useState({ isOpen: false, student: null })
  const [loading, setLoading] = useState({ isComplete: false, hasError: false })
  const [classrooms, setClassrooms] = useState([])

  const loadStudents = useCallback(async () => {
    try {
      const schoolStudents = await getStudentsBySchool(school.organizationUnitId)
      setStudents(schoolStudents)
      setLoading({ isComplete: true, hasError: false })
    } catch (err) {
      setLoading({ isComplete: true, hasError: true })
      onAlert('error', COPY.STUDENT_LOAD_ERROR)
    }
  }, [school, onAlert])

  const loadClassrooms = useCallback(async () => {
    posthog.capture('Accessed student tab from school view', { userRole: role })
    try {
      if (students.length === 0) {
        const schoolClassrooms = await getClassroomsBySchool(school.organizationUnitId)
        setClassrooms(schoolClassrooms)
      }
      setLoading({ isComplete: true, hasError: false })
    } catch (err) {
      setLoading({ isComplete: true, hasError: true })
      onAlert('error', COPY.STUDENT_LOAD_ERROR)
    }
  }, [students.length, onAlert, school.organizationUnitId, role])

  useEffect(() => {
    if (loading.hasError) return

    loadStudents()
    loadClassrooms()
  }, [loadStudents, loadClassrooms, loading.hasError])

  const onEdit = (student) => {
    setStudentDialog({ isOpen: true, student: student })
  }

  const studentColumns = getStudentColumns(role)

  let studentRows = students.map((student) => createStudentData(student, role, onEdit, onAlert))

  studentRows = alphanumericSort(studentRows, 'student', 'name')

  const onStudentCreateOrUpdate = async () => {
    setStudentDialog({ isOpen: false, student: null })
    // reload students to get updated list
    await loadStudents()
  }

  const hasClassrooms = classrooms.length > 0
  const isTeacher = role === ROLE.TEACHER

  return (
    <div>
      {studentDialog.isOpen && (
        <StudentDialog
          student={studentDialog.student}
          districtId={school.organizationId}
          schoolId={school.organizationUnitId}
          role={role}
          onAction={onStudentCreateOrUpdate}
          onClose={() => setStudentDialog({ isOpen: false, student: null })}
          onAlert={onAlert}
        />
      )}
      <TableWrapper>
        {canCreateStudent(role, hasClassrooms, isTeacher) && (
          <CreateButton
            id='createStudentButton'
            onClick={() => setStudentDialog({ isOpen: true, student: null })}
            text='Create Student'
          />
        )}
        {loading.isComplete && (
          <div>
            {students.length ? (
              <Table rows={studentRows} columns={studentColumns} />
            ) : (
              <EmptyStateWrapper>
                {!hasClassrooms && isTeacher ? COPY.EMPTY_STATE_STUDENT_NO_CLASSROOMS : COPY.EMPTY_STATE_STUDENT}
              </EmptyStateWrapper>
            )}
          </div>
        )}
      </TableWrapper>
    </div>
  )
}

export default StudentTab
