import styled from 'styled-components'
import { Alert as MaterialAlert } from '@material-ui/lab'
import * as C from 'constants/colors'

export const Alert = styled(MaterialAlert)`
  &.MuiAlert-root {
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: row;
`

export const Header = styled.div`
  font-family: Inter;
  font-weight: 600;
  font-size: 0.875rem;
  color: ${C.BANNER_TEXT};
  margin-right: 0.25rem;
`

export const Message = styled.div`
  font-family: Inter;
  font-weight: 400;
  font-size: 0.875rem;
  color: ${C.BANNER_TEXT};
`

export const Link = styled.a`
  font-family: Inter;
  font-weight: 500;
  font-size: 0.875rem;
  color: ${C.BANNER_LINK};
  margin-left: 0.5rem;
`
