import React from 'react'
import { BreadcrumbWrapper, BreadcrumbLink, BreadcrumbText } from '../../../../ui-components/breadcrumbs'
import * as ROLE from '../../../../constants/roles'

const Breadcrumbs = ({ student, role }) => {
  const districtId = student.OrganizationUnit.Organization.organizationId
  const districtName = student.OrganizationUnit.Organization.name
  const schoolId = student.OrganizationUnit.organizationUnitId
  const schoolName = student.OrganizationUnit.name
  const classroomId = student.ParticipantGroup[0].participantGroupId
  const classroomName = student.ParticipantGroup[0].name
  const studentId = student.participantId
  const studentName = `${student.firstName} ${student.lastName}`
  if (role === ROLE.SUPER_ADMIN) {
    return (
      <BreadcrumbWrapper>
        <BreadcrumbLink to='/super-admin'>Districts</BreadcrumbLink>
        <BreadcrumbLink to={`/district/${districtId}`}>{districtName}</BreadcrumbLink>
        <BreadcrumbLink to={`/school/${schoolId}`}>{schoolName}</BreadcrumbLink>
        <BreadcrumbLink to={`/classroom/${classroomId}`}>{classroomName}</BreadcrumbLink>
        <BreadcrumbLink to={`/student/${studentId}`}>{studentName}</BreadcrumbLink>
        <BreadcrumbText>RAN</BreadcrumbText>
      </BreadcrumbWrapper>
    )
  } else if (role === ROLE.DISTRICT_ADMIN) {
    return (
      <BreadcrumbWrapper>
        <BreadcrumbLink to={`/district/${districtId}`}>Schools</BreadcrumbLink>
        <BreadcrumbLink to={`/school/${schoolId}`}>{schoolName}</BreadcrumbLink>
        <BreadcrumbLink to={`/classroom/${classroomId}`}>{classroomName}</BreadcrumbLink>
        <BreadcrumbLink to={`/student/${studentId}`}>{studentName}</BreadcrumbLink>
        <BreadcrumbText>RAN</BreadcrumbText>
      </BreadcrumbWrapper>
    )
  } else {
    return (
      <BreadcrumbWrapper>
        <BreadcrumbLink to={`/school/${schoolId}`}>Classrooms</BreadcrumbLink>
        <BreadcrumbLink to={`/classroom/${classroomId}`}>{classroomName}</BreadcrumbLink>
        <BreadcrumbLink to={`/student/${studentId}`}>{studentName}</BreadcrumbLink>
        <BreadcrumbText>RAN</BreadcrumbText>
      </BreadcrumbWrapper>
    )
  }
}
export default Breadcrumbs
