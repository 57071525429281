import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getDistrictCompleteReport } from '../../../../lib/api'

const safePercentage = (part, total) => (part > 0 && total > 0 ? Math.round((part / total) * 100) : 0)

const ReportsTab = () => {
  const [reportData, setReportData] = useState({})
  const [isLoaded, setIsLoaded] = useState(false)
  const [selectedPeriod, setSelectedPeriod] = useState('BOY')

  const params = useParams()
  const { districtId } = params

  useEffect(() => {
    ;(async () => {
      setReportData(await getDistrictCompleteReport(districtId, selectedPeriod))
      setIsLoaded(true)
    })()
  }, [districtId, selectedPeriod])

  const handleTimePeriodChange = (evt) => {
    setSelectedPeriod(evt.target.value)
  }

  return isLoaded ? (
    <>
      <h2>Percentage of students complete</h2>
      <label>
        Select assessment period:
        <select onChange={handleTimePeriodChange}>
          <option value='BOY'>BOY</option>
          <option value='MOY'>MOY</option>
          <option value='EOY'>EOY</option>
        </select>
      </label>
      <h2>by school</h2>
      <table border={1}>
        <thead>
          <tr>
            <th>School</th>
            <th>Started</th>
            <th>Finished</th>
            <th>Total</th>
            <th>
              Percentage
              <br />
              complete
            </th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(reportData.finishedByOrgUnit)
            .sort((a, b) => {
              return reportData.key.orgUnits[a].localeCompare(reportData.key.orgUnits[b])
            })
            .map((orgUnitId, i) => {
              const { total, finished, started } = reportData.finishedByOrgUnit[orgUnitId]
              const name = reportData.key.orgUnits[orgUnitId]
              const finishedPercentage = safePercentage(finished, total)
              return (
                <tr key={`school-row-${i}`}>
                  <td>{name}</td>
                  <td>{started}</td>
                  <td>{finished}</td>
                  <td>{total}</td>
                  <td>{finishedPercentage}%</td>
                </tr>
              )
            })}
        </tbody>
      </table>

      <h2>by classroom</h2>
      <table border={1}>
        <thead>
          <tr>
            <th>School</th>
            <th>Classroom</th>
            <th>Started</th>
            <th>Finished</th>
            <th>Total</th>
            <th>
              Percentage
              <br />
              started
            </th>
            <th>
              Percentage
              <br />
              complete
            </th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(reportData.finishedByOrgUnit)
            .sort((a, b) => {
              return reportData.key.orgUnits[a].localeCompare(reportData.key.orgUnits[b])
            })
            .map((orgUnitId, i) => {
              return Object.keys(reportData.finishedByOrgUnit[orgUnitId].participantGroups).map((pgId, j) => {
                const { total, finished, started } = reportData.finishedByOrgUnit[orgUnitId].participantGroups[pgId]
                const school = reportData.key.orgUnits[orgUnitId]
                const classroom = reportData.key.participantGroups[pgId]
                const startedPercentage = safePercentage(started, total)
                const finishedPercentage = safePercentage(finished, total)
                return (
                  <tr key={`class-row-${i}`}>
                    <td>{school}</td>
                    <td>{classroom}</td>
                    <td>{started}</td>
                    <td>{finished}</td>
                    <td>{total}</td>
                    <td>{startedPercentage}%</td>
                    <td>{finishedPercentage}%</td>
                  </tr>
                )
              })
            })}
        </tbody>
      </table>
      <h2>by grade</h2>
      <table border={1}>
        <thead>
          <tr>
            <th>Grade</th>
            <th>Started</th>
            <th>Finished</th>
            <th>Total</th>
            <th>
              Percentage
              <br />
              started
            </th>
            <th>
              Percentage
              <br />
              complete
            </th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(reportData.finishedByGrade).map((grade, i) => {
            const { total, finished, started } = reportData.finishedByGrade[grade]
            const startedPercentage = safePercentage(started, total)
            const finishedPercentage = safePercentage(finished, total)
            return (
              <tr key={`grade-row-${i}`}>
                <td>{grade}</td>
                <td>{started}</td>
                <td>{finished}</td>
                <td>{total}</td>
                <td>{startedPercentage}%</td>
                <td>{finishedPercentage}%</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </>
  ) : (
    <div>Loading...</div>
  )
}

export default ReportsTab
