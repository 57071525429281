import { LETTER_SOUNDS_ID, LETTER_NAMES_ID } from 'constants/assessments'

export const getQuestionCode = (testId, item) => {
  const prefixes = {
    [LETTER_SOUNDS_ID]: 'LS',
    [LETTER_NAMES_ID]: 'LN'
  }

  return `${prefixes[testId]}_${item}`
}
