// import RatioScore from 'ui-components/tables/cells/RatioScore'
import React from 'react'
import * as S from './styles'
import { ReactComponent as CorrectMark } from 'ui-components/tables/Correct.svg'
import { ReactComponent as IncorrectMark } from 'ui-components/tables/Incorrect.svg'
import InventoryTable from 'ui-components/tables/InventoryTable'

// TODO: extract this to a common util
const itemInventoryList = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
  'CH',
  'SH',
  'TH'
]

const getMappedInventoryData = (studentResults) => {
  const letterNamesData = studentResults.TEST_LETTER_NAMES
  const letterSoundsData = studentResults.TEST_LETTER_SOUNDS
  if (!letterNamesData && !letterSoundsData) {
    return []
  }
  return [
    ['Name'].concat(mapItemLevelResultsToRow('TEST_LETTER_NAMES', letterNamesData?.correctByCode)),
    ['Sound'].concat(mapItemLevelResultsToRow('TEST_LETTER_SOUNDS', letterSoundsData?.correctByCode))
  ]
}

const mapItemLevelResultsToRow = (testCode, correctByCode) => {
  // if a test has been started but 0 questions were answered, return an array filled with null
  if (!correctByCode) return Array(itemInventoryList.length).fill(null)
  const itemFormat = {
    TEST_LETTER_SOUNDS: (letter) => `LS_${letter}`,
    TEST_LETTER_NAMES: (letter) => `LN_${letter}`
  }

  return itemInventoryList.map((letter) => {
    const score = correctByCode[itemFormat[testCode](letter)]
    return score
  })
}

const Legend = () => {
  return (
    <div style={S.legendStyles}>
      <div style={S.legendMarkContainerStyles}>
        <CorrectMark style={S.correctMarkSyles} width={13} height={9} />
        <IncorrectMark style={S.incorrectMarkStyles} width={11} height={10} />
      </div>
      <div style={S.legendTextContainerStyles}>
        <span style={S.legendTextStyle}> Correct</span>
        <span style={S.legendTextStyle}> Incorrect</span>
      </div>
    </div>
  )
}

const RatioScore = ({ correctItems, totalItems }) => {
  return (
    <div style={{ fontFamily: 'Quicksand' }}>
      <span style={{ fontSize: '1.5rem', fontWeight: '600', color: '#4e5d78' }}>{correctItems}</span>
      <span style={{ fontSize: '1.0rem', color: 'rgba(78, 93, 120, 0.9)' }}> / {totalItems}</span>
    </div>
  )
}

const ItemInventory = ({ studentResults, studentName }) => {
  return (
    <>
      <div>
        <div className='header-content' style={S.headerContentStyles}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <h1 style={S.nameHeaderStyle}>{studentName}</h1>
            <h2 style={{ marginTop: '5px' }}>
              <span style={S.headerStyle}>Sound/Symbol Correspondence </span>
              <span style={S.headerStyle2}>| </span> <span style={S.headerStyle3}>Full Inventory</span>
            </h2>
          </div>
          <div style={S.ratioScoresContainerStyles}>
            <div style={S.ratioContainerStyles}>
              <div style={S.ratioTestNameStyles}>Letter Name</div>
              <div style={S.ratioStyles}>
                <RatioScore
                  correctItems={studentResults.TEST_LETTER_NAMES?.correctItems}
                  totalItems={studentResults.TEST_LETTER_NAMES?.totalItems}
                />
              </div>
            </div>
            <div style={S.ratioContainerStyles}>
              <div style={S.ratioTestNameStyles}>Letter Sound</div>
              <div style={S.ratioStyles}>
                <div>
                  <RatioScore
                    correctItems={studentResults.TEST_LETTER_SOUNDS?.correctItems}
                    totalItems={studentResults.TEST_LETTER_SOUNDS?.totalItems}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <InventoryTable
        data={{
          headers: ['Letter'].concat(itemInventoryList),
          scores: getMappedInventoryData(studentResults)
        }}
      />
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Legend />
      </div>
    </>
  )
}

export default ItemInventory
