import { request } from '../helper'

export const getTestDetails = async (testId) => {
  const url = `/participantTest/testResults/${testId}`
  return await request('GET', url)
}

export const sendTestScore = async (questionId, payload) => {
  const url = `/participantTest/addScoreToQuestionResponse/${questionId}`
  return await request('PUT', url, payload)
}
