import React, { useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { getStudent, getCurrDistrictAssmntConfig, getStudentMostRecentRAN } from '../../lib/api'
import { useSessionStore, actionTypes } from '../../stores/SessionStore'
import Grid from '@material-ui/core/Grid'
import Breadcrumbs from './components/Breadcrumbs'
import ToastNotification from '../../components/ToastNotification'
import * as COPY from './copy'
import StudentResultsTable from '../../components/StudentResultsTable'
import StudentInfo from './components/StudentInfo'
import StudentResources from '../../components/StudentResources'
import Unauthorized from '../../components/Unauthorized'
import ErrorBoundary from 'components/ErrorBoundary'
import Banner from 'components/Banner'
import { useAuth } from 'auth'
import {
  ButtonLink,
  ContentWrapper,
  TableShadow,
  InfoShadow,
  InfoWrapper,
  ResourcesShadow,
  ResourcesWrapper,
  Column,
  HeaderBar
} from './styles'
import { TableWrapper } from 'components/StudentResultsTable/styles'
import { getCurrentTimePeriod } from '../../utils/assessmentConfig'
import { useReactToPrint } from 'react-to-print'
import FamilyReport from 'views/FamilyReport'
import Switch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'
import * as ROLE from 'constants/roles'
import { useFeatureFlagPayload } from 'posthog-js/react'

const StudentView = () => {
  const { signOut } = useAuth()
  const params = useParams()
  const { studentId } = params
  const { state, dispatch } = useSessionStore()
  const { role, viewHeader, schoolIds } = state
  const [student, setStudent] = useState({})
  const [studentResults, setStudentResults] = useState(null)
  const [mostRecentRANScore, setMostRecentRANScore] = useState(undefined)
  const [alert, setAlert] = useState({ isOpen: false, type: '', message: '' })
  const [isUnauthorized, setIsUnauthorized] = useState(false)
  const [studentLoading, setStudentLoading] = useState({ isComplete: false, hasError: false })
  const [assmntConfig, setAssmntConfig] = useState(null)
  const [timePeriodAssmntLists, setTimePeriodAssmntLists] = useState(null)
  const [currTimePeriod, setCurrTimePeriod] = useState(null)
  const [selectedTimePeriod, setSelectedTimePeriod] = useState(null)
  const [isAdminView, setIsAdminView] = useState(false)
  const bannerPayload = useFeatureFlagPayload('student-profile-banner')

  useEffect(() => {
    if (assmntConfig) {
      // selected time period defaults to the current time period
      const timePeriod = getCurrentTimePeriod(assmntConfig)
      setCurrTimePeriod(timePeriod)
    }
  }, [assmntConfig])

  // get student
  useEffect(() => {
    if (studentLoading.hasError) return

    let isMounted = true
    ;(async () => {
      try {
        const studentInfo = await getStudent(studentId)
        if (isMounted) {
          setStudent(studentInfo)
          setStudentLoading({ isComplete: true, hasError: false })
        }
      } catch (err) {
        if (err.code === 403) {
          setIsUnauthorized(true)
        } else if (err.code === 401) {
          // timeout error
          await signOut()
          dispatch({ type: actionTypes.LOGOUT })
        } else {
          setStudentLoading({ isComplete: true, hasError: true })
          setAlert({ isOpen: true, type: 'error', message: COPY.STUDENT_LOAD_ERROR })
        }
      }
    })()
    return () => {
      isMounted = false
    }
  }, [studentId, studentLoading.hasError, dispatch, signOut])

  // get current time period and student test results for all time periods
  useEffect(() => {
    let isMounted = true
    ;(async () => {
      if (studentLoading.isComplete && !studentLoading.hasError) {
        try {
          const districtId = student.OrganizationUnit.Organization.organizationId
          const assmntConfig = await getCurrDistrictAssmntConfig(districtId)

          // promises to get student results for boy/moy/eoy
          const studentResultPromises = assmntConfig.map((config) => {
            /* eslint-disable no-async-promise-executor */
            return new Promise(async (resolve, reject) => {
              try {
                const studentInfo = await getStudent(studentId, config.startDate, config.endDate)
                resolve({ period: config.periodName, results: studentInfo.ParticipantTests })
              } catch (err) {
                reject(err)
              }
            })
          })

          const results = await Promise.all(studentResultPromises)

          // get assessment lists that correspond with the student's grade for each time period
          const timePeriodAssmntLists = assmntConfig.reduce((obj, config) => {
            const gradeAssmntList = config.GradeAssessmentLists.find((list) => {
              return list.grade === student.grade
            })
            if (!gradeAssmntList) {
              throw Error(COPY.MISSING_GRADE_ASSESSMENTS)
            }
            return {
              ...obj,
              [config.periodName]: gradeAssmntList.list
            }
          }, {})

          // get student's most recent RAN score
          const ranScore = await getStudentMostRecentRAN(studentId)

          if (isMounted) {
            setAssmntConfig(assmntConfig)
            setStudentResults(results)
            setMostRecentRANScore(ranScore)
            setTimePeriodAssmntLists(timePeriodAssmntLists)
          }
        } catch (err) {
          const message =
            err.message === COPY.MISSING_GRADE_ASSESSMENTS ? COPY.MISSING_GRADE_ASSESSMENTS : COPY.STUDENT_RESULTS_ERROR
          setAlert({ isOpen: true, type: 'error', message: message })
        }
      }
    })()

    return () => {
      isMounted = false
    }
  }, [studentLoading, student, studentId])

  // sets view header based on student data
  useEffect(() => {
    if (studentLoading.isComplete && !studentLoading.hasError) {
      if (!viewHeader.includes(student.lastName)) {
        dispatch({
          type: actionTypes.UPDATE_FIELD,
          field: 'viewHeader',
          data: `Student: ${student.firstName} ${student.lastName}`
        })
      }
    }
  }, [studentLoading, student, viewHeader, dispatch])

  const reloadStudent = async () => {
    try {
      setStudentLoading({ isComplete: false, hasError: false })
      const studentInfo = await getStudent(studentId)
      setStudent(studentInfo)
      setStudentLoading({ isComplete: true, hasError: false })
    } catch (err) {
      setStudentLoading({ isComplete: true, hasError: true })
      setAlert({ isOpen: true, type: 'error', message: COPY.STUDENT_LOAD_ERROR })
    }
  }

  const onAlert = (type, message) => {
    setAlert({ isOpen: true, type: type, message: message })
  }

  // This ref and handler are required for printing the
  // FamilyReport from this view. If we get rid of this
  // version of the FamilyReport, we can delete these.
  const reportRef = useRef()
  const handleParentReportClick = useReactToPrint({
    content: () => reportRef.current
  })

  const studentIsBannerTargetGrade = (targetGrades) => {
    if (!targetGrades || !Array.isArray(targetGrades)) {
      return false
    }
    return targetGrades.includes(student.grade)
  }

  if (isUnauthorized) {
    return <Unauthorized />
  }

  const isViewLoaded =
    studentLoading.isComplete &&
    !studentLoading.hasError &&
    viewHeader.includes(student.lastName) &&
    studentResults &&
    mostRecentRANScore !== undefined

  return (
    <div>
      {isViewLoaded && (
        <>
          <Grid container justify='center' spacing={2}>
            <Grid item xs={11}>
              <Breadcrumbs student={student} role={role} userSchools={schoolIds} />
            </Grid>
          </Grid>
          <ContentWrapper>
            {bannerPayload && studentIsBannerTargetGrade(bannerPayload.grades) && <Banner payload={bannerPayload} />}
            <Grid container justify='center' spacing={3}>
              <Grid item sm={12} md={4}>
                <Column>
                  <InfoShadow>
                    <InfoWrapper>
                      <StudentInfo student={student} role={role} onUpdate={reloadStudent} onAlert={onAlert} />
                    </InfoWrapper>
                  </InfoShadow>
                  <ResourcesShadow>
                    <ResourcesWrapper>
                      <StudentResources studentResults={studentResults} timePeriod={selectedTimePeriod} />
                    </ResourcesWrapper>
                  </ResourcesShadow>
                </Column>
              </Grid>
              <Grid item sm={12} md={7}>
                <TableShadow>
                  <HeaderBar>
                    <h2>
                      Subtest Results{' '}
                      {role === ROLE.SUPER_ADMIN && (
                        <FormControlLabel
                          required
                          control={<Switch checked={isAdminView} onChange={() => setIsAdminView(!isAdminView)} />}
                          label='Admin View'
                        />
                      )}
                    </h2>
                    <ButtonLink onClick={handleParentReportClick}>Print Family Report</ButtonLink>
                  </HeaderBar>
                  <ErrorBoundary fallback={() => <TableWrapper>{COPY.STUDENT_RESULTS_ERROR}</TableWrapper>}>
                    <StudentResultsTable
                      student={student}
                      studentResults={studentResults}
                      mostRecentRANScore={mostRecentRANScore}
                      assmntConfig={assmntConfig}
                      timePeriodAssmntLists={timePeriodAssmntLists}
                      onUpdate={reloadStudent}
                      currTimePeriod={currTimePeriod}
                      selectedTimePeriod={selectedTimePeriod}
                      setSelectedTimePeriod={setSelectedTimePeriod}
                      isAdminView={isAdminView}
                    />
                  </ErrorBoundary>
                </TableShadow>
              </Grid>
            </Grid>
            <div style={{ display: 'none' }}>
              <FamilyReport
                student={student}
                studentResults={studentResults}
                mostRecentRANScore={mostRecentRANScore}
                timePeriod={selectedTimePeriod}
                timePeriodAssmntLists={timePeriodAssmntLists}
                assmntConfig={assmntConfig}
                ref={reportRef}
              />
            </div>
          </ContentWrapper>
        </>
      )}

      {alert.isOpen && (
        <ToastNotification
          alert={alert}
          onClose={() => {
            setAlert({ isOpen: false, type: '', message: '' })
          }}
        />
      )}
    </div>
  )
}

export default StudentView
