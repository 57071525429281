import React, { useState } from 'react'
import { ViewWrapper } from '../../ui-components/backgrounds'
import {
  AuthBackground,
  ContentWrapper,
  FormWrapper,
  PageLink,
  AuthHeader,
  AuthLogo,
  BackArrow
} from '../../ui-components/authStyles'
import { BackWrapper, InputWrapper, FormContent } from './styles'
import { TextField } from '../../ui-components/inputs/inputs'
import { LargeButton } from '../../ui-components/buttons/buttons'
import ToastNotification from '../../components/ToastNotification'
import * as COPY from '../../constants/copy'
import { registerUser } from 'lib/api'
import { USER_ERROR_DEFAULT, USER_IN_USE, USER_NOT_FOUND, USER_STATUS_UNKNOWN } from './copy'

const friendlyError = (msg) => {
  switch (msg) {
    case 'unknown user':
      return USER_NOT_FOUND
    case 'unavailable user':
      return USER_IN_USE
    case 'unknown userstatus':
      return USER_STATUS_UNKNOWN
    default:
      return USER_ERROR_DEFAULT
  }
}

const Register = () => {
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState(null)
  const [alert, setAlert] = useState({ isOpen: false, type: '', message: '' })
  const [isDisabled, setIsDisabled] = useState(false)
  const [succeeded, setSucceeded] = useState(false)

  const submitRegistration = async () => {
    setEmailError(null)
    try {
      await registerUser(email)
      setAlert({
        isOpen: true,
        type: 'success',
        message: 'User registration accepted - please check your email for next steps.'
      })
      setSucceeded(true)
    } catch (err) {
      setAlert({
        isOpen: true,
        type: 'error',
        message: friendlyError(err.message)
      })
    }
    setIsDisabled(false)
  }

  return (
    <ViewWrapper>
      <AuthBackground />
      <ContentWrapper>
        <AuthLogo />
        <FormWrapper>
          <AuthHeader className='reset'>New User Registration</AuthHeader>
          {succeeded ? null : (
            <FormContent>
              <InputWrapper>
                <TextField
                  type='text'
                  placeholder='Your Email'
                  value={email}
                  label='Email'
                  onChange={(event) => {
                    if (emailError) {
                      setEmailError(false)
                    }
                    if (event.target.value.length < 1) {
                      setEmailError(true)
                    }
                    setEmail(event.target.value)
                  }}
                  data-testid='emailInput'
                  error={emailError}
                  helperText={emailError ? COPY.EMPTY_EMAIL_ERROR : ''}
                  severity={emailError ? 'error' : null}
                />
              </InputWrapper>
              <LargeButton
                type='button'
                alt='submit'
                data-testid='submit'
                disabled={isDisabled}
                onClick={async () => {
                  setIsDisabled(true)
                  await submitRegistration()
                }}
              >
                Register
              </LargeButton>
            </FormContent>
          )}

          <BackWrapper>
            <BackArrow />
            <PageLink to='/login'>Back to Login</PageLink>
          </BackWrapper>
        </FormWrapper>
      </ContentWrapper>

      {alert.isOpen && (
        <ToastNotification
          alert={alert}
          path={alert.type === 'success' && '/login'}
          action={alert.type === 'success' && 'GO TO LOGIN'}
          onClose={() => {
            setAlert({ isOpen: false, type: '', message: '' })
          }}
        />
      )}
    </ViewWrapper>
  )
}

export default Register
