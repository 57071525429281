const API_BASE = process.env.REACT_APP_API_BASE_URL

export const buildAudioPath = (questionCode, participantId, participantTestId) => {
  const getAudioEndpoint = `${API_BASE}/participantTest/getAudioFile`

  // formatting the questionId for the file path
  const questionId = questionCode.toLowerCase()

  return `${getAudioEndpoint}?fileName=${participantId}_${participantTestId}_${questionId}.wav`
}

const mapResponseDetails = async (response, participantId, participantTestId, isInvalid) => {
  // the response time is initially saved by the app as responseTimeInSecs, but the final response time set by teachers is saved as evalResponseTime
  const {
    questionCode,
    numOfErrors,
    responseTimeInSecs,
    evalResponseTime,
    participantTestQuestionResponseId
  } = response

  return {
    questionId: participantTestQuestionResponseId,
    scoring: {
      questionId: participantTestQuestionResponseId,
      numOfErrors: numOfErrors,
      evalTime: evalResponseTime,
      isInvalid: isInvalid
    },
    responseTime: responseTimeInSecs,
    audio: buildAudioPath(questionCode, participantId, participantTestId)
  }
}

export const getRANAssessmentDetails = async (testDetails, isInvalid) => {
  if (testDetails.participantId) {
    const { participantId, participantTestId, ParticipantTestQuestionResponses } = testDetails

    // for letter RAN, practiceDetails and questionDetails will remain null in the case that the assessment is completed but there are no responses (student fails identification step)
    let practiceDetails = null
    let questionDetails = null
    if (ParticipantTestQuestionResponses && ParticipantTestQuestionResponses.length > 2) {
      throw new Error(`Unexpected number of RAN responses for participant test ${participantTestId}`)
    }

    for (const response of ParticipantTestQuestionResponses) {
      const isPracticeQuestion = response.questionCode.toLowerCase().includes('prac')
      if (isPracticeQuestion) {
        practiceDetails = await mapResponseDetails(response, participantId, participantTestId, isInvalid)
      } else {
        questionDetails = await mapResponseDetails(response, participantId, participantTestId, isInvalid)
      }
    }

    return { practiceDetails, questionDetails }
  }
}
