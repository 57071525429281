import React from 'react'
import Accordion from '../Accordion'
import * as COPY from './copy'
import AccordionBody from './components/AccordionBody'
import { StudentResourcesWrapper, ExternalLink } from './styles'
import LinkIcon from '@material-ui/icons/Link'
import * as ASSESSMENTS from 'constants/assessments'
import { isAssessmentComplete, getAssessmentScoreAndThreshold } from './helper'

export const ASSESSMENT_RESOURCES = [
  {
    key: ASSESSMENTS.OBJ_RAN_ID,
    info: { header: COPY.OBJ_RAN_HEADER, body: COPY.OBJ_RAN_BODY }
  },
  {
    key: ASSESSMENTS.LETTER_RAN_ID,
    info: { header: COPY.LETTER_RAN_HEADER, body: COPY.LETTER_RAN_BODY }
  },
  {
    key: ASSESSMENTS.RER_LETTER_RAN_ID,
    info: { header: COPY.RER_LETTER_RAN_HEADER, body: COPY.RER_LETTER_RAN_BODY }
  },
  {
    key: ASSESSMENTS.LETTER_NAMES_ID,
    info: { header: COPY.LETTER_NAMES_HEADER, body: COPY.LETTER_NAMES_BODY }
  },
  {
    key: ASSESSMENTS.LETTER_SOUNDS_ID,
    info: { header: COPY.LETTER_SOUNDS_HEADER, body: COPY.LETTER_SOUNDS_BODY }
  },
  {
    key: ASSESSMENTS.RHYMING_ID,
    info: { header: COPY.RHYMING_HEADER, body: COPY.RHYMING_BODY }
  },
  {
    key: ASSESSMENTS.FIRST_SOUNDS_ID,
    info: { header: COPY.FIRST_SOUND_HEADER, body: COPY.FIRST_SOUND_BODY }
  },
  {
    key: ASSESSMENTS.BLENDING_ID,
    info: { header: COPY.BLENDING_HEADER, body: COPY.BLENDING_BODY }
  },
  {
    key: ASSESSMENTS.DELETION_ID,
    info: { header: COPY.DELETION_HEADER, body: COPY.DELETION_BODY }
  },
  {
    key: ASSESSMENTS.NON_WORD_REP_ID,
    info: { header: COPY.NONWORD_HEADER, body: COPY.NONWORD_BODY }
  },
  {
    key: ASSESSMENTS.RER_NW_REP_ID,
    info: { header: COPY.NONWORD_HEADER, body: COPY.NONWORD_BODY }
  },
  {
    key: ASSESSMENTS.NW_READING_ID,
    info: { header: COPY.NW_READING_HEADER, body: COPY.NW_READING_BODY }
  },
  {
    key: ASSESSMENTS.RER_NW_READING_ID,
    info: { header: COPY.NW_READING_HEADER, body: COPY.NW_READING_BODY }
  },
  {
    key: ASSESSMENTS.WORD_READING_ID,
    info: { header: COPY.WORD_READING_HEADER, body: COPY.WORD_READING_BODY }
  },
  {
    key: ASSESSMENTS.RER_WR_ID,
    info: { header: COPY.WORD_READING_HEADER, body: COPY.WORD_READING_BODY }
  },
  {
    key: ASSESSMENTS.RER_NW_SPELLING_ID,
    info: { header: COPY.RER_NW_SPELLING_HEADER, body: COPY.RER_NW_SPELLING_BODY }
  },
  {
    key: ASSESSMENTS.VOCAB_MEASURE_ID,
    info: { header: COPY.VOCAB_HEADER, body: COPY.VOCAB_BODY }
  },
  {
    key: ASSESSMENTS.WORD_MATCHING_ID,
    info: { header: COPY.WORD_MATCHING_HEADER, body: COPY.WORD_MATCHING_BODY }
  },
  {
    key: ASSESSMENTS.ORAL_SENTENCE_MEASURE_ID,
    info: { header: COPY.ORAL_SENTENCE_HEADER, body: COPY.ORAL_SENTENCE_BODY }
  },
  {
    key: ASSESSMENTS.FOLLOW_DIRECTIONS_ID,
    info: { header: COPY.FOLLOW_DIR_HEADER, body: COPY.FOLLOW_DIR_BODY }
  },
  {
    key: ASSESSMENTS.RER_EVO_ID,
    info: { header: COPY.RER_EVO_HEADER, body: COPY.RER_EVO_BODY }
  }
]

const getAccordionResources = (studentResults, timePeriod) => {
  const resourcesList = []
  let incompleteCount = 0

  ASSESSMENT_RESOURCES.forEach((assessment) => {
    // loop through the assessment resources const and find matching student assessment
    const result = studentResults.find((result) => {
      return assessment.key === result.testCode
    })

    if (isAssessmentComplete(result)) {
      const { score, threshold } = getAssessmentScoreAndThreshold(result, assessment.key, timePeriod)
      if (score && score <= threshold) {
        // if the student's score is less than or equal to the threshold, add to list of next step resources to be displayed
        resourcesList.push(assessment)
      }
    } else {
      incompleteCount++
    }
  })

  if (resourcesList.length > 0) {
    return resourcesList.map((assessment) => {
      return (
        <Accordion
          key={assessment.key}
          header={assessment.info.header}
          body={<AccordionBody body={assessment.info.body} />}
        />
      )
    })
  } else {
    return (
      <div className='noAccordion'>
        <p>
          {incompleteCount === ASSESSMENT_RESOURCES.length
            ? COPY.INCOMPLETE_ASSESSMENTS_TEXT
            : COPY.MET_EXPECTATIONS_TEXT}
        </p>
        <ExternalLink href={COPY.NEXT_STEPS_LINK} rel='noreferrer' target='_blank'>
          <p>{COPY.NEXT_STEPS_LINK_TEXT}</p>
          <LinkIcon />
        </ExternalLink>
      </div>
    )
  }
}

const StudentResources = ({ studentResults, timePeriod }) => {
  let timePeriodResults = null
  if (timePeriod && studentResults) {
    const timePeriodData = studentResults.find((results) => results.period === timePeriod)
    if (timePeriodData) {
      timePeriodResults = timePeriodData.results
    }
  }

  return (
    <StudentResourcesWrapper>
      <h2>{COPY.NEXT_STEPS_HEADER}</h2>
      <p className='explanation'>{COPY.NEXT_STEPS_EXPLANATION}</p>
      {timePeriodResults && getAccordionResources(timePeriodResults, timePeriod)}
    </StudentResourcesWrapper>
  )
}

export default StudentResources
