import { hasSoundSymbolCorrNorms } from 'utils/configuration'
import { BOY, MOY, EOY } from 'constants/timePeriods'

// subtest options
export const PERCENTILE = '%'
export const PROFICIENCY = 'proficiency'
export const RATIO = 'ratio'
export const TBD = 'tbd'

// predictive profile options
export const ALG_PERCENTAGE = 'algorithm_percentage'
export const ALG_ICON = 'algorithm_icon'
export const GRAYED_OUT = 'grayed_out'

export const DISPLAY_TEXT = {
  [PERCENTILE]: '%ile',
  [PROFICIENCY]: 'Proficiency',
  [RATIO]: 'Raw Score'
}

export const DISPLAY_RULES = {
  2: {
    TEST_RER_WR: {
      BOY: PERCENTILE,
      MOY: PERCENTILE,
      EOY: PERCENTILE
    },
    TEST_RER_NW_READING: {
      BOY: PERCENTILE,
      MOY: PERCENTILE,
      EOY: PERCENTILE
    },
    TEST_RER_NW_REP: {
      BOY: PERCENTILE,
      MOY: PERCENTILE,
      EOY: PERCENTILE
    },
    TEST_RER_EXPRESSIVE_VOC: {
      BOY: PERCENTILE,
      MOY: PERCENTILE,
      EOY: PERCENTILE
    },
    TEST_RER_NW_SPELLING: {
      BOY: PERCENTILE,
      MOY: PERCENTILE,
      EOY: PERCENTILE
    },
    TEST_WORD_MATCHING: {
      BOY: PERCENTILE,
      MOY: PERCENTILE,
      EOY: PERCENTILE
    },
    TEST_FOLLOW_DIRECTIONS: {
      BOY: PERCENTILE,
      MOY: PERCENTILE,
      EOY: PERCENTILE
    },
    TEST_BLENDING: {
      BOY: null,
      MOY: null,
      EOY: null
    },
    TEST_DELETION: {
      BOY: null,
      MOY: null,
      EOY: null
    },
    TEST_FIRST_SOUNDS: {
      BOY: null,
      MOY: null,
      EOY: null
    },
    TEST_LETTER_NAMES: {
      BOY: null,
      MOY: null,
      EOY: null
    },
    TEST_LETTER_SOUNDS: {
      BOY: null,
      MOY: null,
      EOY: null
    },
    TEST_NON_WORD_REPETITION_MEASURE: {
      BOY: null,
      MOY: null,
      EOY: null
    },
    TEST_NW_READING: {
      BOY: null,
      MOY: null,
      EOY: null
    },
    TEST_ORAL_SENTENCE_MEASURE: {
      BOY: null,
      MOY: null,
      EOY: null
    },
    TEST_RHYMING_WORDS: {
      BOY: null,
      MOY: null,
      EOY: null
    },
    TEST_VOCAB_MEASURE: {
      BOY: null,
      MOY: null,
      EOY: null
    },
    TEST_WORD_READING: {
      BOY: null,
      MOY: null,
      EOY: null
    },
    TEST_LETTER_RAN: {
      BOY: null,
      MOY: null,
      EOY: null
    },
    TEST_RAN_WORDS: {
      BOY: null,
      MOY: null,
      EOY: null
    },
    TEST_RER_LETTER_RAN: {
      BOY: PERCENTILE,
      MOY: PERCENTILE,
      EOY: PERCENTILE
    },
    single_pwr: {
      BOY: ALG_PERCENTAGE,
      MOY: ALG_PERCENTAGE,
      EOY: ALG_PERCENTAGE
    },
    pwr: {
      BOY: null,
      MOY: null,
      EOY: null
    },
    dyslexia: {
      BOY: null,
      MOY: null,
      EOY: null
    },
    kready: {
      BOY: null,
      MOY: null,
      EOY: null
    }
  },
  1: {
    TEST_BLENDING: {
      BOY: null,
      MOY: null,
      EOY: null
    },
    TEST_DELETION: {
      BOY: PERCENTILE,
      MOY: PERCENTILE,
      EOY: PERCENTILE
    },
    TEST_FIRST_SOUNDS: {
      BOY: null,
      MOY: null,
      EOY: null
    },
    TEST_FOLLOW_DIRECTIONS: {
      BOY: PERCENTILE,
      MOY: PERCENTILE,
      EOY: PERCENTILE
    },
    TEST_LETTER_NAMES: {
      BOY: null,
      MOY: null,
      EOY: null
    },
    TEST_LETTER_SOUNDS: {
      BOY: hasSoundSymbolCorrNorms(BOY) ? PERCENTILE : RATIO,
      MOY: null,
      EOY: null
    },
    TEST_NON_WORD_REPETITION_MEASURE: {
      BOY: null,
      MOY: null,
      EOY: null
    },
    TEST_NW_READING: {
      BOY: null,
      MOY: null,
      EOY: null
    },
    TEST_ORAL_SENTENCE_MEASURE: {
      BOY: null,
      MOY: null,
      EOY: null
    },
    TEST_RHYMING_WORDS: {
      BOY: null,
      MOY: null,
      EOY: null
    },
    TEST_VOCAB_MEASURE: {
      BOY: null,
      MOY: null,
      EOY: null
    },
    TEST_WORD_MATCHING: {
      BOY: PERCENTILE,
      MOY: PERCENTILE,
      EOY: PERCENTILE
    },
    TEST_WORD_READING: {
      BOY: null,
      MOY: null,
      EOY: null
    },
    TEST_LETTER_RAN: {
      BOY: PERCENTILE,
      MOY: PERCENTILE,
      EOY: PERCENTILE
    },
    TEST_RAN_WORDS: {
      BOY: null,
      MOY: null,
      EOY: null
    },
    TEST_RER_WR: {
      BOY: PERCENTILE,
      MOY: PERCENTILE,
      EOY: PERCENTILE
    },
    TEST_RER_NW_READING: {
      BOY: PERCENTILE,
      MOY: PERCENTILE,
      EOY: PERCENTILE
    },
    TEST_RER_NW_REP: {
      BOY: PERCENTILE,
      MOY: PERCENTILE,
      EOY: PERCENTILE
    },
    TEST_RER_EXPRESSIVE_VOC: {
      BOY: PERCENTILE,
      MOY: PERCENTILE,
      EOY: PERCENTILE
    },
    TEST_RER_NW_SPELLING: {
      BOY: PERCENTILE,
      MOY: PERCENTILE,
      EOY: PERCENTILE
    },
    TEST_RER_LETTER_RAN: {
      BOY: null,
      MOY: null,
      EOY: null
    },
    single_pwr: {
      BOY: ALG_PERCENTAGE,
      MOY: ALG_PERCENTAGE,
      EOY: ALG_PERCENTAGE
    },
    pwr: {
      BOY: null,
      MOY: null,
      EOY: null
    },
    dyslexia: {
      BOY: null,
      MOY: null,
      EOY: null
    },
    kready: {
      BOY: null,
      MOY: null,
      EOY: null
    }
  },
  K: {
    TEST_BLENDING: {
      BOY: PERCENTILE,
      MOY: PERCENTILE,
      EOY: PERCENTILE
    },
    TEST_DELETION: {
      BOY: null,
      MOY: null,
      EOY: null
    },
    TEST_FIRST_SOUNDS: {
      BOY: PERCENTILE,
      MOY: PERCENTILE,
      EOY: PERCENTILE
    },
    TEST_FOLLOW_DIRECTIONS: {
      BOY: PERCENTILE,
      MOY: PERCENTILE,
      EOY: PERCENTILE
    },
    TEST_LETTER_NAMES: {
      BOY: hasSoundSymbolCorrNorms(BOY) ? PERCENTILE : RATIO,
      MOY: hasSoundSymbolCorrNorms(MOY) ? PERCENTILE : RATIO,
      EOY: hasSoundSymbolCorrNorms(EOY) ? PERCENTILE : RATIO
    },
    TEST_LETTER_SOUNDS: {
      BOY: hasSoundSymbolCorrNorms(BOY) ? PERCENTILE : RATIO,
      MOY: hasSoundSymbolCorrNorms(MOY) ? PERCENTILE : RATIO,
      EOY: hasSoundSymbolCorrNorms(EOY) ? PERCENTILE : RATIO
    },
    TEST_NON_WORD_REPETITION_MEASURE: {
      BOY: PERCENTILE,
      MOY: PERCENTILE,
      EOY: PERCENTILE
    },
    TEST_NW_READING: {
      BOY: null,
      MOY: null,
      EOY: PERCENTILE
    },
    TEST_ORAL_SENTENCE_MEASURE: {
      BOY: PERCENTILE,
      MOY: PERCENTILE,
      EOY: PERCENTILE
    },
    TEST_RHYMING_WORDS: {
      BOY: PERCENTILE,
      MOY: PERCENTILE,
      EOY: PERCENTILE
    },
    TEST_VOCAB_MEASURE: {
      BOY: PERCENTILE,
      MOY: PERCENTILE,
      EOY: PERCENTILE
    },
    TEST_WORD_MATCHING: {
      BOY: PERCENTILE,
      MOY: PERCENTILE,
      EOY: PERCENTILE
    },
    TEST_WORD_READING: {
      BOY: null,
      MOY: null,
      EOY: PERCENTILE
    },
    TEST_LETTER_RAN: {
      BOY: null,
      MOY: null,
      EOY: null
    },
    TEST_RAN_WORDS: {
      BOY: PERCENTILE,
      MOY: PERCENTILE,
      EOY: PERCENTILE
    },
    TEST_RER_WR: {
      BOY: null,
      MOY: null,
      EOY: null
    },
    TEST_RER_NW_READING: {
      BOY: null,
      MOY: null,
      EOY: null
    },
    TEST_RER_NW_REP: {
      BOY: null,
      MOY: null,
      EOY: null
    },
    TEST_RER_EXPRESSIVE_VOC: {
      BOY: null,
      MOY: null,
      EOY: null
    },
    TEST_RER_NW_SPELLING: {
      BOY: null,
      MOY: null,
      EOY: null
    },
    TEST_RER_LETTER_RAN: {
      BOY: null,
      MOY: null,
      EOY: null
    },
    single_pwr: {
      BOY: null,
      MOY: null,
      EOY: null
    },
    pwr: {
      BOY: null,
      MOY: ALG_PERCENTAGE,
      EOY: null
    },
    dyslexia: {
      BOY: ALG_ICON,
      MOY: ALG_ICON,
      EOY: ALG_ICON
    },
    kready: {
      BOY: null,
      MOY: null,
      EOY: null
    }
  },
  PK: {
    TEST_BLENDING: {
      BOY: null,
      MOY: null,
      EOY: PERCENTILE
    },
    TEST_DELETION: {
      BOY: null,
      MOY: null,
      EOY: null
    },
    TEST_FIRST_SOUNDS: {
      BOY: null,
      MOY: PERCENTILE,
      EOY: PERCENTILE
    },
    TEST_FOLLOW_DIRECTIONS: {
      BOY: null,
      MOY: null,
      EOY: null
    },
    TEST_LETTER_NAMES: {
      BOY: hasSoundSymbolCorrNorms(BOY) ? PERCENTILE : RATIO,
      MOY: hasSoundSymbolCorrNorms(MOY) ? PERCENTILE : RATIO,
      EOY: hasSoundSymbolCorrNorms(EOY) ? PERCENTILE : RATIO
    },
    TEST_LETTER_SOUNDS: {
      BOY: hasSoundSymbolCorrNorms(BOY) ? PERCENTILE : RATIO,
      MOY: hasSoundSymbolCorrNorms(MOY) ? PERCENTILE : RATIO,
      EOY: hasSoundSymbolCorrNorms(EOY) ? PERCENTILE : RATIO
    },
    TEST_NON_WORD_REPETITION_MEASURE: {
      BOY: null,
      MOY: null,
      EOY: null
    },
    TEST_NW_READING: {
      BOY: null,
      MOY: null,
      EOY: null
    },
    TEST_ORAL_SENTENCE_MEASURE: {
      BOY: PERCENTILE,
      MOY: PERCENTILE,
      EOY: PERCENTILE
    },
    TEST_RHYMING_WORDS: {
      BOY: PERCENTILE,
      MOY: PERCENTILE,
      EOY: PERCENTILE
    },
    TEST_VOCAB_MEASURE: {
      BOY: PERCENTILE,
      MOY: PERCENTILE,
      EOY: PERCENTILE
    },
    TEST_WORD_MATCHING: {
      BOY: null,
      MOY: null,
      EOY: null
    },
    TEST_WORD_READING: {
      BOY: null,
      MOY: null,
      EOY: null
    },
    TEST_LETTER_RAN: {
      BOY: null,
      MOY: null,
      EOY: null
    },
    TEST_RAN_WORDS: {
      BOY: PERCENTILE,
      MOY: PERCENTILE,
      EOY: PERCENTILE
    },
    TEST_RER_WR: {
      BOY: null,
      MOY: null,
      EOY: null
    },
    TEST_RER_NW_READING: {
      BOY: null,
      MOY: null,
      EOY: null
    },
    TEST_RER_NW_REP: {
      BOY: null,
      MOY: null,
      EOY: null
    },
    TEST_RER_EXPRESSIVE_VOC: {
      BOY: null,
      MOY: null,
      EOY: null
    },
    TEST_RER_NW_SPELLING: {
      BOY: null,
      MOY: null,
      EOY: null
    },
    TEST_RER_LETTER_RAN: {
      BOY: null,
      MOY: null,
      EOY: null
    },
    single_pwr: {
      BOY: null,
      MOY: null,
      EOY: null
    },
    pwr: {
      BOY: null,
      MOY: null,
      EOY: null
    },
    dyslexia: {
      BOY: null,
      MOY: null,
      EOY: null
    },
    kready: {
      BOY: null,
      MOY: null,
      EOY: ALG_ICON
    }
  }
}
