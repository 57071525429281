import React, { useState } from 'react'
import { TableCell } from '../../styles'
import { RAN_ASSESSMENT_IDS, LETTER_NAMES_ID, LETTER_SOUNDS_ID } from 'constants/assessments'
import { getAssessmentDisplay } from 'utils/display'
import ScoreEntryIcon from 'components/ScoreEntryIcon'
import { useFeatureFlagEnabled } from 'posthog-js/react'

const AssessmentCell = ({ cellData, cellClasses }) => {
  const lnlsScoreEntryEnabled = useFeatureFlagEnabled('lnls-score-entry')

  const [isHighlighted, setIsHighlighted] = useState(false)

  let cellContent = null
  let assmntClass = ''

  const {
    key: testCode,
    timePeriod,
    studentId,
    studentGrade,
    setHoverCell,
    assmntListByGrade,
    onClickCell = () => {},
    displayRuleOverwrite
  } = cellData
  const isRAN = RAN_ASSESSMENT_IDS.includes(testCode)
  if (isRAN) {
    // if the assessment is a RAN test...
    const { classroomResults, mostRecentRanScore } = cellData
    // check if there is a ran test for the current time period
    const currPeriodResults = classroomResults[timePeriod].studentResults[studentId]

    // if there is a RAN test for this time period, display that
    if (currPeriodResults && currPeriodResults[testCode]) {
      cellData = { results: currPeriodResults[testCode] }
    } else if (mostRecentRanScore) {
      // if there is not a RAN test for the curr time period, display the most recent RAN score
      const { abilityPercentile, completedFlag, invalid } = mostRecentRanScore
      cellData = {
        results: {
          abilityPercentile: abilityPercentile,
          isComplete: completedFlag,
          assessmentId: testCode,
          isInvalid: invalid
        }
      }
    } else {
      // if there are no RAN tests to display, set results to null for the conditions below
      cellData = { results: null }
    }
  }

  if (!cellData.results) {
    // check if the assessment is part of the student's corresponding grade assessment list
    const isApplicableAssmnt = assmntListByGrade[studentGrade].includes(testCode)
    if (!isApplicableAssmnt) {
      // if assessment not applicable for time period display grayed out cell
      cellContent = <div></div>
      assmntClass = 'notApplicable'
    } else if (lnlsScoreEntryEnabled && [LETTER_NAMES_ID, LETTER_SOUNDS_ID].includes(testCode)) {
      // display score entry icon if LN/LS scores have not been entered
      cellContent = <ScoreEntryIcon />
    } else {
      // if assessment has not been started display empty cell
      cellContent = <div></div>
      assmntClass = 'emptyAssmnt'
    }
  } else {
    // if assessment has been started, display based on display rules
    const { isComplete, abilityPercentile, finalTheta, correctItems, totalItems, isInvalid } = cellData.results
    const { content, classes } = getAssessmentDisplay({
      studentGrade: studentGrade,
      assessmentId: testCode,
      timePeriod: timePeriod,
      isComplete: isComplete,
      abilityPercentile: abilityPercentile,
      finalTheta: finalTheta,
      correctItems: correctItems,
      totalItems: totalItems,
      isInvalid: isInvalid,
      displayRuleOverwrite
    })

    cellContent = content
    assmntClass = classes
  }

  return (
    <TableCell
      onMouseEnter={() => {
        setHoverCell({ subColKey: testCode, studentId: studentId })
        setIsHighlighted(true)
      }}
      onMouseLeave={() => {
        setHoverCell(null)
        setIsHighlighted(false)
      }}
      className={`${cellClasses} defaultCell ${assmntClass} ${isHighlighted && 'hoverCell'}`}
      onClick={onClickCell}
    >
      {cellContent}
    </TableCell>
  )
}

export default AssessmentCell
