// @ts-check

import { Auth } from 'aws-amplify'
import * as COPY from './copy'
import posthog from 'posthog-js'

const API_BASE = process.env.REACT_APP_API_BASE_URL

const getErrorMessage = (errResponse) => {
  let errorMessage = ''
  if (errResponse.message) {
    errorMessage = errResponse.message
  } else if (errResponse.errors && errResponse.errors.length) {
    // only return first error message even if there are multiple
    errorMessage = errResponse.errors[0].message
  }

  if (errorMessage.includes('isEmail')) {
    errorMessage = COPY.INVALID_EMAIL_ERROR
  }

  return errorMessage || COPY.UNEXPECTED_ERROR
}

/**
 * request
 *
 * helper function for constructing and sending requests to the EarlyBird API
 *
 * @param {'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'} method - the http method to use in the request
 * @param {string} url - the path that will be appended to the base API URL
 * @param {object} payload - the object to be stringified and sent as the request body
 * @param {object} init - options for initializing the request
 * @param {boolean} [addAuthHeadersFromSession=true] - automatically add accesstoken to headers
 * @returns {Promise<object>} the json body of the response
 */
export const request = async (method, url, payload = {}, init = {}, addAuthHeadersFromSession = true) => {
  if (!init.headers) {
    if (method === 'GET') {
      init.headers = {}
    } else {
      init.method = method
      init.headers = { 'Content-Type': 'application/json' }
      init.body = JSON.stringify(payload)
    }
  }

  if (!posthog.isFeatureEnabled('session-auth')) {
    const currentAmplifySession = await Auth.currentSession()
    const currentSession = currentAmplifySession
    // @ts-ignore CognitoUserSession type does not have idToken prop for some reason, but this works
    init.headers.accesstoken = currentSession && currentSession.idToken ? currentSession.idToken.jwtToken : {}
  }

  const response = await fetch(`${API_BASE}${url}`, { credentials: 'include', ...init })
  if (!response.ok) {
    const errorCode = response.status
    if (errorCode === 401) {
      Auth.signOut()
      window.location.reload()
    }
    const errorResponse = await response.json()
    const errorMessage = getErrorMessage(errorResponse)
    const error = { code: errorCode, message: errorMessage }
    throw error
  }

  return await response.json()
}
