import { RouterLink, PriorityText } from '../../../../ui-components/cells'
import * as ROLE from '../../../../constants/roles'
import EditCell from '../../../../components/EditCell'
import { deleteDistrict } from '../../../../lib/api'

export const hasManagePermission = (role) => {
  return role === ROLE.SUPER_ADMIN
}

export const getDistrictColumns = (role) => {
  const districtColumns = [
    { title: 'Name', key: 'district', customCell: RouterLink },
    { title: 'State', key: 'state', customCell: PriorityText },
    { title: 'Website', key: 'website' }
  ]

  if (hasManagePermission(role)) {
    districtColumns.push({ title: 'Edit', key: 'edit', customCell: EditCell })
  }

  return districtColumns
}

export const createDistrictRowData = (district, role, onEdit, onAlert) => {
  const districtRows = {
    district: { name: district.name, id: district.organizationId, key: 'district' },
    state: district.state ? district.state : 'N/A',
    website: district.website || 'N/A'
  }

  if (hasManagePermission(role)) {
    districtRows.edit = {
      onEdit: () => onEdit(district),
      deleteData: {
        itemId: district.organizationId,
        itemName: district.name,
        onDelete: deleteDistrict,
        actionTitle: 'Delete',
        onAlert: onAlert,
        type: 'district'
      },
      downloadData: {
        link: `${process.env.REACT_APP_API_BASE_URL}/report/district-data-export/${district.organizationId}`,
        onAlert: onAlert
      }
    }
  }

  return districtRows
}
