import React, { useState, useEffect, useCallback } from 'react'
import Table from '../../../../components/Table'
import StudentDialog from '../../../../components/StudentDialog'
import { getStudentsByClassroom } from '../../../../lib/api'
import * as COPY from '../../copy'
import { hasManagePermission } from '../../../../utils/students'
import { getStudentColumns, createStudentData } from './helper'
import LoadingIndicator from '../../../../components/LoadingIndicator'
import { TableWrapper, CreateButton } from '../../../../ui-components/tabs'
import { EmptyStateWrapper } from '../../../../ui-components/viewStyles'
import { alphanumericSort } from '../../../../utils/sorts'
import posthog from 'posthog-js'

const StudentTab = ({ classroom, role, onAlert }) => {
  const [students, setStudents] = useState([])
  const [studentDialog, setStudentDialog] = useState({ isOpen: false, student: null })
  const [loading, setLoading] = useState({ isComplete: false, hasError: false })

  const loadStudents = useCallback(async () => {
    if (loading.hasError) return
    posthog.capture('Accessed student tab from classroom view', { userRole: role })

    try {
      const schoolStudents = await getStudentsByClassroom(classroom.participantGroupId)
      setStudents(schoolStudents.Participant)
      setLoading({ isComplete: true, hasError: false })
    } catch (err) {
      setLoading({ isComplete: true, hasError: true })
      onAlert('error', COPY.STUDENT_LOAD_ERROR)
    }
  }, [classroom, onAlert, loading.hasError, role])

  useEffect(() => {
    loadStudents()
  }, [loadStudents])

  const onEdit = (student) => {
    setStudentDialog({ isOpen: true, student: student })
  }

  const studentColumns = getStudentColumns(role)

  let studentRows = students.map((student) => createStudentData(student, role, onEdit, onAlert))

  studentRows = alphanumericSort(studentRows, 'student', 'name')

  const onStudentCreateOrUpdate = async () => {
    setStudentDialog({ isOpen: false, student: null })
    // reload students to get updated list
    await loadStudents()
  }

  return (
    <div>
      {studentDialog.isOpen && (
        <StudentDialog
          student={studentDialog.student}
          districtId={classroom.OrganizationUnit.Organization.organizationId}
          schoolId={classroom.organizationUnitId}
          role={role}
          defaultValues={{ classrooms: [classroom] }}
          onAction={onStudentCreateOrUpdate}
          onClose={() => setStudentDialog({ isOpen: false, student: null })}
          onAlert={onAlert}
        />
      )}
      {!loading.hasError && (
        <TableWrapper id='table'>
          {loading.isComplete ? (
            <>
              {hasManagePermission(role) && (
                <CreateButton
                  id='createStudentButton'
                  onClick={() => setStudentDialog({ isOpen: true, student: null })}
                  text='Create Student'
                />
              )}
              {students.length ? (
                <Table rows={studentRows} columns={studentColumns} />
              ) : (
                <EmptyStateWrapper id='emptyText'>{COPY.EMPTY_STATE_STUDENT}</EmptyStateWrapper>
              )}
            </>
          ) : (
            <LoadingIndicator />
          )}
        </TableWrapper>
      )}
    </div>
  )
}

export default StudentTab
