/**
 * probabilisticPercentage takes a number between 0.00 and 1.00 and formats it so that
 * it will contain a number between 1 and 99 with a percent symbol at the end.
 * (e.g. (0.777) => "78%")
 * @param {Number} score
 * @returns A formatted string between 1% and 99%
 */
export const probabilisticPercentage = (score) => {
  if (score === undefined || score === null) {
    return score
  }

  if (score < 0 || score > 1) {
    throw new Error('Probabalistic score out of bounds.')
  }

  score *= 100
  return `${Math.round(Math.min(99, Math.max(1, score)))}%`
}
