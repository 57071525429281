import React, { useState, useEffect, useCallback } from 'react'
import StudentDetailsTable from 'components/StudentDetailsTable'
import {
  getStudentsByClassroom,
  getCurrDistrictAssmntConfig,
  getClassroomRiskScores,
  getStudentMostRecentRAN
} from 'lib/api'
import { formatAndSortStudents } from '../../helper'
import * as COPY from '../../copy'
import LoadingIndicator from 'components/LoadingIndicator'
import ErrorBoundary from 'components/ErrorBoundary'
import { TableWrapper } from 'ui-components/tabs'
import { EmptyStateWrapper } from 'ui-components/viewStyles'
import { getCurrentTimePeriod } from 'utils/assessmentConfig'

const ResultsTab = ({ classroom, onAlert }) => {
  const [loading, setLoading] = useState({ isComplete: false, hasError: false })
  const [classroomResults, setClassroomResults] = useState(null)
  const [currTimePeriod, setCurrTimePeriod] = useState(null)
  const [ranScores, setRANScores] = useState(null)
  const [assmntConfig, setAssmntConfig] = useState(null)
  const [classroomGrades, setClassroomGrades] = useState(null)

  const getGradeAssessments = (classroomGrades, config) => {
    setClassroomGrades(classroomGrades)

    // collect as many grade assessment lists as you need by grade
    const gradeAssmntLists = {}
    classroomGrades.forEach((grade) => {
      const gradeList = config.GradeAssessmentLists.find((list) => list.grade === grade)
      gradeAssmntLists[grade] = gradeList.list
    })
    return gradeAssmntLists
  }

  const loadDistrictAssmntConfig = useCallback(async () => {
    try {
      const districtId = classroom.OrganizationUnit.organizationId
      return await getCurrDistrictAssmntConfig(districtId)
    } catch {
      throw Error('error: ' + COPY.RESULTS_LOAD_ERROR)
    }
  }, [classroom.OrganizationUnit.organizationId])

  // get each student's most recent RAN tests for the table
  const loadRANScoresByStudent = useCallback(async (results) => {
    try {
      const studentResultsList = Object.values(results[0].studentResults)
      const studentRANTests = studentResultsList.map(async (student) => {
        try {
          const mostRecentRanScore = await getStudentMostRecentRAN(student.studentName.participantId)
          return { participantId: student.studentName.participantId, mostRecentRanScore: mostRecentRanScore }
        } catch (err) {
          throw Error('error: ' + COPY.RESULTS_LOAD_ERROR)
        }
      })
      return Promise.all(studentRANTests).then((studentRANTests) => {
        const keyedRANTests = studentRANTests.reduce((RANObj, curr) => {
          const { participantId, mostRecentRanScore } = curr
          RANObj[participantId] = mostRecentRanScore
          return RANObj
        }, {})

        return keyedRANTests
      })
    } catch (err) {
      throw Error('error:' + COPY.RESULTS_LOAD_ERROR)
    }
  }, [])

  const loadClassroomResultsByPeriod = useCallback(async () => {
    try {
      const districtId = classroom.OrganizationUnit.organizationId
      const assmntConfig = await getCurrDistrictAssmntConfig(districtId)
      // get classroom risk scores by participantId
      const riskScores = await getClassroomRiskScores(classroom.participantGroupId)
      // promises to get student results for boy/moy/eoy
      const classroomResultPromises = assmntConfig.map((config) => {
        /* eslint-disable no-async-promise-executor */
        return new Promise(async (resolve, reject) => {
          try {
            const classroomInfo = await getStudentsByClassroom(
              classroom.participantGroupId,
              config.startDate,
              config.endDate
            )

            const { formattedStudents, sortedStudentIdsByGrade } = formatAndSortStudents(
              classroomInfo.Participant,
              riskScores
            )
            resolve({
              period: config.periodName,
              // format assessment lists
              // { K: kinder grade assessment list, PK: pk grade assessment list, ect}
              assmntList: getGradeAssessments(Object.keys(sortedStudentIdsByGrade), config),
              studentResults: formattedStudents,
              sortedStudentIds: sortedStudentIdsByGrade
            })
          } catch (err) {
            reject(err)
          }
        })
      })

      return await Promise.all(classroomResultPromises)
    } catch (err) {
      throw Error('error: ' + COPY.RESULTS_LOAD_ERROR)
    }
  }, [classroom])

  useEffect(() => {
    if (loading.hasError) return

    let isMounted = true
    ;(async () => {
      try {
        let results = await loadClassroomResultsByPeriod()
        const assmntConfig = await loadDistrictAssmntConfig()
        const timePeriod = getCurrentTimePeriod(assmntConfig)
        const ran = await loadRANScoresByStudent(results)
        if (isMounted && results) {
          // save results as an object indexed by period
          results = results.reduce((acc, curr) => {
            acc[curr.period] = {
              assmntList: curr.assmntList,
              studentResults: curr.studentResults,
              sortedStudentIds: curr.sortedStudentIds
            }
            return acc
          }, {})
          setCurrTimePeriod(timePeriod)
          setClassroomResults(results)
          setAssmntConfig(assmntConfig)
          setRANScores(ran)
          setLoading({ isComplete: true, hasError: false })
        }
      } catch (err) {
        setLoading({ isComplete: true, hasError: true })
        onAlert('error', err.message)
      }
    })()

    return () => {
      isMounted = false
    }
  }, [loadClassroomResultsByPeriod, loadDistrictAssmntConfig, loadRANScoresByStudent, loading.hasError, onAlert])

  const hasStudents = classroomResults && Object.values(classroomResults.BOY.studentResults).length
  return (
    <div>
      {!loading.hasError && (
        <TableWrapper>
          {loading.isComplete && !loading.hasError ? (
            <div>
              {
                // check if students (not reliant on scores) are populated in the results array
                hasStudents ? (
                  <ErrorBoundary fallback={() => <div>{COPY.CLASSROOM_LOAD_ERROR}</div>}>
                    <StudentDetailsTable
                      classroomName={classroom.name}
                      classroomGrades={classroomGrades}
                      classroomResults={classroomResults}
                      currTimePeriod={currTimePeriod}
                      assmntConfig={assmntConfig}
                      ranScores={ranScores}
                    />
                  </ErrorBoundary>
                ) : (
                  <EmptyStateWrapper id='emptyText'>{COPY.EMPTY_STATE_RESULT}</EmptyStateWrapper>
                )
              }
            </div>
          ) : (
            <LoadingIndicator />
          )}
        </TableWrapper>
      )}
    </div>
  )
}

export default ResultsTab
