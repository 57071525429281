import React from 'react'
import * as ROLE from '../../constants/roles'
import { BreadcrumbWrapper, BreadcrumbLink, BreadcrumbText } from '../../ui-components/breadcrumbs'
import { useSessionStore } from '../../stores/SessionStore'

const InfoPageBreadcrumbs = ({ title }) => {
  const { state } = useSessionStore()
  const { districtId, role, schoolIds } = state

  if (role) {
    const isMultiSchoolUser = schoolIds?.length > 1
    if (role === ROLE.SUPER_ADMIN) {
      return (
        <BreadcrumbWrapper>
          <BreadcrumbLink to='/super-admin'>Districts</BreadcrumbLink>
          <BreadcrumbText>{title}</BreadcrumbText>
        </BreadcrumbWrapper>
      )
    } else if ([ROLE.DISTRICT_ADMIN, ROLE.SCHOOL_ADMIN].includes(role) || isMultiSchoolUser) {
      return (
        <BreadcrumbWrapper>
          <BreadcrumbLink to={`/district/${districtId}`}>Schools</BreadcrumbLink>
          <BreadcrumbText>{title}</BreadcrumbText>
        </BreadcrumbWrapper>
      )
    } else {
      return (
        <BreadcrumbWrapper>
          <BreadcrumbLink to={`/school/${schoolIds[0]}`}>Classrooms</BreadcrumbLink>
          <BreadcrumbText>{title}</BreadcrumbText>
        </BreadcrumbWrapper>
      )
    }
  }
  return null
}

export default InfoPageBreadcrumbs
