import styled from 'styled-components'
import React from 'react'
import MaterialButton from '@material-ui/core/Button'
import {
  BUTTON_BACKGROUND,
  BUTTON_BACKGROUND_HOVER,
  BUTTON_TEXT_HOVER,
  BUTTON_TEXT_WHITE,
  BUTTON_SECONDARY_BACKGROUND,
  BUTTON_SECONDARY_TEXT,
  BUTTON_SECONDARY_BORDER,
  BUTTON_SECONDARY_TEXT_HOVER,
  BUTTON_SECONDARY_BACKGROUND_HOVER
} from '../../constants/colors'
import AddIcon from '@material-ui/icons/Add'

// large - Primary
export const LargeButton = styled(MaterialButton)({
  '&.MuiButton-root': {
    border: 0,
    borderRadius: '0.9rem',
    width: '100%',
    height: '3.6rem',
    backgroundColor: `${BUTTON_BACKGROUND}`,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: `${BUTTON_BACKGROUND_HOVER}`,
      color: `${BUTTON_TEXT_HOVER}`
    }
  },
  '&.MuiButtonBase-root': {
    color: `${BUTTON_TEXT_WHITE}`,
    fontFamily: 'Quicksand',
    fontWeight: 600,
    fontSize: '1rem'
  }
})

// standard - Primary
export const StandardButton = styled(MaterialButton)({
  '&.MuiButton-root': {
    border: 0,
    borderRadius: '0.9rem',
    height: '2.6rem',
    width: '100%',
    backgroundColor: `${BUTTON_BACKGROUND}`,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: `${BUTTON_BACKGROUND_HOVER}`,
      color: `${BUTTON_TEXT_HOVER}`
    }
  },
  '&.MuiButtonBase-root': {
    color: `${BUTTON_TEXT_WHITE}`,
    fontFamily: 'Quicksand',
    fontWeight: 600,
    fontSize: '.875rem'
  }
})

// standard - Seconday
export const SecondaryButton = styled(MaterialButton)({
  '&.MuiButton-root': {
    border: `solid 2px ${BUTTON_SECONDARY_BORDER}`,
    borderRadius: '0.9rem',
    height: '2.6rem',
    width: '100%',
    backgroundColor: `${BUTTON_SECONDARY_BACKGROUND}`,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: `${BUTTON_SECONDARY_BACKGROUND_HOVER}`,
      color: `${BUTTON_SECONDARY_TEXT_HOVER}`
    }
  },
  '&.MuiButtonBase-root': {
    color: `${BUTTON_SECONDARY_TEXT}`,
    fontFamily: 'Quicksand',
    fontWeight: 600,
    fontSize: '.875rem'
  }
})

export const LargeAddButton = (props) => (
  <LargeButton id={props.id} onClick={props.onClick}>
    <AddIcon fontSize='small' style={{ paddingRight: 5 }} />
    {props.text}
  </LargeButton>
)

export const StandardAddButton = (props) => (
  <StandardButton id={props.id} onClick={props.onClick}>
    <AddIcon fontSize='small' style={{ paddingRight: 5 }} />
    {props.text}
  </StandardButton>
)

export const ButtonLink = styled.a({
  display: 'flex',
  justifyContent: 'center',
  marginLeft: '1rem',
  paddingLeft: '2rem',
  paddingRight: '2rem',
  alignItems: 'center',
  textDecoration: 'none',
  color: BUTTON_TEXT_WHITE,
  fontFamily: 'Quicksand',
  fontWeight: 600,
  fontSize: '0.875rem',
  border: 0,
  borderRadius: '0.9rem',
  height: '2.6rem',
  width: '11.25rem',
  backgroundColor: `${BUTTON_BACKGROUND}`,
  marginBottom: '2.25rem',
  '&:hover': {
    cursor: 'pointer',
    backgroundColor: `${BUTTON_BACKGROUND_HOVER}`,
    color: `${BUTTON_TEXT_HOVER}`
  },
  '&:visited': {
    color: BUTTON_TEXT_WHITE
  }
})
