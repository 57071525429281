// @ts-check
import * as React from 'react'
import percentileDistribution from '../../percentileDistribution.png'
import { Table, TableContainer, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core'
import { EOY } from 'constants/timePeriods'

const KindergartenCopyV2 = ({ timePeriod }) => (
  <>
    <p>
      The EarlyBird Early Literacy Assessment was administered individually to our Kindergarten classroom students. This
      report provides the results of the assessment for your student.
    </p>
    <p>
      EarlyBird is a new, early literacy screener, developed by experts at Boston Children&apos;s Hospital, and used to
      identify areas of strengths and weaknesses for individual students. The screener is a child-friendly game, played
      on an iPad/Chromebook, taking approximately 45 minutes. The student interacts with the character Pip, traveling
      through the city on a fun adventure, while assessing the critical skills found to be predictive of later reading
      success. The assessment moves through each subtest in the game, adapting the level of difficulty according to your
      child&apos;s individual performance. Student responses are captured and scored real-time within the assessment.
    </p>
    <p>
      The screener assesses a range of skills and tasks that are important for identifying or predicting issues with
      reading:
    </p>
    <ul>
      <li>Phonemic Awareness</li>
      <li>Phonics</li>
      <li>Fluency</li>
      <li>Vocabulary</li>
      <li>Comprehension</li>
    </ul>
    <p>This chart provides more information on each of the subtests in the screener:</p>

    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Component</TableCell>
            <TableCell>Subtest</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>Phonemic Awareness</TableCell>
            <TableCell>
              <p>
                <b>Rhyming:</b> Student listens to three words with pictures and chooses the two words that have the
                same ending sound.
              </p>
              <p>
                <b>First Sound Matching:</b> Student listens to the first sound of a word and then identifies a word
                with the same first sound from three choices.
              </p>
              <p>
                <b>Blending:</b> Student listens to two or more sounds and then blends the sounds together to form a
                spoken word.
              </p>
              <p>
                <b>Nonword Repetition:</b> Student listens to a one-to-five syllable word and repeats the word exactly.
              </p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Phonics</TableCell>
            <TableCell>
              <p>
                <b>Letter Name:</b> Students are shown uppercase letters one at a time and asked, “What is the name of
                this letter?” Students verbally state the letter name.
              </p>
              <p>
                <b>Letter Sound:</b> Students are shown uppercase letters one at a time and asked, “What sound does this
                letter make?” Students verbally state the letter sound.
              </p>
              {timePeriod === EOY && (
                <p>
                  <b>Nonword Reading:</b> Student is presented with decodable nonsense words, one at a time, to read out
                  loud and with accuracy.
                </p>
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Fluency</TableCell>
            <TableCell>
              {timePeriod === EOY && (
                <p>
                  <b>Word Reading:</b> Student is presented with high frequency words, one at a time, to read out loud
                  and with accuracy.
                </p>
              )}
              <p>
                <b>Object RAN:</b> Student is timed while naming familiar objects out loud, moving across multiple rows
                from left to right, as quickly as they can.
              </p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Vocabulary</TableCell>
            <TableCell>
              <p>
                <b>Vocabulary:</b> Student listens to a word and identifies it from four pictures.
              </p>
              <p>
                <b>Word Matching:</b> Student listens to three words and identifies two that have a similar meaning.
              </p>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Comprehension</TableCell>
            <TableCell>
              <p>
                <b>Oral Sentence:</b> Student listens to a sentence and identifies the one out of four pictures
                presented that best represents the sentence&apos;s meaning.
              </p>
              <p>
                <b>Follow Directions:</b> Student listens to a single or multiple step sentence, following the
                directions to select the appropriate objects.
              </p>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
    <p>
      EarlyBird results are presented in normed percentiles. Percentiles are expressed as a number between 1 and 99,
      with 50 being the average. This means, if a student scored in the 70th percentile, he/she/they performed as well
      as or better than 70% of the students in the normed sample.
    </p>
    <div>
      <img src={percentileDistribution} alt='Percentile distribution graph' width={600} />
    </div>
    <p>
      Remember that each child has their own unique learning profile and may develop at a different rate than
      his/her/their peers. If you have any questions about this report, or need more information, please contact your
      child&apos;s classroom teacher.
    </p>
  </>
)

export default KindergartenCopyV2
