import { BOY, MOY, EOY } from 'constants/timePeriods'

export const hasSoundSymbolCorrNorms = (timePeriod) => {
  switch (timePeriod) {
    case BOY:
      return process.env.REACT_APP_SOUND_SYMBOL_CORR_BOY_NORMS === 'true'
    case MOY:
      return process.env.REACT_APP_SOUND_SYMBOL_CORR_MOY_NORMS === 'true'
    case EOY:
      return process.env.REACT_APP_SOUND_SYMBOL_CORR_EOY_NORMS === 'true'
  }
}
