import React from 'react'
import { InfoWrapper, Row, Name, Chip } from './styles'

const UserInfoCell = ({ cellData }) => {
  const { name, email, isPending } = cellData

  return (
    <InfoWrapper>
      <Row>
        <Name>{name}</Name>
        {isPending ? <Chip label='Pending' size='small' /> : null}
      </Row>
      <div>{email}</div>
    </InfoWrapper>
  )
}

export default UserInfoCell
