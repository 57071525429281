export const getOrdinalNumber = (number) => {
  if (number === null || number === undefined) {
    return number
  }
  // ensure that the number is between 1 and 99
  const roundedNum = Math.round(Math.min(99, Math.max(1, number)))

  const ordinals = ['th', 'st', 'nd', 'rd']
  const value = roundedNum % 100
  return roundedNum + (ordinals[(value - 20) % 10] || ordinals[value] || ordinals[0])
}
