import styled from 'styled-components'
import { TableRow as MaterialTableRow, TableCell as MaterialTableCell } from '@mui/material'
import * as C from 'constants/colors'

export const TableContainer = styled.div`
  flex-grow: 0;
  overflow-x: auto;
`
export const Table = styled.div`
  display: inline-table;
  border: solid 1px ${C.ITEM_INVENTORY_TABLE_BORDER};
  border-radius: 15px;
  border-spacing: 1px;
  width: 100%;
`

export const TableCell = styled(MaterialTableCell)`
  &.MuiTableCell-root {
    padding: 0;
  }

  // internal border
  &:not(:last-child) {
    border-right: solid 1px ${C.ITEM_INVENTORY_TABLE_BORDER};
  }

  &.titleHeader,
  &.titleBody {
    width: 6.3rem;
    text-indent: 1rem;
  }

  &.body,
  &.letterHeader {
    height: 60px;
    min-width: 48px;
  }

  &.incorrect {
    background-color: ${C.ITEM_INVENTORY_INCORRECT_BG};
    box-shadow: 0 0 0 1px ${C.ITEM_INVENTORY_INCORRECT_BORDER} inset;
  }

  &.disabled {
    background-color: ${C.ITEM_INVENTORY_DISABLED_BG};
  }
`

export const TableRow = styled(MaterialTableRow)`
  //styling border radius for all corners
  &:first-child {
    ${TableCell}:first-child {
      /* Curved border radius for the first cell in each row */
      border-top-left-radius: 15px;
    }

    ${TableCell}:last-child {
      /* Curved border radius for the last cell in each row */
      border-top-right-radius: 15px;
    }
  }

  &:last-child {
    ${TableCell}:first-child {
      /* Curved border radius for the first cell in each row */
      border-bottom-left-radius: 15px;
    }

    ${TableCell}:last-child {
      /* Curved border radius for the last cell in each row */
      border-bottom-right-radius: 15px;
    }
  }

  //styling bottom borders to be darker than default MUI table cell border
  &:not(:last-of-type) {
    ${TableCell} {
      border-bottom: solid 1px ${C.ITEM_INVENTORY_TABLE_BORDER};
    }
  }

  &:first-of-type {
    ${TableCell} {
      border-bottom: solid 2.5px ${C.ITEM_INVENTORY_TABLE_BORDER};
    }
  }
`

export const TextField = styled.span`
  color: ${C.ITEM_INVENTORY_TEXT};

  &.letterText {
    display: flex;
    justify-content: center;
    flex-grow: 0;
    font-family: Lexend;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }

  &.titleText {
    flex-grow: 0;
    margin: 0 5px 0 0;
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
  }
`
