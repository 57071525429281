import React, { useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import InfoPageBreadcrumbs from '../../components/InfoPageBreadcrumbs'
import { ContentView } from '../../ui-components/backgrounds'
import { Header, Body, ExternalLink } from '../../ui-components/viewStyles'
import { useSessionStore, actionTypes } from '../../stores/SessionStore'
import * as COPY from './copy'

const AboutEarlyBird = () => {
  const { state, dispatch } = useSessionStore()
  const { viewHeader } = state

  useEffect(() => {
    if (viewHeader !== '') {
      dispatch({ type: actionTypes.UPDATE_FIELD, field: 'viewHeader', data: '' })
    }
  }, [viewHeader, dispatch])

  const isViewLoaded = viewHeader === ''

  return (
    <div>
      {isViewLoaded && (
        <Grid container justify='center' spacing={0}>
          <Grid item xs={11}>
            <InfoPageBreadcrumbs title={COPY.ABOUT_EB_HEADER} />
            <ContentView>
              <Header>{COPY.ABOUT_EB_HEADER}</Header>
              <Body>
                {COPY.ABOUT_EB_BODY}
                <ExternalLink href={COPY.EB_LINK_ADDRESS} rel='noreferrer' target='_blank'>
                  {COPY.EB_LINK_TEXT}
                </ExternalLink>
                .
              </Body>
            </ContentView>
          </Grid>
        </Grid>
      )}
    </div>
  )
}

export default AboutEarlyBird
