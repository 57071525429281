import { filterAssessmentGroups } from '../../utils/assessments'
import AssessmentCell from './components/AssessmentCell'
import * as GRADE from 'constants/grades'
import * as RULES from 'constants/displayRules'

// getColUnits returns an object that associates each column with its relevant units
export const getColUnits = (studentDetailColumns, classroomGrades, timePeriod) => {
  return studentDetailColumns.reduce((obj, column) => {
    if (['studentInfo', 'predictiveProfile'].includes(column.key)) {
      return {
        ...obj,
        [column.key]: []
      }
    }

    // get the column unit for each grade in the classroom
    const units = new Set()
    column.subColumns.forEach((subcol) => {
      GRADE.GRADE_ORDER.forEach((grade) => {
        if (classroomGrades.includes(grade)) {
          const gradeUnit = RULES.DISPLAY_RULES[grade][subcol.key][timePeriod]
          if (gradeUnit && gradeUnit !== RULES.TBD) {
            units.add(gradeUnit)
          }
        }
      })
    })

    return {
      ...obj,
      [column.key]: [...units]
    }
  }, {})
}

// getFormattedColUnits formats units array to be a readable string to display in the classroom table
export const getFormattedColUnits = (units) => {
  if (!units.length) {
    return null
  }

  const unitText = units.map((unit) => RULES.DISPLAY_TEXT[unit]).join(' or ')
  return `(${unitText})`
}

// getAllUnits returns a list of units that span across all the columns in the table
// used by the table key to determine which units to display
export const getAllUnits = (colUnits) => {
  return [...new Set(Object.values(colUnits).flat(1))]
}

// getPredictiveProfileKeys returns an array of the predictive profile columns that are displayed in the classroom table
// used by the table key to determine which predictice profile values to display
export const getPredictiveProfileKeys = (studentDetailsCol) => {
  const predictiveProfile = studentDetailsCol.find((col) => col.key === 'predictiveProfile')
  return predictiveProfile ? predictiveProfile.subColumns.map((col) => col.key) : []
}

// a cell has a divider border if it is the last cell in a column group
export const hasCellDivider = (fullColumns, columns, colIdx, cellIdx) => {
  // if the cell is the last cell in the column and the column isn't the last column
  return cellIdx === columns.length - 1 && colIdx !== fullColumns.length - 1
}

export const getStickyCols = (key) => {
  switch (key) {
    case 'studentName':
      return 'studentNameStickyHeader'
    case 'grade':
      return 'gradeStickyHeader'
    default:
      return ''
  }
}

export const getColWidth = (assmntList) => {
  const assmntCount = assmntList.split(',').length
  return assmntCount >= 10 ? 'tenColWidth' : 'colWidth'
}

export const getHighestGrade = (classroomGrades) => {
  const gradesHighToLow = [...GRADE.GRADE_ORDER]
  gradesHighToLow.reverse()

  for (const idx in gradesHighToLow) {
    const grade = gradesHighToLow[idx]
    if (classroomGrades.includes(grade)) {
      return grade
    }
  }
}

export const getGradeDivider = (studentIdx, studentList, isHighestGradeInClassroom) => {
  const isLastStudentInGrade = studentIdx === studentList.length - 1
  const hasGradeDivider = isLastStudentInGrade && !isHighestGradeInClassroom
  return hasGradeDivider ? 'gradeDivider' : ''
}

const getSubtestInfo = (assessmentId, classroomGrades, timePeriod) => {
  return null
}

export const getAssessmentCols = (assmntList, classroomGrades, timePeriod) => {
  // only display assessments relevant to time period
  assmntList = filterAssessmentGroups(assmntList)
  return assmntList.map((group) => {
    return {
      ...group,
      // adds custom cell to all assessment subcolumns
      subColumns: group.assessments.map((assessment) => {
        return {
          ...assessment,
          customCell: AssessmentCell,
          info: getSubtestInfo(assessment.key, classroomGrades, timePeriod)
        }
      })
    }
  })
}

// classroomHasScoredLNLS returns true if at least one student in the classroom has a LN or LS score
export const classroomHasScoredLNLS = (classroomResults) => {
  for (const studentId in classroomResults) {
    const studentResults = classroomResults[studentId]
    if (studentResults?.TEST_LETTER_NAMES?.isComplete || studentResults?.TEST_LETTER_SOUNDS?.isComplete) {
      return true
    }
  }
  return false
}
