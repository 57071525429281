import styled from 'styled-components'
import * as C from 'constants/colors'

export const Wrapper = styled.div`
  display: inline-block;
`

export const Circle = styled.div`
  width: 21.3px;
  height: 21.3px;
  border-radius: 50%;
  padding: 2.7px 2.7px 2.7px 2.7px;
  opacity: 0.8;
  background-color: ${C.SCORE_ENTRY_ICON_BG};
  display: flex;
  justify-content: center;
  align-items: center;
`
