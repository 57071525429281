import styled from 'styled-components'
import { VIEW_HEADER_TEXT } from '../../../../constants/colors'

export const Header = styled.div`
  margin: 0rem 2rem 0.375rem 4.6rem;
  font-family: Quicksand;
  font-size: 1.5rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.96;
  letter-spacing: normal;
  text-align: left;
  white-space: nowrap;
  color: ${VIEW_HEADER_TEXT};
`
