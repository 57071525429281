// tracing.js
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http'
import { WebTracerProvider, BatchSpanProcessor } from '@opentelemetry/sdk-trace-web'
import { ZoneContextManager } from '@opentelemetry/context-zone'
import { Resource } from '@opentelemetry/resources'
import { SemanticResourceAttributes } from '@opentelemetry/semantic-conventions'
import { registerInstrumentations } from '@opentelemetry/instrumentation'
import { getWebAutoInstrumentations } from '@opentelemetry/auto-instrumentations-web'

const TRACE_EXPORTER_URL = `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_HONEYCOMB_TRACE_EXPORTER_PATH}`

const exporter = new OTLPTraceExporter({
  url: TRACE_EXPORTER_URL,
  headers: {
    'x-honeycomb-team': process.env.REACT_APP_HONEYCOMB_SEND
  }
})

// The TracerProvider is the core library for creating traces
const provider = new WebTracerProvider({
  resource: new Resource({
    [SemanticResourceAttributes.SERVICE_NAME]: 'dashboard'
  })
})

// The processor sorts through data as it comes in, before it is sent to the exporter
provider.addSpanProcessor(new BatchSpanProcessor(exporter))

// A context manager allows OTel to keep the context of function calls across async functions
// ensuring you don't have disconnected traces
provider.register({
  contextManager: new ZoneContextManager()
})

registerInstrumentations({
  instrumentations: [
    getWebAutoInstrumentations({
      '@opentelemetry/instrumentation-xml-http-request': {
        propagateTraceHeaderCorsUrls: [/api.*earlybirdeducation/, /localhost/]
      },
      '@opentelemetry/instrumentation-fetch': {
        propagateTraceHeaderCorsUrls: [/api.*earlybirdeducation/, /localhost/]
      },
      '@opentelemetry/instrumentation-user-interaction': {
        enabled: false
      }
    })
  ]
})
