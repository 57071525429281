import styled from 'styled-components'

export const ViewWrapper = styled.div`
  height: 100vh;
  width: 100vw;
`

export const ContentView = styled.div({
  top: '11.25rem',
  position: 'absolute',
  width: '91%'
})
