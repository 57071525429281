import React from 'react'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import { TableWrapper, TableRowHead, TableRowBody, TableContainer, TableCell } from './styles'

const Table = ({ columns, rows, test = 'false' }) => {
  // accessing the keys from the columns to correspond with the rows
  const columnKeys = columns.map((column) => column.key)

  return (
    <>
      <TableContainer id='table'>
        <TableWrapper aria-label='simple table'>
          <TableHead>
            <TableRowHead>
              {columns.map((column) => {
                return (
                  <TableCell test={test} className='header' key={column.key}>
                    {column.title}
                  </TableCell>
                )
              })}
            </TableRowHead>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRowBody key={rows.indexOf(row)}>
                {columnKeys.map((columnKey) => {
                  // find the column that matches this columnKey to check if it has a customCell (i.e. link, button, ect)
                  // if it has a customCell, render that & pass data props otherwise render plain text
                  const columnCell = columns.find((column) => column.key === columnKey)
                  return (
                    /* eslint indent: off */
                    <TableCell test={test} className={columnKey} key={columnKey}>
                      {columnCell.customCell ? <columnCell.customCell cellData={row[columnKey]} /> : row[columnKey]}
                    </TableCell>
                  )
                })}
              </TableRowBody>
            ))}
          </TableBody>
        </TableWrapper>
      </TableContainer>
    </>
  )
}

export default Table
