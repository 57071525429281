import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import MaterialButton from '@material-ui/core/Button'
import {
  AUTH_MODAL_BACKGROUND,
  BUTTON_BACKGROUND,
  BUTTON_BACKGROUND_HOVER,
  BUTTON_TEXT_HOVER,
  TABLE_ROW_HOVER
} from '../../constants/colors'
import { AuthSubHeader } from '../../ui-components/authStyles'

export const ButtonWrapper = styled.div({
  alignSelf: 'flex-end',
  marginTop: '1rem',
  width: '12rem'
})

export const CloseButton = styled(IconButton)({
  position: 'absolute!important',
  right: '10px',
  top: '10px'
})

export const DropzoneWrapper = styled.span({
  display: 'flex',
  flexDirection: 'column',
  minWidth: '427px',
  '& .MuiDropzoneArea-root': {
    borderColor: BUTTON_BACKGROUND,
    backgroundColor: TABLE_ROW_HOVER,
    minHeight: '200px',
    padding: '1rem'
  }
})

export const InstructionsButton = styled(MaterialButton)({
  '&.MuiButton-root': {
    border: 0,
    borderRadius: '0.9rem',
    height: '2.6rem',
    margin: '1rem 0',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: `${BUTTON_BACKGROUND_HOVER}`,
      color: `${BUTTON_TEXT_HOVER}`
    }
  },
  '&.MuiButtonBase-root': {
    color: `${BUTTON_BACKGROUND}`,
    fontFamily: 'Quicksand',
    fontWeight: 600,
    fontSize: '.875rem'
  }
})

export const RosterFormWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  width: '27rem',
  paddingTop: '2.5rem',
  paddingBottom: '2.5rem',
  paddingRight: '1rem',
  paddingLeft: '1rem',
  backgroundColor: AUTH_MODAL_BACKGROUND,
  borderRadius: '1rem'
})
export const SubHeader = styled(AuthSubHeader)({
  marginBottom: '0'
})
