import { OBJ_RAN_ID, LETTER_RAN_ID, RER_LETTER_RAN_ID } from '../constants/assessments'

export const ASSETS_PATH = process.env.REACT_APP_ASSETS_BASE_URL

// icons
export const LOGIN_PIP = `${ASSETS_PATH}/images/login-pip.png`

// files

export const PRIVACY_POLICY = `${ASSETS_PATH}/privacy_policy.txt`

// images
export const OBJ_RAN_STIMULI = `${ASSETS_PATH}/${OBJ_RAN_ID}/visual/RAN_Stimuli.png`
export const OBJ_RAN_STIMULI_PK = `${ASSETS_PATH}/${OBJ_RAN_ID}/visual/RAN_Stimuli_PK.png`
export const LETTER_RAN_STIMULI = `${ASSETS_PATH}/${LETTER_RAN_ID}/visual/Letter_RAN_Stimuli.png`
export const RER_LETTER_RAN_STIMULI = `${ASSETS_PATH}/${RER_LETTER_RAN_ID}/visual/stimuli-reference.png`

// logo
export const EB_LOGO = `${ASSETS_PATH}/images/EarlyBirdLogo.png`
