import styled from 'styled-components'
import * as COLOR from '../../../constants/colors'

export const RatioScoreContainer = styled.div({
  fontFamily: 'Inter',
  color: COLOR.RATIO_SCORE_TEXT,
  textDecoration: 'underline',
  textUnderlineOffset: '3px',

  '&:hover': {
    cursor: 'pointer',
    color: COLOR.RATIO_SCORE_TEXT_HOVER
  }
})
