import styled from 'styled-components'
import { TableCell as TC } from '../../styles'
import * as COLOR from '../../../../constants/colors'

const sliderWidth = '17.063rem'

export const CellWrapper = styled.div({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  minWidth: '19rem'
})

export const TableCell = styled(TC)({
  justifyContent: 'center',
  alignItems: 'center'
})

export const SliderWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  position: 'relative',
  zIndex: 1,
  border: `1px solid ${COLOR.SLIDER_BORDER}`
})

export const Rectangle = styled.div({
  height: '1rem'
})

export const SliderGradient = styled(Rectangle)({
  background: `linear-gradient(0.25turn, ${COLOR.SLIDER_DARK}, ${COLOR.SLIDER_MEDIUM} 10%, ${COLOR.SLIDER_LIGHT}, ${COLOR.SLIDER_WHITE})`,
  width: sliderWidth
})

export const Circle = styled.div({
  width: '1.5rem',
  height: '1.5rem',
  borderRadius: '50%',
  border: '1px solid #475eb6',
  position: 'absolute',
  top: 5,
  transform: 'translate(-50%, -40%)',
  zIndex: 2,
  left: (props) => {
    const roundedPercentile = Math.round(props.percentile)
    const position = (273 * roundedPercentile) / 100 // length of slider is 273px
    return `${position}px`
  }
})

export const Marker = styled(Circle)({
  backgroundColor: COLOR.SLIDER_MARKER
})
