import React, { useRef } from 'react'
import { useAuth, useCurrentUserQuery } from 'auth'
import { actionTypes, useSessionStore } from '../../stores/SessionStore'
import { Link } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import { ListItem, ListItemText } from '@material-ui/core'
import { HamburgerNav } from './styles'
import * as COPY from './copy'
import { TEACHER_RESOURCES_LINK } from '../../constants/links'
import LinkIcon from '@material-ui/icons/Link'
import { DownloadCSVIcon } from 'ui-components/icons'
import { useFeatureFlagEnabled } from 'posthog-js/react'

const HamburgerMenu = ({ isMenuOpen, handleMenuClose }) => {
  const { dispatch, state } = useSessionStore()
  const { firstName } = state
  const { signOut } = useAuth()
  const currentUserQuery = useCurrentUserQuery()

  const logout = async () => {
    try {
      await signOut()
      dispatch({ type: actionTypes.LOGOUT })
    } catch (err) {
      alert(`Error: ${err.message}`)
    }
  }

  const DownloadLink = (props) => {
    const hiddenDownloadRef = useRef()
    const url = `${process.env.REACT_APP_API_BASE_URL}/dashboard/resources/${props.filename}/download`

    const download = async (e, url, onError) => {
      e.preventDefault()
      try {
        await fetch(url, {
          method: 'GET',
          credentials: 'include'
        })
          .then((res) => {
            if (!res.ok) {
              throw new Error('Network error')
            }
            return res
          })
          .then((res) => res.blob())
          .then((blob) => {
            const file = window.URL.createObjectURL(blob)
            hiddenDownloadRef.current.setAttribute('href', file)
            hiddenDownloadRef.current.setAttribute('download', props.filename)
            hiddenDownloadRef.current.click()
          })
      } catch (err) {
        onError(err.message)
      }
    }

    const handleError = (err) => alert(err)

    return (
      <>
        <a href='#' onClick={(e) => download(e, url, handleError)}>
          {props.text} <DownloadCSVIcon />
        </a>
        <a style={{ display: 'hidden' }} ref={hiddenDownloadRef} />
      </>
    )
  }

  // For cleanup, just remove this component totally
  const DownloadLinksFlag = (props) => {
    const enabled = useFeatureFlagEnabled('teacher-resources-download-links')

    return enabled ? <>{props.children}</> : null
  }

  return (
    <Grid item xs={2}>
      <HamburgerNav open={isMenuOpen}>
        <ListItem className='welcome'>
          <ListItemText disableTypography primary={`Welcome, ${firstName}`} />
          <ListItemText disableTypography className='email-cont'>
            <p id='user-email' title={currentUserQuery.data?.email}>
              {currentUserQuery.data?.email}
            </p>
          </ListItemText>
        </ListItem>
        <div className='welcome-spacer'></div>
        <ListItem id='dividerAbove' onClick={handleMenuClose}>
          <a target='_blank' rel='noreferrer' href={COPY.DOCUMENTATION_LINK}>
            <ListItemText disableTypography primary={COPY.DOCUMENTATION_TEXT} />
            <LinkIcon />
          </a>
        </ListItem>
        <ListItem className='dividerAbove' onClick={handleMenuClose}>
          <a target='_blank' rel='noreferrer' href={TEACHER_RESOURCES_LINK}>
            <ListItemText disableTypography primary={COPY.TEACHER_RESOURCES_TEXT} />
            <LinkIcon />
          </a>
        </ListItem>
        <ListItem className='dividerAbove' onClick={handleMenuClose} component={Link} to='/customer-support'>
          <ListItemText disableTypography primary={COPY.CUSTOMER_SUPPORT_TEXT} />
        </ListItem>
        <ListItem className='dividerAbove' onClick={handleMenuClose} component={Link} to='/about-earlybird'>
          <ListItemText disableTypography primary={COPY.ABOUT_EB_TEXT} />
        </ListItem>
        <div className='welcome-spacer' />
        <DownloadLinksFlag>
          <ListItem className='dividerAbove'>
            <DownloadLink text='PreK Test Materials' filename='PK EarlyBird Supplemental Test Materials.zip' />
          </ListItem>
          <ListItem className='dividerAbove'>
            <DownloadLink text='Kindergarten Test Materials' filename='K EarlyBird Supplemental Test Materials.zip' />
          </ListItem>
          <ListItem className='dividerAbove'>
            <DownloadLink text='Grade 1 Test Materials' filename='Grade 1 EarlyBird Supplemental Test Materials.zip' />
          </ListItem>
          <ListItem className='dividerAbove'>
            <DownloadLink text='Grade 2 Test Materials' filename='Grade 2 EarlyBird Supplemental Test Materials.zip' />
          </ListItem>
        </DownloadLinksFlag>
        <ListItem className='logout' alt='logout' data-testid='logout' button onClick={logout}>
          <ListItemText disableTypography primary='Logout' />
        </ListItem>
      </HamburgerNav>
    </Grid>
  )
}

export default HamburgerMenu
