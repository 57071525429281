import React, { useState } from 'react'
import { Navigation, HamburgerIcon } from './styles'
import Grid from '@material-ui/core/Grid'
import MenuIcon from '@material-ui/icons/Menu'
import CloseIcon from '@material-ui/icons/Close'
import HamburgerMenu from '../HamburgerMenu'
import Logo from './components/Logo'
import ViewHeader from './components/ViewHeader'
import { useLocation } from 'react-router-dom'

const NavBar = () => {
  const location = useLocation()
  const [isMenuOpen, setMenuOpen] = useState(false)

  const handleMenuClick = (event) => {
    setMenuOpen(!isMenuOpen)
  }

  const handleMenuClose = () => {
    setMenuOpen(false)
  }

  return (
    <Grid container justify='center' spacing={0}>
      <Grid item xs={11}>
        <Navigation>
          <HamburgerIcon id={isMenuOpen ? 'menuOpen' : 'menuClosed'} onClick={handleMenuClick}>
            {isMenuOpen ? (
              <CloseIcon data-testid='closeButton' fontSize='large' />
            ) : (
              <MenuIcon data-testid='menuButton' fontSize='large' />
            )}
          </HamburgerIcon>
          <Logo />
          <ViewHeader location={location} />
        </Navigation>
        <HamburgerMenu handleMenuClose={handleMenuClose} isMenuOpen={isMenuOpen} />
      </Grid>
    </Grid>
  )
}

export default NavBar
