import React, { useState, useEffect } from 'react'
import {
  TableRow,
  TableCell,
  TableContainer,
  Table,
  TableBody,
  StudentTableHeader,
  TimePeriodHeader,
  EmptySliderCell
} from '../../styles'
import { SliderScaleWrapper, SliderScale, SliderScaleNumber } from './styles'
import TableHead from '@material-ui/core/TableHead'
import PredictiveProfileToken from '../PredictiveProfileToken'
import { getScoreCellClasses } from '../../helper'
import { getStudentRiskScores } from 'lib/api'
import { getPredictiveProfileDisplay } from 'utils/display'
import { BOY, MOY, EOY } from 'constants/timePeriods'
import { DYSLEXIA, PREDICTIVE_PROFILE_DISPLAY_NAMES, PWR, SINGLE_COL_PWR } from 'constants/assessments'

const PredictiveProfileTable = ({ student, predictiveProfileRows, selectedTimePeriod, setSelectedTimePeriod }) => {
  const [riskScores, setRiskScores] = useState(null)

  // get risk scores for each time period
  useEffect(() => {
    let isMounted = true
    ;(async () => {
      const riskScores = await getStudentRiskScores(student.participantId)
      if (isMounted) {
        setRiskScores(riskScores)
      }
    })()
    return () => {
      isMounted = false
    }
  }, [student.participantId])

  const getContent = (key, timePeriod) => {
    let riskScore
    if (key === SINGLE_COL_PWR) {
      riskScore = {
        pwr: riskScores?.[timePeriod]?.[PWR] || null,
        dyslexia: riskScores?.[timePeriod]?.[DYSLEXIA] || null
      }
    } else {
      riskScore = riskScores?.[timePeriod]?.[key] || null
    }
    const isClassroomView = false
    return getPredictiveProfileDisplay(
      key,
      student.grade,
      student.participantId,
      timePeriod,
      riskScore,
      isClassroomView
    )
  }

  const getTimePeriodCellClasses = (timePeriod, classes, rowIdx) => {
    return `scoreCell
    ${getScoreCellClasses(timePeriod, selectedTimePeriod, rowIdx, predictiveProfileRows.length)}
    ${classes}
  `
  }

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <StudentTableHeader colSpan={2}>Predictive Profile</StudentTableHeader>
            <StudentTableHeader colSpan={1}>
              <SliderScaleWrapper>
                <SliderScale>
                  {[20, 40, 60, 80].map((num, idx) => {
                    return <SliderScaleNumber key={idx}>{num}</SliderScaleNumber>
                  })}
                </SliderScale>
              </SliderScaleWrapper>
            </StudentTableHeader>
            <TimePeriodHeader selected={selectedTimePeriod === BOY} onClick={() => setSelectedTimePeriod(BOY)}>
              {BOY}
            </TimePeriodHeader>
            <TimePeriodHeader selected={selectedTimePeriod === MOY} onClick={() => setSelectedTimePeriod(MOY)}>
              {MOY}
            </TimePeriodHeader>
            <TimePeriodHeader selected={selectedTimePeriod === EOY} onClick={() => setSelectedTimePeriod(EOY)}>
              {EOY}
            </TimePeriodHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {riskScores &&
            predictiveProfileRows.map((row, rowIdx) => {
              const { content: BOYContent, classes: BOYClasses } = getContent(row, BOY)
              const { content: MOYContent, classes: MOYClasses } = getContent(row, MOY)
              const { content: EOYContent, classes: EOYClasses } = getContent(row, EOY)

              return (
                <TableRow key={row}>
                  <TableCell className='rowToken'>
                    <PredictiveProfileToken tokenName={row} />
                  </TableCell>
                  <TableCell className='rowTitle'>{PREDICTIVE_PROFILE_DISPLAY_NAMES[row]}</TableCell>
                  <TableCell>
                    <EmptySliderCell id='emptySlider' />
                  </TableCell>
                  <TableCell className={getTimePeriodCellClasses(BOY, BOYClasses, rowIdx)}>{BOYContent}</TableCell>
                  <TableCell className={getTimePeriodCellClasses(MOY, MOYClasses, rowIdx)}>{MOYContent}</TableCell>
                  <TableCell className={getTimePeriodCellClasses(EOY, EOYClasses, rowIdx)}>{EOYContent}</TableCell>
                </TableRow>
              )
            })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default PredictiveProfileTable
