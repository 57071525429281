import React, { useState } from 'react'
import { TableCell } from '../../styles'
import { getPredictiveProfileDisplay } from 'utils/display'

const PredictiveProfileCell = ({ cellData, cellClasses }) => {
  const [isHighlighted, setIsHighlighted] = useState(false)

  const { key, timePeriod, riskScore, studentId, studentGrade, setHoverCell } = cellData

  const isClassroomView = true
  const { content, classes } = getPredictiveProfileDisplay(
    key,
    studentGrade,
    studentId,
    timePeriod,
    riskScore,
    isClassroomView
  )

  return (
    <TableCell
      onMouseEnter={() => {
        setHoverCell({ subColKey: key, studentId: studentId })
        setIsHighlighted(true)
      }}
      onMouseLeave={() => {
        setHoverCell(null)
        setIsHighlighted(false)
      }}
      className={`${cellClasses} ${classes} ${isHighlighted && 'hoverCell'}`}
    >
      {content}
    </TableCell>
  )
}

export default PredictiveProfileCell
