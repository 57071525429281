/* eslint multiline-ternary: ["off", "always-multiline"] */
import React, { useState } from 'react'
import { ViewWrapper } from '../../ui-components/backgrounds'
import {
  AuthBackground,
  ContentWrapper,
  FormWrapper,
  PageLink,
  AuthHeader,
  AuthSubHeader,
  AuthLogo,
  BackArrow
} from '../../ui-components/authStyles'
import { FormContent, InputWrapper, ResetWrapper, BackWrapper, InstructionsText } from './styles'
import { TextField } from '../../ui-components/inputs/inputs'
import { LargeButton } from '../../ui-components/buttons/buttons'
import ToastNotification from '../../components/ToastNotification'
import { EMPTY_EMAIL_ERROR } from '../../constants/copy'
import * as COPY from './copy'
import { useAuth } from 'auth'

const ForgotPassword = () => {
  const { resetPassword } = useAuth()
  const [email, setEmail] = useState('')
  const [showForm, setShowForm] = useState(true)
  const [emailError, setEmailError] = useState(null)
  const [alert, setAlert] = useState({ isOpen: false, type: '', message: '' })
  const [isDisabled, setIsDisabled] = useState(false)
  const validEmail = /\S+@\S+\.\S+/

  const sendForgotPasswordEmail = async () => {
    if (email === '') {
      setIsDisabled(false)
      setEmailError(true)
    } else if (!email.match(validEmail)) {
      setIsDisabled(false)
      setAlert({ isOpen: true, type: 'error', message: COPY.FORGOT_PASSWORD_ERROR })
    } else {
      try {
        await resetPassword(email)
        setShowForm(false)
      } catch (err) {
        setIsDisabled(false)
        setEmail('')
        setAlert({ isOpen: true, type: 'error', message: COPY.FORGOT_PASSWORD_ERROR })
      }
    }
  }

  return (
    <ViewWrapper>
      <AuthBackground />
      <ContentWrapper>
        <AuthLogo />
        <FormWrapper>
          <AuthHeader>Forgot Password?</AuthHeader>

          {showForm ? (
            <FormContent>
              <AuthSubHeader>{COPY.FORM_INSTRUCTIONS}</AuthSubHeader>
              <InputWrapper>
                <TextField
                  type='text'
                  placeholder='Your email'
                  value={email}
                  label='Email'
                  onChange={(event) => {
                    if (emailError) {
                      setEmailError(false)
                    }
                    setEmail(event.target.value)
                  }}
                  error={emailError !== null}
                  helperText={emailError ? EMPTY_EMAIL_ERROR : ''}
                  severity={emailError ? 'error' : null}
                  data-testid='emailInput'
                />
              </InputWrapper>
              <LargeButton
                type='button'
                disabled={isDisabled}
                alt='submit'
                data-testid='submit'
                onClick={() => {
                  setIsDisabled(true)
                  sendForgotPasswordEmail()
                }}
              >
                Submit
              </LargeButton>

              <ResetWrapper>
                Have a code? <PageLink to='/reset-password'>Reset password here!</PageLink>
              </ResetWrapper>
            </FormContent>
          ) : (
            <FormContent>
              <InstructionsText>
                {COPY.FORGOT_PASSWORD_INSTRUCTIONS}
                <PageLink id='resetPasswordLink' to='/reset-password'>
                  reset your password here.
                </PageLink>
              </InstructionsText>
              <InstructionsText>{COPY.FORGOT_PASSWORD_INSTRUCTIONS_2}</InstructionsText>
            </FormContent>
          )}

          <BackWrapper>
            <BackArrow />
            <PageLink to='/login'>Back to Login</PageLink>
          </BackWrapper>
        </FormWrapper>
      </ContentWrapper>
      {alert.isOpen && (
        <ToastNotification
          alert={alert}
          onClose={() => {
            setAlert({ isOpen: false, type: '', message: '' })
          }}
        />
      )}
    </ViewWrapper>
  )
}

export default ForgotPassword
