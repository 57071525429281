import React from 'react'
import { StandardButton } from '../../ui-components/buttons/buttons'
import { DialogActions, DialogTitle, DialogBox, DialogContent, DialogButton, CancelButton } from './styles'

const Dialog = ({
  header,
  body,
  actionTitle,
  onAction,
  cancelTitle = 'Cancel',
  onClose,
  width = null,
  centerDialogActions = false
}) => {
  return (
    <DialogBox
      open={true}
      onClose={onClose}
      disableBackdropClick={true}
      aria-labelledby='dialog-title'
      fullWidth={width !== null}
      maxWidth={width}
    >
      <DialogTitle id='dialog-title'>{header}</DialogTitle>
      <DialogContent>{body}</DialogContent>
      <DialogActions centerDialogActions={centerDialogActions}>
        <DialogButton>
          {cancelTitle ? (
            <CancelButton onClick={onClose} color='primary' id='dialog-cancel-button'>
              {cancelTitle}
            </CancelButton>
          ) : null}
        </DialogButton>
        <DialogButton>
          <StandardButton onClick={onAction} color='primary' id='dialog-action-button'>
            {actionTitle}
          </StandardButton>
        </DialogButton>
      </DialogActions>
    </DialogBox>
  )
}

export default Dialog
