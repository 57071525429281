import styled from 'styled-components'
import { REPORT_BUTTON_INFO_TEXT } from 'constants/colors'

export const ActionRow = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1.75rem;
  width: 100%;
`
export const ButtonWrapper = styled.div`
  width: 13.875rem;
  margin-left: 1.5rem;
`

export const InfoWrapper = styled.div`
  font-family: Inter;
  font-size: 0.75rem;
  color: ${REPORT_BUTTON_INFO_TEXT};
  font-weight: 600;
  display: flex;
  justify-content: center;
  padding-top: 0.5rem;
`
