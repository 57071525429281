import React from 'react'
import AddIcon from '@material-ui/icons/Add'
import { buildDataURL } from './helper'
import { ButtonLink } from './styles'

const CSVButton = ({ classroomResults, classroomGrades, fileName, columns, selectedTimePeriod, ranScores }) => {
  const dataURL = buildDataURL(classroomResults, classroomGrades, columns, selectedTimePeriod, ranScores)
  return (
    <ButtonLink role='button' download={fileName} target='_self' href={dataURL}>
      <AddIcon fontSize='small' style={{ paddingRight: 5 }} />
      Export CSV
    </ButtonLink>
  )
}

export default CSVButton
