import React from 'react'
import { Header } from './styles'
import { useSessionStore } from '../../../../stores/SessionStore'

const ViewHeader = () => {
  const { state } = useSessionStore()
  const { viewHeader } = state

  return <Header>{viewHeader}</Header>
}

export default ViewHeader
