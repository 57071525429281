import styled from 'styled-components'
import MaterialInfoIcon from '@material-ui/icons/Info'
import * as COLOR from 'constants/colors'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`

export const InfoIcon = styled(MaterialInfoIcon)({
  color: COLOR.INFO_ICON_DEFAULT,
  height: '0.9rem',
  width: '0.9rem',
  '&:hover': {
    cursor: 'pointer',
    color: COLOR.INFO_ICON_ACTIVE
  },
  '&.infoOpen': {
    color: COLOR.INFO_ICON_ACTIVE
  },
  '&.left': {
    marginLeft: '0.25rem'
  },
  '&.right': {
    marginRight: '0.25rem'
  }
})
