import React from 'react'
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import Tooltip from '@material-ui/core/Tooltip'
import { FormControl, TooltipText } from '../../styles'
import { Select, MenuItem } from '../../../../ui-components/inputs/inputs'

export const FormSelect = ({ field, fieldValues, updateField, hasError }) => {
  const getSelectOptions = (field) => {
    let options = field.options
    // if field has a parent key, its option values depend on the current value of the parent field
    // ex: classroom options depend on which school is selected
    if (field.parentKey) {
      const currParentValue = fieldValues[field.parentKey]
      options = currParentValue ? field.options[currParentValue] : []
    }
    return options
  }

  const formBody = (
    <FormControl
      id={`${field.key}FormControl`}
      // to disable a select, add "isDisabled: true" to field obj
      disabled={field.isDisabled || false}
      required={field.isRequired}
      error={hasError}
    >
      <InputLabel shrink id={`${field.key}Label`}>
        {field.label}
      </InputLabel>
      <Select
        id={`${field.key}Select`}
        severity={hasError ? 'error' : ''}
        labelId={`${field.key}Label`}
        value={fieldValues[field.key]}
        onChange={(event) => {
          updateField(field.key, event.target.value, field.childKey)
        }}
      >
        {getSelectOptions(field) &&
          getSelectOptions(field).map((option) => {
            return (
              <MenuItem key={option.key} value={option.value}>
                {option.label}
              </MenuItem>
            )
          })}
      </Select>
      <FormHelperText>{hasError && `${field.label} is a required field.`}</FormHelperText>
    </FormControl>
  )

  return (
    <div>
      {field.tooltipText ? (
        <div id={`${field.key}Tooltip`}>
          <Tooltip title={<TooltipText>{field.tooltipText}</TooltipText>}>{formBody}</Tooltip>
        </div>
      ) : (
        formBody
      )}
    </div>
  )
}

export default FormSelect
