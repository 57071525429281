import styled from 'styled-components'
import MaterialChip from '@mui/material/Chip'
import * as COLOR from 'constants/colors'

export const InfoWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column'
})

export const Row = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center'
})

export const Name = styled.div({
  marginRight: '0.5rem',
  color: COLOR.TABLE_TEXT_PRIORITY
})

export const Chip = styled(MaterialChip)({
  '&.MuiChip-root': {
    backgroundColor: COLOR.WARNING_CHIP
  }
})
