import styled from 'styled-components'
import React from 'react'
import MaterialTextField from '@material-ui/core/TextField'
import * as COLORS from '../../constants/colors'
import MaterialSelect from '@material-ui/core/Select'
import MaterialMenuItem from '@material-ui/core/MenuItem'
import MaterialAutocomplete from '@material-ui/lab/Autocomplete'

const borderColor = (severity) => {
  switch (severity) {
    case 'error':
      return COLORS.ERROR_INPUT_BORDER
    case 'success':
      return COLORS.SUCCESS_INPUT_BORDER
    default:
      return COLORS.DEFAULT_INPUT_BORDER
  }
}

const StyledSelect = styled(MaterialSelect)`
  width: 100%;
  height: 3.2rem;
  border: solid 1px ${(props) => borderColor(props.severity)};
  border-radius: 0.983rem;

  & .MuiInputBase-root {
    padding: 0.625rem;
  }

  &.Mui-focused {
    border-color: ${COLORS.ACTIVE_INPUT_BORDER};
  }

  & .MuiInputBase-input {
    padding-left: 1rem;
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
  }

  & .MuiSelect-select:focus {
    background-color: transparent;
  }

  & ::placeholder {
    font-family: Inter;
    font-size: 1rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.81;
    letter-spacing: normal;
    text-align: left;
    color: ${COLORS.INPUT_PLACEHOLDER_TEXT};
  }

  &.MuiDisabled {
    color: rgba(0, 0, 0, 0.26);
    border: solid 1px ${COLORS.DISABLED_INPUT};
  }
`

export const Select = (props) => {
  const { children, ...selectProps } = props
  return (
    <StyledSelect {...selectProps} disableUnderline>
      {children}
    </StyledSelect>
  )
}

export const StyledTextField = styled(MaterialTextField)`
  width: 100%;

  &.MuiFormControl-root {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  & .MuiInputBase-root {
    border: solid 1px ${(props) => borderColor(props.severity)};
    border-radius: 0.938rem;
    padding: 0.625rem;
    background-color: ${COLORS.INPUT_BACKGROUND};
  }

  & .Mui-focused {
    border-color: ${COLORS.ACTIVE_INPUT_BORDER};
    text-decoration: none;
  }

  & .MuiInput-underline:after {
    border-bottom: none;
  }

  & .MuiInput-underline:before {
    border-bottom: none;
  }

  & .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: none;
  }

  & .MuiAutocomplete-inputRoot[class*='MuiInput-root'] {
    padding: 0.625rem;
  }

  & ::placeholder {
    font-family: Inter;
    font-size: 1rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.81;
    letter-spacing: normal;
    text-align: left;
    color: ${COLORS.INPUT_PLACEHOLDER_TEXT};
  }

  & .MuiInputBase-root {
    color: ${COLORS.DIALOG_INPUT_TEXT};
    font-family: Inter;
    font-size: 1rem;
    font-weight: 600;
  }

  & div.Mui-disabled {
    color: rgba(0, 0, 0, 0.26);
    border: solid 1px ${COLORS.DISABLED_INPUT};
  }

  & .MuiInput-underline.Mui-disabled:before {
    border-bottom-style: none;
  }
`
export const TextField = (props) => {
  const { children, ...textFieldProps } = props
  return (
    <StyledTextField {...textFieldProps} InputLabelProps={{ shrink: true }}>
      {children}
    </StyledTextField>
  )
}

export const selectOptionStyles = {
  fontFamily: 'Inter',
  fontSize: '1rem',
  fontWeight: 600,
  color: COLORS.DIALOG_INPUT_TEXT,

  '&.MuiDisabled': {
    color: 'rgba(0, 0, 0, 0.26)'
  }
}

export const MultiSelectAutocomplete = styled(MaterialAutocomplete)({
  '& .MuiChip-label': {
    ...selectOptionStyles
  },
  '& .MuiAutocomplete-tag': {
    maxWidth: '10rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
})

export const MenuItem = styled(MaterialMenuItem)({
  '&.MuiMenuItem-root': {
    ...selectOptionStyles
  }
})

const StyledSmallerSelect = styled(MaterialSelect)`
  width: 100%;
  height: 3.2rem;
  border-radius: 0.983rem;

  & .MuiInputBase-root {
    padding: 0.625rem;
  }

  &.Mui-focused {
    border-color: ${COLORS.ACTIVE_INPUT_BORDER};
  }

  & .MuiInputBase-input {
    padding-left: 1rem;
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
    font-family: Inter;
    font-size: 1rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.81;
    letter-spacing: normal;
    text-align: left;
    color: ${COLORS.DIALOG_INPUT_TEXT};
  }

  & .MuiSelect-select:focus {
    background-color: transparent;
  }

  & ::placeholder {
    font-family: Inter;
    font-size: 1rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.81;
    letter-spacing: normal;
    text-align: left;
    color: ${COLORS.INPUT_PLACEHOLDER_TEXT};
  }

  &.Mui-disabled {
    color: rgba(0, 0, 0, 0.26);
    border: solid 1px ${COLORS.DISABLED_INPUT};
  }
`

export const SmallerSelect = (props) => {
  const { children, ...selectProps } = props
  return (
    <StyledSmallerSelect {...selectProps} disableUnderline>
      {children}
    </StyledSmallerSelect>
  )
}
