import { request } from '../helper'

export const getSchool = async (schoolId) => {
  const url = `/organizationUnit/${schoolId}`
  return await request('GET', url)
}

export const createSchool = async (payload) => {
  const url = '/organizationUnit'
  return await request('POST', url, payload)
}

export const updateSchool = async (payload) => {
  const url = '/organizationUnit'
  return await request('PUT', url, payload)
}

export const getSchoolsByDistrict = async (districtId) => {
  const url = `/organization/orgUnits/${districtId}`
  return await request('GET', url)
}

export const deleteSchool = async (schoolId) => {
  const url = `/organizationUnit/${schoolId}`
  return await request('DELETE', url)
}
