import React, { useEffect } from 'react'
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom'
import Amplify from 'aws-amplify'
import { setAnalyticsUserId, setAnalyticsUserDistrict } from 'analytics'
import * as SentryConfig from 'sentry'
import { useSessionStore, actionTypes } from './stores/SessionStore'
import LoginView from './views/LoginView'
import LoginCallback from 'views/LoginCallback'
import ForgotPassword from './views/ForgotPassword'
import ResetPassword from './views/ResetPassword'
import Register from './views/Register'
import CompleteNewPassword from './views/CompleteNewPassword'
import SuperAdminView from './views/SuperAdminView'
import DistrictView from './views/DistrictView'
import SchoolView from './views/SchoolView'
import ClassroomView from './views/ClassroomView'
import StudentView from './views/StudentView'
import TestDetailsView from './views/TestDetailsView'
import AboutEarlyBird from './views/AboutEarlyBird'
import NavBar from './components/NavBar'
import CustomerSupport from './views/CustomerSupport'
import IdleTimerContainer from './components/IdleTimerContainer'
import IdleTimeoutView from './views/IdleTimeoutView'
import CsvRosterView from './views/CsvRosterView/CsvRosterView'
import * as ROLE from './constants/roles'
import { getUserByEmail } from './lib/api'
import { usePostHog } from 'posthog-js/react'
import { useCurrentUserQuery } from 'auth'
import LoadingIndicator from 'components/LoadingIndicator'

const setupAuth = () => {
  // globally configure AWS cognito
  Amplify.configure({
    Auth: {
      region: process.env.REACT_APP_AWS_REGION,
      userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID
    }
  })
}

const App = () => {
  const { state, dispatch } = useSessionStore()
  const { isLoggedIn, userEmail, districtId, schoolIds, loginRedirectPathName, role, tempPassword, isTimedOut } = state

  const posthog = usePostHog()
  const currentUserQuery = useCurrentUserQuery()
  const userIsLoggedIn = currentUserQuery.isSuccess && currentUserQuery.data?.email
  // have to use this proxy 'roleIsLoading' until we move sessionStore to react-query
  const roleIsLoading = userIsLoggedIn && !role

  useEffect(() => setupAuth(), [])

  // check if user is already logged in
  // react-query explicitly says not to do state-syncing like this
  // however this is necessary until SessionStore is fixed or moved to react query
  useEffect(() => {
    if (!currentUserQuery.isSuccess) return
    const userInfo = currentUserQuery.data

    if (userInfo) {
      dispatch({
        type: actionTypes.UPDATE_FIELD,
        field: 'userEmail',
        data: userInfo.email
      })
      dispatch({ type: actionTypes.UPDATE_FIELD, field: 'isLoggedIn', data: true })
    } else {
      dispatch({ type: actionTypes.UPDATE_FIELD, field: 'isLoggedIn', data: false })
    }
  }, [dispatch, currentUserQuery.data, currentUserQuery.isSuccess])

  // if isLoggedIn is true, fetch the users information from the api and store in the session store
  useEffect(() => {
    ;(async () => {
      if (isLoggedIn && userEmail) {
        try {
          const userObject = await getUserByEmail(userEmail)
          const userId = userObject.userId
          const firstName = userObject.firstName
          const lastName = userObject.lastName
          const role = userObject.Role[0].roleName
          const districtId = userObject.organizationId
          const districtName = userObject.Organization.name
          const schoolIds = userObject.OrganizationUnits.map((school) => school.organizationUnitId)
          setAnalyticsUserId(userId)
          setAnalyticsUserDistrict(districtName)
          posthog.identify(userId)
          posthog.group('organization', districtId, {
            organization_id: districtId,
            organization_name: districtName
          })
          SentryConfig.addUserIdentifier(userId)
          SentryConfig.addDistrictIdentifier(districtId)
          dispatch({
            type: actionTypes.SET_USER_INFO,
            role: role,
            userId: userId,
            districtId: districtId,
            schoolIds: schoolIds,
            firstName: firstName,
            lastName: lastName
          })
        } catch (err) {
          alert(`Error fetching user data: ${err.message}`)
          return <Redirect to='/login' />
        }
      }
    })()
  }, [isLoggedIn, userEmail, dispatch, posthog])

  const PrivateRoute = ({ render, children, ...params }) => {
    // user login check still in progress
    if (isLoggedIn === null) {
      return <div></div>
    }
    const loginRedirect = ({ location }) => {
      return (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: location }
          }}
        />
      )
    }

    return (
      <Route
        {...params}
        render={
          isLoggedIn
            ? () => (
                /* eslint indent: off */
                <div>
                  <NavBar />
                  {children}
                </div>
              )
            : loginRedirect
        }
      />
    )
  }

  // route the user based on their role
  const renderLandingPage = () => {
    if (isLoggedIn && role === null) {
      return <div></div>
    }

    switch (role) {
      case ROLE.SUPER_ADMIN:
        return <Redirect to='/super-admin' />
      case ROLE.DISTRICT_ADMIN:
        return <Redirect to={`/district/${districtId}`} />
      case ROLE.SCHOOL_ADMIN:
        return <Redirect to={`/district/${districtId}`} />
      case ROLE.TEACHER:
        if (schoolIds.length > 1) {
          return <Redirect to={`/district/${districtId}`} />
        }
        return <Redirect to={`/school/${schoolIds[0]}`} />
      default:
        return tempPassword ? <CompleteNewPassword /> : <LoginView />
    }
  }

  // wait until we've gotten the user's role and then redirect to the next path
  const renderLoginRedirectPathName = () => {
    if (role) {
      return <Redirect to={loginRedirectPathName} />
    } else if (isTimedOut) {
      return (
        <Redirect
          to={{
            pathname: '/timeout',
            state: { from: loginRedirectPathName }
          }}
        />
      )
    } else {
      return <LoginView />
    }
  }
  // we are fetching role via use effects that load inconsistently
  // need to have this hack with 'roleIsLoading' until can move session store to react query
  if (currentUserQuery.isLoading || roleIsLoading) return <LoadingIndicator />

  return (
    <Router>
      <IdleTimerContainer />
      <Switch>
        <Route
          path='/login'
          render={loginRedirectPathName ? () => renderLoginRedirectPathName() : () => renderLandingPage()}
        />
        <Route path='/forgot-password' component={ForgotPassword} />
        <Route path='/login-callback' component={LoginCallback} />
        <Route path='/register' component={Register} />
        <Route path='/reset-password' component={ResetPassword} />
        <Route path='/complete-new-password' render={() => renderLandingPage()} />
        <Route path='/timeout' component={IdleTimeoutView} />
        <Route path='/rostering/:districtId' component={CsvRosterView} />
        <PrivateRoute path='/about-earlybird'>
          <AboutEarlyBird />
        </PrivateRoute>
        <PrivateRoute path='/customer-support'>
          <CustomerSupport />
        </PrivateRoute>
        <PrivateRoute path='/super-admin'>
          <SuperAdminView />
        </PrivateRoute>
        <PrivateRoute path='/district/:districtId'>
          <DistrictView />
        </PrivateRoute>
        <PrivateRoute path='/school/:schoolId'>
          <SchoolView />
        </PrivateRoute>
        <PrivateRoute path='/classroom/:classroomId'>
          <ClassroomView />
        </PrivateRoute>
        <PrivateRoute path='/student/:studentId'>
          <StudentView />
        </PrivateRoute>
        <PrivateRoute path='/test-details/:testId'>
          <TestDetailsView />
        </PrivateRoute>
        <PrivateRoute path='/'>{renderLandingPage()}</PrivateRoute>
      </Switch>
    </Router>
  )
}

export default App
