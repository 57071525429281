import React from 'react'
import { probabilisticPercentage } from 'utils/formatting'
import { Wrapper, Percentage, LabelPercentage, Label } from './styles'
import { pwrTextByRisk } from 'constants/singleColPwr'

const SingleColPwrScore = ({ pwrScore, riskCategory, displayLabel }) => {
  return (
    <Wrapper>
      {displayLabel ? (
        <>
          <LabelPercentage>{probabilisticPercentage(pwrScore)}</LabelPercentage>
          <Label>{pwrTextByRisk[riskCategory]}</Label>
        </>
      ) : (
        <Percentage>{probabilisticPercentage(pwrScore)}</Percentage>
      )}
    </Wrapper>
  )
}

export default SingleColPwrScore
