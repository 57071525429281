import React, { useState } from 'react'
import { TableCell } from '../../styles'
import * as A from '../../../../constants/assessments'
import RANScoring from '../../../RANScoring'
import TestDetails from '../TestDetails'
import { TitleLink } from './styles'

const AssessmentTitleCell = ({ isAdminView, student, assessment, studentScores, onUpdate }) => {
  const [scoringDialog, setScoringDialog] = useState({ isOpen: false })
  let cellContent = null
  const { title, key } = assessment

  const expressiveAssessments = A.EXPRESSIVE_ASSESSMENTS
  // If Admin View, all expressive assessments that have been completed will have an option to listen to the response audio.
  // If not Admin View, only RAN will have the option to listen to audio.
  const assessmentsWithAudio = isAdminView ? expressiveAssessments : A.RAN_ASSESSMENT_IDS
  if (assessmentsWithAudio.includes(key)) {
    // do not render link for incomplete assessment
    cellContent =
      studentScores && studentScores.completedFlag ? (
        <TitleLink onClick={() => setScoringDialog({ isOpen: true })}>{title}</TitleLink>
      ) : (
        title
      )
  } else {
    cellContent = title
  }

  const isRan = A.RAN_ASSESSMENT_IDS.includes(key)

  return (
    <>
      <TableCell className='rowTitle'>{cellContent}</TableCell>

      {scoringDialog.isOpen &&
        (isRan ? (
          <RANScoring
            student={student}
            onUpdate={onUpdate}
            testId={studentScores.participantTestId}
            isInvalid={studentScores.invalid}
            onClose={() => setScoringDialog({ isOpen: false })}
          />
        ) : (
          <TestDetails test={studentScores} onClose={() => setScoringDialog({ isOpen: false })} />
        ))}
    </>
  )
}

export default AssessmentTitleCell
