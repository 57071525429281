import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { DropzoneArea } from 'material-ui-dropzone'
import { Dialog, List, ListItem, ListItemText } from '@material-ui/core'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import CloseIcon from '@material-ui/icons/Close'
import { CloudDone } from '@material-ui/icons'
import AttachmentIcon from '@material-ui/icons/Attachment'
import { ViewWrapper } from '../../ui-components/backgrounds'
import { LargeButton } from '../../ui-components/buttons/buttons'
import { AuthBackground, AuthHeader, AuthLogo, ContentWrapper } from '../../ui-components/authStyles'
import { LoginHeader } from '../LoginView/styles'
import { LINK_HOVER } from '../../constants/colors'
import { ButtonWrapper, CloseButton, DropzoneWrapper, InstructionsButton, SubHeader, RosterFormWrapper } from './styles'

const CsvRosterView = () => {
  const params = useParams()
  const { districtId } = params

  const [displayInstructions, setDisplayInstructions] = useState(false)
  const [districtExists, setDistrictExists] = useState('unset')
  const [districtName, setDistrictName] = useState(null)
  const [fileObjects, setFileObjects] = useState([])
  const [fileToSend, setFileToSend] = useState(null)
  const [fileName, setFileName] = useState(null)
  const [successfulSubmission, setSuccessfulSubmission] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = `${process.env.REACT_APP_API_BASE_URL}/roster-stream-old/check-district/${districtId}`
        const districtFound = await fetch(url).then((res) => res.json())
        if (districtFound.name) {
          setDistrictExists(true)
          setDistrictName(districtFound.name)
        }
      } catch (err) {
        setDistrictExists(false)
        setSuccessfulSubmission(false)
      }
    }
    if (districtExists === 'unset') fetchData()
  }, [districtId, districtExists])

  const handleChange = (files) => {
    setFileObjects(files)
  }

  const processFile = (fileObject) => {
    const { name } = fileObject.file
    const reader = new FileReader()
    reader.readAsBinaryString(fileObject.file)
    reader.onload = function (event) {
      const base64EncodedFile = btoa(event.target.result)
      setFileToSend(base64EncodedFile)
      setFileName(name)
    }
    reader.onerror = function (error) {
      alert(`Error: ${error}`)
    }
    return <CloudDone fontSize='large' color='primary' />
  }

  const handleSubmit = async () => {
    try {
      const url = `${process.env.REACT_APP_API_BASE_URL}/roster-stream-old/process-roster-file`
      const payload = {
        file: fileToSend,
        fileName,
        districtName
      }
      const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
      })
      if (!response.ok) {
        throw new Error('Network error. If issue continues, please contact support.')
      }
      setSuccessfulSubmission(true)
    } catch (err) {
      alert(`Upload error: ${err.message}`)
    }
  }

  return (
    <ViewWrapper>
      <AuthBackground />
      <ContentWrapper>
        <AuthLogo />
        <RosterFormWrapper>
          {districtExists ? (
            <>
              <AuthHeader>
                <LoginHeader>CSV Rostering: {districtName}</LoginHeader>
                <SubHeader>Upload zipped roster file below.</SubHeader>
              </AuthHeader>
              <InstructionsButton variant='text' onClick={() => setDisplayInstructions(true)}>
                View Instructions
              </InstructionsButton>
              <Dialog
                onClose={() => setDisplayInstructions(false)}
                open={displayInstructions}
                style={{ minWidth: '500px' }}
              >
                <MuiDialogTitle>
                  <LoginHeader style={{ marginTop: '2rem' }}>Instructions</LoginHeader>
                  <CloseButton aria-label='close' onClick={() => setDisplayInstructions(false)}>
                    <CloseIcon />
                  </CloseButton>
                </MuiDialogTitle>
                <MuiDialogContent style={{ minWidth: '500px', paddingBottom: '3rem' }}>
                  <h3 style={{ margin: '0 1rem' }}>EarlyBird accepts the following roster formats:</h3>
                  <List component='nav'>
                    <ListItem
                      button
                      component='a'
                      href='https://www.imsglobal.org/oneroster-v11-final-csv-tables'
                      rel='noreferrer'
                      target='_blank'
                    >
                      <ListItemText primary='OneRoster v1.1 CSV Tables' style={{ color: LINK_HOVER }} />
                    </ListItem>
                    <ListItem
                      button
                      component='a'
                      href='https://docs.google.com/spreadsheets/d/161eBzlYLy0I97mS8bxidAJBWM8p9MRPHMQkhHVoByVA/'
                      rel='noreferrer'
                      target='_blank'
                      style={{ color: LINK_HOVER }}
                    >
                      <ListItemText primary='EarlyBird CSV Spec' />
                    </ListItem>
                  </List>
                  <h5>
                    When you have filled in the tables with your district&apos;s roster data, zip all the files into 1
                    single zipped file, then upload the file to this page and we will be in touch shortly.
                  </h5>
                  <h5>
                    If you have any questions, or need further assistance, please email&nbsp;
                    <a href='mailto:support@earlybirdeducation.com'>support@earlybirdeducation.com</a>
                  </h5>
                </MuiDialogContent>
              </Dialog>
              {successfulSubmission ? (
                <>
                  <h1>Upload successful.</h1>
                  <h3>Thank you!</h3>
                </>
              ) : (
                <DropzoneWrapper>
                  <DropzoneArea
                    acceptedFiles={['application/zip', '.zip']}
                    dropzoneText={'Drag & Drop or Click Here'}
                    fileObjects={fileObjects}
                    filesLimit={1}
                    Icon={AttachmentIcon}
                    maxFileSize={15000000}
                    onChange={handleChange}
                    onAdd={(newFileObjs) => {
                      setFileObjects([].concat(fileObjects, newFileObjs))
                    }}
                    onDelete={() => {
                      setFileObjects([])
                    }}
                    getPreviewIcon={processFile}
                    showFileNames
                  />
                  <ButtonWrapper>
                    <LargeButton
                      type='submit'
                      alt='submit'
                      data-testid='submit'
                      disabled={!fileName}
                      onClick={handleSubmit}
                    >
                      Submit
                    </LargeButton>
                  </ButtonWrapper>
                </DropzoneWrapper>
              )}
            </>
          ) : (
            <h1>District not found</h1>
          )}
        </RosterFormWrapper>
      </ContentWrapper>
    </ViewWrapper>
  )
}

export default CsvRosterView
