import React from 'react'
import { ReactComponent as ScoreEntryIcon } from 'ui-components/ScoreEntryIcon.svg'
import { Alert, Content, Header, Message, Link } from './styles'

const getIcon = (customIcon) => {
  switch (customIcon) {
    case 'score-entry':
      return <ScoreEntryIcon title='score-entry-icon' />
    default:
      return null // if icon is null, then the default icon associated with the alert severity will be displayed
  }
}

/*
    payload fields
    - severity: error, warning, info, or success. Determines banner color and icon.
    - message: message to display
    - header: bolded text displayed before the message
    - link: link displayed after message. Object with "url" and "text" fields.
    - customIcon: string that corresponds to custom icon that will overwrite default alert icon
*/
const Banner = ({ payload }) => {
  return (
    <Alert severity={payload?.severity || 'info'} icon={getIcon(payload?.customIcon)}>
      <Content>
        {payload?.header && <Header>{payload.header}</Header>}
        <Message>{payload?.message || ''}</Message>
        {payload?.link && (
          <Link target='_blank' rel='noopener noreferrer' href={payload.link.url}>
            {payload.link.text}
          </Link>
        )}
      </Content>
    </Alert>
  )
}

export default Banner
