import React from 'react'
import { LogoContainer, LogoImg } from './styles'
import { EB_LOGO } from '../../../../constants/assets'
import * as ROLE from '../../../../constants/roles'
import { useSessionStore } from '../../../../stores/SessionStore'

const Logo = () => {
  const { state } = useSessionStore()
  const { districtId, role, schoolIds } = state
  const isMultiSchoolUser = schoolIds && schoolIds.length > 1
  let landingPage
  if (role === ROLE.SUPER_ADMIN) {
    landingPage = '/super-admin'
  } else if ([ROLE.DISTRICT_ADMIN, ROLE.SCHOOL_ADMIN].includes(role) || isMultiSchoolUser) {
    landingPage = `/district/${districtId}`
  } else if (role === ROLE.TEACHER) {
    landingPage = `/school/${schoolIds[0]}`
  }

  return (
    <a href={landingPage}>
      <LogoContainer>
        <LogoImg src={EB_LOGO} />
      </LogoContainer>
    </a>
  )
}

export default Logo
