import styled from 'styled-components'

export const SliderScaleWrapper = styled.div({
  display: 'flex',
  width: '100%',
  justifyContent: 'center'
})

export const SliderScale = styled.div({
  display: 'flex',
  flexDirection: 'row',
  width: '17rem'
})

export const SliderScaleNumber = styled.div({
  width: '20%',
  textAlign: 'right'
})
