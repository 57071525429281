import React, { useReducer, useContext, createContext } from 'react'

const SessionContext = createContext()

export const initialState = {
  isLoggedIn: null,
  userEmail: null,
  userId: null,
  role: null,
  districtId: null,
  schoolIds: null,
  firstName: null,
  lastName: null,
  didLogOut: null,
  loginRedirectPathName: null,
  tempPassword: null,
  viewHeader: '',
  isTimedOut: false
}

export const actionTypes = {
  UPDATE_FIELD: 'UPDATE_FIELD',
  SET_USER_INFO: 'SET_USER_INFO',
  LOGOUT: 'LOGOUT',
  LOGIN: 'LOGIN',
  ADD_NEW_USER: 'ADD_NEW_USER',
  IDLE_LOGOUT: 'IDLE_LOGOUT'
}

export const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_FIELD:
      return {
        ...state,
        [action.field]: action.data
      }
    case actionTypes.SET_USER_INFO:
      return {
        ...state,
        role: action.role,
        districtId: action.districtId,
        schoolIds: action.schoolIds,
        firstName: action.firstName,
        lastName: action.lastName,
        userId: action.userId
      }
    case actionTypes.LOGOUT:
      return { ...initialState, isLoggedIn: false, didLogOut: true }
    case actionTypes.IDLE_LOGOUT:
      return {
        ...initialState,
        isLoggedIn: false,
        isTimedOut: true,
        loginRedirectPathName: action.loginRedirectPathName
      }
    case actionTypes.LOGIN:
      return {
        ...state,
        userEmail: action.userEmail,
        loginRedirectPathName: action.loginRedirectPathName,
        isLoggedIn: action.isLoggedIn
      }
    case actionTypes.ADD_NEW_USER:
      return {
        ...state,
        userEmail: action.userEmail,
        tempPassword: action.tempPassword
      }
    default:
      return state
  }
}

export const SessionProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return <SessionContext.Provider value={{ state, dispatch }}>{children}</SessionContext.Provider>
}

export const useSessionStore = () => useContext(SessionContext)
