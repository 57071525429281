import React, { useState, useEffect } from 'react'
import { sendTestScore } from '../../../../lib/api'
import ToastNotification from '../../../ToastNotification'
import { StandardButton } from '../../../../ui-components/buttons/buttons'
import { ButtonWrapper, ScoringSection, ScoredWrapper } from './styles'
import { TextField } from '../../../../ui-components/inputs/inputs'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import * as COPY from './copy'

const RANScoringSection = ({ scoringData }) => {
  const { questionId, numOfErrors, evalTime, isInvalid } = scoringData
  const [isEditing, setIsEditing] = useState(true)
  const [evaluationTime, setEvaluationTime] = useState('')
  const [savedEvaluationTime, setSavedEvaluationTime] = useState('')
  const [errorCount, setErrorCount] = useState('')
  const [savedErrorCount, setSavedErrorCount] = useState('')
  const [alert, setAlert] = useState({ isOpen: false, type: '', message: '' })
  const [isScored, setIsScored] = useState(false)
  const [isResponseInvalid, setIsResponseInvalid] = useState(false)
  const [savedIsResponseInvalid, setSavedIsResponseInvalid] = useState(false)

  const isNumericNonNegative = (number) => {
    number = parseInt(number)
    return Number.isInteger(number) && number >= 0
  }

  useEffect(() => {
    if (evalTime || isInvalid || isInvalid === false) {
      setIsResponseInvalid(isInvalid)
      setSavedIsResponseInvalid(isInvalid)

      const initEvalTime = isInvalid ? '' : evalTime
      setEvaluationTime(initEvalTime)
      setSavedEvaluationTime(initEvalTime)

      const initNumErrors = isInvalid ? '' : numOfErrors
      setErrorCount(initNumErrors)
      setSavedErrorCount(initNumErrors)

      setIsEditing(false)
      setIsScored(true)
    }
  }, [evalTime, numOfErrors, isInvalid])

  const onAlert = (type, message) => {
    setAlert({ isOpen: true, type: type, message: message })
  }

  const restoreStateToSavedValues = () => {
    setIsEditing(true)
    setEvaluationTime(savedEvaluationTime)
    setErrorCount(savedErrorCount)
  }

  const isEvaluationTimeValid = isNumericNonNegative(evaluationTime)
  const isErrorCountValid = errorCount ? isNumericNonNegative(errorCount) : true

  const saveScoring = async () => {
    try {
      if ((isEvaluationTimeValid && isErrorCountValid) || isResponseInvalid) {
        const payload = {
          score: 1,
          evalResponseTime: isResponseInvalid ? 0 : evaluationTime,
          numOfErrors: errorCount === '' || isResponseInvalid ? null : errorCount,
          invalid: isResponseInvalid
        }
        await sendTestScore(questionId, payload)
        setIsScored(true)
        setSavedErrorCount(errorCount)
        setSavedEvaluationTime(evaluationTime)
        setSavedIsResponseInvalid(isResponseInvalid)
        onAlert('success', COPY.RAN_SCORING_SAVED_SUCCESS)
        setIsEditing(false)
      } else if (!isEvaluationTimeValid) {
        setIsEditing(true)
        onAlert('error', COPY.RAN_SCORING_TIME_ERROR)
      } else {
        setIsEditing(true)
        onAlert('error', COPY.RAN_SCORING_ERROR_COUNT_ERROR)
      }
    } catch (err) {
      restoreStateToSavedValues()
      onAlert('error', COPY.RAN_SCORING_SAVE_ERROR)
    }
  }

  return (
    <ScoringSection>
      {isEditing ? (
        <div>
          <TextField
            id='timeTextInput'
            required
            InputLabelProps={{
              shrink: true
            }}
            label='Scored Response Time (secs):'
            type='number'
            inputProps={{ min: 0 }}
            value={evaluationTime}
            onChange={(event) => setEvaluationTime(event.target.value)}
            disabled={isResponseInvalid}
          />
          <TextField
            id='errorTextInput'
            InputLabelProps={{
              shrink: true
            }}
            label='Num. of Errors'
            type='number'
            inputProps={{ min: 0 }}
            value={errorCount}
            onChange={(event) => setErrorCount(event.target.value)}
            disabled={isResponseInvalid}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={isResponseInvalid}
                onChange={() => {
                  setIsResponseInvalid(!isResponseInvalid)
                  setErrorCount('')
                  setEvaluationTime('')
                }}
              />
            }
            label='Invalid'
          />
          <ButtonWrapper>
            <StandardButton
              onClick={() => {
                saveScoring()
              }}
            >
              Save
            </StandardButton>
            {isScored && (
              <StandardButton
                onClick={() => {
                  setErrorCount(savedErrorCount)
                  setEvaluationTime(savedEvaluationTime)
                  setIsEditing(false)
                }}
              >
                Cancel
              </StandardButton>
            )}
          </ButtonWrapper>
        </div>
      ) : (
        <>
          <ScoredWrapper>
            {savedIsResponseInvalid ? (
              <p>Response marked as Invalid.</p>
            ) : (
              <div>
                <p>
                  Scored Response Time: <b>{savedEvaluationTime} secs</b>
                </p>
                <p>
                  Num. of Errors: <b>{savedErrorCount === '' || savedErrorCount === null ? 'N/A' : savedErrorCount}</b>
                </p>
              </div>
            )}
            <StandardButton id='edit' onClick={() => setIsEditing(true)}>
              Edit
            </StandardButton>
          </ScoredWrapper>
        </>
      )}

      {alert.isOpen && (
        <ToastNotification
          alert={alert}
          onClose={() => {
            setAlert({ isOpen: false, type: '', message: '' })
          }}
        />
      )}
    </ScoringSection>
  )
}

export default RANScoringSection
