// @ts-check

import ReactGA from 'react-ga4'

export const initialize = () => {
  const measurementId = process.env.REACT_APP_GA4_MEASUREMENT_ID

  if (!measurementId) {
    console.warn('Analytics measurement ID was not set. Skipping analytics setup.')
    return
  }

  try {
    ReactGA.initialize(measurementId)
  } catch (err) {
    console.warn(`Analytics failed to initialize: ${err.message}. Skipping analytics setup.`)
  }
}

export const setAnalyticsUserId = (userId) => {
  // Calling set before initialize/calling set if gtag is never initialized doesn't
  // seem to cause and problems, so no additional error handling needed here.
  ReactGA.set({
    user_id: userId
  })
}

export const setAnalyticsUserDistrict = (districtName) => {
  ReactGA.set({
    user_properties: {
      user_organization: districtName
    }
  })
}
