import React from 'react'
import { Header, UnauthorizedWrapper, PageLink } from './styles'
import { UNAUTHORIZED_MSG } from './copy'

const Unauthorized = () => {
  return (
    <UnauthorizedWrapper>
      <Header>{UNAUTHORIZED_MSG}</Header>
      <PageLink to='/'>Return home!</PageLink>
    </UnauthorizedWrapper>
  )
}

export default Unauthorized
