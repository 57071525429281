import * as GRADE from 'constants/grades'
import { BOY, MOY, EOY } from 'constants/timePeriods'

export const RISK_FLAG_BASE =
  'This flag indicates the likelihood the student will be at risk for severe weakness in phonological skills at the end of the year, which increases the risk of developing severe word reading difficulties including dyslexia, presuming the student does not receive appropriate, evidence-based remediation.'
export const RISK_FLAG_BASE_RER =
  'This flag indicates the likelihood the student will be at risk for severe word reading difficulties including dyslexia, presuming the student does not receive appropriate, evidence-based remediation.'
export const RISK_FLAG_SUBTEST_INTRO = 'This flag is driven by an algorithm reflective of the student scores in'
export const RISK_FLAG_SINGLE_SUBTEST_INTRO = 'This flag is driven by an algorithm reflective of the student scores in'
export const RISK_FLAG_MULTI_SUBTEST_INTRO =
  'This flag is driven by an algorithm reflective of the student scores for these subtests in each grade:'
export const RISK_FLAG_SUBTEST_STRINGS = {
  [BOY]: {
    [GRADE.KINDERGARTEN]: 'Rhyming, Nonword Repetition, and Follow Directions',
    [GRADE.FIRST]: 'Word Reading',
    [GRADE.SECOND]: 'Word Reading'
  },
  [MOY]: {
    [GRADE.KINDERGARTEN]: 'Rhyming, Nonword Repetition, and Follow Directions',
    [GRADE.FIRST]: 'Word Reading',
    [GRADE.SECOND]: 'Word Reading'
  },
  [EOY]: {
    [GRADE.KINDERGARTEN]: 'Rhyming, Nonword Repetition, and Follow Directions',
    [GRADE.FIRST]: 'Word Reading',
    [GRADE.SECOND]: 'Word Reading'
  }
}

export const RISK_FLAG_INFO = {
  [BOY]: {
    [GRADE.KINDERGARTEN]:
      RISK_FLAG_BASE + '\n\n' + `${RISK_FLAG_SUBTEST_INTRO} ${RISK_FLAG_SUBTEST_STRINGS[BOY][GRADE.KINDERGARTEN]}.`,
    [GRADE.FIRST]:
      RISK_FLAG_BASE_RER + '\n\n' + `${RISK_FLAG_SINGLE_SUBTEST_INTRO} ${RISK_FLAG_SUBTEST_STRINGS[BOY][GRADE.FIRST]}.`,
    [GRADE.SECOND]:
      RISK_FLAG_BASE_RER +
      '\n\n' +
      `${RISK_FLAG_SINGLE_SUBTEST_INTRO} ${RISK_FLAG_SUBTEST_STRINGS[BOY][GRADE.SECOND]}.`,
    [GRADE.MULTI]:
      RISK_FLAG_BASE +
      '\n\n' +
      `For Kindergarten, this flag is driven by an algorithm reflective of the student scores in ${
        RISK_FLAG_SUBTEST_STRINGS[BOY][GRADE.KINDERGARTEN]
      }.`
  },
  [MOY]: {
    [GRADE.KINDERGARTEN]:
      RISK_FLAG_BASE + '\n\n' + `${RISK_FLAG_SUBTEST_INTRO} ${RISK_FLAG_SUBTEST_STRINGS[MOY][GRADE.KINDERGARTEN]}.`,
    [GRADE.FIRST]:
      RISK_FLAG_BASE_RER + '\n\n' + `${RISK_FLAG_SINGLE_SUBTEST_INTRO} ${RISK_FLAG_SUBTEST_STRINGS[MOY][GRADE.FIRST]}.`,
    [GRADE.SECOND]:
      RISK_FLAG_BASE_RER +
      '\n\n' +
      `${RISK_FLAG_SINGLE_SUBTEST_INTRO} ${RISK_FLAG_SUBTEST_STRINGS[MOY][GRADE.SECOND]}.`,
    [GRADE.MULTI]:
      RISK_FLAG_BASE +
      '\n\n' +
      RISK_FLAG_MULTI_SUBTEST_INTRO +
      '\n\n' +
      `\u2022 Kindergarten: ${RISK_FLAG_SUBTEST_STRINGS[MOY][GRADE.KINDERGARTEN]}` +
      '\n' +
      `\u2022 1st Grade: ${RISK_FLAG_SUBTEST_STRINGS[MOY][GRADE.FIRST]}`
  },
  [EOY]: {
    [GRADE.KINDERGARTEN]:
      RISK_FLAG_BASE + '\n\n' + `${RISK_FLAG_SUBTEST_INTRO} ${RISK_FLAG_SUBTEST_STRINGS[EOY][GRADE.KINDERGARTEN]}.`,
    [GRADE.FIRST]:
      RISK_FLAG_BASE_RER + '\n\n' + `${RISK_FLAG_SINGLE_SUBTEST_INTRO} ${RISK_FLAG_SUBTEST_STRINGS[EOY][GRADE.FIRST]}.`,
    [GRADE.SECOND]:
      RISK_FLAG_BASE_RER +
      '\n\n' +
      `${RISK_FLAG_SINGLE_SUBTEST_INTRO} ${RISK_FLAG_SUBTEST_STRINGS[EOY][GRADE.SECOND]}.`,
    [GRADE.MULTI]: RISK_FLAG_BASE
  }
}
