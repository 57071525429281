import React, { useState, useEffect, useCallback } from 'react'
import { getSuperAdmins } from '../../../../lib/api'
import { actionTypes, useSessionStore } from '../../../../stores/SessionStore'
import { useAuth } from 'auth'
import * as COPY from '../../copy'
import Table from '../../../../components/Table'
import { TableWrapper } from '../../../../ui-components/tabs'
import { PriorityText } from '../../../../ui-components/cells'
import { createSuperAdminRowData } from './helper'
import { alphanumericSort } from '../../../../utils/sorts'

const SuperAdminTab = ({ onAlert }) => {
  const { signOut } = useAuth()
  const { dispatch } = useSessionStore()
  const [superAdmins, setSuperAdmins] = useState([])
  const [loading, setLoading] = useState({ isComplete: false, hasError: false })

  const loadSuperAdmins = useCallback(async () => {
    try {
      const admins = await getSuperAdmins()
      setSuperAdmins(admins)
      setLoading({ isComplete: true, hasError: false })
    } catch (err) {
      // timeout error
      if (err.code === 401) {
        await signOut()
        dispatch({ type: actionTypes.LOGOUT })
      }
      setLoading({ isComplete: true, hasError: true })
      onAlert('error', COPY.SUPERADMIN_LOAD_ERROR)
    }
  }, [onAlert, dispatch, signOut])

  useEffect(() => {
    if (loading.hasError) return

    loadSuperAdmins()
  }, [loadSuperAdmins, loading.hasError])

  let superAdminRows = superAdmins.map((superAdmin) => createSuperAdminRowData(superAdmin))

  superAdminRows = alphanumericSort(superAdminRows, 'superAdmin')

  const superAdminColumns = [
    { title: 'Name', key: 'superAdmin', customCell: PriorityText },
    { title: 'Email', key: 'email' }
  ]

  return (
    <TableWrapper>
      <Table rows={superAdminRows} columns={superAdminColumns} />
    </TableWrapper>
  )
}

export default SuperAdminTab
