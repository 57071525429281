import { RouterLink } from '../../../../ui-components/cells'
import * as COPY from '../../../../constants/copy'
import * as ROLE from '../../../../constants/roles'
import EditCell from '../../../../components/EditCell'
import { deleteClassroom } from '../../../../lib/api'

export const hasManagePermissions = (role) => {
  return [ROLE.SUPER_ADMIN, ROLE.DISTRICT_ADMIN, ROLE.SCHOOL_ADMIN].includes(role)
}

export const getClassroomColumns = (role) => {
  const classroomColumns = [
    { title: 'Name', key: 'classroom', customCell: RouterLink },
    { title: 'Grades', key: 'grades' },
    { title: 'Teachers', key: 'teachers' }
  ]
  if (hasManagePermissions(role)) {
    classroomColumns.push({ title: 'Edit', key: 'edit', customCell: EditCell })
  }

  return classroomColumns
}

const formatTeachers = (classroom) => {
  const hasTeacher = classroom.User.length
  return hasTeacher
    ? classroom.User.map((teacher) => `${teacher.firstName} ${teacher.lastName}`).join(', ')
    : COPY.NO_TEACHER_ASSIGNED
}

const getClassroomGrades = (classroom) => {
  const grades = {}
  for (const student of classroom.Participant) {
    grades[student.grade] = (grades[student.grade] || 0) + 1
  }
  const sortedGradeValuePairs = Object.entries(grades).sort((a, b) => b[1] - a[1])
  const sortedGrades = sortedGradeValuePairs
    .map((pair) => pair[0])
    .map((gr) => (gr === '1' ? '1st' : gr === '2' ? '2nd' : gr))
    .join(', ')
  return sortedGrades
}

export const createClassroomData = (classroom, role, onEdit, onAlert) => {
  const classroomData = {
    classroom: { name: classroom.name, id: classroom.participantGroupId, key: 'classroom' },
    grades: getClassroomGrades(classroom),
    teachers: formatTeachers(classroom)
  }
  if (hasManagePermissions(role)) {
    classroomData.edit = {
      onEdit: () => onEdit(classroom),
      deleteData: {
        itemId: classroom.participantGroupId,
        itemName: classroom.name,
        onDelete: deleteClassroom,
        actionTitle: 'Delete',
        onAlert: onAlert,
        type: 'classroom'
      }
    }
  }
  return classroomData
}
