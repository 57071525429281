import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import ToastNotification from '../../components/ToastNotification'
import TabContext from '@material-ui/lab/TabContext'
import { Tab, TabList, TabPanel, TabWrapper } from '../../ui-components/tabs'
import SuperAdminTab from './components/SuperAdminTab'
import DistrictTab from './components/DistrictTab'
import { useSessionStore, actionTypes } from '../../stores/SessionStore'
import * as ROLE from '../../constants/roles'
import Unauthorized from '../../components/Unauthorized'
import * as COPY from './copy'

const SUPER_ADMIN_TAB = 'superAdmin'
const DISTRICT_TAB = 'district'

const SuperAdminView = () => {
  const { state, dispatch } = useSessionStore()
  const { role, viewHeader } = state
  const [alert, setAlert] = useState({ isOpen: false, type: '', message: '' })
  const [currTab, setCurrTab] = useState(DISTRICT_TAB)

  useEffect(() => {
    if (viewHeader !== COPY.VIEW_HEADER) {
      dispatch({ type: actionTypes.UPDATE_FIELD, field: 'viewHeader', data: COPY.VIEW_HEADER })
    }
  }, [viewHeader, dispatch])

  const handleTabChange = (_, newValue) => {
    setCurrTab(newValue)
  }

  const onAlert = (type, message) => {
    setAlert({ isOpen: true, type: type, message: message })
  }

  if (role !== ROLE.SUPER_ADMIN) {
    return <Unauthorized />
  }

  const isViewLoaded = viewHeader === COPY.VIEW_HEADER

  return (
    <div>
      {isViewLoaded && (
        <TabWrapper>
          <Grid container justify='center' spacing={2}>
            <Grid item xs={11}>
              <TabContext value={currTab}>
                <TabList onChange={handleTabChange}>
                  <Tab label='Districts' value={DISTRICT_TAB} />
                  <Tab label='Super Admins' value={SUPER_ADMIN_TAB} />
                </TabList>

                <TabPanel value={DISTRICT_TAB}>
                  <DistrictTab role={role} onAlert={onAlert} />
                </TabPanel>

                <TabPanel value={SUPER_ADMIN_TAB}>
                  <SuperAdminTab onAlert={onAlert} />
                </TabPanel>
              </TabContext>
            </Grid>
          </Grid>
        </TabWrapper>
      )}
      <div>
        {alert.isOpen && (
          <ToastNotification
            alert={alert}
            onClose={() => {
              setAlert({ isOpen: false, type: '', message: '' })
            }}
          />
        )}
      </div>
    </div>
  )
}

export default SuperAdminView
