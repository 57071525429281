/* eslint indent: off */
import React, { useEffect, useState } from 'react'
import FormDialog from 'components/FormDialog'
import { getSchoolsByDistrict, createUser, updateUser } from 'lib/api'
import { useSessionStore } from 'stores/SessionStore'
import { buildUserPayload } from './helper'
import * as ROLE from 'constants/roles'
import * as COPY from './copy'
import posthog from 'posthog-js'

const DISTRICT_ADMIN_OPTION = {
  label: ROLE.DISPLAY_DISTRICT_ADMIN,
  value: ROLE.DISTRICT_ADMIN_ROLE_ID,
  key: ROLE.DISTRICT_ADMIN_ROLE_ID
}
const SCHOOL_ADMIN_OPTION = {
  label: ROLE.DISPLAY_SCHOOL_ADMIN,
  value: ROLE.SCHOOL_ADMIN_ROLE_ID,
  key: ROLE.SCHOOL_ADMIN_ROLE_ID
}
const TEACHER_OPTION = {
  label: ROLE.DISPLAY_TEACHER,
  value: ROLE.TEACHER_ROLE_ID,
  key: ROLE.TEACHER_ROLE_ID
}

const DistrictUserDialog = ({ user = null, districtId, onAction, onClose, onAlert }) => {
  const { state } = useSessionStore()
  const { role } = state

  const [schoolOptions, setSchoolOptions] = useState(null)
  const [isLoaded, setIsLoaded] = useState(false)

  const createSchoolOption = (school) => {
    return { key: school.organizationUnitId, value: school.organizationUnitId, label: school.name }
  }

  // fetch available schools for a user to be assigned to
  useEffect(() => {
    ;(async () => {
      try {
        const schools = await getSchoolsByDistrict(districtId)
        const options = schools.OrganizationUnits.map(createSchoolOption)
        setSchoolOptions(options)

        setIsLoaded(true)
      } catch (err) {
        onAlert('error', COPY.FETCH_SCHOOL_ERROR)
      }
    })()
  }, [districtId, onAlert])

  // check role of logged in user to determine which user types can be created
  const getRoleOptions = () => {
    return [ROLE.SUPER_ADMIN, ROLE.DISTRICT_ADMIN].includes(role)
      ? [DISTRICT_ADMIN_OPTION, SCHOOL_ADMIN_OPTION, TEACHER_OPTION]
      : [SCHOOL_ADMIN_OPTION, TEACHER_OPTION]
  }

  const fields = isLoaded
    ? [
        [
          {
            key: 'firstName',
            label: 'First Name',
            isRequired: true,
            type: 'text',
            defaultValue: user ? user.firstName : ''
          },
          {
            key: 'lastName',
            label: 'Last Name',
            isRequired: true,
            type: 'text',
            defaultValue: user ? user.lastName : ''
          }
        ],
        [
          {
            key: 'email',
            label: 'Email',
            isRequired: true,
            type: 'text',
            defaultValue: user ? user.email : '',
            isDisabled: Boolean(user), // enabled during creation, disabled during edit
            toolTipText: user ? COPY.DISABLED_EMAIL_TOOLTIP : null
          }
        ],
        [
          {
            key: 'role',
            label: 'Role',
            isRequired: true,
            type: 'select',
            defaultValue: user ? user.Role[0]?.roleId : '',
            options: getRoleOptions(),
            toolTipText: user ? COPY.DISABLED_EMAIL_TOOLTIP : null
          }
        ],
        [
          {
            key: 'schools',
            label: 'School(s)',
            isRequired: true,
            type: 'multiSelect',
            defaultValue: user ? user.OrganizationUnits.map(createSchoolOption) : null,
            options: schoolOptions,
            conditionalRenderCheck: (fieldValues) =>
              [ROLE.SCHOOL_ADMIN_ROLE_ID, ROLE.TEACHER_ROLE_ID].includes(fieldValues.role)
          }
        ]
      ]
    : []

  const updateOrCreateUser = async (fieldValues) => {
    const payload = buildUserPayload(user, districtId, fieldValues)
    if (user) {
      await updateUser(payload)
    } else {
      await createUser(payload)
    }
    await onAction()
    const successMsg = user ? COPY.USER_UPDATE_SUCCESS : COPY.USER_CREATE_SUCCESS
    onAlert('success', successMsg)
    posthog.capture(user ? 'Edited user' : 'Created user', { userRole: role })
  }

  return (
    <div>
      {isLoaded && (
        <FormDialog
          fields={fields}
          multiCol={true}
          header={`${user ? 'Update' : 'Create'} User`}
          actionTitle={user ? 'Update' : 'Create'}
          onAction={updateOrCreateUser}
          onClose={onClose}
        />
      )}
    </div>
  )
}

export default DistrictUserDialog
