import { request } from '../helper'

export const getDistrict = async (districtId) => {
  const url = `/organization/${districtId}`
  return await request('GET', url)
}

export const getDistricts = async () => {
  const url = '/organization'
  return await request('GET', url)
}

export const createDistrict = async (payload) => {
  const url = '/organization'
  return await request('POST', url, payload)
}

export const updateDistrict = async (payload) => {
  const url = '/organization'
  return await request('PUT', url, payload)
}

export const deleteDistrict = async (districtId) => {
  const url = `/organization/${districtId}`
  return await request('DELETE', url)
}

export const getCurrDistrictAssmntConfig = async (districtId) => {
  const url = `/assessmentConfig/currentAcademicYear/${districtId}`
  return await request('GET', url)
}

export const getDistrictCompleteReport = async (districtId, timePeriod) => {
  const url = '/report/students-complete'
  const payload = { orgId: districtId, timePeriod }
  let response
  try {
    response = await request('POST', url, payload)
  } catch (err) {
    console.log(err)
  }
  return response
}
