import { RouterLink } from '../../../../ui-components/cells'
import { getFormattedDate } from '../../../../utils/dates'
import { hasDeletePermission, getStudentTeacher, hasManagePermission } from '../../../../utils/students'
import EditCell from '../../../../components/EditCell'
import { deleteStudent } from '../../../../lib/api'

export const getStudentColumns = (role) => {
  const studentColumns = [
    { key: 'student', title: 'Name', customCell: RouterLink },
    { key: 'participantCode', title: 'Participant Code' },
    { key: 'dob', title: 'Date of Birth' },
    { key: 'teacher', title: 'Teachers' }
  ]
  if (hasManagePermission(role)) {
    studentColumns.push({ title: 'Edit', key: 'edit', customCell: EditCell })
  }

  return studentColumns
}

export const createStudentData = (student, role, onEdit, onAlert) => {
  const studentData = {
    student: { name: `${student.lastName}, ${student.firstName}`, id: student.participantId, key: 'student' },
    participantCode: student.participantCode,
    dob: getFormattedDate(student.dateOfBirth),
    teacher: getStudentTeacher(student)
  }

  if (hasManagePermission(role)) {
    studentData.edit = {
      onEdit: () => onEdit(student)
    }
  }

  if (hasDeletePermission(role)) {
    studentData.edit.deleteData = {
      itemId: student.participantId,
      itemName: `${student.firstName} ${student.lastName}`,
      onDelete: deleteStudent,
      actionTitle: 'Delete',
      onAlert: onAlert,
      userWarning: true,
      type: 'student'
    }
  }

  return studentData
}

export const canCreateStudent = (role, hasClassrooms, isTeacher) => {
  if (isTeacher && hasManagePermission(role) && hasClassrooms) {
    // if the user is a teacher, has manage permissions, and has classrooms, display button
    return true
  } else if (isTeacher && hasManagePermission(role) && !hasClassrooms) {
    // if the user is a teacher, has manage permissions, but has no classrooms, do not display button
    return false
  } else if (!isTeacher && hasManagePermission(role)) {
    // if the user is not a teacher and has manage permissions, display button
    return true
  }
}
