import styled from 'styled-components'
import { TableCell as InventoryTableCell } from 'components/ItemInventoryV2/ui-components/tables'
import { Popover as MaterialPopover } from '@mui/material'
import * as C from 'constants/colors'

export const TableCell = styled(InventoryTableCell)`
  &.edited {
    box-shadow: 0 0 0 2px ${C.ITEM_INVENTORY_EDITED_BORDER} inset;
  }

  &.edited.correct {
    background-color: ${C.ITEM_INVENTORY_SELECTED_BG};
  }

  &.correct,
  &.incorrect,
  &.edited {
    &:hover {
      box-shadow: 0 0 0 2px ${C.ITEM_INVENTORY_HOVER_BORDER} inset;
    }
  }
`

export const Popover = styled(MaterialPopover)`
  & .MuiPaper-root {
    min-width: 19.375rem;
    border: solid 2px ${C.ITEM_INVENTORY_HOVER_BORDER};
    background-color: ${C.ITEM_INVENTORY_SELECTED_BG};
    border-radius: 0;
  }
`
export const PopoverWrapper = styled.div`
  margin: 0.5rem;
  font-size: 15px;
  color: ${C.ITEM_INVENTORY_TEXT};
`

export const PopoverHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${C.ITEM_INVENTORY_POPOVER_HEADER};
  height: 2.8rem;
`

export const PopoverTestName = styled.div`
  font-family: Quicksand;
  font-weight: 600;
`

export const PopoverItem = styled.div`
  font-family: Lexend;
  font-weight: 600;
  margin-left: 0.5rem;
`

export const PopoverBody = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.8rem;
  font-family: Inter;
  font-weight: 400;
`
