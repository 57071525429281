import React, { useState, useEffect, useCallback } from 'react'
import { getDistricts } from '../../../../lib/api'
import * as COPY from '../../copy'
import Table from '../../../../components/Table'
import { createDistrictRowData, getDistrictColumns, hasManagePermission } from './helper'
import DistrictDialog from '../../../../components/DistrictDialog'
import { TableWrapper, CreateButton } from '../../../../ui-components/tabs'
import { alphanumericSort } from '../../../../utils/sorts'
import { actionTypes, useSessionStore } from '../../../../stores/SessionStore'
import { useAuth } from 'auth'
import { ButtonLinkWrapper } from './styles'
// todo - when full district report is ready in the api, comment in
// import { ButtonLink } from '../../../../ui-components/buttons/buttons'
// import GetAppIcon from '@material-ui/icons/GetApp'

const DistrictTab = ({ role, onAlert }) => {
  const { signOut } = useAuth()
  const { dispatch } = useSessionStore()
  const [districts, setDistricts] = useState([])
  const [districtDialog, setDistrictDialog] = useState({ isOpen: false, district: null })
  const [loading, setLoading] = useState({ isComplete: false, hasError: false })

  const loadDistricts = useCallback(async () => {
    try {
      const allDistricts = await getDistricts()
      setDistricts(allDistricts)
      setLoading({ isComplete: true, hasError: false })
    } catch (err) {
      // timeout error
      if (err.code === 401) {
        await signOut()
        dispatch({ type: actionTypes.LOGOUT })
      }
      setLoading({ isComplete: true, hasError: true })
      onAlert('error', COPY.DISTRICT_LOAD_ERROR)
      return null
    }
  }, [onAlert, dispatch, signOut])

  useEffect(() => {
    if (loading.hasError) return

    let isMounted = true
    ;(async () => {
      const districts = await loadDistricts()

      if (isMounted && districts) {
        setDistricts(districts)
      }
    })()
    return () => {
      isMounted = false
    }
  }, [loadDistricts, loading.hasError])

  const onEdit = (district) => {
    setDistrictDialog({ isOpen: true, district: district })
  }

  const districtColumns = getDistrictColumns(role)

  let districtRows = districts.map((district) => createDistrictRowData(district, role, onEdit, onAlert))

  districtRows = alphanumericSort(districtRows, 'district', 'name')

  const onDistrictCreateOrUpdate = async () => {
    setDistrictDialog({ isOpen: false, district: null })
    // reload district to get updated list
    await loadDistricts()
  }

  return (
    <div>
      {districtDialog.isOpen && (
        <DistrictDialog
          district={districtDialog.district}
          onAction={onDistrictCreateOrUpdate}
          onClose={() => setDistrictDialog({ isOpen: false, district: null })}
          onAlert={onAlert}
        />
      )}

      <TableWrapper>
        {hasManagePermission(role) && (
          <>
            <ButtonLinkWrapper>
              <CreateButton
                id='createDistrictButton'
                data-testid='createDistrict'
                onClick={() => setDistrictDialog({ isOpen: true, district: null })}
                text='Create District'
              />

              {/* todo - when full district report is ready in the api, comment in
              <ButtonLink href={`${process.env.REACT_APP_API_BASE_URL}/organization/report`}>
                <GetAppIcon />
                Full Report
              </ButtonLink> */}
            </ButtonLinkWrapper>
          </>
        )}
        <Table id='table' rows={districtRows} columns={districtColumns} />
      </TableWrapper>
    </div>
  )
}

export default DistrictTab
