import styled from 'styled-components'
import { PageLink, AuthHeader, AuthSubHeader } from '../../ui-components/authStyles'
import MaterialAlert from '@mui/material/Alert'

export const LoginWrapper = styled.div({
  position: 'absolute',
  top: (props) => (props.bannerCount === 1 ? '6.25rem' : '11rem'),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center'
})

export const LoginHeader = styled(AuthHeader)({
  marginBottom: '1.25rem'
})

export const LoginSubHeader = styled(AuthSubHeader)({
  marginBottom: '2rem'
})

export const ForgotPasswordLink = styled(PageLink)({
  paddingTop: '1.25rem',
  fontSize: '0.9rem'
})

export const AlertWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '0.5rem'
})

export const Alert = styled(MaterialAlert)({
  marginTop: '1rem'
})
