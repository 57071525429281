/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react'
import { getTestDetails, getStudent } from '../../lib/api'
import { useParams } from 'react-router-dom'
import { useSessionStore, actionTypes } from '../../stores/SessionStore'
import Grid from '@material-ui/core/Grid'
import Breadcrumbs from './components/Breadcrumbs'
import ToastNotification from '../../components/ToastNotification'
import * as COPY from './copy'
import { OBJ_RAN_STIMULI } from '../../constants/assets'
import { getAssessmentDetails } from './helper'
import Table from '../../components/Table'
import RANScoringSection from '../../components/RANScoring/components/RANScoringSection'
import AudioPlayerCell from './components/AudioPlayerCell'
import RANScoringInstructions from './components/RANScoringInstructions'
import Accordion from '../../components/Accordion'
import { ContentView } from '../../ui-components/backgrounds'
import Unauthorized from '../../components/Unauthorized'
import { Image, ImageWrapper, Subheader } from './styles'
import { TableWrapper } from '../../ui-components/tabs'

const TestDetailsView = () => {
  const params = useParams()
  const { testId } = params
  const { state, dispatch } = useSessionStore()
  const { role, viewHeader } = state
  const [testDetails, setTestDetails] = useState({})
  const [student, setStudent] = useState({})
  const [alert, setAlert] = useState({ isOpen: false, type: '', message: '' })
  const [practiceResults, setPracticeResults] = useState([])
  const [questionResults, setQuestionResults] = useState([])
  const [loading, setLoading] = useState({ isComplete: false, hasError: false })
  const [isUnauthorized, setIsUnauthorized] = useState(false)

  useEffect(() => {
    if (loading.hasError) return

    let isMounted = true
    ;(async () => {
      try {
        const testInfo = await getTestDetails(testId)
        const studentInfo = await getStudent(testInfo.participantId)
        if (isMounted) {
          setTestDetails(testInfo)
          setStudent(studentInfo)
          setLoading({ isComplete: true, hasError: false })
        }
      } catch (err) {
        if (err.code === 403) {
          setIsUnauthorized(true)
        } else {
          setLoading({ isComplete: true, hasError: true })
          setAlert({ isOpen: true, type: 'error', message: COPY.TEST_DETAILS_LOAD_ERROR })
        }
      }
    })()
    return () => {
      isMounted = false
    }
  }, [testId, loading.hasError])

  useEffect(() => {
    if (loading.isComplete && !loading.hasError) {
      if (viewHeader !== COPY.VIEW_HEADER) {
        dispatch({ type: actionTypes.UPDATE_FIELD, field: 'viewHeader', data: COPY.VIEW_HEADER })
      }
    }
  }, [loading, viewHeader, dispatch])

  useEffect(() => {
    ;(async () => {
      try {
        const details = await getAssessmentDetails(testDetails)
        if (details) {
          setPracticeResults(details.practiceDetails)
          setQuestionResults(details.questionDetails)
        }
      } catch (err) {
        setAlert({ isOpen: true, type: 'error', message: COPY.TEST_DETAILS_LOAD_ERROR })
      }
    })()
  }, [testDetails])

  const practiceRanColumns = [
    { title: 'Audio', key: 'audio', customCell: AudioPlayerCell },
    { title: 'Response Time (secs)', key: 'responseTime' }
  ]

  const ranScoringColumns = [
    { title: 'Audio', key: 'audio', customCell: AudioPlayerCell },
    { title: 'Response Time (secs)', key: 'responseTime' },
    { title: 'Scoring', key: 'scoring', customCell: RANScoringSection }
  ]

  if (isUnauthorized) {
    return <Unauthorized />
  }

  const isViewLoaded = loading.isComplete && !loading.hasError && viewHeader === COPY.VIEW_HEADER

  return (
    <>
      {isViewLoaded && (
        <Grid container justify='center' spacing={2}>
          <Grid item xs={11}>
            <Breadcrumbs student={student} role={role} />

            <ContentView>
              <Accordion header={COPY.RAN_SCORING_INSTRUCTIONS_HEADER} body={<RANScoringInstructions />} />

              <TableWrapper>
                <Subheader>Practice Question</Subheader>
                {practiceResults.length ? (
                  <Table test='true' columns={practiceRanColumns} rows={practiceResults} />
                ) : (
                  COPY.NO_TEST_RESULTS
                )}
              </TableWrapper>

              <TableWrapper>
                <Subheader>Test Details</Subheader>
                {questionResults.length ? (
                  <Table test='true' columns={ranScoringColumns} rows={questionResults} />
                ) : (
                  COPY.NO_TEST_RESULTS
                )}
              </TableWrapper>

              <ImageWrapper>
                <Image src={OBJ_RAN_STIMULI} />
              </ImageWrapper>
            </ContentView>
          </Grid>
        </Grid>
      )}

      {alert.isOpen && (
        <ToastNotification
          alert={alert}
          onClose={() => {
            setAlert({ isOpen: false, type: '', message: '' })
          }}
        />
      )}
    </>
  )
}

export default TestDetailsView
