import * as React from 'react'
import { styled } from '@mui/material/styles'
import Switch from '@mui/material/Switch'
import {
  TOGGLE_BACKGROUND,
  TOGGLE_BORDER,
  TOGGLE_THUMB,
  TOGGLE_THUMB_BORDER,
  TOGGLE_THUMB_SHADOW,
  TOGGLE_TEXT,
  TOGGLE_TEXT_BOLD
} from '../../constants/colors'

export const CustomSwitch = styled((props) => (
  <Switch focusVisibleClassName='.Mui-focusVisible' disableRipple {...props} />
))(({ ...props }) => ({
  width: '8rem',
  height: '2.25rem',
  padding: 0,
  marginLeft: 0,
  borderRadius: '0.75rem',
  border: `1px solid ${TOGGLE_BORDER}`,
  background: TOGGLE_BACKGROUND,
  fontFamily: 'Quicksand',
  fontSize: '0.75rem',
  display: 'flex',
  alignItems: 'center',
  zIndex: 0,
  '& .MuiSwitch-switchBase': {
    padding: 3,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(103%)',
      '& + .MuiSwitch-track': {
        backgroundColor: TOGGLE_BACKGROUND,
        opacity: 1
      }
    },
    '&:hover': {
      borderRadius: '0.75rem'
    }
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: '3.5rem',
    height: '1.75rem',
    borderRadius: '0.625rem',
    border: `1px solid ${TOGGLE_THUMB_BORDER}`,
    background: TOGGLE_THUMB,
    position: 'relative',
    zIndex: -1,
    opacity: props.content.isDisabled ? 0.55 : 1,
    boxShadow: `0px 0px 8px 1px ${TOGGLE_THUMB_SHADOW}`
  },
  '& .MuiSwitch-track': {
    backgroundColor: TOGGLE_BACKGROUND,
    borderRadius: '0.75rem',
    opacity: 1,
    transitionDuration: 500
  },
  '&:before, &:after': {
    content: '""',
    position: 'absolute',
    zIndex: 4
  },
  '&:before': {
    content: `"${props.content.before}"`,
    left: '10%',
    fontSize: '0.75rem',
    fontWeight: getLabelFontWeight(!props.content.displayPercentile, props.content.isDisabled),
    textTransform: 'capitalize',
    color: getLabelColor(!props.content.displayPercentile, props.content.isDisabled)
  },
  '&:after': {
    content: `"${props.content.after}"`,
    right: '13%',
    fontSize: '0.75rem',
    fontWeight: getLabelFontWeight(props.content.displayPercentile, props.content.isDisabled),
    textTransform: 'lowercase',
    color: getLabelColor(props.content.displayPercentile, props.content.isDisabled)
  }
}))

const getLabelColor = (isSelected, isDisabled) => {
  if (!isSelected || isDisabled) {
    return TOGGLE_TEXT
  } else {
    return TOGGLE_TEXT_BOLD
  }
}

const getLabelFontWeight = (isSelected, isDisabled) => {
  if (!isSelected || isDisabled) {
    return 600
  } else {
    return 700
  }
}
