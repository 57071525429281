export const getScoreCellClasses = (timePeriod, selectedTimePeriod, index, groupCount) => {
  // For purposes of printing, we'll ignore the styles
  // related to highlighting the current time period
  // Leaving this here however as a reference in case
  // we need to do something similar in the report.

  // const isSelected = timePeriod === selectedTimePeriod

  // if (isSelected) {
  //   const isFirstInSelectedGroup = index === 0
  //   const isLastInSelectedGroup = index === groupCount - 1

  //   return `
  //     selectedTimePeriod
  //     selected-${timePeriod}
  //     ${isFirstInSelectedGroup && 'firstInSelectedGroup'}
  //     ${isLastInSelectedGroup && 'lastInSelectedGroup'}
  //   `
  // }

  return ''
}
