import React from 'react'
import { TableContainer, Table, TableRow, TableCell, TextField } from './styles'
import { ReactComponent as CorrectMark } from './Correct.svg'
import { ReactComponent as IncorrectMark } from './Incorrect.svg'

const tableCellStyles = {
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center'
}

const tableCellContent = (item) => {
  if (item === 1) {
    return (
      <div style={tableCellStyles}>
        <CorrectMark width={14} height={10} />
      </div>
    )
  }
  if (item === 0) {
    return (
      <div style={tableCellStyles}>
        <IncorrectMark width={12} height={12} />
      </div>
    )
  }
  return null
}
const InventoryTable = ({ data }) => {
  return (
    <TableContainer>
      <Table>
        <TableRow>
          <TableCell className='titleHeader'>
            <TextField className='titleText'>{data.headers[0]}</TextField>
          </TableCell>
          {data.headers.slice(1).map((item, colIdx) => {
            return (
              <TableCell key={colIdx} className='letterHeader'>
                <TextField className='letterText'>{item}</TextField>
              </TableCell>
            )
          })}
        </TableRow>
        {data.scores.map((row, rowIdx) => (
          <TableRow key={rowIdx}>
            <TableCell className='titleBody'>
              <TextField className='titleText'>{row[0]}</TextField>
            </TableCell>
            {row.slice(1).map((item, colIdx) => (
              <TableCell key={colIdx} className={`body ${item === 1 ? 'correct' : item === 0 ? 'incorrect' : 'null'}`}>
                {tableCellContent(item)}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </Table>
    </TableContainer>
  )
}

export default InventoryTable
