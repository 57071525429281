import styled from 'styled-components'
import * as COLOR from '../../constants/colors'

export const FormContent = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  marginTop: '2.4rem'
})

export const InputWrapper = styled.div({
  margin: '1.375rem 0rem 1.75rem 0rem'
})

export const ResetWrapper = styled.div({
  margin: '2rem auto 0 auto',
  color: COLOR.FORGOT_PWD_TEXT
})

export const BackWrapper = styled.div({
  marginTop: '1.2rem'
})

export const InstructionsText = styled.div({
  color: COLOR.FORGOT_PWD_TEXT,
  fontFamily: 'Inter',
  fontWeight: 500,
  fontSize: '1rem',
  marginBottom: '1rem'
})
