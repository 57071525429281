import { filterAssessmentGroups } from 'utils/assessments'
import { BOY, MOY, EOY, timePeriodsToDate } from 'constants/timePeriods'
import { PWR, SINGLE_COL_PWR, DYSLEXIA, KREADY, LETTER_NAMES_ID, LETTER_SOUNDS_ID } from 'constants/assessments'
import * as RULES from 'constants/displayRules'

export const getAssessmentGroups = (timePeriodAssmntLists) => {
  if (timePeriodAssmntLists) {
    const getAssessments = (timePeriod) => timePeriodAssmntLists[timePeriod].split(',').map((el) => el.trim())
    // gets list of assessments that will be taken across all time periods
    const assessments = [...new Set([...getAssessments(BOY), ...getAssessments(MOY), ...getAssessments(EOY)])]
    return filterAssessmentGroups(assessments)
  }
  return []
}

// isApplicableRiskScore determines if the specified risk score type is applicable to the student's grade and specified time periods
export const isApplicableRiskScore = (key, grade, timePeriods) => {
  // gets the display rule for each time period based on the student's grade
  const displayRule = RULES.DISPLAY_RULES[grade][key]
  const timePeriodRules = timePeriods.map((timePeriod) => displayRule[timePeriod])
  const ruleSet = [...new Set(timePeriodRules)]
  // not applicable if all display rules are null - should not be displayed
  const isApplicable = !(ruleSet.length === 1 && ruleSet[0] === null)
  return isApplicable
}

// getPredictiveProfileRows determines which risk scores (dyslexia, pwr, single col pwr, kreadiness) to display
// only returns risk scores that are relevant to the specified grade and time periods
export const getPredictiveProfileRows = (grade, timePeriods) => {
  const rows = [] // rows to be displayed in predictive profile
  const predictiveProfileKeys = [DYSLEXIA, PWR, KREADY, SINGLE_COL_PWR]

  predictiveProfileKeys.forEach((key) => {
    if (isApplicableRiskScore(key, grade, timePeriods)) {
      rows.push(key)
    }
  })
  return rows
}

// getScoringUnits returns an array of all assessment scoring units that are relevant to the specified grade and time period
export const getScoringUnits = (assessmentGroups, grade, timePeriod) => {
  const units = new Set()
  assessmentGroups.forEach((group) => {
    group.assessments.forEach((assessment) => {
      timePeriodsToDate[timePeriod].forEach((period) => {
        const unit = RULES.DISPLAY_RULES[grade][assessment.key][period]
        if (unit && unit !== RULES.TBD) {
          units.add(unit)
        }
      })
    })
  })

  if (!units.size) {
    return []
  }

  return [...units]
}

export const getScoreCellClasses = (timePeriod, selectedTimePeriod, index, groupCount) => {
  const isSelected = timePeriod === selectedTimePeriod

  if (isSelected) {
    const isFirstInSelectedGroup = index === 0
    const isLastInSelectedGroup = index === groupCount - 1

    return `
      selectedTimePeriod
      selected-${timePeriod}
      ${isFirstInSelectedGroup && 'firstInSelectedGroup'}
      ${isLastInSelectedGroup && 'lastInSelectedGroup'}
    `
  }

  return ''
}

// studentHasScoredLNLS returns true if student has at least one LN or LS score across all time periods
export const studentHasScoredLNLS = (studentResultsByTimePeriod) => {
  const testResults = [
    ...studentResultsByTimePeriod[BOY],
    ...studentResultsByTimePeriod[MOY],
    ...studentResultsByTimePeriod[EOY]
  ]
  for (const result of testResults) {
    if ([LETTER_NAMES_ID, LETTER_SOUNDS_ID].includes(result?.testCode) && result?.completedFlag) {
      return true
    }
  }
  return false
}
