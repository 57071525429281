import React, { useState } from 'react'
import Dialog from 'components/Dialog'
import InventoryHeader from '../InventoryHeader'
import RatioScore from '../RatioScore'
import EditableInventoryTable from '../EditableInventoryTable'
import { HeaderContainer } from './styles'
import { LETTER_SOUNDS_ID } from 'constants/assessments'
import { getQuestionCode } from 'components/ItemInventoryV2/utils'

const EditModeContent = ({ testId, scoresByCode, totalItems, studentName }) => {
  const [editedScores, setEditedScores] = useState(scoresByCode)

  const onScoreClick = (item) => {
    const code = getQuestionCode(testId, item)
    setEditedScores({ ...editedScores, [code]: editedScores[code] ? 0 : 1 })
  }

  // getCorrectItemsCount returns the number of items marked as correct based on the edits made to the table.
  // Because an item's score is always 0 or 1, all the scores can be added together to get the total correct.
  const getCorrectItemsCount = () => {
    return Object.values(editedScores).reduce((a, b) => a + b)
  }

  // areScoresEdited compares the original scores and edited scores to determine if any scores have been edited
  const areScoresEdited = () => {
    for (const questionCode of Object.keys(scoresByCode)) {
      if (scoresByCode[questionCode] !== editedScores[questionCode]) {
        return true
      }
    }
    return false
  }

  return (
    <>
      <HeaderContainer>
        <InventoryHeader studentName={studentName} />
        <RatioScore
          testName={testId === LETTER_SOUNDS_ID ? 'Letter Sound' : 'Letter Name'}
          correctItems={getCorrectItemsCount()}
          totalItems={totalItems}
          isEdited={areScoresEdited()}
        />
      </HeaderContainer>
      <EditableInventoryTable
        testId={testId}
        originalScores={scoresByCode}
        editedScores={editedScores}
        onScoreClick={onScoreClick}
      />
    </>
  )
}

const EditModeItemInventory = ({ testId, testData, studentName, onClose }) => {
  return (
    <Dialog
      body={
        <EditModeContent
          testId={testId}
          scoresByCode={testData.correctByCode}
          totalItems={testData.totalItems}
          studentName={studentName}
        />
      }
      actionTitle={'Close'}
      cancelTitle={null}
      onAction={() => onClose()}
    />
  )
}

export default EditModeItemInventory
